import React, { useEffect, useState } from "react";
import CustomLoader from "../../components/Loader";
import { useHistory } from "react-router";

const ErrorPage = () => {
    const [show, setShow] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 3000);
    })
    return (
        <div className="mt-5 col-10">
            {
                show == true ?
                    <div>
                        <div className="text-center">
                            <img src={require('../../assets/css/images/404.png').default} height={50} style={{ marginTop: '20%', }} />
                        </div>
                        <h3 className="text-center">Page not found</h3>
                        <div className="text-center returnText" onClick={() => history.push({
                            pathname: '/business-unit-type'
                        })}>Return to homepage</div>
                    </div> :
                    <CustomLoader isLoading={show == false ? true : false} />
            }
        </div>
    )
}
export default ErrorPage;