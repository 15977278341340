import React, { useState, useEffect } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, generatePath } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import Pagination from '../../../../components/Pagination';
import ModalPopup from '../../../../components/ModalPopUp';
import NoData from "../../../../components/NoDataFound";
import { useHistory } from "react-router";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import moment from "moment";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";

const ViewCompliances = (props) => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const [data, setData] = useState()
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    // Edit
    const [edit, setEdit] = useState('');
    const [id, setId] = useState('');
    // Toast
    const { addToast } = useToasts();
    //Goto back
    const goBack = () => {
        window.history.back();
    }
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    const history = useHistory();
    const [showChatModal, setShowChatModal] = useState(false)


    useEffect(() => {
        getContractData()
    }, [])

    const getComplianceList = (page, key, id) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract-compliance/list/${page}?contract_id=${id}&keyword=` + new_key, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            })
            .catch(error => {
                alert(error)
                setLoading(false)
            })
    }

    const getContractData = () => {
        if (props.location.state == null) {
        } else {
            setData(props.location.state.detail)
            getComplianceList(1, '', props.location.state.detail.contract_id)
        }
    }

    const nextPage = (pageNumber) => {
        getComplianceList(pageNumber)
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const Search = (key) => {
        getComplianceList(1, key)
    }


    const _onEdit = (val) => {
        history.push({
            pathname: '/edit-compliance',
            state: { details: val, rfx_data: props.location.state.rfx_data }
        })
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">View Compliance/s</h2>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mb-3" onClick={() => goBack()}>Back to contract page</button>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <NavLink to="/add-contract" className="btn btn-brand-1 bg-success px-4" onClick={goBack}>Back</NavLink>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Contract ID</label>
                                <p>{data?.contract_number}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Contract Title</label>
                                <p>{data?.contract_title}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Client Name</label>
                                <p>{data?.rfx_client_agency}</p>
                            </div>
                        </div>
                    </div>

                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">Compliance Code </th>
                                <th scope="col">Compliance Name</th>
                                <th scope="col">Compliance Due Date </th>

                                <th scope="col" className="text-center" width="110">Action</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/contract-compliance/'}
                            updateList={getComplianceList}
                        ></ModalPopup>
                        <tbody>
                            {
                                getListData.map((res) => {
                                    return (
                                        <tr>
                                            <td>{res.code}</td>
                                            <td>{res.compliance_name}</td>
                                            <td>{res.due_date ? moment(res.due_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : null}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button>
                                                <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Compliance Found'} />
                            : getListData.length === 0 && loading === false ?
                                <SearchNoDataFound message={'No Compliance Found'} /> : null
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                            />
                        </nav>
                    </div>

                </div>
            </div>


            {/* Filter Modal Box */}
            <Modal show={show} onHide={handleClose}
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-header px-md-5">
                    <h5 className="modal-title" id="">Filter Contract</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <Modal.Body>
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option>US</option>
                                    <option>CANADA</option>
                                    <option></option>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx type</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option>ComboBox</option>
                                    <option>--</option>
                                    <option></option>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Client/Agency</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Category</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option>ComboBox</option>
                                    <option>--</option>
                                    <option></option>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Title</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RFx Number</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Approval status </label>
                                <select id="disabledSelect" className="form-control custom-select ">
                                    <option>Pending</option>
                                    <option>Go</option>
                                    <option>No-Go</option>
                                    <option>Pushback</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contract Status</label>
                                <select id="disabledSelect" className="form-control custom-select ">
                                    <option>Pending</option>
                                    <option>Go</option>
                                    <option>No-Go</option>
                                    <option>Pushback</option>
                                </select>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contract Start Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contract End Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1">Save</button>
                </div>
            </Modal>
            {
                showChatModal === false && props.location.state && props.location.state.rfx_data && props.location.state.rfx_data.id && (
                    <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                        {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                        {/*<img src={chatIcon} alt="Chat Icon" />*/}
                        {/* Commenting end. Divya. October 06 2023.. */}
                        {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                        <img src={chatIcon}  className="chat-icon"/>
                        {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                        {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                        {/* Commenting end. Divya. November 14 2023.. */}
                        {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                        <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                        {/* Divya. November 14 2023. Code End */}
                        {/* Divya. October 06 2023. Code End */}
                    </div>
                )
            }

            {
                showChatModal &&
                <Chat
                    id={props.location.state.rfx_data.id}
                    title={props.location.state.rfx_data.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default ViewCompliances;