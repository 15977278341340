import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import { APIMethod } from "../API/APIClient";

const Header = (props) => {
  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const ToogleClass = () => {
    var element = document.getElementById("main-wraper");
    element.classList.toggle("toggled");
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  const getUserDetail = () => {
    var method = "GET";
    const apifetcherObj = APIMethod(`/users/me`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setUserData(data);
        }
      })
      .catch((error) => {});
  };

  const Profile = () => {
    history.push("/my-profile");
  };

  return (
    <>
      <header className="header ">
        <div className="row main-row">
          <div className="col-lg-6 col-md-9 col-6 align-self-center d-flex align-items-center pr-0">
            <img
              src="/images/logo.png"
              width="160"
              alt="Logo"
              className="img-fluid header-logo mr-3"
            />
            <a className=" text-dark font-22" onClick={ToogleClass}>
              <img
                src="/images/hamburger-menu.svg"
                width="30"
                className="hamburger-menu"
              />
            </a>
          </div>
          <div className="col-lg-6 col-md-3 col-6 d-flex justify-content-end align-items-center">
            <Dropdown>
              <Dropdown.Toggle
                className="d-flex bg-transparent text-dark p-0 border-0 h-auto profile-dd my-2"
                id="dropdown-basic"
              >
                <div className="mr-2">
                  {
                    <img
                      src={
                        userData.image
                          ? userData.image
                          : require("../assets/css/images/profile.png").default
                      }
                      width="35"
                      height="35"
                      id="img_header"
                      className="object-cover rounded mr-2"
                    />
                  }
                </div>
                <div>
                  <span
                    id="user_name"
                    className="font-medim d-block mb-n1 text-white"
                    style={{ lineHeight: "17px" }}
                  >
                    Hi, {userData.first_name} {userData.last_name}
                  </span>
                  {/* <span className="font-12 text-light">Business Profile</span> */}
                </div>
              </Dropdown.Toggle>
              {props.toShow === true ? (
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => Profile()}>
                    <i className="icofont-ui-user"></i> My Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={props.logoutHandler}>
                    <i className="icofont-logout"></i> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              ) : (
                ""
              )}
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
