import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import ChangePassword from "../../../components/ChangePassword";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const MyProfile = (props) => {
	const [userData, setUserData] = useState([]);
	const [formFields, setFormFields] = useState({ id: '', user: '', email: '', phone: '', password: '', address: '', first_name: '', last_name: '' })
	const [flag, setFlag] = useState(false);

	const [uploadLoader, setUploadLoader] = useState(false);
	const [value, setValue] = useState('')
	// Toast
	const { addToast } = useToasts();
	const [buttonLoader, setButtonLoader] = useState(false);

	const [showModalPopup, setShowModalPopup] = useState(false);
	const handleShow = () => setShow(true);
	useEffect(() => {
		getUserDetails()
	}, [])

	const getUserDetails = () => {
		var method = 'GET'
		const apifetcherObj = APIMethod(`/users/me`, null, method)
		apifetcherObj
			.then(response => { return Promise.all([response.status, response.json()]) })
			.then(res => {
				let statusCode = res[0]
				let data = res[1]

				if (statusCode === 200) {
					setUserData(data)
					setFormFields({ ...formFields, first_name: data.first_name, last_name: data.last_name, email: data.email, address: data.address })
					setValue(data.phone_no)
					if (data.image) {
						document.getElementById("img_header").src = data.image
					}

				} else {
					addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
				}
			})
			.catch(error => {
			})
	}

	const imageUpload = event => {
		setUploadLoader(true)
		var method = 'POST'
		var form = new FormData();
		form.append('image', event.target.files[0]);
		const apifetcherObj = FileUploadMethod(`/users/image`, form, method)
		apifetcherObj
			.then(response => { return Promise.all([response.status, response.json()]) })
			.then(res => {
				let statusCode = res[0]
				let data = res[1]

				if (statusCode === 200) {
					addToast("Image uploaded successfully", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
					getUserDetails()
					setUploadLoader(false)
				} else {
					addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
					setUploadLoader(false)
				}
			})
			.catch(error => {
				setUploadLoader(false)
			})
	}


	const save = () => {
		if (formFields.name === '' || formFields.email === '' || value === '' || formFields.address === "") {
			setFlag(true)
			addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
		} else {
			setButtonLoader(true)
			var method = 'PUT'
			var params = JSON.stringify({
				id: userData.id,
				first_name: formFields.first_name,
				last_name: formFields.last_name,
				email: formFields.email,
				phone_no: value,
				address: formFields.address
			})
			const apifetcherObj = APIMethod('/users', params, method)
			apifetcherObj
				.then(response => { return Promise.all([response.status, response.json()]) })
				.then(res => {
					let statusCode = res[0]
					let data = res[1]

					if (statusCode === 200) {
						addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
						//setFormFields({ ...formFields, name: data.name, email: data.email, address: data.address })
						getUserDetails()
						setValue(data.phone)
						if (formFields.name) {
							document.getElementById("first_name").innerHTML = formFields.first_name
							document.getElementById("last_name").innerHTML = formFields.last_name
						}
						setButtonLoader(false)
					} else {
						addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
						setButtonLoader(false)
					}
				})
				.catch(error => {
					setButtonLoader(false)
				})
		}
	}
	const isShowPopup = (status) => {
		setShowModalPopup(status)
	};


	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const formFieldsObject = {
			...formFields,
			[name]: value
		}
		setFormFields(formFieldsObject);
	}


	return (
		<>
			<div className="page-wrapper">
				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card rounded-5 p-3 border">
								<div className="card-body form-style">
									<div className="row">
										<div className="col-lg-12 col-md-5  mb-4 text-center">
											<div className="mb-3">
												{
													userData.image === undefined ?
														<img src={require('../../../assets/css/images/profile.png').default} className="img-fluid rounded-circle" alt="No image found" id="img_my_profile" width="120" />
														:
														<img src={userData.image} className="img-fluid rounded-circle" alt="No image found" id="img_my_profile" style={{ height: 120, width: 120 }} />
												}
											</div>
											<button type="button" className="btn px-5 upload-img-merge-btn  btn-primary  text-capitalize position-relative" disabled={uploadLoader}>

												<input type="file" className="btn upload-img" id="AddPhoto" onChange={imageUpload} />

												{uploadLoader && (
													<img src={require('../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
												)}

												<span>Upload</span></button>
										</div>
										<div className="col-lg-6 mb-4">
											<label for="">First Name</label>
											<input type="text" className="form-control" id="first_name" name="first_name" value={formFields['first_name']} onChange={handleChange} />
											<span className="text-danger">{flag === true && formFields.first_name === '' ? 'Please enter first name' : null}</span>
										</div>

										<div className="col-lg-6 mb-4">
											<label for="">Last Name</label>
											<input type="text" className="form-control" id="last_name" name="last_name" value={formFields['last_name']} onChange={handleChange} />
											<span className="text-danger">{flag === true && formFields.last_name === '' ? 'Please enter last name' : null}</span>
										</div>

										<div className="col-lg-6 mb-4">
											<label>Email</label>
											<input type="email" className="form-control" name="email" value={formFields['email']} onChange={handleChange} />
											{
												/* eslint-disable-next-line */
												<span className="text-danger">{flag === true && (formFields.email === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formFields.email)) ? 'Please Enter Valid Email' : null} </span>
											}

											<div className="pull-right">
												<a className="change-password" onClick={() => setShowModalPopup(!showModalPopup)}>Change Password</a>
											</div>
											<ChangePassword
												showModalPopup={showModalPopup}
												onPopupClose={isShowPopup}
												id={userData.id}

											/>
										</div>

										<div className="col-lg-6 mb-4">
											<label for="">Phone</label>
											<PhoneInput
												placeholder="Enter phone number"
												value={value}
												className="form-control"
												onChange={setValue}
												countrySelectProps={{ unicodeFlags: true }}
												defaultCountry="US"
												international
											/>
											<span className="text-danger">{flag === true && formFields.phone === '' ? 'Please enter phone number' : null}</span>
										</div>


										<div className="col-lg-12 mb-4">
											<label for="">Address</label>
											<textarea className="form-control h-auto" id=" " cols="30" rows="3" onChange={handleChange} name="address" value={formFields['address']} />
											<span className="text-danger">{flag === true && formFields.address === '' ? 'Please enter your address' : null} </span>


										</div>
										<div className="col-12 text-center mt-4">
											<button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
												{buttonLoader && (
													<img src={require('../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
												)}
												<span>Save</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default MyProfile;