import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Tab, Nav, Row, Col, } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import { useLocation, useHistory, useParams } from "react-router-dom";
import { APIMethod, TOAST_TIMEOUT, FileUploadMethod } from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import RawRFx from "../../../../components/RawRFx";
import SectionizedRFx from "../../../../components/SectionizedRFx";
import { checkEmptyString, isEdit, MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL, roleHelper } from "../../../../utility/utility";
import moment from "moment";
import ReactModal from 'react-modal-resizable-draggable';
import Loader from '../../../../components/Loader';
import { Editor } from '@tinymce/tinymce-react';
import Chat from "../../../../components/Chat";
import chatIcon from '../../../../assets/css/images/chat.png';
import saveIcon from '../../../../assets/css/images/save.png';
import { act } from "react-dom/test-utils";
/* Added this code for  WOII-223. Prasanna. June 11 2024.. Code Start */
import Swal from 'sweetalert2';
/* Prasanna. June 11 2024. Code End */
/* Added this code for  WOII-221. Harsha. June 11 2024.. Code Start */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
/* Harsha. June 11 2024. Code End */
import Popup from 'reactjs-popup';
import { CKEditor } from 'ckeditor4-react';
var role_data = {}

const AddProposalTemplate = (props) => {
    const descriptionRef = useRef(null);
    const proposalStructureRef = useRef(null);
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [contentData, setContentData] = useState([]);
    const [saveTemplateLoader, setSaveTemplateLoader] = useState(false);
    const [approveLoader, setAprroveLoader] = useState(false);
    const [pushbackLoader, setPushbackLoader] = useState(false);
    const [sendForApprovalLoader, setSendForApprovalLoader] = useState(false);
    const [rfxData, setRfxData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [approverData, setApproverData] = useState([]);
    const [url, setUrl] = useState("");
    const { addToast } = useToasts();
    const location = useLocation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [flag, setFlag] = useState(false);
    const editIndex = useRef('');
    const [addIndex, setAddIndex] = useState('');
    const [pageComing, setPageComing] = useState('');
    const [modalType, setModalType] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [generateProposal, setGenerateProposal] = useState(false);
    const [preRfxData, setPreRfxData] = useState();
    const [proposalButton, setProposalButton] = useState(false)
    const [formFields, setFormFields] = useState({
        comment: '', id: '', commentAddLoader: false, heading: '', description: '', modalButtonLoader: false, isComment: false, proposalStructure: ''
    })
    const [showChatModal, setShowChatModal] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [postLoader, setPostLoader] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [excel, setExcel] = useState();
    const [excelBool, setExcelBool] = useState(false)
    const [text, setText] = useState("")
    const [importFromExcelTextLoader, setImportFromExcelTextLoader] = useState(false)
    var access_proposal_actions = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL)

    const isViewPath = /^\/view-approval-proposal-template\/\d+$/.test(window.location.pathname);

    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    const [collapseToggle, setCollapseToggle] = useState(false)
    //---Date Time picker
    //coollapse menu - 1
    const CollapseMenu1 = (value) => {
        setCollapseToggle(value)
        var element = document.getElementById("PraposalTemp");
        console.log(element)
        element.classList.toggle("collapse-tab");

        for (var i = 0; i < 5; i++) {
            var hideIcon = document.getElementById(`hideCollapseButton${i + 1}`);
            if (hideIcon) {
                hideIcon.classList.toggle("show-coll-menu-page");
                var hideText = document.getElementById(`hideCollapseText${i + 1}`);
                hideText.classList.toggle("hide-coll-menu-page");
            } else {
                break;
            }
        }
    }

    /* Added this code for  WOII-221. Harsha. June 11 2024.. Code Start */
    const handleDragEnd = (result) => {
        if (!result.destination) return; // dropped outside the list
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const reorderedItems = Array.from(contentData);
        const [removed] = reorderedItems.splice(startIndex, 1);
        reorderedItems.splice(endIndex, 0, removed);
        setContentData(reorderedItems); // Update the state with reordered items
    };
    /* Harsha. June 11 2024. Code End */

    const handleCloseExcel = () => {
        setIsOpen(false);
    };

    const handleExcelSelect = (event) => {
        setExcel(event.target.files[0])
        setExcelBool(true)
        setText("Please remove the Excel file to enter text")
        disableDivAndButtons(true, 'textDiv')
    };

    const handleExcelRemove = () => {
        console.log("dd")
        setExcel(undefined)
        setExcelBool(false)
        setText("")
        disableDivAndButtons(false, 'textDiv')
    };

    const handleText = (event) => {
        setText(event.target.value);
        // disableDivAndButtons(text.length > 7, 'uploadDiv');
    };

    function disableDivAndButtons(value, elementId) {
        const uploadDiv = document.getElementById(elementId);
        const buttons = uploadDiv.getElementsByTagName('button');
        if (value) {
            uploadDiv.classList.add('disabled');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].disabled = true;
            }
        } else {
            uploadDiv.classList.remove('disabled');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].disabled = false;
            }
        }
    }

    //coollapse menu
    const CollapseMenu = () => {
        var element = document.getElementById("PraposalTemp");
        element.classList.toggle("collapse-tab");

        for (var i = 0; i < 3; i++) {
            var hideIcon = document.getElementById(`hideCollapseButton${i + 1}`);
            if (hideIcon) {
                hideIcon.classList.toggle("show-coll-menu-page");
                var hideText = document.getElementById(`hideCollapseText${i + 1}`);
                hideText.classList.toggle("hide-coll-menu-page");
            } else {
                break;
            }
        }
    }

    useEffect(() => {
        getTemplateContentList()
        getRfxData()
        isEdit(access_proposal_actions) ? getApproverVersion() : null
        getTemplateContentComments()
        checkPage()
        getAiImageSegmentation()
    }, [])

    const getTemplateContentList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/list/1?pre_rfx_id=${id}&is_approver=false`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                console.log(data);
                if (statusCode === 200) {
                    if (data.list.length == 0) {
                        contentData.push({ title: '', description: '', section: '', priority: '' })
                    } else {
                        setContentData(data.list)
                    }
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getApproverVersion = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/list/1?pre_rfx_id=${id}&is_approver=true`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setApproverData(data.list)
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRfxData = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRfxData(data)
                    setUrl(data.url);
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }
    function getRoles() {
        var roles = JSON.parse(localStorage.getItem("user_Role"))
        console.log(roles)
        for (let i = 0; i < roles.length; i++) {
            for (let j = 0; j < roles[i].access.length; j++) {
                role_data[roles[i].access[j].name] = {
                    'is_create': roles[i].access[j].is_create,
                    'is_delete': roles[i].access[j].is_delete,
                    'is_update': roles[i].access[j].is_update,
                    'is_view': roles[i].access[j].is_view
                }
            }
        }

    }

    const checkPage = () => {
        if (props.location.state == null) {
        } else {
            setPageComing(props.location.state.page)
        }
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${id}?rfx_type=AI`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    getRoles()

                    setPreRfxData(data)
                    setLoading(false);

                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const handleEditorChange = (event) => {
        descriptionRef.current = event.editor.getData();
    };

    const getAiImageSegmentation = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setFormFields({ ...formFields, proposalStructure: data.proposal_structure })
                    setLoading(false);
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                    setLoading(false);
                }
            })
            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const _onGenerateProposal = () => {
        if (proposalButton == false) {
            addToast('Please update the template as per the Approver’s Suggestion and click on Save. If no changes, still click on Save button.', { appearance: 'error', autoDismiss: true });
        } else {
            setGenerateProposal(true)
            setButtonLoader(true)
            var method = 'PUT'
            const apifetcherObj = APIMethod(`/action/event/GENERATE_PROPOSAL/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        history.push({ pathname: `/mp-genrate-proposal/${id}`, state: { detail: id, rfx_number: props.location.state.rfx_number, rfx_title: props.location.state.title, page: 'rfx', from: 'Proposal Template' } })
                        _onSendForApproval('GENERATE_PROPOSAL', 'none')
                        setButtonLoader(false)
                        setGenerateProposal(false)
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                        setGenerateProposal(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                    setGenerateProposal(false)
                })
        }
    }

    const _onAddTemplateContent = (index) => {
        setModalType('Add')
        setAddIndex(index)
        setShowModal(!showModal)
        formFields.heading = '';
        descriptionRef.current = '';
    }

    /* Commenting the following code for WOII-223. Prasanna. June 11 2024.. Code Start */
    // const _onDeleteTemplateContent = (index) => {
    //     var array = contentData;
    //     array.splice(index, 1)
    //     setContentData([])
    //     setContentData(array)
    //     setContentData([...contentData])

    // }
    /* Commenting end. Prasanna. June 11 2024.. */

    /* Added this code for  WOII-223. Prasanna. June 11 2024.. Code Start */
    const _onDeleteTemplateContent = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this template?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'No, keep it',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#044661',
        }).then((result) => {
            if (result.isConfirmed) {
                var array = contentData.slice();
                array.splice(index, 1);
                setContentData(array);
            }
        });
    };
    /* Prasanna. June 11 2024. Code End */

    const onSectionChange = (index, value, type) => {
        for (var i in contentData) {
            if (i == index) {
                contentData[i][type] = value
                pageComing === 'template_approval' ? contentData[i].is_checked = true : false
            }
        }
        setContentData([...contentData])
    }

    const _onSaveTemplate = (type) => {
        if (formFields.isComment === false && type != undefined && type != null) {
            addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (props.location.state.status == 'PTA' && type == null) {
                addToast('Make sure you have updated your template as per Approvers Version', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
            }
            if (contentData.length > 0) {
                for (var i in contentData) {
                    contentData[i].priority = parseInt(i)
                    contentData[i].total_sections = contentData.length
                    if (!contentData[i].title) {
                        addToast('Please add all the titles', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        return;
                    }
                    if (!contentData[i].description) {
                        addToast('Please add all the descriptions', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        return;
                    }
                    if (!contentData[i].section) {
                        addToast('Please select section levels', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        return;
                    }
                    if (!contentData[i].is_checked || !contentData[i].is_check) {
                        contentData[i].is_checked = false;
                        contentData[i].is_check = false;
                    }
                }
                var method = 'PUT'
                console.log(contentData)
                var params = JSON.stringify(contentData)
                if (type != 'PENDING_APPROVAL') {
                    setSaveTemplateLoader(true)
                }
                if (type == 'APPROVED') {
                    setAprroveLoader(true)
                }
                if (type == 'PUSH_BACK') {
                    setPushbackLoader(true)
                }
                if (type == 'PENDING_APPROVAL') {
                    setSendForApprovalLoader(true)
                }
                const apifetcherObj = APIMethod(`/template-content/${location.state.detail}?stage=proposal_template&is_approver=${pageComing === 'template_approval' ? true : pageComing === 'template' ? false : false}`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            setProposalButton(true)
                            if (type == 'PENDING_APPROVAL') {
                                {/* Commenting the following code for WOII-32. Divya. April 23 2024.. Code Start  */ }
                                //addToast('Sent for Approval', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                {/* Commenting end. Divya. April 23 2024.. */ }
                                _onSendForApproval('PENDING_APPROVAL')
                            } else if (type == 'APPROVED') {
                                {/* Commenting the following code for WOII-32. Divya. April 23 2024.. Code Start  */ }
                                // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                {/* Commenting end. Divya. April 23 2024.. */ }
                                _onSendForApproval('APPROVED')
                            } else if (type == 'PUSH_BACK') {
                                onUpdateStatus('TEMPLATE_PUSHBACK')
                            } else {
                                {/* Commenting the following code for WOII-32. Divya. April 23 2024.. Code Start  */ }
                                //addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                                {/* Commenting end. Divya. April 23 2024.. */ }
                                _onSendForApproval('PENDING', 'none')
                                setSaveTemplateLoader(false)
                            }
                            if (window.location.pathname == '/add-proposal-template') {
                                onUpdateStatus('GENERATE_PROPOSAL_TEMPLATE')
                            }
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                            if (type != 'PENDING_APPROVAL') {
                                setSaveTemplateLoader(false)
                            }
                        }
                    })
                    .catch(error => {
                        if (type != 'PENDING_APPROVAL') {
                            setSaveTemplateLoader(false)
                        }
                    })
            }
        }
    }

    const onUpdateStatus = (key) => {
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    formFields.isComment = false;
                    setAprroveLoader(false)
                    setPushbackLoader(false)
                    if (key != 'UPDATE_OR_APPROVE_TEMPLATE') {
                        goBack()
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
            })
    }

    const getTemplateContentComments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/comments/list/${id}?comment_type=TEMPLATE_CONTENT`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const importText = async (file) => {
        setImportFromExcelTextLoader(true)
        setIsOpen(false)
        var method = 'POST'
        var params = JSON.stringify({
            proposal_structure: text,
            pre_rfx_id: id,
        })

        const apifetcherObj = APIMethod(`/proposal-template-statuses/retry/1`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                if (statusCode === 200) {
                    setImportFromExcelTextLoader(false)
                    if (data.list.length == 0) {
                        contentData.push({ title: '', description: '', section: '', priority: '' })
                    } else {
                        setContentData(data.list)
                    }
                } else {
                    setImportFromExcelTextLoader(false)
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                setImportFromExcelTextLoader(false)
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    };

    const importExcel = async () => {
        try {
            console.log("In");
            setImportFromExcelTextLoader(true)
            setIsOpen(false)

            var method = 'POST'
            const formData = new FormData();
            formData.append('excel', excel);
            formData.append('pre_rfx_id', id)

            const apifetcherObj = await FileUploadMethod(`/proposal-template-statuses/retry/excel/1`, formData, method);
            const [responseStatus, responseData] = await Promise.all([apifetcherObj.status, apifetcherObj.json()]);

            if (responseStatus === 200) {
                console.log(responseData)
                setImportFromExcelTextLoader(false)
                if (responseData.list.length == 0) {
                    contentData.push({ title: '', description: '', section: '', priority: '' })
                } else {
                    setContentData(responseData.list)
                }
            } else {
                setImportFromExcelTextLoader(false)
                addToast(responseData.message, { appearance: 'error', autoDismiss: true });
            }
        } catch {
            setImportFromExcelTextLoader(false)
            setLoading(false)
        }

    }

    const _onSendForApproval = (status, type) => {
        if (formFields.isComment == false && type != 'none') {
            addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            var request_url = '/pre-rfx'
            if (contentData.length > 0) {
                var method = 'PUT'
                if (status == 'PENDING') {
                    params = JSON.stringify({
                        id: location.state.detail,
                        is_proposal_template_exist_but_not_proposal: true
                    })
                } else if (status == 'PENDING_APPROVAL') {
                    params = JSON.stringify({
                        id: location.state.detail,
                        template_status: status,
                        is_proposal_template_exist_but_not_proposal: true
                    })
                } else if (status == 'GENERATE_PROPOSAL') {
                    params = JSON.stringify({
                        id: location.state.detail,
                        proposal_status: 'PENDING',
                        is_proposal_exist: true,
                        proposal_start_date: moment(new Date()).format('DD/MM/YYYY')
                    })
                }
                else {
                    var params = JSON.stringify({
                        id: location.state.detail,
                        template_status: status,
                    })
                }
                const apifetcherObj = APIMethod(`${request_url}`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                            if (status == 'PENDING_APPROVAL') {
                                onUpdateStatus('SEND_FOR_APPROVAL_FOR_TEMPLATE')
                            } else if (status == 'APPROVED') {
                                onUpdateStatus('UPDATE_OR_APPROVE_TEMPLATE')
                                goBack()
                            } else if (status == 'PUSH_BACK') {
                                onUpdateStatus('TEMPLATE_PUSHBACK')
                            }
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        }
                    })
                    .catch(error => {
                        addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    })
            }
        }
    }

    const addComment = () => {
        if (formFields.comment == '') {
            addToast('Please enter comment', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setPostLoader(true)
            var method = 'POST'
            var params = JSON.stringify({
                comment: formFields.comment,
                pre_rfx_id: location.state.detail,
                type: 'TEMPLATE_CONTENT'
            })
            const apifetcherObj = APIMethod(`/template-content/post/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        getTemplateContentComments()
                        formFields.comment = ''
                        setPostLoader(false)
                        formFields.isComment = true
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setPostLoader(false)
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setPostLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const onEdit = (res, index, type) => {
        setFormFields({ ...formFields, heading: res.title })
        // setEditIndex(index)
        descriptionRef.current = '';
        editIndex.current = `${index}_${res.title}`
        if (type == 'EDIT') {
            setShow(!show)
        } else {
            setShowModal(!showModal)
        }
        setModalType('Edit')
    }

    const handleClose = () => {
        setShowModal(false)
        editIndex.current = '';
        formFields.heading = '';
        descriptionRef.current = '';
        setModalType('')
    }

    const onsubmit = () => {
        let temp = editIndex.current.split("_")
        if (formFields.heading == '' || checkEmptyString(descriptionRef.current)) {
            addToast('Please Fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            setFlag(true)
        } else {
            if (modalType == 'Edit') {
                formFields.modalButtonLoader = true
                for (var i in contentData) {
                    if (i == temp[0]) {
                        contentData[i].title = formFields.heading
                        pageComing === 'template_approval' ? contentData[i].is_checked = true : false
                    }
                }
                setContentData([...contentData])

                for (var i in contentData) {
                    if (i == temp[0]) {
                        contentData[i].description = descriptionRef.current
                        pageComing === 'template_approval' ? contentData[i].is_checked = true : false
                    }
                }
                setContentData([...contentData])
                formFields.modalButtonLoader = false
                handleClose()
                editIndex.current = '';
                formFields.heading = '';
                descriptionRef.current = '';
            } else {
                contentData.splice(addIndex + 1, 0, { title: formFields.heading, description: descriptionRef.current, section: '', priority: '' })
                setContentData([...contentData])
                handleClose()
                formFields.heading = '';
                descriptionRef.current = '';
                setAddIndex('')
            }
        }
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Loader
                        isLoading={buttonLoader}
                        type={`FULLPAGE${generateProposal ? '$$$' : ''}`}
                    />

                    <div className="mb-3">
                        <div className="d-flex align-items-center">
                            {
                                location.state != undefined &&
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={goBack}>{location.state != undefined ? location.state.from : ''}</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{pageComing === 'template_approval' || pageComing === 'template' || window.location.pathname === '/edit-proposal-template' ? 'Edit' : 'Add'} Proposal Templates</Breadcrumb.Item>
                                </Breadcrumb>
                            }
                            <div className="mr-4 ml-5">
                                <h4 className="font-16 font-medium">RFx No. : <span className="text-secondary">{preRfxData?.rfx_number ? preRfxData?.rfx_number : '-'}</span></h4>
                            </div>
                            <div className="mr-4">
                                <h4 className="font-16 font-medium">RFx Title : <span className="text-secondary " title={preRfxData?.title} style={{ wordBreak: 'break-all', width: 50 }}>{preRfxData?.title}</span></h4>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-7.5 col-md-7.5 mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <div className="row col-12">
                                <div className="col-5">
                                    <h2 className="page-title">{pageComing === 'template_approval' ? 'Proposal Template Approval' : 'Proposal Template'}</h2>
                                </div>
                                <div className="col-7 row">
                                    <h4 className="font-16 font-medium">RFx No. : <span className="text-secondary">{preRfxData?.rfx_number ? preRfxData?.rfx_number : '-'}</span></h4>
                                    <h4 className="font-16 font-medium">RFx Title : <span className="text-secondary " title={preRfxData?.title} style={{ wordBreak: 'break-all', width: 50 }}>{preRfxData?.title}</span></h4>
                                </div>
                                 <div className="col-lg-4.5 col-md-4.5">
                        <div className="d-flex justify-content-end">
                            <div>
                                <button type="button" className="btn btn-brand-1 bg-success px-4" style={{ lineHeight: '1' }} onClick={() => _onGenerateProposal()} disabled={pageComing === 'Approval' || pageComing === 'View' || pageComing === 'Add' || preRfxData?.template_status != 'APPROVED' ? true : false || isViewPath}>Generate Proposal</button>
                            </div>
                          
                            <div className="ml-2">
                                <button type="button" className="btn btn-brand-1 bg-success" disabled={importFromExcelTextLoader} style={{ lineHeight: '1' }} onClick={() => setIsOpen(true)}>
                                    {importFromExcelTextLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    {importFromExcelTextLoader ? (
                                        <span>Processing....</span>
                                    ) : (
                                        <span>Import From Excel/Text</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                            </div>
                            <div>
                                <a onClick={CollapseMenu}>
                                    <img src="/images/collapse-icon.svg" width="27" />
                                </a>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-12 d-flex align-items-center">
                            {/* Page Title */}
                            <div className="col-lg-4 col-md-4">
                                <h2 className="page-title">
                                    {pageComing === 'template_approval' ? 'Proposal Template Approval' : 'Proposal Template'}
                                </h2>
                            </div>

                            {/* Buttons */}
                            <div className="col-lg-4.5 col-md-4.5 ml-auto d-flex justify-content-end">
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-brand-1 bg-success px-4"
                                        style={{ lineHeight: '1' }}
                                        onClick={() => _onGenerateProposal()}
                                        disabled={
                                            pageComing === 'Approval' ||
                                            pageComing === 'View' ||
                                            pageComing === 'Add' ||
                                            preRfxData?.template_status !== 'APPROVED' ||
                                            isViewPath
                                        }
                                    >
                                        Generate Proposal
                                    </button>
                                </div>
                                <div className="ml-2">
                                    <button
                                        type="button"
                                        className="btn btn-brand-1 bg-success"
                                        disabled={importFromExcelTextLoader}
                                        style={{ lineHeight: '1' }}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        {importFromExcelTextLoader && (
                                            <img
                                                src={require('../../../../assets/css/images/button_loader.gif').default}
                                                alt="loading..."
                                                style={{ marginLeft: 5 }}
                                            />
                                        )}
                                        {importFromExcelTextLoader ? <span>Processing....</span> : <span>Import From Excel/Text</span>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Commenting the following code for WOII-198 Divya. March 30 2024.. Code Start */}
                    {/* <div className="col-lg-4.5 col-md-4.5 text-right d-md-flex align-items-end justify-content-end">
                        <div className="container">
                            <div className="row">
                                <div className="col" style={{width:'250px'}}> */}
                    {/* Commenting end. Divya  March 30 2024.. */}
                    {/* Added this code for  WOII-198 Divya. March 30 2024.. Code Start  */}
                    {/* <div className="col-lg-4.5 col-md-4.5">
                        <div className="d-flex justify-content-end">
                            <div> */}
                    {/* Divya. March 30 2024. Code End  */}
                    {/* <button type="button" className="btn btn-brand-1 bg-success px-4" style={{ lineHeight: '1' }} onClick={() => _onGenerateProposal()} disabled={pageComing === 'Approval' || pageComing === 'View' || pageComing === 'Add' || preRfxData?.template_status != 'APPROVED' ? true : false || isViewPath}>Generate Proposal</button>
                            </div> */}
                    {/* Commenting the following code for WOII-198 Divya. March 30 2024.. Code Start */}
                    {/* </div>
                            </div> */}
                    {/* <div className="container">
                                <div className="row">
                                    <div className="col" style={{width:'250px'}}> */}
                    {/* Commenting end. Divya  March 30 2024.. */}
                    {/* Added this code for  WOII-198 Divya. March 30 2024.. Code Start  */}
                    {/* <div className="ml-2"> */}
                    {/* Divya. March 30 2024. Code End  */}
                    {/* <button type="button" className="btn btn-brand-1 bg-success" disabled={importFromExcelTextLoader} style={{ lineHeight: '1' }} onClick={() => setIsOpen(true)}>
                                    {importFromExcelTextLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    {importFromExcelTextLoader ? (
                                        <span>Processing....</span>
                                    ) : (
                                        <span>Import From Excel/Text</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* Commenting the following code for WOII-198 Divya. March 30 2024.. Code Start */}
                    {/* </div>
                    </div>  */}
                    {/* Commenting end. Divya  March 30 2024.. */}

                    <div className="col px-0 d-flex overflow-auto pb-4 mb-4" style={{ height: "78vh" }}>
                        <div className="">

                            <div className=" mb-4 mr-4" id="PraposalTemp">
                                <Tab.Container id="controlled-tab-example" defaultActiveKey="RowRFX" >
                                    <Row>
                                        <div className="tab-style-1 col-12 d-flex">
                                            <Nav className="d-flex  nav nav-tabs ">
                                                {collapseToggle && (
                                                    <Nav.Item className="mr-3">
                                                        <div id='hideCollapseText3'>
                                                            <a href="javascript:void(0)" onClick={() => CollapseMenu1(false)}>
                                                                <img src={require('../../../../assets/css/images/shrink-right.png').default} width={30} height={30} className='menu-page ml-2 mt-3 mr-2' />
                                                            </a>
                                                        </div>
                                                    </Nav.Item>
                                                )}
                                                <Nav.Item className="mr-3">
                                                    <img src={require('../../../../assets/css/images/view-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton1' title="Raw RFX" width={30} height={30} />
                                                    <div id='hideCollapseText1'>
                                                        <Nav.Link eventKey="RowRFX">Raw RFX</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                                {/* {Commenting the below code for removing Sectionized RFx June 14 2024 Code Start...}

                                                <Nav.Item>
                                                    <img src={require('../../../../assets/css/images/section-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton2' title="Sectionized RFx" width={30} height={30} />
                                                    <div id='hideCollapseText2'>
                                                        <Nav.Link eventKey="SectionizedRFx" className="mr-3"> Sectionized RFx</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                               {Commenting the code for removing Sectionized RFx June 14 2024...Code End} */ }
                                                <Nav.Item>
                                                    <img src={require('../../../../assets/css/images/structure.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2 mb-3' id='hideCollapseButton2' title="Proposal Structure" width={30} height={30} />
                                                    <div id='hideCollapseText2'>
                                                        <Nav.Link eventKey="ProposalStructure">Proposal Structure</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Col sm={12} className="tab-style-2">
                                            <Tab.Content>
                                                <a href="javascript:void(0)" onClick={() => CollapseMenu1(true)}>
                                                    <img src={require('../../../../assets/css/images/shrink-left.png').default} width={30} height={30} style={{ float: "right" }} />
                                                </a>
                                                {
                                                    url ?
                                                        <RawRFx
                                                            url={url}
                                                        /> : null
                                                }
                                                <SectionizedRFx
                                                    page={'/view-parse-rfx-detail'}
                                                    rfx_id={id}
                                                    // Added this code for  WOII-72. Divya. 11 September 2023.. Code Start
                                                    height={'1000'}
                                                //Divya. September 11 2023. Code End
                                                />
                                                <Tab.Pane eventKey="ProposalStructure">
                                                    <div className="form-group">
                                                        {
                                                            formFields.proposalStructure ?
                                                                // <textarea className="form-control h-auto" rows="25" value={formFields['proposalStructure']} name="proposalStructure" disabled={true} />
                                                                // <Editor
                                                                //     apiKey={tiny_key}
                                                                //     onInit={(evt, editor) => proposalStructureRef.current = editor}
                                                                //     initialValue={formFields.proposalStructure}
                                                                //     disabled={true || isViewPath}
                                                                //     init={{
                                                                //         height: 500,
                                                                //         menubar: 'file edit view insert format tools table tc help',
                                                                //         selector: 'textarea#full-featured',
                                                                //         plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table ',
                                                                //         toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                //         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                //     }}
                                                                // />
                                                                <CKEditor
                                                                    initData={formFields.proposalStructure}
                                                                    readOnly={true || isViewPath}
                                                                    onInstanceReady={(event) => proposalStructureRef.current = event.editor}
                                                                    name="proposal_structure_pt"
                                                                    config={{
                                                                        height: 500,
                                                                        versionCheck: false,
                                                                        toolbar: [
                                                                            { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                            { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                            { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                            { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                            { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                        ],
                                                                    }}
                                                                />
                                                                :
                                                                <div className="col-12 d-flex justify-content-center mt-3">
                                                                    <span>No Proposal Structure Found</span>
                                                                </div>
                                                        }
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                        <div className="">
                            <div className="d-flex">
                                <div style={{ minWidth: "695px" }}>
                                    <div className="d-flex justify-content-between mb-3 align-items-center">
                                        <h3 className="page-title mb-0">Template Content</h3>
                                    </div>

                                    <div style={{ width: '100%', backgroundColor: 'white' }} className="mb-4">
                                        <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                            <Row >
                                                <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Heading/Title</th>
                                                            <th scope="col">Description</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                {/*<Col md={4} style={{ height: 450, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>*/}
                                                {/* Commenting end. Divya. September 11 2023.. */}
                                                {/*Added this code for  WOII-72. Divya. September 11 2023.. Code Start*/}
                                                <Col md={4} style={{ height: 540, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                                                    {/*Divya. September 11 2023. Code End*/}
                                                    {/* <Nav variant="pills" className="flex-column vertical-tabs"> */}
                                                    {/*Added this code for  WOII-221. Harsha. June 11 2024.. Code Start*/}
                                                    <DragDropContext onDragEnd={handleDragEnd}>
                                                        <Droppable droppableId="headings">
                                                            {(provided) => (
                                                                <Nav {...provided.droppableProps} ref={provided.innerRef} variant="pills" className="flex-column vertical-tabs">

                                                                    {
                                                                        contentData?.map((res, i) => {
                                                                            let temp = editIndex.current.split("_")
                                                                            if (contentData[i].section == "" && i > 0) {
                                                                                contentData[i].section = contentData[i - 1].section
                                                                            }

                                                                            return (
                                                                                <Nav.Item key={i} style={{ flexDirection: 'row' }}>
                                                                                    {/* <Nav.Link eventKey={i} className="mt-2">{res.title}</Nav.Link> */}
                                                                                    <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                                        {(provided) => (
                                                                                            <Nav.Link ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} eventKey={i} className="mt-2">{res.title}</Nav.Link>
                                                                                        )}
                                                                                    </Draggable>
                                                                                    <div style={{ flexDirection: 'row' }}>
                                                                                        <button type="button" className="btn btn-action edit mr-1" onClick={() => onEdit(res, i, 'EDIT')} disabled={pageComing === 'View' || (preRfxData?.template_status === 'PENDING_APPROVAL' && location.state.status != 'Proposal Template Is Pushedback' && role_data['Proposal Template Approvals']?.is_create == false && role_data['Proposal Template Approvals']?.is_update == false) || preRfxData?.proposal_status === 'PENDING' ? true : false || isViewPath}><i className="icofont-pencil-alt-2"></i></button>
                                                                                        <button type="button" className="btn" style={{ padding: 0 }} onClick={() => _onAddTemplateContent(i)} disabled={pageComing === 'View' ? true : false || isViewPath}><i className="icofont-plus-square"></i></button>
                                                                                        {<button type="button" className="btn" onClick={() => _onDeleteTemplateContent(i)} disabled={pageComing === 'View' ? true : false || isViewPath}><i className="icofont-minus-square"></i></button>}
                                                                                        <button type="button" className="btn" disabled={(editIndex.current != "" ? (temp[0] == i ? false : true) : true) || isViewPath}><img src={saveIcon} height={20} width={20} onClick={() => onsubmit()} /></button>
                                                                                        <select id="disabledSelect" className="font-14 ml-2" style={{ backgroundColor: 'white', height: 40, borderColor: '#e6e6e6', borderRadius: 6 }} value={res.section} name={res.section} onChange={e => onSectionChange(i, e.target.value, 'section')} disabled={((modalType != 'Edit' || pageComing === 'View') ? true : false) || isViewPath}>
                                                                                            <option value="" disabled selected>Select your option</option>
                                                                                            <option value={'Section-Level 1'}>Section-Level 1</option>
                                                                                            <option value={'Section-Level 2'}>Section-Level 2</option>
                                                                                            <option value={'Section-Level 3'}>Section-Level 3</option>
                                                                                            <option value={'Section-Level 4'}>Section-Level 4</option>
                                                                                            <option value={'Question'}>Question</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </Nav.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </Nav>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                    {/*Harsha. June 11 2024. Code End*/}
                                                </Col>
                                                <Col sm={7}>
                                                    <Tab.Content className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme mt-4 h-auto mb-4" >
                                                        {
                                                            contentData?.map((res, j) => {
                                                                let temp = editIndex.current.split("_")
                                                                descriptionRef.current = res.description
                                                                return (
                                                                    <Tab.Pane eventKey={j} key={j}>
                                                                        {/* <div style={{ overflow: 'scroll', height: 390 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div> */}
                                                                        {editIndex.current != "" ? (temp[0] == j ? <input type="text" className="form-control mb-4" onChange={handleChange} name="heading" value={formFields['heading']} /> : null) : null}
                                                                        {/* <Editor
                                                                            apiKey={tiny_key}
                                                                            onEditorChange={(evt, editor) => descriptionRef.current = evt}
                                                                            initialValue={descriptionRef.current}
                                                                            disabled={(modalType != 'Edit' || (temp[0] != j ? true : false) || isViewPath)}
                                                                            init={{
                                                                                height: 500,
                                                                                menubar: 'file edit view insert format tools table tc help',
                                                                                selector: 'textarea#full-featured',
                                                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table ',
                                                                                toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                            }}
                                                                        /> */}
                                                                        <CKEditor
                                                                            initData={descriptionRef.current}
                                                                            readOnly={(modalType != 'Edit' || (temp[0] != j ? true : false) || isViewPath)}
                                                                            onInstanceReady={(event) => descriptionRef.current = event.editor}
                                                                            onChange={handleEditorChange}
                                                                            name="template_description_pt"
                                                                            config={{
                                                                                height: 361,
                                                                                versionCheck: false,
                                                                                toolbar: [
                                                                                    { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                                    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                                    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                                    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                                    { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                                    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                                    { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                                ],
                                                                            }}
                                                                        />
                                                                    </Tab.Pane>
                                                                )
                                                            })
                                                        }
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                                {
                                    pageComing === 'template_approval' ? null : approverData.length > 0 ?
                                        <div className="ml-3" style={{ minWidth: "695px" }}>
                                            <div className="d-flex justify-content-between mb-3 align-items-center">
                                                <h3 className="page-title mb-0">Approver's Version</h3>
                                            </div>
                                            <div style={{ width: 960, backgroundColor: 'white' }} className="mb-4">
                                                <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                                    <Row >
                                                        <table className="table table-style-1 mb-4 table-md-responsive" id="" style={{ width: '97.2%', marginLeft: 14 }}>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Heading/Title</th>
                                                                    <th scope="col">Description</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                        {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                        {/*<Col md={4} style={{ height: 450, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>*/}
                                                        {/* Commenting end. Divya. September 11 2023.. */}
                                                        {/* Added this code for  WOII-72. Divya. 11 September 2023.. Code Start*/}
                                                        <Col md={4} style={{ height: 540, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                                                            {/*Divya. September 11 2023. Code End*/}
                                                            <Nav variant="pills" className="flex-column vertical-tabs">
                                                                {
                                                                    approverData?.map((res, i) => {
                                                                        return (
                                                                            <Nav.Item key={i} style={{ flexDirection: 'row' }}>
                                                                                <Nav.Link eventKey={i}>{res.title}</Nav.Link>
                                                                                <div style={{ flexDirection: 'row' }}>
                                                                                    <select id="disabledSelect" className="font-14 mb-2" style={{ backgroundColor: 'white', height: 40, borderColor: '#e6e6e6', borderRadius: 6 }} value={res.section} name={res.section} disabled={true}>
                                                                                        <option value="" disabled selected>Select your option</option>
                                                                                        <option value={'Section-Level 1'}>Section-Level 1</option>
                                                                                        <option value={'Section-Level 2'}>Section-Level 2</option>
                                                                                        <option value={'Section-Level 3'}>Section-Level 3</option>
                                                                                        <option value={'Section-Level 4'}>Section-Level 4</option>
                                                                                        <option value={'Question'}>Question</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Nav.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Nav>
                                                        </Col>
                                                        <Col sm={7}>
                                                            <Tab.Content className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme mt-4 h-auto mb-4" >
                                                                {
                                                                    approverData?.map((res, j) => {
                                                                        return (
                                                                            <Tab.Pane eventKey={j} key={j}>
                                                                                {/* Commenting the following code for WOII-72. Divya. September 11 2023.. Code Start */}
                                                                                {/*<div style={{ overflow: 'scroll', height: 390 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div>*/}
                                                                                {/* Commenting end. Divya. September 11 2023.. */}
                                                                                {/* Added this code for  WOII-72. Divya. 11 September 2023.. Code Start*/}
                                                                                <div style={{ overflow: 'scroll', height: 500 }} dangerouslySetInnerHTML={{ __html: (res.description && res.description.length > 200 ? `${res.description}` : res.description) }}></div>
                                                                                {/*Divya. September 11 2023. Code End*/}
                                                                            </Tab.Pane>
                                                                        )
                                                                    })
                                                                }
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        </div> : null
                                }
                            </div>
                            {
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="font-16 mb-0">Comments</h4>
                                        </div>
                                        <div className="card-body bg-white">
                                            {
                                                commentData.map((res, index) => {
                                                    return (
                                                        <div key={index} className="bg-light p-2 rounded mb-2">
                                                            <p className="mb-1">
                                                                <span className="font-medium mr-2">{res.user.first_name}{res.user.last_name}</span>
                                                                <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                                {/* <span className="badge badge-warning">Pushed Back</span> */}
                                                            </p>
                                                            <p className="font-14 mb-0 ">{res.comment}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                <div className="form-group form-style">
                                                    <label>Add Comment</label>
                                                    <textarea className="form-control h-auto mb-2" rows="2" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                                                    <button type="button" className="btn btn-brand-1 btn-sm" onClick={() => addComment()} disabled={postLoader || pageComing === 'View'}>
                                                        {postLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        Post <i className="icofont-paper-plane"></i></button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className=" text-center mt-4 mb-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={goBack}>Cancel</button>
                                {
                                    pageComing === 'template_approval' || pageComing === 'View' || preRfxData?.template_status == 'Template Sent for Approval' ? null :
                                        <button type="button" className="btn btn-lg btn-brand-1 mr-3 ml-3 bg-success" onClick={() => _onSaveTemplate()} disabled={saveTemplateLoader || (preRfxData?.template_status === 'PENDING_APPROVAL' && location.state.status != 'Proposal Template Is Pushedback' && role_data['Proposal Template Approvals']?.is_create == false && role_data['Proposal Template Approvals']?.is_update == false) || preRfxData?.proposal_status === 'PENDING' || isViewPath}>
                                            {saveTemplateLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Save</button>
                                }
                                {
                                    (preRfxData?.template_status === 'Template Sent for Approval' || preRfxData?.template_status === 'Proposal Template Approved' || pageComing === 'template_approval' || pageComing === 'View')
                                        ? null : (
                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => _onSaveTemplate('PENDING_APPROVAL')} disabled={sendForApprovalLoader || preRfxData?.template_status === 'APPROVED' || (preRfxData?.template_status === 'PENDING_APPROVAL' && location.state.status != 'Proposal Template Is Pushedback') || isViewPath}>
                                                {sendForApprovalLoader && (
                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                )}
                                                Send for Approval
                                            </button>
                                        )

                                }
                                {pageComing === 'template_approval' ? preRfxData?.template_status == 'Template Sent for Approval (PTS)' || preRfxData?.template_status == 'APPROVED' ? null : <button type="button" className="btn btn-brand-1 bg-success px-4 ml-3" onClick={() => _onSaveTemplate('APPROVED')} disabled={approveLoader || isViewPath}>{approveLoader && (
                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}Approve</button> : null}
                                {pageComing === 'template_approval' ? preRfxData?.template_status == 'Template Sent for Approval (PTS)' || preRfxData?.template_status == 'APPROVED' ? null : <button type="button" className="btn btn-brand-1 px-4 ml-3" onClick={() => _onSaveTemplate('PUSH_BACK')} disabled={pushbackLoader || isViewPath}>{pushbackLoader && (
                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}Push Back</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <ReactModal
                isOpen={isOpen}
                onRequestClose={handleCloseExcel}
                className="contained-modal-title-vcenter"
                initWidth={850}
                initHeight={'auto'}
                top={137.5}>
                <div className="modal-header">
                    <h5 className="modal-title">Upload Excel or Import Text</h5>
                    <button type="button" className="close" onClick={handleCloseExcel}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div id="uploadDiv" className="form-group">
                        <label>Upload Questions/Proposal Structure in Excel</label>
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" id="inputGroupFile02" accept=".xlsx" onChange={handleExcelSelect} disabled={text.length > 7} />
                            <label className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{excel === undefined ? 'Choose File' : excel.name}</label>
                            <div style={{ marginTop: '10px', display: "flex", columnGap: "10px", alignItems: 'center' }} >
                                <button type="button" className="btn btn-brand-1 bg-danger btn-sm" onClick={handleExcelRemove}>Remove</button>
                                <a href='/Template winopps.xlsx' className="btn btn-brand-1 bg-success btn-sm " download>Download Template</a>
                                <Popup
                                    trigger={
                                        <img src="/info.jpg" alt="info" width={24} height={24} style={{ cursor: "pointer" }} />
                                    }
                                    position="bottom center"
                                    closeOnDocumentClick
                                    arrow={true}
                                >
                                    <div style={{ width: "300px", fontSize: '14px', backgroundColor: 'black', textAlign: "center", fontFamily: 'Cursive', borderRadius: "10px", color: "white" }}>
                                        <p>Information on how to fill excel sheet and download excel option</p>
                                    </div>
                                </Popup>
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-3">
                        <p style={{ color: "red" }}>OR</p>
                    </div>
                    <div id="textDiv" className="form-group" style={{ paddingTop: '12px' }}>
                        <label>Copy and Paste the Proposal Structure from RFx or Your Own Text</label>
                        <div class="form-group">
                            <textarea
                                className="form-control"
                                id="ProposalStructureArea"
                                rows="8"
                                value={text}
                                onChange={handleText}
                                disabled={excelBool}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseExcel}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={!excelBool ? () => importText() : () => importExcel()} disabled={!(excelBool || text.length > 7)}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Import</span>
                    </button>
                </div>
            </ReactModal>


            <ReactModal
                initWidth={840}
                initHeight={'auto'}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={showModal}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{modalType} Template Content</h5>
                    <button type="button" className="close" onClick={handleClose} >
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Heading / Title</label>
                                <input type="text" className="form-control" onChange={handleChange} name="heading" value={formFields['heading']} />
                                <span className="text-danger">{flag === true && formFields.heading === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                {/* <Editor
                                    apiKey={tiny_key}
                                    onEditorChange={(evt, editor) => descriptionRef.current = evt}
                                    initialValue={formFields.description}
                                    init={{
                                        height: 390,
                                        menubar: 'file edit view insert format tools table tc help',
                                        selector: 'textarea#full-featured',
                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table ',
                                        toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                /> */}
                                {/* Added this code for  WOII-261. Prasanna. October 16 2024.. Code Start*/}
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                {/*Prasanna. October 16 2024. Code End*/}
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleClose()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onsubmit()} disabled={formFields.modalButtonLoader || isViewPath}>
                        {formFields.modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                </div>
            </ReactModal>
            {
                showChatModal == false &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                    {/*<img src={chatIcon} />*/}
                    {/* Commenting end. Divya. October 06 2023.. */}
                    {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                    <img src={chatIcon} className="chat-icon" />
                    {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                    {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                    {/* Commenting end. Divya. November 14 2023.. */}
                    {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                    <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                    {/* Divya. November 14 2023. Code End */}
                    {/* Divya. October 06 2023. Code End */}
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={preRfxData.id}
                    title={preRfxData?.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default AddProposalTemplate;