import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { APIMethod } from '../API/APIClient'

class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false
        };
    }

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }

    delete = () => {
        this.props.deleteData();
    }

    render() {
        return (
            <Fragment>
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="sign-in-title">
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{alignSelf: 'center'}}>
                        <div className="ml-5 mr-5">
                            <img src={require('../assets/css/images/cross.png').default} style={{marginLeft: '37%'}} alt="No image found" id="img_close" width="80" />
                            <p style={{fontSize: 20, textAlign: 'center'}} className="mt-4">Are You Sure ?</p>
                            <p style={{textAlign: 'center'}}>Do you really want to Archive these records? This process cannot be undone.</p>
                            <button type="button" className="btn btn-light mr-1" style={{marginLeft: '30%'}} onClick={() => this.isShowModal(false)}> Cancel</button>
                            {/* <button type="button" className="btn btn-danger ml-3" onClick={() => this.delete()}> Delete</button> */}
                            <button type="button" className="btn btn-danger ml-3" onClick={() => this.delete()}> Archive</button>
                        </div>
                    </Modal.Body>

                </Modal >
            </Fragment >

        );
    }
}

export default (DeleteModal);