import React, { useEffect, useState } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { NavLink } from "react-router-dom";
import { APIMethod, FileUploadMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import moment from "moment";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from "react-modal-resizable-draggable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ManageQApproval = (props) => {
  const [getListData, setGetListData] = useState([]);
  const [RFxCategoryData, setRFxCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  // Edit
  const [edit, setEdit] = useState("");
  const [id, setId] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 07 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 07 2023. Code End */
  }
  // Toast
  const { addToast } = useToasts();
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectAllValue, setSelectAllValue] = useState(false);
  const [formFields, setFormFields] = useState({
    // Commenting the following code for WOII-185. Divya. March 15 2024.. Code Start
    //fromDate: undefined, toDate: undefined, question: '', answer: '', rfxCategory: ''
    // Commenting end. Divya  March 15 2024.. */}
    // Added this code for  WOII-185. Divya. March 15 2024.. Code Start
    fromDate: undefined,
    toDate: undefined,
    question: "",
    answer: "",
    rfxCategory: "",
    status: "ACTIVE",
    // Divya. March 15 2024. Code End
  });
  {
    /*Added this code for  WOII-118. Divya. December 23 2023.. Code Start */
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [file, setFile] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [excel, setExcel] = useState();
  {
    /* Divya. December 23 2023. Code End*/
  }
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getQAList(1)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    getQAList(currentPage);
    {
      /* Divya. November 07 2023. Code End */
    }
    getRfxCategoryList();
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    // }, [])
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 07 2023. Code End */
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  {
    /*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseExcel = () => {
    setIsOpen(false);
  };

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const handleExcelSelect = (event) => {
    setExcel(event.target.files[0]);
  };

  {
    /*Added this code for  WOII-118. Bhovan. December 27 2023.. Code Start*/
  }
  const save = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", excel);

      const apifetcherObj = await FileUploadMethod(
        "/qna/file",
        formData,
        "POST"
      );
      //   console.log(apifetcherObj.text())
      const [responseStatus, responseData] = await Promise.all([
        apifetcherObj.status,
        apifetcherObj.text(),
      ]);

      if (responseStatus === 200) {
        addToast(responseData, { appearance: "success", autoDismiss: true });
        setIsOpen(false);
        // return responseData.result;
      } else {
        addToast(responseData, { appearance: "error", autoDismiss: true });
        // Handle the error or set appropriate state here
        setIsOpen(false);
        setButtonLoader(false);
      }
    } catch (error) {
      setIsOpen(false);
      addToast("Error while uploading the file", {
        appearance: "error",
        autoDismiss: true,
      });
      // Handle the error or set appropriate state here
      setButtonLoader(false);
      //   console.error('Error during file upload:', error);
    }
  };

  {
    /* Bhovan. December 27 2023. Code End*/
  }

  {
    /* Commenting the following code for WOII-118. Bhovan. December 27 2023. Comment Code Start*/
  }
  // const save = async(file) => {
  //     try {
  //     const formData = new FormData();
  //     formData.append('file', excel);

  //     const apifetcherObj = FileUploadMethod(`/qna/file`, formData, 'POST');
  //         apifetcherObj
  //             .then(response => { return Promise.all([response.status, response.json()]) })
  //             .then(res => {
  //                 let statusCode = res[0]
  //                 let data = res[1]
  //                 console.log(res,statusCode)
  //                 if (statusCode === 200) {
  //                     addToast(data.message, { appearance: 'success', autoDismiss: true });
  //                     resolve(data.result);
  //                 } else {
  //                     addToast(data.message, { appearance: 'error', autoDismiss: true });
  //                     setButtonLoader(false)
  //                 }
  //             })
  //         }
  //             catch(error){
  //                 setButtonLoader(false)
  //             }
  //         }
  // }
  {
    /* Divya. December 23 2023. Code End*/
  }
  {
    /* Bhovan. December 27 2023. Comment End*/
  }
  const checkPermission = (data) => {
    const resObject = data.find(
      (item) => item.name === "Content Library Setup"
    );
    const valObject = resObject.access.find((item) => item.name === "Q&As");
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getQAList = (page, key, type) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var filter_start_date = "";
    if (formFields.fromDate) {
      filter_start_date =
        moment(formFields.fromDate).format("YYYY-MM-DD") ==
          moment(formFields.toDate).format("YYYY-MM-DD")
          ? ""
          : moment(formFields.fromDate).format("YYYY-MM-DD");
    }
    const filter_end_date =
      formFields.toDate === undefined
        ? ""
        : moment(formFields.toDate).format("YYYY-MM-DD");
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //const apifetcherObj = APIMethod(`/qna/list/${page}?question=${formFields.question}&answer=${formFields.answer}&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${formFields.rfxCategory}&question_answer=` + new_key, null, method)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    {
      /* Commenting the following code for WOII-185. Divya. March 15 2024.. Code Start  */
    }
    //const apifetcherObj = APIMethod(`/qna/list/${page}?question=${formFields.question}&answer=${formFields.answer}&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${formFields.rfxCategory}&is_approvals=${true}&limit=${rowsPerPage}&question_answer=` + new_key, null, method)
    {
      /* Divya. November 07 2023. Code End */
    }
    {
      /* Commenting end. Divya  March 15 2024.. */
    }
    {
      /* Added this code for  WOII-185. Divya. March 15 2024.. Code Start */
    }
    const apifetcherObj = APIMethod(
      `/qna/list/${page}?question=${formFields.question}&answer=${formFields.answer
      }&from_date=${filter_start_date}&to_date=${filter_end_date}&rfx_category_id=${formFields.rfxCategory
      }&is_approvals=${true}&limit=${rowsPerPage}&status=${formFields.status
      }&question_answer=` + new_key,
      null,
      method
    );
    {
      /* Divya. December 23 2023. Code End*/
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          var newData = [];
          for (let i = 0; i < data.list.length; i++) {
            if (data.list[i].q_status == "PENDING_APPROVAL") {
              newData.push(data.list[i]);
            }
          }
          setGetListData(newData);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        } else {
          addToast(data.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  {
    /* Added this code for  WOII-185. Divya. March 15 2024.. Code Start */
  }
  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(`/qna/${id}/enable`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getQAList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling Qna:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(`/qna/${id}/delete`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getQAList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting Qna:", error);
      });
  };
  {
    /* Divya. December 23 2023. Code End*/
  }

  const getRfxCategoryList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setRFxCategoryData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 07 2023.. Code Start
    //getQAList(pageNumber)
    //Commenting end. Divya. November 07 2023..
    //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
    setCurrentPage(pageNumber);
    if (selectAllValue === true) {
      setSelectAllValue(false);
    }
    {
      /* Divya. November 07 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */
  }
  // const handleRowsPerPageChange = (selectedValue) => {
  //   setRowsPerPage(selectedValue);
  // };
  {
    /* Divya. November 02 2023. Code End */
  }

  /* Added this code for  WOII-262. Prasanna. October 23 2024.. Code Start */
  useEffect(() => {
    const currentPath = window.location.pathname;
    const savedRowsPerPage = localStorage.getItem(`rowsPerPage_${currentPath}`);
    if (savedRowsPerPage) {
      setRowsPerPage(Number(savedRowsPerPage));
    }
  }, []);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);

    const currentPath = window.location.pathname;
    localStorage.setItem(`rowsPerPage_${currentPath}`, newRowsPerPage);
  };

  /* Prasanna. October 23 2024. Code End */


  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const Search = (key) => {
    getQAList(1, key);
  };

  const _onEdit = (val) => {
    history.push({
      pathname: "/edit-q-a/:id",
      state: { details: val, page: "qna_approvals" },
    });
  };

  const getData = () => {
    getQAList(1);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "QUESTION") {
      formFields.question = "";
    } else if (type === "ANSWER") {
      formFields.answer = "";
    } else if (type === "RFX_CATEGORY") {
      formFields.rfxCategory = "";
    } else if (type === "FROM_DATE") {
      formFields.fromDate = undefined;
    } else if (type === "TO_DATE") {
      formFields.toDate = undefined;
    }
    //Added this code for  WOII-185. Divya. March 15 2024.. Code Start
    else if (type == "STATUS") {
      formFields.status = "";
    }
    // Divya. December 23 2023. Code End
    else {
      formFields.question = "";
      formFields.answer = "";
      formFields.rfxCategory = "";
      {
        /* Added this code for  WOII-185. Divya. March 15 2024.. Code Start */
      }
      formFields.status = "";
      {
        /* Divya. December 23 2023. Code End*/
      }
      formFields.fromDate = undefined;
      formFields.toDate = undefined;
      handleClose();
    }
    getQAList(1, "", "FILTER");
  };

  const _onFilter = () => {
    getQAList(1, "", "FILTER");
    handleClose();
  };

  const setFromDate = (date) => {
    const formFieldsObject = {
      ...formFields,
      fromDate: date,
    };
    setFormFields(formFieldsObject);
  };

  const dateValidation = (date) => {
    if (formFields.fromDate) {
      const formFieldsObject = {
        ...formFields,
        toDate: date,
      };
      setFormFields(formFieldsObject);
    } else {
      addToast("Please select RFx closing from date first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  function truncateText(text, maxLength = 200, datatype = 1) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;

    // Get the decoded text content
    var decodedText = tempElement.textContent || tempElement.innerText;
    decodedText = decodedText.replace(/\s+/g, " ");
    if (datatype === 0) {
      return decodedText;
    }
    // Truncate the replaced text if needed
    if (decodedText.length <= maxLength) {
      return decodedText;
    } else {
      return decodedText.substring(0, maxLength) + "…";
    }
  }

  const selectedQandAsapproval = (id) => {
    setSelectAllValue(!selectAllValue);
    for (var i in getListData) {
      if (id == getListData[i].id) {
        getListData[i].isSelected = !getListData[i].isSelected;
      }
    }
    setGetListData([...getListData]);
  };

  const onDeleteAll = () => {
    var array = [];
    var count = 0;
    for (var i in getListData) {
      if (getListData[i].isSelected == true) {
        count = count + 1;
        array.push(getListData[i].id);
      }
    }
    if (count == 0) {
      addToast("Please select first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setLoading(true);
      var method = "DELETE";
      var body = JSON.stringify(array);
      const apifetcherObj = APIMethod("/qna/delete/bulk", body, method);
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];

          if (statusCode === 200) {
            setSelectAllValue(false);
            setLoading(false);
            getQAList(1);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const onSelectAll = () => {
    for (var i in getListData) {
      getListData[i].isSelected = !getListData[i].isSelected;
    }
    setSelectAllValue(true);
    setGetListData([...getListData]);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Q&As Approvals</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                {/*Commenting the following code for WOII-118. Divya. December 23 2023.. Code Start*/}
                {/*<input type="search" className="form-control" placeholder="Search by question and answer" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>*/}
                {/*Commenting end. Divya. december 23 2023..*/}
                {/*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/}
                <input
                  type="search"
                  className="form-control"
                  style={{ width: "200px" }}
                  placeholder="Search by question and answer"
                  onChange={(event) => Search(event.target.value)}
                />
                <div
                  className="input-group-prepend"
                  style={{ position: "relative", zIndex: "2" }}
                >
                  <span
                    className="input-group-text text-secondary"
                    style={{ marginLeft: "-40px", zIndex: "2" }}
                  >
                    <i className="icofont-search-1"></i>
                  </span>
                </div>
                {/* Divya. December 23 2023. Code End*/}
              </div>
              {/* UnCommenting the following code for WOII-185. Divya. March 15 2024.. Code Start  */}
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShow(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {/* UnCommenting end. Divya  March 15 2024..  */}
              <button
                type="button"
                className="btn delete-all-button mr-5"
                style={{ float: "right" }}
                onClick={() => onDeleteAll(true)}
              >
                Delete
              </button>
            </div>
          </div>

          <div className="mb-2 filter-by">
            {formFields.question ? (
              <div className="badge">
                <span className="lable">Question</span>
                <span className="value">{formFields.question}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("QUESTION")}
                ></i>
              </div>
            ) : null}
            {formFields.answer ? (
              <div className="badge">
                <span className="lable">Answer</span>
                <span className="value">{formFields.answer}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("ANSWER")}
                ></i>
              </div>
            ) : null}
            {/* Added this code for  WOII-185. Divya. March 15 2024.. Code Start */}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                      ? "Archived"
                      : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
            {/* Divya. March 15 2024. Code End  */}
            {formFields.rfxCategory ? (
              <div className="badge">
                <span className="lable">RFx Category</span>
                {RFxCategoryData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.category == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("RFX_CATEGORY")}
                ></i>
              </div>
            ) : null}
            {formFields.fromDate ? (
              <div className="badge">
                <span className="lable">Q&As From Date</span>
                <span className="value">
                  {moment(formFields.fromDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("FROM_DATE")}
                ></i>
              </div>
            ) : null}
            {formFields.toDate ? (
              <div className="badge">
                <span className="lable">Q&As To Date</span>
                <span className="value">
                  {moment(formFields.toDate).format("MM-DD-YYYY")}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("TO_DATE")}
                ></i>
              </div>
            ) : null}
          </div>
          <div
            className="table-responsive "
            style={{
              height: "70vh",
              overflowY: "auto",
              marginBottom: "20px",
              resize: "vertical",
              overflow: "auto",
              maxHeight: "110vh"
            }}
          >
            <table className="table table-style-1 table-responsive-lg mb-4 table reports">
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      onChange={() => onSelectAll()}
                      checked={selectAllValue}
                    />
                  </th>
                  <th scope="col">QAID</th>
                  <th scope="col">Question/Topic</th>
                  <th scope="col">Answer</th>
                  <th scope="col">RFx Category</th>
                  <th scope="col">Creation Date</th>
                  <th scope="col" className="text-center" width="80">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/qna/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? getListData.map((res) => {
                    {
                      /* Added this code for  WOII-185. Divya. March 15 2024.. Code Start */
                    }
                    const isRowArchived = res.status === "DELETED";
                    {
                      /* Divya. March 15 2024. Code End  */
                    }
                    return (
                      // Commenting the following code for WOII-185. Divya. March 15 2024.. Code Start
                      //<tr>
                      //Commenting end. Divya  March 15 2024..
                      // Added this code for  WOII-185. Divya. March 15 2024.. Code Start
                      <tr
                        key={res.id}
                        className={isRowArchived ? "archived" : ""}
                      >
                        {/* Divya. March 15 2024. Code End */}
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => selectedQandAsapproval(res.id)}
                            checked={res.isSelected}
                          />
                        </td>
                        <td>QA{res.id}</td>
                        <td title={res.question}>
                          {res.question.replace(/(.{50})..+/, "$1…")}
                        </td>
                        <td
                          title={res.answer
                            .replace(/<[^>]+>/g, "")
                            .replace(/&rsquo;/g, "’")
                            .replace(/&nbsp;/g, " ")
                            .replace(/&ndash;/g, "—")
                            .replace(/&lt;/g, "<")
                            .replace(/&gt;/g, ">")
                            .replace(/&amp;/g, "&")
                            .replace(/&quot;/g, '"')
                            .replace(/&apos;/g, "'")
                            .replace(/&copy;/g, "©")
                            .replace(/&reg;/g, "®")
                            .replace(/&trade;/g, "™")
                            .replace(/&euro;/g, "€")
                            .replace(/&pound;/g, "£")
                            .replace(/&yen;/g, "¥")
                            .replace(/&cent;/g, "¢")
                            .replace(/&times;/g, "×")
                            .replace(/&divide;/g, "÷")
                            .replace(/&plusmn;/g, "±")
                            .replace(/&frac14;/g, "¼")
                            .replace(/&frac12;/g, "½")
                            .replace(/&frac34;/g, "¾")}
                        >
                          {truncateText(res.answer)}
                        </td>
                        <td>{res.rfx_category_name}</td>
                        <td>
                          {res.start_date
                            ? moment(res.start_date).format("MM-DD-YYYY")
                            : null}
                        </td>
                        <td className="text-center">
                          {/* Commenting the following code for WOII-185. Divya. March 15 2024.. Code Start  */}
                          {/* {
                                                        editing === true ?
                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> : null
                                                    } */}
                          {/* Commenting end. Divya  March 15 2024..
                                                    Added this code for  WOII-185. Divya. March 15 2024.. Code Start */}
                          {isRowArchived ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-action enable"
                                onClick={() => enableRow(res.id)}
                              >
                                <i
                                  className="icofont-check"
                                  style={{ fontSize: "18px" }}
                                ></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-action delete ml-1"
                                onClick={() => deleteRow(res.id)}
                              >
                                <i className="icofont-archive"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              {editing === true ? (
                                <button
                                  type="button"
                                  className="btn btn-action edit mr-1"
                                  onClick={() => _onEdit(res)}
                                >
                                  <i className="icofont-pencil-alt-2"></i>
                                </button>
                              ) : null}
                              {deleting === true ? (
                                <button
                                  type="button"
                                  className="btn btn-action delete"
                                  onClick={() => _onDelete(res.id)}
                                >
                                  <i className="icofont-archive"></i>
                                </button>
                              ) : null}
                            </>
                          )}
                          {/* Divya. March 15 2024. Code End */}
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Q&A Entrie Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                /* Added this code for  WOII-262. Prasanna. October 21 2024.. Code Start */
                rowsPerPage={rowsPerPage}
                onrowsPerPageChange={handleRowsPerPageChange}
              /* Prasanna. October 21 2024. Code End */
              //Added this code for  WOII-120. Divya. November 07 2023.. Code Start
              // rowsPerPage={handleRowsPerPageChange}
              // Divya. November 07 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter Q&As
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Question/Topic</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="question"
                  value={formFields["question"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Answer</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="answer"
                  value={formFields["answer"]}
                />
              </div>
            </div>
            {/* Added this code for  WOII-185. Divya. March 15 2024.. Code Start */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option value="DELETED">Archived</option>
                </select>
              </div>
            </div>
            {/* Divya. March 15 2024. Code End */}
            <div className="col-md-6">
              <div className="form-group">
                <label>Category</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="rfxCategory"
                  value={formFields["rfxCategory"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {RFxCategoryData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Q&As From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  onChange={(date) => setFromDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Q&As To Date </label>
                <DatePicker
                  selected={formFields.toDate}
                  onChange={(date) => dateValidation(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={formFields.fromDate}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>

      {/*Added this code for  WOII-118. Divya. December 23 2023.. Code Start*/}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        className="contained-modal-title-vcenter"
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
      >
        <div className="modal-header">
          <h5 className="modal-title">Select Word Document</h5>
          <button type="button" className="close" onClick={handleCloseModal}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Upload Word Document</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile02"
                accept=".docx"
                onChange={handleFileSelect}
              />
              <label
                className="custom-file-label"
                htmlFor="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                {file === undefined ? "Choose File" : file.name}
              </label>
              <span className="text-danger">
                {modalFlag === true && file === undefined
                  ? "Please Select file"
                  : null}
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={save}
            disabled={buttonLoader}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>SaveWord</span>
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleCloseExcel}
        className="contained-modal-title-vcenter"
        initWidth={550}
        initHeight={"auto"}
        top={137.5}
      >
        <div className="modal-header">
          <h5 className="modal-title">Select Excel Document</h5>
          <button type="button" className="close" onClick={handleCloseExcel}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Upload Excel Document</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile02"
                accept=".xlsx"
                onChange={handleExcelSelect}
              />
              <label
                className="custom-file-label"
                htmlFor="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
              >
                {excel === undefined ? "Choose File" : excel.name}
              </label>
              <span className="text-danger">
                {modalFlag === true && excel === undefined
                  ? "Please Select file"
                  : null}
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseExcel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => save()}
            disabled={buttonLoader}
          >
            {buttonLoader && (
              <img
                src={
                  require("../../../../assets/css/images/button_loader.gif")
                    .default
                }
                alt="loading..."
                style={{ marginLeft: 5 }}
              />
            )}
            <span>Save</span>
          </button>
        </div>
      </ReactModal>
      {/* Divya. December 23 2023. Code End*/}
    </>
  );
};
export default ManageQApproval;
