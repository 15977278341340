import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { APIMethod } from '../API/APIClient'
import CustomLoader from './Loader';

class ModalPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false
        };
    }

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }

    delete = () => {
        this.setState({ loading: true });
        var method = 'DELETE'
        const apifetcherObj = APIMethod(this.props.endPoint + this.props.id, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    this.props.updateList('1');
                    this.isShowModal(false)
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
            })
    }

//Added this code for  WOII-185. Divya. March 14 2024.. Code Start 
    archive = () => {
        this.setState({ loading: true });
        var method = 'PUT';
        const apifetcherObj = APIMethod(
            this.props.endPoint + this.props.id + '/archive',
            null,
            method
        );
        apifetcherObj
            .then(response => {
                return Promise.all([response.status, response.json()]);
            })
            .then(res => {
                let statusCode = res[0];
                let data = res[1];

                if (statusCode === 200) {
                    this.props.updateList('1');
                    this.isShowModal(false)
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    };
   // Divya. March 14 2024. Code End 
    render() {
        return (
            <>
                <Fragment>
                    <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            {/* Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start */}
                            {/* <Modal.Title id="sign-in-title">
                                Alert
                            </Modal.Title> */}
                            {/* Commenting end. Divya  March 14 2024.. */}
                            {/* Added this code for  WOII-185. Divya. March 14 2024.. Code Start  */}
                            <Modal.Title id="sign-in-title">
                                Archive/Delete
                            </Modal.Title>
                            {/* Divya. March 14 2024. Code End  */}
                        </Modal.Header>
                        {/* Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start */}
                        {/* <Modal.Body style={{ alignSelf: 'center' }}> */}
                        {/* Commenting end. Divya  March 14 2024.. */}
                        {/* Added this code for  WOII-185. Divya. March 14 2024.. Code Start  */}
                        <Modal.Body style={{ alignSelf: 'center', height: '200px', width: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center'  }}>
                        {/* Divya. March 14 2024. Code End  */}
                        {/* Commenting the following code for WOII-185. Divya. March 14 2024.. Code Start */}
                            {/* <div className="ml-5 mr-5">
                                <img src={require('../assets/css/images/cross.png').default} style={{ marginLeft: '37%' }} alt="No image found" id="img_close" width="80" />
                                <p style={{ fontSize: 20, textAlign: 'center' }} className="mt-4">Are You Sure ?</p>
                                <p style={{ textAlign: 'center' }}>Do you really want to archive these records? This process cannot be undone.</p>
                                <button type="button" className="btn btn-light mr-1" style={{ marginLeft: '30%' }} onClick={() => this.isShowModal(false)}> Cancel</button>
                                {/* <button type="button" className="btn btn-danger ml-3" onClick={() => this.delete()}> Delete</button> */}
                                {/*<button type="button" className="btn btn-danger ml-3" onClick={() => this.delete()}> Archive</button> */}
                                {/* Commenting end. Divya  March 14 2024.. */}
                                {/* Added this code for  WOII-185. Divya. March 14 2024.. Code Start  */}
                                <div className="ml-5 mr-5 d-flex justify-content-between">
                                    <div className="d-flex flex-column align-items-center" style={{ paddingRight: '5px' }}>
                                        <button type="button" className="btn btn-brand-1" onClick={() => this.archive()}>Archive</button> 
                                        <div style={{fontSize:"16px"}} className="mt-2">Inactive Data</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center" style={{ paddingLeft: '5px' }}>
                                        <button type="button" className="btn btn-danger px-4" onClick={() => this.delete()} style={{ fontSize: '18px', width: '120px' }}>Delete</button> 
                                        <div style={{fontSize:"16px"}} className="mt-2">Permanently Removes Data</div>
                                    </div>
                                </div>
                                {/* Divya. March 14 2024. Code End  */}
                        </Modal.Body>

                    </Modal >
                </Fragment >
            </>
        );
    }
}

export default (ModalPopup);