import React, { useState, useEffect, useRef, useMemo } from "react";
import { Breadcrumb, Modal, Button, Alert } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, tiny_key } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from "../../../../utility/utility";
import {CKEditor} from 'ckeditor4-react';

var TOAST_TIMEOUT = 2500;
const AddQa = (props) => {
    const answerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [pageComing, setPageComing] = useState('');
    const [approverData, setApproverData] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const [formFields, setFormFields] = useState({ comment: '', id: '', commentAddLoader: false, isComment: false, topic: '', q_status: '', keywords: '', RfxCategoryId: '', id: '' })
    const [answer, setAnswer] = useState('');
    const [sendForApprovalLoader, setSendForApprovalLoader] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [pushbackLoader, setPushbackLoader] = useState(false);

    const [commentData, setCommentData] = useState([]);
    const [postLoader, setPostLoader] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const [flag, setFlag] = useState(false);
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    // Toast
    const { addToast } = useToasts();
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    const [cancelModal, setCancelModal] = useState(false);

    const editor = useRef(null)

    const handleModal = () => {
        setCancelModal(!cancelModal)
    }

    useEffect(() => {
        getRFxCategoryList()
        if (props.location.state !== null) {
            getTemplateContentComments()
        }
        check()
    }, [])

    useEffect(() => {
        if (answerRef.current) {
            answerRef.current.setData(answer, {
                    callback: function () {
                    console.log('Data is set:', this.getData());
                    },
            });
        }
    }, [answerRef.current])

    const check = () => {
        if (props.location.state === null) {
        } else {
            setPageComing(props.location.state.page)

            setFormFields({ ...formFields, topic: props.location.state.details.question, q_status: props.location.state.details.q_status, keywords: props.location.state.details.keyword, RfxCategoryId: props.location.state.details.rfx_category_id, id: props.location.state.details.id })
            if (props.location.state.details.aquestion != null) {
                var approver = { 'topic': props.location.state.details.aquestion, 'keywords': props.location.state.details.akeyword, 'RfxCategoryId': props.location.state.details.arfx_category_id, 'answer': props.location.state.details.aanswer, 'start_date': props.location.state.details.astart_date }
                setApproverData(approver)

            }

            setAnswer(props.location.state.details.answer)
            setStartDate(new Date(props.location.state.details.start_date))
        }
    }

    const getRFxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }
    const getTemplateContentComments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/template-content/comments/list/${props.location.state.details.id ? props.location.state.details.id : null}?comment_type=QNA_COMMENT`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }
    const addComment = () => {
        if (formFields.comment == '') {
            addToast('Please enter comment', { autoDismissTimeout: 2500, appearance: 'error', autoDismiss: true });
        } else {
            setPostLoader(true)
            var method = 'POST'
            var params = JSON.stringify({
                comment: formFields.comment,
                pre_rfx_id: props.location.state.details.id,
                type: 'QNA_COMMENT'
            })
            const apifetcherObj = APIMethod(`/template-content/post/comment`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode == 200) {
                        // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        getTemplateContentComments()
                        formFields.comment = ''
                        setPostLoader(false)
                        formFields.isComment = true
                    } else {
                        addToast(data.message, { autoDismissTimeout: 2000, appearance: 'error', autoDismiss: true });
                        setPostLoader(false)
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: 2000, appearance: 'error', autoDismiss: true });
                    setPostLoader(false)
                })
        }
    }

    const formatDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }



    const save = (type = '') => {
        if (formFields.isComment === false && (type == 'PENDING_APPROVAL' || type == 'PUSH_BACK' || type == 'APPROVED')) {
            addToast('Please Enter Comment First', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
        else {
            let newdate = moment(startDate).format(
                "YYYY-MM-DD"
            );
            if (formFields.topic === '' || formFields.keywords === '' || checkEmptyString(answerRef.current.getData()) || formFields.RfxCategoryId === '') {
                setFlag(true)
                addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
            } else {


                if (formFields.id === '') {
                    setButtonLoader(true)
                    var method = 'POST'

                    var params = JSON.stringify({
                        question: formFields.topic,
                        keyword: formFields.keywords,
                        answer: answerRef.current.getData(),
                        start_date: formatDate('Sun May 11,2014'),
                        rfx_category_id: formFields.RfxCategoryId,
                        q_status: 'Q&A_CREATED',
                    })



                } else {
                    if (type == 'PENDING_APPROVAL') {
                        // if(!isSaved){
                        //     addToast('Changes not saved !', {autoDismissTimeout: 2000, appearance: 'error', autoDismiss: true });

                        //     return
                        // }

                        setSendForApprovalLoader(true)
                        var method = 'PUT'
                        var params = JSON.stringify({
                            id: formFields.id,
                            question: formFields.topic,
                            keyword: formFields.keywords,
                            answer: answerRef.current.getData(),
                            start_date: newdate,
                            rfx_category_id: formFields.RfxCategoryId,
                            q_status: 'PENDING_APPROVAL',
                        })

                    }
                    else if (type == 'PUSH_BACK' && pageComing == 'qna_approvals') {
                        setPushbackLoader(true)
                        var method = 'PUT'
                        var params = JSON.stringify({
                            id: formFields.id,
                            aquestion: formFields.topic,
                            akeyword: formFields.keywords,
                            aanswer: answerRef.current.getData(),
                            astart_date: newdate,
                            arfx_category_id: formFields.RfxCategoryId,
                            q_status: 'PUSH_BACK',
                        })
                    }
                    else if (type == 'APPROVED' && pageComing == 'qna_approvals') {
                        setApproveLoader(true)
                        var method = 'PUT'
                        var params = JSON.stringify({
                            id: formFields.id,
                            question: formFields.topic,
                            keyword: formFields.keywords,
                            answer: answerRef.current.getData(),
                            start_date: newdate,
                            rfx_category_id: formFields.RfxCategoryId,
                            q_status: 'APPROVED',
                        })

                    }
                    else {

                        setButtonLoader(true)
                        setIsSaved(true)
                        var method = 'PUT'
                        var params = JSON.stringify({
                            id: formFields.id,
                            question: formFields.topic,
                            keyword: formFields.keywords,
                            answer: answerRef.current.getData(),
                            start_date: newdate,
                            rfx_category_id: formFields.RfxCategoryId,
                        })


                    }



                }


                const apifetcherObj = APIMethod(`/qna/`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]
                        if (statusCode === 200) {
                            if (type == 'PENDING_APPROVAL') {
                                addToast('Q&A Sent for Approval', { appearance: 'success', autoDismiss: true });
                            }
                            else if (type == 'PUSH_BACK') {
                                addToast('Q&A Pushed Back', { appearance: 'success', autoDismiss: true });
                            }
                            else if (type == 'APPROVED') {
                                addToast('Q&A Approved', { appearance: 'success', autoDismiss: true });
                            }
                            else {
                                addToast(data.message, { appearance: 'success', autoDismiss: true });
                            }

                            formFields.isComment = false
                            setButtonLoader(false)
                            setSendForApprovalLoader(false)
                            setFlag(false)
                            goBack()
                        } else {
                            addToast(data.message, { appearance: 'error', autoDismiss: true });
                            setSendForApprovalLoader(false)
                            setButtonLoader(false)
                        }
                    })
                    .catch(error => {
                        setSendForApprovalLoader(false)
                        setButtonLoader(false)
                    })
            }
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const modalCheck = () => {
        if (formFields.topic === '' && formFields.keywords === '' && answer === '' && formFields.RfxCategoryId === '') {
            goBack();
        } else {
            goBack();
        }
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Modal show={cancelModal} onHide={() => handleModal()}  {...props}
                        size=""
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>Alert</Modal.Header>
                        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-lg btn-brand-1" onClick={() => handleModal()}>No</Button>
                            <Button className="btn btn-lg btn-brand-1 bg-danger" onClick={goBack}>Yes</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Content Library Setup</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Content Entries
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{formFields.id ? 'Edit Q & A' : 'Add Q & A'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="d-flex" style={{ height: 540, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                        <div style={{ minWidth: "900px" }}>
                            <div className="add-header text-center">
                                <span className="add-title ">{formFields.id ? 'Edit Q&A' : 'Add Q&A'}</span>
                            </div>
                            <div className="form-wraper form-style mb-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                        {/*<h4 className="font-20 font-medium">Topic</h4>*/}
                                        {/* Commenting end. Divya. September 07 2023.. */}
                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                        <h2 className="font-20 font-bold">Topic</h2>
                                        {/* Divya. September 07 2023. Code End */}
                                        <div className="form-group">
                                            <label>Topic/Question</label>
                                            <input disabled={formFields['q_status'] == 'APPROVED' ? true : false} type="text" className="form-control" onChange={handleChange} name="topic" value={formFields['topic']} />
                                            <validate
                                                flag={true}
                                                name={formFields.topic}
                                                error={'Please Enter Topic/Question'}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Creation Date</label>
                                            <DatePicker
                                                selected={startDate}
                                                disabled={formFields['q_status'] == 'APPROVED' ? true : false}
                                                onChange={(date) => setStartDate(date)}
                                                className="form-control"
                                                placeholder="select Date"
                                                dateFormat="MM-dd-yyyy"
                                                scrollableYearDropdown
                                                showYearDropdown
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>RFx Category</label>
                                            <select id="disabledSelect" disabled={formFields['q_status'] == 'APPROVED' ? true : false} className="form-control custom-select" value={formFields['RfxCategoryId']} name="RfxCategoryId" onChange={handleChange}>
                                                <option value="" disabled selected>Select your option</option>
                                                {
                                                    getListData.map((res) => {
                                                        return (
                                                            <option value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{flag === true && formFields.RfxCategoryId === '' ? 'Please Enter Topic/Question' : null}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Keywords/Tag</label>
                                            <input disabled={formFields['q_status'] == 'APPROVED' ? true : false} type="text" className="form-control" onChange={handleChange} name="keywords" value={formFields['keywords']} />
                                            <span className="text-danger">{flag === true && formFields.keywords === '' ? 'Please Enter Keywords/Tag' : null}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                        {/*<h4 className="font-20 font-medium">Content</h4>*/}
                                        {/* Commenting end. Divya. September 07 2023.. */}
                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                        <h2 className="font-20 font-bold">Content</h2>
                                        {/* Divya. September 07 2023. Code End */}
                                        {/* <Editor
                                            apiKey={tiny_key}
                                            disabled={formFields['q_status'] == 'APPROVED' ? true : false}
                                            onInit={(evt, editor) => answerRef.current = editor}
                                            initialValue={answer}
                                            init={{
                                                height: `${formFields['q_status'] == 'APPROVED' ? 300 : 500}`,
                                                menubar: 'file edit view insert format tools table tc help',
                                                selector: 'textarea#full-featured',
                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                                toolbar: 'undo redo |blocks| fontfamily fontsize | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                            }}
                                        /> */}
                                        <CKEditor
                                            initData={answer}
                                            readOnly={formFields['q_status'] == 'APPROVED' ? true : false}
                                            onInstanceReady={(event) => answerRef.current = event.editor}
                                            name="ai_scope"
                                            config={{
                                                versionCheck: false,
                                                toolbar: [
                                                { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                    ],
                                             }}
                                        />
                                        <span className="text-danger">{flag === true && answer === '' ? 'Please Enter Content' : null}</span>
                                    </div>
                                    {props.location.state !== null && (
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="font-16 mb-0">Comments</h4>
                                                </div>
                                                <div className="card-body bg-white">
                                                    {
                                                        commentData.map((res, index) => {
                                                            return (
                                                                <div key={index} className="bg-light p-2 rounded mb-2">
                                                                    <p className="mb-1">
                                                                        <span className="font-medium mr-2">{res.user.first_name}{res.user.last_name}</span>
                                                                        <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                                        {/* <span className="badge badge-warning">Pushed Back</span> */}
                                                                    </p>
                                                                    <p className="font-14 mb-0 ">{res.comment}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <div className="form-group form-style">
                                                            <label>Add Comment</label>
                                                            <textarea className="form-control h-auto mb-2" rows="2" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
                                                            <button type="button" className="btn btn-brand-1 btn-sm" onClick={() => addComment()} disabled={postLoader || pageComing === 'View'}>
                                                                {postLoader && (
                                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                )}
                                                                Post <i className="icofont-paper-plane"></i></button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    )}


                                    <div className="col-12 text-right mt-4">
                                        {
                                            formFields['q_status'] != 'APPROVED' ? (
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger px-4" onClick={() => modalCheck()}>Cancel</button>
                                            ) : (
                                                <button type="button" className="btn btn-lg btn-brand-1 bg-success ml-3 px-4" disabled={true}>
                                                    <span>Q&A Approved</span>
                                                </button>

                                            )
                                        }

                                        {
                                            (pageComing === 'qna_approvals' || formFields['q_status'] == 'APPROVED' || pageComing === 'View')
                                                ? null : (
                                                    <button type="button" className="btn btn-lg btn-brand-1 bg-success ml-3 px-4" onClick={() => save()} disabled={buttonLoader}>
                                                        {buttonLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        <span>Save</span>
                                                    </button>
                                                )
                                        }
                                        {
                                            (pageComing === 'qna_approvals' || formFields['q_status'] == 'APPROVED' || pageComing === 'View' ||props.location.state === null )
                                                ? null : (
                                                    <button type="button" className="btn btn-lg btn-brand-1 px-4 ml-3" onClick={() => save('PENDING_APPROVAL')} disabled={sendForApprovalLoader}>
                                                        {sendForApprovalLoader && (
                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                        )}
                                                        Send for Approval
                                                    </button>
                                                )
                                        }
                                        {pageComing === 'qna_approvals' ? <button type="button" className="btn btn-brand-1 bg-success px-4 ml-3" onClick={() => save('APPROVED')} disabled={approveLoader}>{approveLoader && (
                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}Approve</button> : null}
                                        {pageComing === 'qna_approvals' ? <button type="button" className="btn btn-brand-1 px-4 ml-3" onClick={() => save('PUSH_BACK')} disabled={pushbackLoader}>{pushbackLoader && (
                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}Push Back</button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {pageComing !== 'qna_approvals' && approverData && formFields['q_status'] !== 'APPROVED' && (
                            <div className="ml-3" style={{ minWidth: "900px" }}>
                                <div className="add-header text-center">
                                    <span className="add-title ">Approver’s Version</span>
                                </div>
                                <div className="form-wraper form-style mb-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                            {/*<h4 className="font-20 font-medium">Topic</h4>*/}
                                            {/* Commenting end. Divya. September 07 2023.. */}
                                            {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                            <h2 className="font-20 font-bold">Topic</h2>
                                            {/* Divya. September 07 2023. Code End */}
                                            <div className="form-group">
                                                <label>Topic/Question</label>
                                                <input disabled={true} type="text" className="form-control" onChange={handleChange} name="topic" value={approverData['topic']} />
                                                <validate
                                                    flag={true}
                                                    name={formFields.topic}
                                                    error={'Please Enter Topic/Question'}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Creation Date</label>
                                                <DatePicker
                                                    selected={approverData['start_date']}
                                                    disabled={true}
                                                    className="form-control"
                                                    placeholder="select Date"
                                                    dateFormat="MM-dd-yyyy"
                                                    scrollableYearDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>RFx Category</label>
                                                <select id="disabledSelect" disabled={true} className="form-control custom-select" value={approverData['RfxCategoryId']} name="RfxCategoryId" onChange={handleChange}>
                                                    <option value="" disabled selected>Select your option</option>
                                                    {
                                                        getListData.map((res) => {
                                                            return (
                                                                <option value={res.id}>{res.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="text-danger">{flag === true && formFields.RfxCategoryId === '' ? 'Please Enter Topic/Question' : null}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Keywords/Tag</label>
                                                <input disabled={true} type="text" className="form-control" onChange={handleChange} name="keywords" value={approverData['keywords']} />
                                                <span className="text-danger">{flag === true && formFields.keywords === '' ? 'Please Enter Keywords/Tag' : null}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                            {/*<h4 className="font-20 font-medium">Content</h4>*/}
                                            {/* Commenting end. Divya. September 07 2023.. */}
                                            {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                            <h2 className="font-20 font-bold">Content</h2>
                                            {/* Divya. September 07 2023. Code End */}
                                            {/* <Editor
                                                disabled={true}
                                                apiKey={tiny_key}
                                                // onInit={(evt, editor) => answerRef.current = editor}
                                                initialValue={approverData['answer']}
                                                init={{
                                                    height: 500,
                                                    menubar: 'file edit view insert format tools table tc help',
                                                    selector: 'textarea#full-featured',
                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                                    toolbar: 'undo redo  |blocks| fontfamily fontsize| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                }}
                                            /> */}
                                            <span className="text-danger">{flag === true && answer === '' ? 'Please Enter Content' : null}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>

            </div>


        </>
    )
}
export default AddQa;