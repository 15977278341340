import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import { useHistory } from "react-router";
import Pagination from "../../../../components/Pagination";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";

const regex = /(<([^>]+)>)/gi;

const CompanyCapabilityEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("product");
  const [listData, setListData] = useState([]);
  // Edit
  const [editPath, setEditPath] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [deleteEndPoint, setDeleteEndPoint] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // Toast
  const { addToast } = useToasts();
  const history = useHistory();
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    getList("product", "", 1);
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Organizational Setup");
    const valObject = resObject.access.find(
      (item) => item.name === "Companies & Capabilities"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getList = (k, searchKeyword, page) => {
    setLoading(true);
    var method = "GET";
    let new_page = page === undefined ? "1" : page;
    let key = localStorage.getItem("tab")
      ? localStorage.getItem("tab")
      : k === undefined
      ? "product"
      : k;
    const new_keyword = searchKeyword === undefined ? "" : searchKeyword;
    var companyId = localStorage.getItem("companyId");
    const apifetcherObj = APIMethod(
      `/${key}/list/${new_page}?keyword=${new_keyword}&company_id=${
        companyId ? companyId : props.location.state.company_id
      }`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setListData(data.list);
          if (new_page === "1") {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        } else {
          addToast(data.message, {
            autoDismissTimeout: TOAST_TIMEOUT,
            appearance: "error",
            autoDismiss: true,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const onChangeTab = (k) => {
    localStorage.setItem("tab", k);
    setKey(k);
    getList(k);
  };

  const _onDelete = (id, endPoint) => {
    setDeleteId(id);
    setDeleteEndPoint(endPoint);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const _onEdit = (val, path) => {
    history.push({
      pathname: path + val.id,
      state: { details: val },
    });
  };

  const Search = (key, k) => {
    getList(k, key);
  };

  const nextPage = (pageNumber) => {
    getList(key, " ", pageNumber);
  };

  const onAddProduct = (path) => {
    history.push({
      pathname: path,
      state: { company_id: props.location.state.company_id },
    });
  };

  const editButtonClick = (res, path) => {
    _onEdit(res, path);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0 ">Company Capabilities</h2>
            </div>
            <div className="col-lg-7 col-md-12 text-right d-md-flex align-items-center justify-content-center"></div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4 text-md-left text-center">
              <h4 className="font-16 font-medium">
                Business Unit Code :{" "}
                <span className="text-secondary">
                  {props.location.state.code}
                </span>
              </h4>
            </div>
            <div className="col-md-4 text-center">
              <h4 className="font-16 font-medium">
                Company/Org. Name :{" "}
                <span className="text-secondary">
                  {props.location.state.company_name}
                </span>
              </h4>
            </div>
            <div className="col-md-4 text-md-right text-center">
              <h4 className="font-16 font-medium">
                Business Unit Type :{" "}
                <span className="text-secondary">
                  {props.location.state.business_unit_type}
                </span>
              </h4>
            </div>
          </div>
          <div className="tab-style-1">
            <Tabs
              defaultActiveKey={
                localStorage.getItem("tab")
                  ? localStorage.getItem("tab")
                  : "product"
              }
              transition={false}
              onSelect={(k) => onChangeTab(k)}
              // onSelect={(k) => setKey(k)}
              id="noanim-tab-example"
            >
              <Tab eventKey="product" title="Products">
                <div className="row justify-content-end mb-3">
                  <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                    <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                      <input
                        type="search"
                        className="form-control border border-right-0"
                        placeholder="Search"
                        onChange={(event) =>
                          Search(event.target.value, "product")
                        }
                      />
                      <span className="input-group-text text-secondary border border-left-0">
                        <i className="icofont-search-1"></i>
                      </span>
                    </div>
                    {adding === true ? (
                      <button
                        className="btn btn-brand-1 btn-h-40 bg-success px-4"
                        onClick={() => onAddProduct("/add-product")}
                      >
                        + Add Product
                      </button>
                    ) : null}
                  </div>
                </div>
                <table
                  className="table table-style-1 table-responsive-lg mb-4 table reports"
                  id="reportTable"
                >
                  <thead>
                    <tr>
                      <th scope="col">product name</th>
                      <th scope="col">function</th>
                      <th scope="col">details</th>
                      <th scope="col">nigp/unpsc</th>
                      <th scope="col" className="text-center" width="110">
                        {editing === true || deleting === true
                          ? "Action"
                          : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false
                      ? listData.map((res) => {
                          return (
                            <tr>
                              <td>{res.name}</td>
                              <td>{res.function}</td>
                              <td>
                                {res.details && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.details,
                                    }}
                                  ></div>
                                )}
                              </td>
                              <td>{res.code}</td>
                              <td className="text-center">
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() =>
                                      editButtonClick(res, "/edit-product/")
                                    }
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id, "product")}
                                  >
                                    <i className="icofont-archive"></i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                {listData.length === 0 && loading === false ? (
                  <SearchNoDataFound message={"No Products Found"} />
                ) : (
                  ""
                )}
                {<Loader isLoading={loading} />}
                <div className="text-md-right">
                  <nav aria-label="Page navigation">
                    <Pagination
                      pages={pageCount}
                      nextPage={nextPage}
                      currentPage={currentPage}
                      pageCount={pageCount}
                    />
                  </nav>
                </div>
              </Tab>
              <Tab eventKey="services" title="Services">
                <div className="row justify-content-end mb-3">
                  <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                    <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                      <input
                        type="search"
                        className="form-control border border-right-0"
                        placeholder="Search"
                        onChange={(event) =>
                          Search(event.target.value, "services")
                        }
                      />
                      <span className="input-group-text text-secondary border border-left-0">
                        <i className="icofont-search-1"></i>
                      </span>
                    </div>
                    {adding === true ? (
                      <button
                        className="btn btn-brand-1 btn-h-40 bg-success px-4"
                        onClick={() => onAddProduct("/add-service")}
                      >
                        + Add Service
                      </button>
                    ) : null}
                  </div>
                </div>
                <table
                  className="table table-style-1 table-responsive-lg mb-4 table reports"
                  id="reportTable"
                >
                  <thead>
                    <tr>
                      <th scope="col">Service Name</th>
                      <th scope="col">function</th>
                      <th scope="col">details</th>
                      <th scope="col">nigp/UNPSC</th>
                      <th scope="col" className="text-center" width="110">
                        {editing === true || deleting === true
                          ? "Action"
                          : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false
                      ? listData.map((res) => {
                          return (
                            <tr>
                              <td>{res.name}</td>
                              <td>{res.category}</td>
                              <td>
                                {res.description && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.description,
                                    }}
                                  ></div>
                                )}
                              </td>
                              <td>{res.code}</td>
                              <td className="text-center">
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() =>
                                      editButtonClick(res, "/edit-service/")
                                    }
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() =>
                                      _onDelete(res.id, "services")
                                    }
                                  >
                                    <i className="icofont-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                {listData.length === 0 && loading === false ? (
                  <SearchNoDataFound message={"No Services Found"} />
                ) : (
                  ""
                )}
                {<Loader isLoading={loading} />}
                <Pagination
                  pages={pageCount}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </Tab>
              <Tab eventKey="process" title="Process">
                <div className="row justify-content-end mb-3">
                  <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                    <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                      <input
                        type="search"
                        className="form-control border border-right-0"
                        placeholder="Search"
                        onChange={(event) =>
                          Search(event.target.value, "process")
                        }
                      />
                      <span className="input-group-text text-secondary border border-left-0">
                        <i className="icofont-search-1"></i>
                      </span>
                    </div>
                    {adding === true ? (
                      <button
                        className="btn btn-brand-1 btn-h-40 bg-success px-4"
                        onClick={() => onAddProduct("/add-process")}
                      >
                        + Add Process
                      </button>
                    ) : null}
                  </div>
                </div>
                <table
                  className="table table-style-1 table-responsive-lg mb-4 table reports"
                  id="reportTable"
                >
                  <thead>
                    <tr>
                      <th scope="col">Process Name</th>
                      <th scope="col">function</th>
                      <th scope="col">details</th>
                      <th scope="col">nigp/UNPSC</th>
                      <th scope="col" className="text-center" width="110">
                        {editing === true || deleting === true
                          ? "Action"
                          : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false
                      ? listData.map((res) => {
                          return (
                            <tr>
                              <td>{res.name}</td>
                              <td>{res.function}</td>
                              <td>
                                {res.details && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.details,
                                    }}
                                  ></div>
                                )}
                              </td>
                              <td>{res.code}</td>
                              <td className="text-center">
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() =>
                                      editButtonClick(res, "/edit-process/")
                                    }
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() => _onDelete(res.id, "process")}
                                  >
                                    <i className="icofont-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                {listData.length === 0 && loading === false ? (
                  <SearchNoDataFound message={"No Process Found"} />
                ) : (
                  ""
                )}
                {<Loader isLoading={loading} />}
                <Pagination
                  pages={pageCount}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </Tab>
              <Tab eventKey="methodology" title="Methodology">
                <div className="row justify-content-end mb-3">
                  <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                    <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                      <input
                        type="search"
                        className="form-control border border-right-0"
                        placeholder="Search"
                        onChange={(event) =>
                          Search(event.target.value, "methodology")
                        }
                      />
                      <span className="input-group-text text-secondary border border-left-0">
                        <i className="icofont-search-1"></i>
                      </span>
                    </div>
                    {adding === true ? (
                      <button
                        className="btn btn-brand-1 btn-h-40 bg-success px-4"
                        onClick={() => onAddProduct("/add-methodology")}
                      >
                        + Add Methodology
                      </button>
                    ) : null}
                  </div>
                </div>
                <table
                  className="table table-style-1 table-responsive-lg mb-4 table reports"
                  id="reportTable"
                >
                  <thead>
                    <tr>
                      <th scope="col">Methodology</th>
                      <th scope="col">details</th>
                      <th scope="col">Certifications</th>
                      <th scope="col">nigp/UNPSC</th>
                      <th scope="col" className="text-center" width="110">
                        {editing === true || deleting === true
                          ? "Action"
                          : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false
                      ? listData.map((res) => {
                          return (
                            <tr>
                              <td>{res.name}</td>
                              <td>
                                {res.detail && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.detail,
                                    }}
                                  ></div>
                                )}
                              </td>
                              <td>{res.certifcations}</td>
                              <td>{res.nigp_code}</td>
                              <td className="text-center">
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() =>
                                      editButtonClick(res, "/edit-methodology/")
                                    }
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() =>
                                      _onDelete(res.id, "methodology")
                                    }
                                  >
                                    <i className="icofont-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                {listData.length === 0 && loading === false ? (
                  <SearchNoDataFound message={"No Methodology Found"} />
                ) : (
                  ""
                )}
                {<Loader isLoading={loading} />}
                <Pagination
                  pages={pageCount}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </Tab>
              <Tab eventKey="quality-control" title="Quality Control">
                <div className="row justify-content-end mb-3">
                  <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                    <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                      <input
                        type="search"
                        className="form-control border border-right-0"
                        placeholder="Search"
                        onChange={(event) =>
                          Search(event.target.value, "quality-control")
                        }
                      />
                      <span className="input-group-text text-secondary border border-left-0">
                        <i className="icofont-search-1"></i>
                      </span>
                    </div>
                    {adding === true ? (
                      <button
                        className="btn btn-brand-1 btn-h-40 bg-success px-4"
                        onClick={() => onAddProduct("/add-qc")}
                      >
                        + Add QC
                      </button>
                    ) : null}
                  </div>
                </div>
                <table
                  className="table table-style-1 table-responsive-lg mb-4 table reports"
                  id="reportTable"
                >
                  <thead>
                    <tr>
                      <th scope="col">Quality Control NAME</th>
                      <th scope="col">Quality Control Details</th>
                      <th scope="col">Certifcations</th>
                      <th scope="col" className="text-center" width="110">
                        {editing === true || deleting === true
                          ? "Action"
                          : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false
                      ? listData.map((res) => {
                          return (
                            <tr>
                              <td>{res.name}</td>
                              <td>
                                {res.detail && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.detail,
                                    }}
                                  ></div>
                                )}
                              </td>
                              <td>{res.certifcations}</td>
                              <td className="text-center">
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() =>
                                      editButtonClick(res, "/edit-qc/")
                                    }
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() =>
                                      _onDelete(res.id, "quality-control")
                                    }
                                  >
                                    <i className="icofont-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                {listData.length === 0 && loading === false ? (
                  <SearchNoDataFound message={"No Quality Control Found"} />
                ) : (
                  ""
                )}
                {<Loader isLoading={loading} />}
                <Pagination
                  pages={pageCount}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </Tab>
              <Tab eventKey="security-procedures" title="Security">
                <div className="row justify-content-end mb-3">
                  <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                    <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                      <input
                        type="search"
                        className="form-control border border-right-0"
                        placeholder="Search"
                        onChange={(event) =>
                          Search(event.target.value, "security-procedures")
                        }
                      />
                      <span className="input-group-text text-secondary border border-left-0">
                        <i className="icofont-search-1"></i>
                      </span>
                    </div>
                    {adding === true ? (
                      <button
                        className="btn btn-brand-1 btn-h-40 bg-success px-4"
                        onClick={() => onAddProduct("/add-security")}
                      >
                        + Add Security
                      </button>
                    ) : null}
                  </div>
                </div>
                <table
                  className="table table-style-1 table-responsive-lg mb-4 table reports"
                  id="reportTable"
                >
                  <thead>
                    <tr>
                      <th scope="col">Security Procedure</th>
                      <th scope="col">function</th>
                      <th scope="col">details</th>
                      {/* <th scope="col">nigp/UNPSC</th> */}
                      <th scope="col" className="text-center" width="110">
                        {editing === true || deleting === true
                          ? "Action"
                          : null}
                      </th>
                    </tr>
                  </thead>
                  <ModalPopup
                    showModalPopup={showModalPopup}
                    onPopupClose={isShowPopup}
                    id={deleteId}
                    endPoint={`/${deleteEndPoint}/`}
                    updateList={() => getList(`${deleteEndPoint}`)}
                  ></ModalPopup>
                  <tbody>
                    {loading === false
                      ? listData.map((res) => {
                          return (
                            <tr>
                              <td>{res.name}</td>
                              <td>
                                {res.detail && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.detail,
                                    }}
                                  ></div>
                                )}
                              </td>
                              <td>{res.certifcations}</td>
                              <td className="text-center">
                                {editing === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action edit mr-1"
                                    onClick={() =>
                                      editButtonClick(res, "/edit-security/")
                                    }
                                  >
                                    <i className="icofont-pencil-alt-2"></i>
                                  </button>
                                ) : null}
                                {deleting === true ? (
                                  <button
                                    type="button"
                                    className="btn btn-action delete"
                                    onClick={() =>
                                      _onDelete(res.id, "security-procedures")
                                    }
                                  >
                                    <i className="icofont-trash"></i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                {listData.length === 0 && loading === false ? (
                  <SearchNoDataFound message={"No Security Found"} />
                ) : (
                  ""
                )}
                {<Loader isLoading={loading} />}
                <Pagination
                  pages={pageCount}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyCapabilityEdit;
