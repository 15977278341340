import React from "react";
import logo from '../assets/css/images/loader.gif';
import loader from '../assets/css/images/ajax-loader.gif'


const CustomLoader = ({ isLoading, type }) => {
    return (
        <div>
            {
                type != 'FULLPAGE' &&
                <div className="col-12" style={{ alignItems: 'center', textAlign: 'center', justifyContent: 'center', backgroundColor: 'white', marginTop: '-22px', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    {
                        isLoading == true ?
                            <img src={logo} alt="loading..." /> : null
                    }
                </div>
            }
            {
                type == 'FULLPAGE' && isLoading &&
                <div className="resultLoading">
                    <div className='bg' style={{ backgroundColor: '#000000' }}></div>
                    <div style={{ textAlign: 'center', marginTop: '22%' }}>
                        <img style={{ position: 'relative' }} src={loader} alt="No loader found" />
                        <div style={{ position: 'relative', color: 'white' }}>Loading...</div>
                    </div>
                </div >
            }
            {
                type == 'FULLPAGE$$$' && isLoading &&
                <div className="resultLoading">
                    <div className='bg' style={{ backgroundColor: '#000000' }}></div>
                    <div style={{ textAlign: 'center', marginTop: '22%' }}>
                        <img style={{ position: 'relative' }} src={loader} alt="No loader found" />
                        {/* Commenting the following code for WOII-208. Prasanna. June 03 2024.. Code Start */}
                        {/* <div style={{ position: 'relative', color: 'white' }}>Please wait while relavant answers from your Content Repository are extracted to create the proposal . If you have more  questions in this template, it will take even longer.</div> */}
                        {/* Commenting end. Prasanna. June 03 2024.. */}
                        {/* Added this code for  WOII-208. Prasanna. June 03 2024.. Code Start */}
                        <div style={{ position: 'relative', color: 'white', textAlign: "center" }}>
                            Please wait while relavant answers from your Content Repository are extracted to create the proposal . <br /> If you have more  questions in this template, it will take even longer.</div>
                        {/* Prasanna. June 03 2024. Code End */}
                    </div>
                </div >
            }
        </div>
    )
}

export default CustomLoader;