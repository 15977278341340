import React, { useState, useEffect } from "react";
import Leftnavbar from '../../../../components/Leftnavbar';
import { Breadcrumb, FormCheck, Modal, Button } from 'react-bootstrap';
import RfxCategories from '../../Organizational_Setup/RFx_Categories/RfxCategories';
import { APIMethod } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';

const AddKeyword = (props) => {
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [formFields, setFormFields] = useState({ isNew: '', notice: '', bidSource: '', businessUnitId: '', RFxCategoryId: '', id: '' })
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [tags, setTags] = React.useState([]);
    const [tagError, setTagsError] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [flag, setFlag] = useState(false);
    const { addToast } = useToasts();
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    const [cancelModal, setCancelModal] = useState(false);

    const handleModal = () => {
        setCancelModal(!cancelModal)
    }

    useEffect(() => {
        getRFxCategoryList()
        getBusinessUnitList()
        check()
    }, [])

    const check = () => {
        if (props.location.state === null) {
        } else {
            setFormFields({
                ...formFields, businessUnitId: props.location.state.details.business_unit, RFxCategoryId: props.location.state.details.rfx_category,
                bidSource: props.location.state.details.bid_source, id: props.location.state.details.id, notice: props.location.state.details.notice
            })
            setTags(props.location.state.details.exclusions)
        }
    }

    const getRFxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const save = () => {
        if (formFields.businessUnitId === '' || formFields.RFxCategoryId === '' || formFields.bidSource === '' ||
            tags === '') {
            setFlag(true)
            addToast('Please fill all the fields', { appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            if (formFields.id === '') {
                var method = 'POST'
                var params = JSON.stringify({
                    business_unit: formFields.businessUnitId,
                    bid_source: formFields.bidSource,
                    rfx_category: formFields.RFxCategoryId,
                //    notice: formFields.notice,
                    exclusions: tags,
                })
            } else {
                var method = 'PUT'
                var params = JSON.stringify({
                    id: formFields.id,
                    business_unit: formFields.businessUnitId,
                    bid_source: formFields.bidSource,
                    rfx_category: formFields.RFxCategoryId,
                //    notice: formFields.notice,
                    exclusions: tags,
                })
            }
            const apifetcherObj = APIMethod(`/keyword-exclusions`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setFormFields({ ...formFields, businessUnitId: '', bidSource: '', RFxCategoryId: '', notice: '', isNew: '' })
                        setTags([])
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        setButtonLoader(false)
                        setFlag(false)
                        goBack();
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const addTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setTags([...tags, event.target.value]);
            event.target.value = "";
        }
    };

    const removeTags = index => {
        setTags([...tags.filter(tag => tags.indexOf(tag) !== index)]);
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const modalCheck = () => {
        if (formFields.businessUnitId === '' && formFields.RFxCategoryId === '' && formFields.bidSource === '' ) {
            goBack();
        } else {
            //   handleModal();
            goBack();
        }
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Modal show={cancelModal} onHide={() => handleModal()}  {...props}
                        size=""
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>Alert</Modal.Header>
                        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-lg btn-brand-1" onClick={() => handleModal()}>No</Button>
                            <Button className="btn btn-lg btn-brand-1 bg-danger" onClick={goBack}>Yes</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Content Library Setup</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Keyword Exclusions
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{formFields.id ? 'Edit Keyword Exclusions' : 'Add Keyword Exclusions'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title">{formFields.id ? 'Edit Keyword Exclusions' : 'Add Keyword Exclusions'}</span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Business Unit</label>
                                    <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="businessUnitId" value={formFields['businessUnitId']}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            businessUnitData.map((res) => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.businessUnitId === '' ? 'Please Select Business Unit' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 d-md-flex">
                                <div className="form-group w-50 mr-3">
                                    <label>Bid Source</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="bidSource" value={formFields['bidSource']} />
                                    <span className="text-danger">{flag === true && formFields.bidSource === '' ? 'Please Enter Bid Source' : null}</span>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="RFxCategoryId" value={formFields['RFxCategoryId']}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxCategoryData.map((res) => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.RFxCategoryId === '' ? 'Please Select RFX Category' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Exclusions (Press Enter after adding exclusions)</label>
                                    <div className="d-flex-row App">
                                        <div className="App">
                                            <div className="tags-input">
                                                <ul id="tags">
                                                    {
                                                        tags?.map((tag, index) => (
                                                            <li key={index} className="tag">
                                                                <span>{tag}</span>
                                                                <span className='tag-close-icon'
                                                                    onClick={() => removeTags(index)}
                                                                >
                                                                    x
                                                                </span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                <input type="text" className="form-control" onKeyUp={addTags} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => modalCheck()}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                    {buttonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default AddKeyword;