import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import Form from 'react-bootstrap/Form'
import { useHistory, useParams, NavLink } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import moment from "moment";
import ModalPopup from '../../../../components/ModalPopUp';
import Comment from "../../../../components/Comment";
import Toggle from 'react-toggle';
import { Editor } from '@tinymce/tinymce-react';
import Chat from "../../../../components/Chat";
import chatIcon from '../../../../assets/css/images/chat.png';
import {CKEditor} from 'ckeditor4-react';
// import { getCompaniesList } from "../../Organizational_Setup/Companies/Companies";

const EditPreRfxApproval = (props) => {
    const descriptionRef = useRef(null);
    const descriptionRef2 = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState();
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [pageComing, setPageComing] = useState('');
    // file
    const [file, setFile] = useState();
    const [fileResponse, setFileResponse] = useState();
    // Add modal box
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [RFxTypeData, setRFxTypeData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [RFxStatusData, setRFxStatusData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [editData, setEditData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [addDocuments, setAddDocuments] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [role, setRole] = useState('');
    const [bid, setBid] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [licenses, setLicenses] = useState(false);
    const [conditions, setConditions] = useState(false);
    const [constraints, setconstraints] = useState(false);
    const [business, setBusiness] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [qualitycontrolList, setQualityControlList] = useState([]);

    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProductDescription, setTotalProductDescription] = useState('')
    const [totalServiceDescription, setTotalServiceDescription] = useState('')
    const [totalQualityDescription, setTotalQualityDescription] = useState('')
    const [requirements, setRequirements] = useState('');
    const [myCompanyCap,setMyCompanyCap] = useState({});
    const [fiveCompaniesCap, setFiveCompaniesCap] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [fiveProcessing, setFiveProcessing] = useState(false);
    var completeRequirements = "";
    var fiveCompanyList = [];

    //Goto back
    const goBack = () => {
        window.history.back();
    }
    const [formFields, setFormFields] = useState({
        businessUnit: '', RFxNumber: '', RFxTitle: '', RFxType: '', RFxStatus: '', RFxCategory: '', RFxClientAgency: '',
        description: '', sourceURL: '', state: '', submissionFormat: '', buyerName: '', buyerTitle: '', buyerContact: '',
        buyerEmail: '', id: '', comment: '', fileName: '', document_type_id: '',
        //  version: '',
          documentDescription: '',
        countryId: '', stateId: '', thirdPartySourceUrl: '', nicasCode: '', bidData: '', vendorData: '', licenseData: '',
        conditionData: '', constraintsData: '', businessData: '', isComment: false
    })
    const [flag, setFlag] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    // Toast
    const { addToast } = useToasts();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);

    //--------date-Piicker----------
    const [startDate, setStartDate] = useState();
    //---Date Time picker
    const [startDateTime, setStartDateTime] = useState();
    const { id } = useParams();
    const [showChatModal, setShowChatModal] = useState(false)
    const handleClose = () => {
        setShow(!show)
        setEditIndex(-1)
        setFormFields({ ...formFields, document_type_id: '', fileName: '',
        //  version: '',
          documentDescription: '' })
    }

    useEffect(() => {
        getBusinessUnitList()
        getRFxTypeList()
        getRFxStatusList()
        getRFxCategoryList()
        getDocumentTypeList()
        getCountryList()
        check()
        getCompaniesList(1, undefined, "Self")
        // getCompaniesList(1);
    }, [])


    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.setData(formFields.description, {
                callback: function () {
                    console.log("Description is Set")
                }
            })
        }

        if (descriptionRef2.current) {
            descriptionRef2.current.setData(formFields.description, {
                callback: function () {
                    console.log("Description is Set")
                }
            })
        }
    }, [formFields])

    const isViewPath = /^\/view-pre-rfx-approval\/\d+$/.test(window.location.pathname);


    const check = () => {
        const userRole = localStorage.getItem('userRole');
        // completeRequirements = '';
        setRole(userRole)
        if (props.location.state == null) {
        } else {
            setPageComing(props.location.state.page)
        }
        if (id == null) {
        } else {
            setLoading(true)
            var method = 'GET'
            const apifetcherObj = APIMethod(`/pre-rfx/${id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setEditData(data)
                        setFormFields({
                            ...formFields, id: data.id, businessUnit: data.business_unit, RFxNumber: data.rfx_number,
                            RFxTitle: data.title, RFxCategory: data.rfx_category, RFxClientAgency: data.rfx_client_agency,
                            description: data.scope, sourceURL: data.client_url, RFxType: data.rfx_type_id,
                            RFxStatus: data.rfx_status_id, state: data.state, submissionFormat: data.subbmission_format,
                            buyerName: data.business_name, buyerTitle: data.buyer_title, buyerContact: data.buyer_contact,
                            buyerEmail: data.buyer_email, thirdPartySourceUrl: data.third_ps_url, nicasCode: data.naics,
                            buyerName: data.buyer_name, stateId: data.state_id, countryId: data.country_id, bidData: data.rfx_constraints.conference_mandatory,
                            vendorData: data.rfx_constraints.local_vendor_eligible, licenseData: data.rfx_constraints.certification_license_needed,
                            conditionData: data.rfx_constraints.financial_condition, constraintsData: data.rfx_constraints.other_constraints,
                            businessData: data.rfx_constraints.small_firms
                        })
                        // console.log("setting requirements")
                        // setRequirements(data.scope);
                        completeRequirements = data.scope;
                        setRequirements(data.scope);
                        setVendors(data.vendor)
                        setVendors(data.rfx_constraints.is_local_vendor_eligible)
                        setBid(data.rfx_constraints.is_conference_mandatory)
                        setLicenses(data.rfx_constraints.is_certification_license_needed)
                        setConditions(data.rfx_constraints.is_financial_condition)
                        setconstraints(data.rfx_constraints.is_other_constraints)
                        setBusiness(data.rfx_constraints.is_small_firms)
                        setDocumentData(data.documents)
                        setAddDocuments(data.documents)
                        console.log(data.documents)
                        setLoading(false)
                        setFlag(false)
                        if (data.id) {
                            getComments()
                        }
                        if (data.country_id) {
                            getStateList(data.country_id)
                        }
                        if (data.published) {
                            setStartDate(moment(data.published, 'DD/MM/YYYY').toDate())
                        }
                        if (data.due) {
                            setStartDateTime(moment(data.due, 'DD/MM/YYYY HH:mm:ss').toDate())
                        }
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading(false)
                })
        }
    }

    const getCompaniesList = async (page, key, company) => {
        setLoading(true);
        const new_key = key === undefined ? '' : key;
        global.new_key = key === undefined ? '' : key;
        var method = 'GET';
        try {
            const response = await APIMethod(`/company-details/list/${page}?limit=10000&keyword=${new_key}`, null, method);
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                setCompaniesList(data.list);
                const companiesList = data.list;
                if(company === "Self")
                {
                    setProcessing(true);
                    const selfCompany = companiesList.find(company => company.business_unit_type_id_name.toLowerCase() === "self");
        
                    if (selfCompany) {
                        const selfCompanyId = selfCompany.id;
                        const pList = await fetchProductList(selfCompany.id);
                        const sList = await fetchServicesList(selfCompany.id);
                        const qList = await fetchQualityControlList(selfCompany.id);
                        const concatenatedDescription = await concatenateDescriptions(pList, sList, qList);
                        // console.log(concatenatedDescription)
                        capabilityMatch(concatenatedDescription[0], concatenatedDescription[1], concatenatedDescription[2], concatenatedDescription[3],"Self", selfCompany.name).then(() => {
                            setProcessing(false);
                        })
                        // console.log(`Company ID with 'business_unit_type_id_name' equal to 'Self': ${selfCompanyId}`);
                    } else {
                        addToast("No company with 'business type' of Self", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
                        console.log("No company with 'business_unit_type_id_name' equal to 'Self' found.");
                        setProcessing(false);
                    }
                }
                else
                {
                    fiveCompanyList = []
                    setFiveProcessing(true)
                    const filteredCompanies = companiesList
                    .filter(company => company.business_unit_type_id_name.toLowerCase() !== 'self')
                    .slice(0, 5);
                    if(filteredCompanies.length !== 0){
                        const promisearray = await Promise.all(filteredCompanies.map(async (company) => {
                            const companyId = company.id;
                          
                            const pList = await fetchProductList(companyId);
                            const sList = await fetchServicesList(companyId);
                            const qList = await fetchQualityControlList(companyId)
                            const concatenatedDescriptionList = await concatenateDescriptions(pList, sList, qList);
                            // console.log(concatenatedDescriptionList);
                            const matchPercent = await capabilityMatch(concatenatedDescriptionList[0], concatenatedDescriptionList[1], concatenatedDescriptionList[2], concatenatedDescriptionList[3], company.business_unit_type_id_name, company.name);
                            // console.log(matchPercent)
                        })
                        );
                        console.log(fiveCompanyList);
                        setFiveCompaniesCap(fiveCompanyList);
                        setFiveProcessing(false)
                    }
                    else{
                        addToast("No other companies are listed.", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
                        console.log("there are no other companies");
                        setFiveProcessing(false);
                    }
                    // Wait for all descriptions to be concatenated
                    // await Promise.all(descriptionsPromises);
                    // const concatenatedDescriptions = await Promise.all(descriptionsPromises);

                }
    
                if (page === 1) {
                    setPageCount(data.count);
                }
                setCurrentPage(page);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // Handle any errors that may occur during the API calls or processing
            setProcessing(false);
            setFiveProcessing(false);
            console.error(error);
        }
    };
    

    
    const fetchProductList = async (companyId) => {
        try {
            var method = 'GET';
            const key_word = '';
            const apifetcherObj = APIMethod(`/product/list/1?keyword=${key_word}&company_id=${companyId}`, null, method);
    
            // Await the response and convert it to JSON
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                // Use Promise.all to map submodules asynchronously
                const productListWithSubmodules = await Promise.all(
                    data.list.map(async (product) => {
                        const product_data = await fetchProductSubmodules(product.id);
                        // console.log(product_data);
                        product.submodules = product_data;
                        return product;
                    })
                );
    
                // Return the result to resolve the promise
                setProductList(productListWithSubmodules);
                // console.log(productListWithSubmodules)
                return productListWithSubmodules;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                // Return an empty array or handle the error as needed
                return [];
            }
        } catch (error) {
            alert(error);
            // Handle the error and return an empty array or handle it as needed
            return [];
        }
    };
    

    const fetchServicesList = async (companyId) => {
        try {
            var method = 'GET';
            const key_word = '';
            const apifetcherObj = APIMethod(`/services/list/1?keyword=${key_word}&company_id=${companyId}`, null, method);
    
            // Await the response and convert it to JSON
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                // Use Promise.all to map submodules asynchronously
                const serviceListWithSubmodules = await Promise.all(
                    data.list.map(async (service) => {
                        const service_data = await fetchServiceSubmodules(service.id);
                        // console.log(service_data);
                        service.submodules = service_data;
                        return service;
                    })
                );
    
                // Return the result to resolve the promise
                setServiceList(serviceListWithSubmodules);
                // console.log(serviceListWithSubmodules);
                return serviceListWithSubmodules;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                // Return an empty array or handle the error as needed
                return [];
            }
        } catch (error) {
            alert(error);
            // Handle the error and return an empty array or handle it as needed
            return [];
        }
    };
    

    const fetchQualityControlList = async (companyId) => {
        try {
            var method = 'GET';
            const key_word = '';
            const apifetcherObj = APIMethod(`/quality-control/list/1?keyword=${key_word}&company_id=${companyId}`, null, method);
    
            // Await the response and convert it to JSON
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                // Use Promise.all to map submodules asynchronously
                const qualityListWithSubmodules = await Promise.all(
                    data.list.map(async (quality) => {
                        const quality_data = await fetchQualityControlSubmodules(quality.id);
                        // console.log(quality_data);
                        quality.submodules = quality_data;
                        return quality;
                    })
                );
    
                // Return the result to resolve the promise
                setQualityControlList(qualityListWithSubmodules);
                // console.log(qualityListWithSubmodules);
                return qualityListWithSubmodules;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                // Return an empty array or handle the error as needed
                return [];
            }
        } catch (error) {
            alert(error);
            // Handle the error and return an empty array or handle it as needed
            return [];
        }
    };
    

    const fetchProductSubmodules = async (id) => {
        try {
            var method = 'GET';
            const apifetcherObj = APIMethod(`/module/list/PRODUCT/${id}/1`, null, method);
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                return data.list;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                return []; // Return an empty array or handle the error as needed
            }
        } catch (error) {
            console.error("Error fetching product submodules:", error);
            alert(error);
            return []; // Return an empty array or handle the error as needed
        }
    };
    

    const fetchServiceSubmodules = async (id) => {
        try {
            var method = 'GET';
            const apifetcherObj = APIMethod(`/module/list/SERVICE/${id}/1`, null, method);
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                return data.list;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                return []; // Return an empty array or handle the error as needed
            }
        } catch (error) {
            console.error("Error fetching service submodules:", error);
            alert(error);
            return []; // Return an empty array or handle the error as needed
        }
    };    

    const fetchQualityControlSubmodules = async (id) => {
        try {
            var method = 'GET';
            const apifetcherObj = APIMethod(`/module/list/QUALITY_CONTROL/${id}/1`, null, method);
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);
    
            if (statusCode === 200) {
                return data.list;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                return []; // Return an empty array or handle the error as needed
            }
        } catch (error) {
            console.error("Error fetching quality control submodules:", error);
            alert(error);
            return []; // Return an empty array or handle the error as needed
        }
    };
    
    const concatenateDescriptions = (pList, sList, qList) => {
        // Initialize variables to store concatenated descriptions
        return new Promise((resolve, reject) => {

            let productDescription = "";
            let serviceDescription = "";
            let qualityControlDescription = "";
            // console.log(pList)
            // console.log(sList)
            // console.log(qList)
            // console.log("in concat");
            // console.log(productList);
            pList.forEach((item) => 
            {
                if (item.description) {
                    productDescription += `${item.description}\n`;
                }
                if (item.submodules && Array.isArray(item.submodules)) {
                    item.submodules.forEach(submodule => {
                        if (submodule.description) {
                            productDescription += `${submodule.description}\n`;
                        }
                    });
                }
        
            });
            setTotalProductDescription(productDescription);

            sList.forEach((item) => 
            {
                if (item.description) {
                    serviceDescription += `${item.description}\n`;
                }
                if (item.submodules && Array.isArray(item.submodules)) {
                    item.submodules.forEach(submodule => {
                        if (submodule.description) {
                            serviceDescription += `${submodule.description}\n`;
                        }
                    });
                }
        
            });
            setTotalServiceDescription(serviceDescription);

            qList.forEach((item) => 
            {
                if (item.detail) {
                    const withoutHtml = item.detail.replace(/<\/?[^>]+(>|$)/g, "");
                    const withoutNbsp = withoutHtml.replace(/&nbsp;/g, " ");
                    qualityControlDescription += `${withoutNbsp}\n`;
                }
                if (item.submodules && Array.isArray(item.submodules)) {
                    item.submodules.forEach(submodule => {
                        if (submodule.description) {
                            qualityControlDescription += `${submodule.description}\n`;
                        }
                    });
                }
        
            });
            setTotalQualityDescription(qualityControlDescription);

            // console.log(productDescription);
            // console.log(serviceDescription);
            // console.log(qualityControlDescription);
          
            completeRequirements = descriptionRef2.current.getData();

            let textWithoutHtml = completeRequirements.replace(/<[^>]*>/g, '');

            // Remove HTML entities like &nbsp;
            completeRequirements = textWithoutHtml.replace(/&[^\s]*;/g, '');
            completeRequirements = completeRequirements.replace(/\n+/g, '\n');
            // console.log(completeRequirements);
            // console.log(productDescription)
            // resolve([completeRequirements.replace(/\n/g, ''), productDescription.replace(/\n/g, ''), serviceDescription.replace(/\n/g, ''), qualityControlDescription.replace(/\n/g, '')]);
            // console.log(completeRequirements)
            // console.log(productDescription)
            // console.log(serviceDescription)
            // console.log(qualityControlDescription)
            textWithoutHtml = productDescription.replace(/<[^>]*>/g, '');
            productDescription = textWithoutHtml.replace(/&[^\s]*;/g, '');
            productDescription = productDescription.replace(/\n+/g, '\n');
            
            textWithoutHtml = serviceDescription.replace(/<[^>]*>/g, '');
            serviceDescription = textWithoutHtml.replace(/&[^\s]*;/g, '');
            serviceDescription = serviceDescription.replace(/\n+/g, '\n');

            textWithoutHtml = qualityControlDescription.replace(/<[^>]*>/g, '');
            qualityControlDescription = textWithoutHtml.replace(/&[^\s]*;/g, '');
            qualityControlDescription = qualityControlDescription.replace(/\n+/g, '\n');

            // console.log(completeRequirements)
            // console.log(productDescription)
            // console.log(serviceDescription)
            // console.log(qualityControlDescription)
            
            resolve([completeRequirements, productDescription, serviceDescription, qualityControlDescription]);
        });
    }

    const capabilityMatch = async (completeRequirements, productDescription, serviceDescription, qualityControlDescription, compType, compName) => {
        return new Promise((resolve, reject) => {
            var method = 'POST'
            const requestData = JSON.stringify({
                doctext_key: completeRequirements,
                producttext_key: productDescription,
                servicetext_key: serviceDescription,
                qualitytext_key: qualityControlDescription,
            });
            console.log(completeRequirements)
            console.log(productDescription)
            console.log(serviceDescription)
            console.log(qualityControlDescription)
            console.log(compName)
            console.log(compType)
            const apifetcherObj = APIMethod(`/pre-rfx/textmatch`, requestData, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    if (statusCode === 200) {
                        if(compType === "Self")
                        {
                            data.result.type = compType;
                            data.result.name = compName
                            console.log(data.result);
                            setMyCompanyCap(data.result);
                            resolve();
                        }
                        else
                        {
                            // fiveCompanyList.push(data);
                            data.result.type = compType;
                            data.result.name = compName;
                            console.log(data.result);
                            const updatedCompanies = [...fiveCompaniesCap, data.result]
                            fiveCompanyList.push(data.result)
                            // console.log(fiveCompanyList)
                            // setFiveCompaniesCap(updatedCompanies);
                            resolve()
                        }
                    }
                })
                .catch(error => {
                    console.error('API call error:', error);
                    setProcessing(false);
                    setFiveProcessing(false);
                })
        });
    };
    
    

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getDocumentTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxTypeList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-types/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxTypeData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxStatusList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-statuses/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxStatusData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getRFxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getCountryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/country/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCountryData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getStateList = (id) => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/region/state/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setStateData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })

    }

    const getComments = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/comments/${id}?comment_type=PRE_RFX`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                    setLoading(false)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
        if (name === 'countryId') {
            getStateList(event.target.value)
        }
    }

    const _onSendApproval = (val) => {
        if (formFields.isComment == false && val != 'PENDING') {
            addToast("Please Enter Comment First", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (val) {
            setButtonLoader(val)
            setLoading(true)
            var method = 'PUT'
            var params = JSON.stringify({
                id: id,
                rfx_status: val
            })
            // console.log(params);
            setTimeout(() => {
                setButtonLoader(false)
                goBack()
            }, 1000 * 30);
            const apifetcherObj = APIMethod(`/pre-rfx/status`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setLoading(false)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        goBack()
                        formFields.isComment = false;
                        if (val == 'APPROVED') {
                            // onUpdateStatus('OPEN', data.id)
                        } else {
                            setButtonLoader(val)
                        }
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(val)
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }


    const onUpdateStatus = (key, aiId) => {
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${aiId ? aiId : id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    setButtonLoader()
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                alert(error)
            })
    }

    const onSetComment = () => {
        formFields.isComment = true;
    }

    let config = {
        events: {
            initialized() {
                const editor = this;
                editor.edit.off();

                setTimeout(() => {
                    editor.edit.off();
                }, 5000);
            }
        }
    };


    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/pre-rfx/document/'}
                updateList={check}
            ></ModalPopup>
            <div className="page-wrapper d-flex">
                <div className="page-content">
                    <div className="mb-3" >
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Pre-RFx</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>Pre-RFx Approval</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Pre-RFx Approval</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">Pre-RFx Approval</span>
                    </div>
                    <div className="form-wraper form-style mb-4" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<p className="font-medium">RFx Information</p>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">RFx Information</p>
                                    {/* Divya. September 01 2023. Code End */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Business Unit</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['businessUnit']} name="businessUnit" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            businessUnitData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.businessUnit === '' ? 'Please Enter Business Unit' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Number</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxNumber" value={formFields['RFxNumber']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.RFxNumber === '' ? 'Please Enter RFx Number' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label>RFx Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxTitle" value={formFields['RFxTitle']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.RFxTitle === '' ? 'Please Enter RFx Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>RFx Type</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RFxType']} name="RFxType" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxTypeData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.RFxType === '' ? 'Please Select RFx Type' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Publish on</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        minDate={new Date()}
                                        readOnly={true}
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Due Date & Time</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={startDateTime}
                                        onChange={(date) => setStartDateTime(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        placeholderText="Select Date & Time"
                                        readOnly={true}
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Category</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['RFxCategory']} name="RFxCategory" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            RFxCategoryData.map((res, index) => {
                                                return (
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.RFxCategory === '' ? 'Please Select RFx Category' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>RFx Client Agency</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="RFxClientAgency" value={formFields['RFxClientAgency']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.RFxClientAgency === '' ? 'Please Enter RFx Client Agency' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12 ">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<label>Description/Scope</label>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">Description/Scope</p>
                                    {/* Divya. September 01 2023. Code End */}
                                    {/* <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => descriptionRef.current = editor}
                                        initialValue={formFields.description}
                                        disabled={true}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality  visualchars fullscreen image  table lists visualblocks',
                                            toolbar: 'undo redo | blocks |  strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    /> */}
                                    <CKEditor
                                        initData={formFields.description}
                                        readOnly={true}
                                        onInstanceReady={(event) => descriptionRef.current = event.editor}
                                        name="eprfx_description"
                                        config={{
                                            height: 500,
                                            versionCheck: false,
                                            toolbar: [
                                            { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                            { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                            { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                            { name: 'colors', items: ['TextColor', 'BGColor'] },
                                            { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                            ],
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description/Scope' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['countryId']} name="countryId" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        {
                                            countryData.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.id}>{val.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.countryId === '' ? 'Please Select Country' : null}</span>
                                </div>
                            </div>
                            {
                                formFields.countryId ?
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label>State/Province</label>
                                            <select id="disabledSelect" className="form-control custom-select" value={formFields['stateId']} name="stateId" onChange={handleChange} disabled={true}>
                                                <option value="" disabled selected>Select your option</option>
                                                {
                                                    stateData.map((res, index) => {
                                                        return (
                                                            <option key={index} value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{flag === true && formFields.stateId === '' ? 'Please Select State/Province' : null}</span>
                                        </div>
                                    </div> : null
                            }

                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Client/Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="sourceURL" value={formFields['sourceURL']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.sourceURL === '' ? 'Please Enter Client/Source URL' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Submission Format</label>
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['submissionFormat']} name="submissionFormat" onChange={handleChange} disabled={true}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option>Online</option>
                                        <option>Email</option>
                                    </select>
                                    <span className="text-danger">{flag === true && formFields.submissionFormat === '' ? 'Please Select Submission Format' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>3rd Party Source URL</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="thirdPartySourceUrl" value={formFields['thirdPartySourceUrl']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.thirdPartySourceUrl === '' ? 'Please Enter 3rd Party Source URL' : null}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>NAICS Codes</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="nicasCode" value={formFields['nicasCode']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.nicasCode === '' ? 'Please Enter NIACS Codes' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">RFx Constraints:</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">RFx Constraints:</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Pre-Bid Conference mandatory? </lable><br />
                                    <Toggle
                                        checked={bid}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setBid(!bid)}
                                        disabled={true}
                                    />
                                    {
                                        bid === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="bidData" value={formFields['bidData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Only local vendors eligible?</lable><br />
                                    <Toggle
                                        checked={vendors}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setVendors(!vendors)}
                                        disabled={true} />
                                    {
                                        vendors === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="vendorData" value={formFields['vendorData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any Certification/licenses needed?</lable><br />
                                    <Toggle
                                        checked={licenses}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setLicenses(!licenses)}
                                        disabled={true} />
                                    {
                                        licenses === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="licenseData" value={formFields['licenseData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any financial conditions?</lable><br />
                                    <Toggle
                                        checked={conditions}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setConditions(!conditions)}
                                        disabled={true} />
                                    {
                                        conditions === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="conditionData" value={formFields['conditionData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Any other constraints</lable><br />
                                    <Toggle
                                        checked={constraints}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setconstraints(!constraints)}
                                        disabled={true} />
                                    {
                                        constraints === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="constraintsData" value={formFields['constraintsData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <lable className="">Set aside for small business/minority firms?</lable><br />
                                    <Toggle
                                        checked={business}
                                        icons={false}
                                        className='custom-toggle-classname'
                                        onChange={() => setBusiness(!business)}
                                        disabled={true} />
                                    {
                                        business === true ?
                                            <div className="form-style mt-2">
                                                <input type="text" className="form-control" style={{ height: "38px" }} onChange={handleChange} name="businessData" value={formFields['businessData']} />
                                            </div> : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">Buyer Information</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">Buyer Information</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerName" value={formFields['buyerName']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.buyerName === '' ? 'Please Enter Buyer Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="buyerTitle" value={formFields['buyerTitle']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.buyerTitle === '' ? 'Please Enter Buyer Title' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Contact</label>
                                    <input type="text" className="form-control" onChange={handleChange} disabled={true} onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            return false;
                                        }

                                        return true;
                                    }} name="buyerContact" value={formFields['buyerContact']} />
                                    <span className="text-danger">{flag === true && formFields.buyerContact === '' ? 'Please Enter Buyer Contact' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Buyer Email</label>
                                    <input type="text" className="form-control" onChange={handleChange} disabled={true} name="buyerEmail" value={formFields['buyerEmail']} />
                                    <span className="text-danger">{flag === true && formFields.buyerEmail === '' ? 'Please Enter Buyer Email' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                {/*<p className="font-medium">RFx Attachments</p>*/}
                                {/* Commenting end. Divya. September 01 2023.. */}
                                {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                <p className="font-20 font-bold">RFx Attachments</p>
                                {/* Divya. September 01 2023. Code End */}
                            </div>
                            <div className="col-12 text-right">
                                {
                                    editData.rfx_status == "PENDING" || formFields.id === '' ?
                                        <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={handleShow}>Add</button>
                                        :
                                        null
                                }

                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        {/* <th>Version</th> */}
                                        <th>Description</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td style={{ maxWidth: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{res.file_name}</td>
                                                    {/* <td>{res.version}</td> */}
                                                    <td title={res.description}>{res.description && res.description.length > 145 ? `${res.description.substring(0, 145)}...` : res.description}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")}><i className="icofont-eye-alt"></i></button>
                                                        {/* <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(i, res)}><i className="icofont-pencil-alt-2"></i></button>
                                                        <button type="button" className="btn btn-action delete" onClick={() => deleteDocument(i)}><i className="icofont-trash"></i></button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                            {
                                formFields.id ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="font-16 mb-0">Pre-RFx Comments</h4>
                                            </div>
                                            <div className="card-body bg-white">
                                                {
                                                    commentData.map((res, index) => {
                                                        const commentTextStyle = {
                                                            whiteSpace: 'pre-wrap', // Preserve line breaks and wrap text
                                                            maxHeight: '300px', // Add a maximum height for vertical scrolling
                                                            overflowX: 'auto', // Horizontal scrollbar when needed
                                                            border: '1px solid #ccc', // Optional border for the comment container
                                                            padding: '10px', // Optional padding for better presentation
                                                        };
                                                        return (
                                                            <div key={index} className="bg-light p-2 rounded mb-2">
                                                                <p className="mb-1">
                                                                    <span className="font-medium mr-2">{res.user.first_name} {res.user.last_name}</span>
                                                                    <span className="text-secondary font-12 mr-3">{moment(res.created_date).format("D-MMM-YYYY h:mm a")}</span>
                                                                </p>
                                                                {/* <div dangerouslySetInnerHTML={{ __html: res.comment }} /> */}
                                                                <div style={commentTextStyle}>
                                                                    {res.comment}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <Comment
                                                    id={formFields.id}
                                                    updateCommentList={getComments}
                                                    enableComment={onSetComment}
                                                    editor={!isViewPath} />
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            <div className="col-12 text-center mt-4 row" style={{ justifyContent: 'center' }}>

                                {
                                    editData.rfx_status === 'DECLINED' || editData.rfx_status === 'APPROVED' || editData.rfx_status === 'PUSH_BACK' ?
                                        null
                                        :
                                        <div>
                                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={() => _onSendApproval('DECLINED')} disabled={(buttonLoader == 'DECLINED' ? true : false) || (isViewPath)}> {buttonLoader == 'DECLINED' && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}No Go</button>
                                            <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => _onSendApproval('APPROVED')} disabled={(buttonLoader == 'APPROVED' ? true : false) || (isViewPath)}>{buttonLoader == 'APPROVED' && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Go</button>
                                            <button type="button" className="btn btn-brand-1  px-4" onClick={() => _onSendApproval('PUSH_BACK')} disabled={(buttonLoader == 'PUSH_BACK' ? true : false) || (isViewPath)}>{buttonLoader == 'PUSH_BACK' && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}Push Back</button>
                                        </div>

                                }
                            </div>
                            <div className="col-12 text-center mt-4 row" style={{ justifyContent: 'center' }}>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3 mt-1" onClick={goBack}>Cancel</button>
                            </div>
                                
                                {/* <NavLink to="/pre-rfx-approvals-filters" className="btn btn-brand-1  px-4">Push Back </NavLink> */}
                            
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="add-header text-center" style={{ marginTop: 40 }}>
                        <span className="add-title ">Capability Match</span>
                    </div>
                    <div className="form-wraper form-style mb-4" >
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                     {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<label>RFx Scope/Requirements</label>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">RFx Scope/Requirements</p>
                                    {/* Divya. September 01 2023. Code End */}
                                    {/* <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => descriptionRef.current = editor}
                                        initialValue={formFields.description}
                                        disabled={isViewPath}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    /> */}
                                    <CKEditor
                                        initData={formFields.description}
                                        readOnly={isViewPath}
                                        onInstanceReady={(event) => descriptionRef2.current = event.editor}
                                        name="eprfx_description_cm"
                                        config={{
                                            height: 500,
                                            versionCheck: false,
                                            toolbar: [
                                            { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                            { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                            { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                            { name: 'colors', items: ['TextColor', 'BGColor'] },
                                            { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                            ],
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description/Scope' : null}</span>
                                </div>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Company</th>
                                        {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                                        {/* <th>Bus. Type</th> */}
                                        {/* Commenting end. Divya. January 08 2024.. 
	                                    Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                                        <th>Company Type</th>
                                        {/* Divya. January 08 2024. Code End */}
                                        <th>Product Match</th>
                                        <th>Service Match</th>
                                        <th>Quality Match</th>
                                    </tr>
                                        {myCompanyCap.name === undefined ? <tr><td colSpan="5" className="text-center"><span className="spinner-border spinner-border-sm text-center" role="status" aria-hidden="true"/></td></tr>:
                                            <tr>
                                            <td>{myCompanyCap?.name || "N/A"}</td>
                                            <td>{myCompanyCap?.type || "N/A"}</td>
                                            <td>{myCompanyCap['01 productPercentage'] !== undefined ? `${myCompanyCap['01 productPercentage']}%` : "N/A"}</td>
                                            <td>{myCompanyCap['02 servicePercentage'] !== undefined ? `${myCompanyCap['02 servicePercentage']}%` : "N/A"}</td>
                                            <td>{myCompanyCap['03 qualityPercentage'] !== undefined ? `${myCompanyCap['03 qualityPercentage']}%` : "N/A"}</td>
                                            </tr>
                                        }
                                </table>
                            </div>

                            {/* Commenting the following code for WOII-86. Divya. September 11 2023.. Code Start */}
                            {/*<div className="col-12 mt-4 row" style={{ justifyContent: 'center', width: 620 }}>*/}
                            {/* Commenting end. Divya. September 11 2023.. */}
                            {/* Added this code for  WOII-86. Divya. September 11 2023.. Code Start */}
                            <div className="col-12 mt-2 row" style={{ justifyContent: 'center', width: 620, height: '60px' }}>
                            {/* Divya. September 11 2023. Code End */}
                            <button
                            type="button"
                            className='btn btn-lg btn-brand-1'
                            onClick={() => getCompaniesList(1, undefined, "Self")}
                            disabled={processing || isViewPath}
                            style={{ '&:focus': { backgroundColor: '#218838' }, minWidth:"350px" }}
                            >
                            {processing ? (
                                
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                            ) : (
                                'Match My Company Capabilities Again'
                            )}
                            </button>  
                            </div>
                            <div className="col-12 mt-2 row" style={{ justifyContent: 'center', width: 620, height: '60px' }}>                              
                            <button
                            type="button"
                            className='btn btn-lg btn-brand-1'
                            onClick={() => getCompaniesList(1)}
                            disabled={fiveProcessing || isViewPath}
                            style={{ '&:focus': { backgroundColor: '#218838' }, minWidth:"350px" }}
                            >
                            {fiveProcessing ? (
                                
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                            ) : (
                                'Match Other Companies'
                            )}
                            </button>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Company</th>
                                        {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                                        {/* <th>Bus. Type</th> */}
                                        {/* Commenting end. Divya. January 08 2024.. 
	                                    Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                                        <th>Company Type</th>
                                        {/* Divya. January 08 2024. Code End */}
                                        <th>Product Match</th>
                                        <th>Service Match</th>
                                        <th>Quality Match</th>
                                    </tr>
                                    {
                                        fiveCompaniesCap.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res?.name || "N/A"}</td>
                                                    <td>{res?.type || "N/A"}</td>
                                                    <td>{res['01 productPercentage'] !== undefined ? `${res['01 productPercentage']}%` : "N/A"}</td>
                                                    <td>{res['02 servicePercentage'] !== undefined ? `${res['02 servicePercentage']}%` : "N/A"}</td>
                                                    <td>{res['03 qualityPercentage'] !== undefined ? `${res['03 qualityPercentage']}%` : "N/A"}</td>
                          
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showChatModal == false &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                    {/*<img src={chatIcon} />*/}
                    {/* Commenting end. Divya. October 06 2023.. */}
                    {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                    <img src={chatIcon}  className="chat-icon"/>
                    {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                    {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                    {/* Commenting end. Divya. November 14 2023.. */}
                    {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                    <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                    {/* Divya. November 14 2023. Code End */}
                    {/* Divya. October 06 2023. Code End */}
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={id}
                    title={formFields.RFxTitle}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default EditPreRfxApproval;