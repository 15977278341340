import React, { useState } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { Breadcrumb, Tabs, Tab, Nav, Row, Col, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from "react-router-dom";

const CreateProposalTemplateApproval = () => {
  //Goto back
  const goBack = () => {
    window.history.back();
  };

  //--------date-Piicker----------
  const [startDate, setStartDate] = useState(new Date());
  //---Date Time picker
  const [startDateTime, setStartDateTime] = useState(new Date());
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          {/* <div className="mb-3">
                            <Breadcrumb>
                                <Breadcrumb.Item >Proposal Templates</Breadcrumb.Item>
                                <Breadcrumb.Item active>Add Proposal Templates</Breadcrumb.Item>
                            </Breadcrumb>
                        </div> */}
          <h2 className="page-title ">Manage Proposal Template Approvals</h2>

          <div className="tab-style-1 mb-4">
            <Tab.Container
              id="controlled-tab-example"
              defaultActiveKey="RowRFX"
            >
              <Row>
                <div className="col-12 d-flex">
                  <Nav className="d-flex  nav nav-tabs ">
                    <Nav.Item className="mr-3">
                      <Nav.Link eventKey="RowRFX">Row RFX</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="SectionizedRFx" className="mr-3">
                        {" "}
                        Sectionized RFx
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ProposalStructure">
                        Proposal Structure
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="RowRFX">
                      <img
                        src="/images/raw-rfx.png"
                        className="img-fluid w-100"
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="SectionizedRFx"></Tab.Pane>
                    <Tab.Pane eventKey="ProposalStructure">
                      <textarea
                        className="form-control h-auto"
                        rows="10"
                        autocomplete="off"
                      >
                        II. INSTRUCTIONS TO OFFERORS -- B. SPECIAL INSTRUCTIONS
                        CLARIFICATION (NOV 2007) Pursuant to Section
                        11-35-1520(8), the Procurement Ofcer may elect to
                        communicate with you after opening for the purpose of
                        clarifying either your ofer or the requirements of the
                        solicitation. Such communications may be conducted only
                        with oferors who have submitted an ofer which obviously
                        conforms in all material aspects to the solicitation.
                        Clarifcation of an ofer must be documented in writing
                        and included with the ofer. Clarifcations may not be
                        used to revise an ofer or the solicitation. Section
                        11-35-1520(8); R.19-445.2080 02-2B055-1 MAIL PICKUP (JAN
                        2006) The State Procurement Ofce picks up all mail from
                        The US Postal Service once daily around 8:30 a.m.
                        (excluding weekends and holidays). See provision
                        entitled Deadline for Submission of Ofer. 02-2B080-1
                        ON-LINE BIDDING INSTRUCTIONS (MAR 2015) You must
                        register before you can submit an ofer on line! See
                        clause entitled “VENDOR REGISTRATION MANDATORY.” (b)
                        Steps for On-Line Bidding: #1 The link provided on the
                        solicitation’s Cover Page will take you to our web based
                        on-line bidding system, where you will enter and/or
                        upload your ofer. #2 Follow the general user
                        instructions posted at www.procurement.sc.gov under the
                        heading “Submitting Ofers.” #3 Confrm your ofer has a
                        status of “submitted” by refreshing the “RFx and
                        Auctions” screen
                      </textarea>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>

          <div className="d-flex justify-content-between mb-3 align-items-center">
            <h3 className="page-title mb-0">Template Content</h3>
            <NavLink
              to="/genrate-proposal"
              className="btn btn-brand-1 bg-success px-4"
            >
              Genrate Proposal
            </NavLink>
          </div>

          <table
            className="table table-style-1 table-responsive-lg mb-4 table reports"
            id="reportTable"
          >
            <thead style={{ zIndex: 10 }} className="sticky-top">
              <tr>
                <th scope="col">Heading Title </th>
                <th scope="col">Description</th>
                <th scope="col">Section/Question Type</th>
                <th scope="col" className="text-center" width="110">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cover Letter</td>
                <td> </td>
                <td width="190" className="form-style">
                  <div className="form-group mb-0">
                    <select
                      id="disabledSelect"
                      className="form-control custom-select font-14"
                    >
                      <option>Section-Level 1</option>
                      <option>Section-Level 2</option>
                      <option>Section-Level 3</option>
                      <option>Section-Level 4</option>
                    </select>
                  </div>
                </td>
                <td className="text-center">
                  <a className="font-20 mr-2">
                    <i className="icofont-plus-square"></i>
                  </a>
                  <a className="font-20 mr-2">
                    <i className="icofont-minus-square"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>Company Background</td>
                <td> </td>
                <td width="190" className="form-style">
                  <div className="form-group mb-0">
                    <select
                      id="disabledSelect"
                      className="form-control custom-select font-14"
                    >
                      <option>Section-Level 1</option>
                      <option>Section-Level 2</option>
                      <option>Section-Level 3</option>
                      <option>Section-Level 4</option>
                    </select>
                  </div>
                </td>
                <td className="text-center">
                  <a className="font-20 mr-2">
                    <i className="icofont-plus-square"></i>
                  </a>
                  <a className="font-20 mr-2">
                    <i className="icofont-minus-square"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="ml-4">
                    How Many years the company has been in business
                  </span>
                </td>
                <td> </td>
                <td width="190" className="form-style">
                  <div className="form-group mb-0">
                    <select
                      id="disabledSelect"
                      className="form-control custom-select font-14"
                    >
                      <option>Section-Level 1</option>
                      <option>Section-Level 2</option>
                      <option>Section-Level 3</option>
                      <option>Section-Level 4</option>
                    </select>
                  </div>
                </td>
                <td className="text-center">
                  <a className="font-20 mr-2">
                    <i className="icofont-plus-square"></i>
                  </a>
                  <a className="font-20 mr-2">
                    <i className="icofont-minus-square"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div className=" text-center mt-4">
            <button
              type="button"
              className="btn btn-lg btn-brand-1 bg-danger mr-3"
              onClick={goBack}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-lg btn-brand-1 mr-3">
              Save
            </button>
          </div>

          <div className="d-flex justify-content-between mb-3 align-items-center">
            <h3 className="page-title mb-0">Approver's Version</h3>
          </div>

          <table
            className="table table-style-1 table-responsive-lg mb-4 table reports"
            id="reportTable"
          >
            <thead>
              <tr>
                <th scope="col">Heading Title </th>

                <th scope="col">Section/Question Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cover Letter</td>

                <td width="190" className="form-style">
                  <div className="form-group mb-0">
                    <select
                      id="disabledSelect"
                      className="form-control custom-select font-14"
                    >
                      <option>Section-Level 1</option>
                      <option>Section-Level 2</option>
                      <option>Section-Level 3</option>
                      <option>Section-Level 4</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Company Background</td>

                <td width="190" className="form-style">
                  <div className="form-group mb-0">
                    <select
                      id="disabledSelect"
                      className="form-control custom-select font-14"
                    >
                      <option>Section-Level 1</option>
                      <option>Section-Level 2</option>
                      <option>Section-Level 3</option>
                      <option>Section-Level 4</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="ml-4">
                    How Many years the company has been in business
                  </span>
                </td>

                <td width="190" className="form-style">
                  <div className="form-group mb-0">
                    <select
                      id="disabledSelect"
                      className="form-control custom-select font-14"
                    >
                      <option>Section-Level 1</option>
                      <option>Section-Level 2</option>
                      <option>Section-Level 3</option>
                      <option>Section-Level 4</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default CreateProposalTemplateApproval;
