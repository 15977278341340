import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, generatePath } from "react-router-dom";
import Pagination from '../../../../components/Pagination';
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import { useHistory } from "react-router";
import { useToasts } from 'react-toast-notifications';
import moment from "moment";
import 'chart.js/auto';
import { Pie,Chart} from 'react-chartjs-2';

const AverageTimes = (props) => {
    let rfxStages = ['Rfx creation', 'Rfx sent for approval', 'Rfx approved', 'Done with data edits', 'Template creation', 'Proposal creation',
    'Proposal sent for approval', 'Award creation', 'Contract creation'];
    // let selectedCheckboxes = [0, 1];
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const [getOverallAverage, setOverallAverage] = useState([]);
 
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [selectAllValue, setSelectAllValue] = useState(false);
    const[displayFlag,setdisplayFlag]=useState(true);
    
    const [formFields, setFormFields] = useState({
        businessUnitId: '', rfxClient: '', rfxNumber: '', rfxTitle: '', initialAndTargetStage: [0 , 4], rfxCategory: '', fromDate: undefined, toDate: undefined,
    })
    const { addToast } = useToasts();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([0, 4]);

    useEffect(() => {
        var value = localStorage.getItem('Pre_rfx_page')
        var final_data = value && JSON.parse(value)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        getPreRFxList(final_data ? final_data.page : currentPage)
        if (final_data && final_data.pageCount) {
            setPageCount(final_data.pageCount)
        }
        // checkPermission(data)
        getRfxCategoryList()
        getBusinessUnitList()
        var filters = localStorage.getItem('pre_rfx_filter')
        var filters_data = filters && JSON.parse(filters)
       
    }, [currentPage])

    // const checkPermission = (data) => {
    //     const resObject = data.find(item => item.name === "Manage Opportunities")
    //     const valObject = resObject.access.find(item => item.name === "Pre-RFxs")
    //     setAdding(valObject.is_create)
    //     setEditing(valObject.is_update)
    //     setDeleting(valObject.is_delete)
    // }

   
    const getPreRFxList = (page, key, type) => {
        console.log("data test",getListData)
        localStorage.removeItem("Pre_rfx_page")
        setLoading(true)
        const new_key = key === undefined ? '' : key
        var filter_start_date = ''
        if (formFields.fromDate) {
            filter_start_date = moment(formFields.fromDate).format('DD-MM-YYYY') == moment(formFields.toDate).format('DD-MM-YYYY') ? '' : moment(formFields.fromDate).format('DD-MM-YYYY')
        }
        // console.log(filter_start_date,"eeeeeeeeeeee")

        const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('DD-MM-YYYY')
        // console.log(filter_end_date,'RRRRRRRRRRRRRRRRRR')
        
        var method = 'GET'
        console.log("bussines id",formFields.businessUnitId);
        console.log("inital",formFields.initialstage)
        console.log("targ",formFields.targetstage)
      

        const apifetcherObj = APIMethod(`/dashboard/avg-times/?bu=${formFields.businessUnitId}&from=${rfxStages[formFields.initialAndTargetStage[0]]}&to=${rfxStages[formFields.initialAndTargetStage[1]]}&fromDate=${filter_start_date}&toDate=${filter_end_date}` , null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                if (statusCode === 200) {
                    console.log(data.id,"In api call")
                    setGetListData(data.id.table)
                    setOverallAverage(data.id.overallAverage)
                 
                    // for (var i in data.list) {
                    //     data.list[i].isSelected = false;
                    // }
                    // if (page === 1) {
                    //     setPageCount(data.count)
                    // }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

     const handleCheckboxChange = (index) => {
        const isChecked = selectedCheckboxes.includes(index);

        if (!isChecked) {
            if (selectedCheckboxes.length < 2) {
                setSelectedCheckboxes([...selectedCheckboxes, index]);
                setFormFields(prevState => ({
                    ...prevState,
                    initialAndTargetStage: [...prevState.initialAndTargetStage, index].sort((a, b) => a - b)
                }));
            } else {
                return;
            }
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== index));
            setFormFields(prevState => ({
                ...prevState,
                initialAndTargetStage: prevState.initialAndTargetStage.filter(item => item !== index)
            }));
        }
    };

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    console.log("testing data------>",data)
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

   
    
    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }


    const dateValidation = (date) => {
        if (formFields.fromDate) {
            const formFieldsObject = {
                ...formFields,
                toDate: date
            }
            setFormFields(formFieldsObject);
        } else {
            addToast('Please select RFx closing from date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }


    const setFromDate = (date) => {
        console.log("---------->",date)
    //    const updatedt= moment(date).format('MM-DD-YYYY')
    //    console.log("**********",updatedt)
        const formFieldsObject = {
            ...formFields,
            fromDate: date
        }
        setFormFields(formFieldsObject);
    }
    const reset_filter=()=>{
        const formFieldsObject = {
            ...formFields,
            businessUnitId:" ",
            initialstage:" ",
            targetstage:" ",
            fromDate: undefined,
            toDate:undefined
        }
        setFormFields(formFieldsObject);
    }

    const Formatdate=(inpdate)=>{
        const finaldate=inpdate.split('/')
        return finaldate[1]+"/"+finaldate[0]+"/"+finaldate[2];

    }
//     const CalculateDuration=(fromdt,todt)=>{
        
//         var fromDate = new Date(fromdt); // Assuming res.from_date is in a valid date format
//         var toDate = new Date(todt); // Assuming res.to_date is in a valid date format

//         // Calculate the duration in milliseconds
//         var durationInMillis = fromDate.getTime()-toDate.getTime();

//         // Convert milliseconds to days
//         var durationInDays = durationInMillis / (1000 * 3600 * 24);
//         console.log("duration",durationInDays)
// // // Display the duration
// // console.log("Duration between the dates: " + durationInDays + " days");
//         return durationInDays

//     }

function result(from_date, to_date) {
    // Splitting the date strings and converting them to arrays
    const from_parts = from_date.split('/');
    const to_parts = to_date.split('/');

    // Creating Date objects from the date parts (MM/DD/YYYY format)
    const from = new Date(from_parts[2], from_parts[1] - 1, from_parts[0]);
    const to = new Date(to_parts[2], to_parts[1] - 1, to_parts[0]);

    // Calculating the difference in milliseconds
    const difference_ms = Math.abs(to - from);

    // Converting milliseconds to days (1000 ms * 60 sec * 60 min * 24 hours)
    const difference_days = Math.ceil(difference_ms / (1000 * 60 * 60 * 24));

    return difference_days;
}
    const _onFilter = () => {
        // if((formFields.intialstage == formFields.targetstage) && (formFields.initialstage)){
        //     addToast('Both source and target filters should not be same', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });

        // }
        console.log("eneterd filter")
        if(formFields.initialAndTargetStage[0] == undefined || formFields.initialAndTargetStage[1] == undefined) {
            addToast('Please select atleast two options', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
        getPreRFxList(1, '')
        handleClose()
        }
    }

    const getData = () => {
        getPreRFxList(currentPage)
    }

    const selectedPrerfx = (id) => {
        setSelectAllValue(!selectAllValue)
        for (var i in getListData) {
            if (id == getListData[i].id) {
                getListData[i].isSelected = !getListData[i].isSelected
            }
        }
        setGetListData([...getListData])
    }

    const onDeleteAll = () => {
        var array = [];
        var count = 0;
        for (var i in getListData) {
            if (getListData[i].isSelected == true) {
                count = count + 1;
                array.push(getListData[i].id)
            }
        }
        if (count == 0) {
            addToast('Please select first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setLoading(true)
            var method = 'DELETE'
            var body = JSON.stringify(array);
            const apifetcherObj = APIMethod('/pre-rfx/delete/bulk', body, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setSelectAllValue(false)
                        setLoading(false)
                        getPreRFxList(1)
                    } else {
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-4 align-items-center justify-content-between">
                        <div className="col-lg-3 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Average Times</h2>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by RFx title" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            {adding === true ? <NavLink to={{ pathname: "/add-pre-rfx", state: { page: 'preRfx' } }} className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null}
                        </div> */}
                    </div>
                    <div className="table-responsive">
                        <table className="table table-style-1 mb-4 table-md-responsive" id="">
                            <thead>
                                <tr className="text-left cols-3">
                                    <th style={{ textTransform: 'none' }} colSpan={3}>Filter RFx stages</th>
                                </tr>

                            </thead>
                            <ModalPopup
                                showModalPopup={showModalPopup}
                                onPopupClose={isShowPopup}
                                id={deleteId}
                                endPoint={'/pre-rfx/'}
                                updateList={getData}
                            ></ModalPopup>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="" style={{marginTop:'28px'}}>
                                            <div className="form-group">
                                                <label>Business Unit</label>
                                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="businessUnitId" value={formFields['businessUnitId']}>
                                                    <option value="">All</option>
                                                    {
                                                        businessUnitData.map((res, index) => {
                                                            return (
                                                                <option key={index} value={res.id}>{res.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="" style={{marginTop:'28px'}}>
                                            <div className="form-group">
                                            <label style={{marginBottom: '18px'}}>Rfx Stages</label>    
                                            {rfxStages.map((option, index) => (
                                                <div key={index} style={{ marginBottom: '10px', marginLeft: '10px'}}>
                                                    <label style={{ display: 'flex', alignItems: 'center' }}> {/* Align items horizontally */}
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => handleCheckboxChange(index)}
                                                            checked={selectedCheckboxes.includes(index)}
                                                            style={{ transform: 'scale(1.5)' }}
                                                        />
                                                        <span style={{ fontWeight: selectedCheckboxes.includes(index) ? 'bold' : 'normal', paddingLeft: '8px' }}>{option}</span> {/* Add padding for space */}
                                                    </label>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* <div className="">
                                            <div className="form-group">
                                                <label>Pre-RFx Status</label>
                                                <select id="disabledSelect" className="form-control custom-select " onChange={handleChange} name="preRfxStatus" value={formFields['preRfxStatus']}>
                                                    <option value="">All</option>
                                                    <option>Open</option>
                                                    <option>Pending</option>
                                                    <option value="APPROVED">Go</option>
                                                    <option Value="DECLINED">No-Go</option>
                                                    <option value="PUSH_BACK">Pushedback</option>
                                                    <option value="PENDING_APPROVAL">Sent for Approval</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        
                                    </td>

                                    <td>
                                        <div className=""  style={{marginTop:'28px'}}>
                                            <div className="form-group">
                                                <label>From Date</label>
                                                <DatePicker
                                                    selected={formFields.fromDate}
                                                    onChange={(date) => setFromDate(date)}
                                                    className="form-control"
                                                    placeholder="select Date"
                                                    dateFormat="MM-dd-yyyy"
                                                    scrollableYearDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className=""  style={{marginTop:'20%', marginLeft: '50%'}}>
                                            <div className="form-group">
                                                <label>Overall Average</label>
                                                {getOverallAverage !== undefined ? (
                                                    <div style={{ border: '1px solid black', padding: '10px', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>
                                                        <p style={{ margin: 0, padding: '5px', borderRadius: '3px', backgroundColor: 'lightgray' }}>
                                                            {getOverallAverage}
                                                        </p>
                                                    </div>
                                                    ) : (
                                                    <div style={{ border: '1px solid black', padding: '10px', borderRadius: '5px' }}>
                                                        {/* No background when no text */}
                                                        <p style={{ margin: 0, padding: '5px', borderRadius: '3px' }}>
                                                            
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                    <div className=""  style={{marginTop:'28px'}}>
                                            <div className="form-group">
                                            <label>To Date</label> 
                                                <DatePicker
                                                    selected={formFields.toDate}
                                                    onChange={(date) => dateValidation(date)}
                                                    className="form-control"
                                                    placeholder="select Date"
                                                    dateFormat="MM-dd-yyyy"
                                                    minDate={formFields.fromDate}
                                                    scrollableYearDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                               
                            </tbody>
                            
                            
                        </table>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={reset_filter}>Reset</button>
                    <button className="btn btn-lg btn-brand-1 bg-success" onClick={_onFilter}>Confirm</button>
                      
                    </div>
                    

                    </div>
                    <div>
                    { getListData.length>0 ?(
                        <div>
                             <table className="table table-style-1 mb-4" id="">
                                <thead>
                                    <tr>
                                        <th scope="col">BU</th>
                                        <th scope="col" style={{ minWidth: "125px", textTransform: 'none' }}>RFx TITLE</th>
                                        <th scope="col" style={{ width: 181, minWidth: "125px", textTransform: 'none' }}>STATUS</th>   
                                        <th scope="col" style={{ textTransform: 'none', minWidth: "115px" }}>{rfxStages[formFields.initialAndTargetStage[0]]+" "+"Date"}</th>
                                        <th scope="col" style={{ textTransform: 'none', minWidth: "115px" }}>{rfxStages[formFields.initialAndTargetStage[1]]+ " " +"Date"}</th>
                                        <th scope="col" style={{ textTransform: 'none', minWidth: "115px" }}>Total Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading==false? ( 
                                            getListData.map((res,index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td>{res.business_unit}</td>
                                                        <td>{res.rfx_title}</td>
                                                        <td>{res.rfx_status}</td>
                                                        <td>{Formatdate(res.from_date)}</td>
                                                        <td>{Formatdate(res.to_date)}</td>
                                
                                                        <td>{result(res.from_date,res.to_date)+" "+"days"}</td>



                                                    </tr>
                                                )
                                            })
                                        ):null
                                    }
                                </tbody>
                            </table>

                        </div>
                    ): "No Data Matched to the applied filters"}
                    </div>
                   


                </div>
            </div>


        </>
    )
}
export default AverageTimes;

