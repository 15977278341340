import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { APIMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";

const CompanyCapability = (props) => {
  const [loading, setLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    getCompaniesList(1);
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
  }, []);

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Organizational Setup");
    const valObject = resObject.access.find(
      (item) => item.name === "Companies & Capabilities"
    );
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getCompaniesList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    var method = "GET";
    const apifetcherObj = APIMethod(
      `/company-details/list/${page}?keyword=${new_key}`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setCompaniesList(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const nextPage = (pageNumber) => {
    getCompaniesList(pageNumber);
  };

  const Search = (key) => {
    getCompaniesList(1, key);
  };

  const _onEdit = (val) => {
    history.push({
      pathname: "/edit-company-capability",
      state: {
        company_name: val.name,
        business_unit_code: val.name,
        business_unit_type: val.business_unit_type_id_name,
        code: val.code,
      },
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Company Capabilites</h2>
            </div>
            <div className="col-lg-4 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
            </div>
          </div>

          <div
            className="table-responsive"
            style={{ height: "400px", overflowY: "auto" }}
          >
            <table
              className="table table-style-1 table-responsive-lg mb-4 table reports"
              id="reportTable"
            >
              <thead
                className="sticky-top"
                style={{
                  zIndex: 10,
                }}
              >
                <tr>
                  <th scope="col">Business Unit </th>
                  <th scope="col">BU Type </th>
                  <th scope="col">Company Name </th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/company-details/"}
                updateList={getCompaniesList}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? companiesList.map((res, index) => {
                      return (
                        <tr key={index}>
                          <td>{res.business_name}</td>
                          <td>{res.business_unit_type_id_name}</td>
                          <td>{res.name}</td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-action edit mr-1"
                              onClick={() => _onEdit(res)}
                            >
                              <i className="icofont-pencil-alt-2"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-action delete"
                              onClick={() => _onDelete(res.id)}
                            >
                              <i className="icofont-archive"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          {companiesList.length === 0 && loading === false ? <NoData /> : ""}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                endPoint={"/company-details/list/"}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
              />
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyCapability;
