export const MENU_ORGANIZATIONAL_SETUP = 'Organizational Setup'
export const MENU_BUSINESS_UNITS = 'Business Units'

export const MANAGE_PROPOSALS_AND_AWARDS = 'Manage Proposals & Awards'
export const PROPOSAL_TEMPLATE = 'Proposal Templates'
export const PROPOSAL_TEMPLATE_APPROVAL = 'Proposal Template Approvals'
export const PROPOSAL_AWARDS = 'Proposal Awards'
export const MANAGE_OPPORTUNITIES = 'Manage Opportunities'
export const RFXS = 'RFxs'
export const Pre_RFx_Approvals = 'Pre-RFx Approvals'
export const Manage_Proposals_Awards = 'Manage Proposals & Awards'
export const MANAGE_CONTRACTS = 'Manage Contracts'
export const CONTRACTS = 'Contracts'
export const Proposal_Approvals = 'Proposal Approvals'

export const getCapitalLettersCount = str => {
  var letters = 0;
  var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var ar = alphabet.split("");
  for (var i = 0; i < str.length; i++) {
    if (ar.indexOf(str[i]) > -1) {
      letters = letters + 1;
    }
  }
  return letters;
}

export const getSmallLettersCount = str => {
  var letters = 0;
  var alphabet = "abcdefghijklmnopqrstuvwxyz";
  var ar = alphabet.split("");
  for (var i = 0; i < str.length; i++) {
    if (ar.indexOf(str[i]) > -1) {
      letters = letters + 1;
    }
  }
  return letters;
}

export const roleHelper = (menuName, screenName) => {
  var data = JSON.parse(localStorage.getItem("user_Role"))
  const resObject = data.find(item => item.name === menuName)
  const valObject = resObject.access.find(item => item.name === screenName)
  return valObject;
}

export const isView = (obj) => {
  return (obj && obj.is_view)
}

export const isCreate = (obj) => {
  return (obj && obj.is_create)
}

export const isEdit = (obj) => {
  return (obj && obj.is_update)
}

export const isDelete = (obj) => {
  return (obj && obj.is_delete)
}

export const REGEX = /(<([^>]+)>)/ig;

export const checkEmptyString = (value) => {
  if (!value || value == undefined || value == '') {
    return true;
  }
  return false;
}
