import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import moment from "moment";

//Code written for Esub_WOII-1 Notification setup
const Notification_Setup = (props) => {
    const [notificationCheckbox, setNotificationCheckbox] = useState(false);
    const [emailCheckbox, setEmailCheckbox] = useState(false);
    const [slackCheckbox, setSlackCheckbox] = useState(false);
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [channel, setChannel] = useState('');
    const [skypeCheckbox, setSkypeCheckbox] = useState(false);
    const [skypeInviteURL, setSkypeInviteURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [edit, setEdit] = useState('');
    const { addToast } = useToasts();

    useEffect(() => {
        getNotificationDetails();
    }, [])


    const getNotificationDetails = (page, key) => {

        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/bot-notifications`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    // console.log(data);

                    setNotificationCheckbox(data.msgstatus)
                    setEmailCheckbox(data.emailstatus);
                    setSlackCheckbox(data.slackstatus);
                    setChannel(data.orgname);
                    setSkypeCheckbox(data.skypestatus);
                    setSkypeInviteURL(data.skypeinviteurl);
                    setStartDate(moment(data.effectivedate, 'YYYY-MM-DD').toDate());
                    setDescription(data.description);
                    if (data.orgname) {
                        setEdit('/')
                    }
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })

    }


    const formatDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const save = () => {
        // console.log(edit) ;
        if (channel == '') {
            addToast('Please enter a channel Name', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
        else if (description === '') {
            addToast('Please fill the Description', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }

        else {
            setButtonLoader(true)
            var method = (edit === '/') ? 'PUT' : 'POST';
            var params = JSON.stringify({
                orgname: channel,
                msgstatus: notificationCheckbox,
                emailstatus: emailCheckbox,
                skypestatus: skypeCheckbox,
                skypeinviteurl: skypeInviteURL,
                slackstatus: slackCheckbox,
                description: description,
                effectivedate: formatDate('Sun May 11,2014')
            })
            const apifetcherObj = APIMethod('/bot-notifications', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    if (statusCode === 200) {
                        setButtonLoader(false)
                        setEdit('/')
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const handleCheckBoxChange = (e) => {
        setNotificationCheckbox(e.target.checked);
        setEmailCheckbox(false);
        setSlackCheckbox(false);
        setSkypeCheckbox(false);
    }
    const redirectToSkype = () => {
        // window.location.href = skypeInviteURL;
        window.open(skypeInviteURL, '_blank');
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="row mb-3">
                        <div className="col-7">
                            <h2 className="page-title mb-md-0">Notifications Setup</h2>
                        </div>
                    </div>

                    <table className="table table-style-1 table-responsive-md table reports mx-auto" id="reportTable">
                        <thead>
                            <tr>
                                <th colSpan="2" className="text-center">Notifications Setup</th>
                            </tr>
                        </thead>
                        {
                            !loading ?
                                <tbody>
                                    <tr>
                                        <td className="text-start">Activate/Deactivate:</td>
                                        <td>
                                            <div className="custom-control custom-checkbox text-start">
                                                <input
                                                    type="checkbox"
                                                    id="notificationCheckbox"
                                                    className="custom-control-input"
                                                    checked={notificationCheckbox}
                                                    onChange={(e) => handleCheckBoxChange(e)}
                                                />
                                                <label
                                                    htmlFor="notificationCheckbox"
                                                    className="custom-control-label font-bold"
                                                >
                                                    Opt in to Receive Notifications.
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">Notification Types:</td>
                                        <td className="text-start">
                                            <div className="custom-control custom-checkbox mt-3">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="os_create_1"
                                                    checked={!notificationCheckbox ? false : emailCheckbox}
                                                    onChange={(e) => setEmailCheckbox(e.target.checked)}
                                                />
                                                <label className="custom-control-label" htmlFor="os_create_1">
                                                    <span>Email</span>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-checkbox mt-3">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="os_create_2"
                                                    checked={!notificationCheckbox ? false : skypeCheckbox}
                                                    onChange={(e) => setSkypeCheckbox(e.target.checked)}
                                                />
                                                <label className="custom-control-label " htmlFor="os_create_2">
                                                    <span>Skype </span>
                                                </label>
                                                {
                                                    skypeCheckbox && skypeInviteURL ? <button type="button" className="ml-2 bg-skyblue" onClick={redirectToSkype} >Add to Contacts</button> : null
                                                }

                                                {/* <a href={skypeInviteURL} target="_blank" rel="noopener noreferrer">
                                                    (Add to Skype Contacts)
                                                </a> */}
                                            </div>
                                            <div className="custom-control custom-checkbox mt-3 mb-3">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="os_create_3"
                                                    checked={!notificationCheckbox ? false : slackCheckbox}
                                                    onChange={(e) => setSlackCheckbox(e.target.checked)}
                                                />
                                                <label className="custom-control-label" htmlFor="os_create_3">
                                                    <span>Message Board (Slack)</span>
                                                </label>
                                            </div>
                                            {
                                                slackCheckbox && notificationCheckbox ? <div className="text-start mt-2" style={{ width: '400px' }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Slack Channel Name"
                                                        value={channel}
                                                        onChange={(e) => setChannel(e.target.value)}
                                                    />
                                                </div> : null
                                            }

                                        </td>
                                    </tr>
                                    <tr>

                                        <td className="text-start" >Description:</td>
                                        <td className="text-start" >
                                            <input
                                                type="text"
                                                className="form-control" style={{ width: '400px' }}
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="ml-4">
                                        <td className="text-start">Effective Start Date:</td>
                                        <td className="text-start">
                                            <div style={{ width: '200px' }}>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    className="form-control"
                                                    placeholder="select Date"
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={new Date()}
                                                    scrollableYearDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </td>
                                    </tr>

                                </tbody> : null
                        }


                    </table>
                    <div className="">
                        {
                            <Loader
                                isLoading={loading}
                            />
                        }
                    </div>
                    <div className="col-12 text-center mt-4">

                        <button type="button" className="btn btn-lg btn-brand-1 bg-success mt-3" onClick={save} >
                            {buttonLoader && (
                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                            )}
                            <span>Save</span>
                        </button>
                    </div>
                </div>



            </div>



        </>
    )
}
export default Notification_Setup;