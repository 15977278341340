import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, useLocation,useHistory} from "react-router-dom";
// import { useHistory } from "react-router";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
// import Pagination from '../../../../components/Pagination';
import moment from "moment";
import Loader from '../../../../components/Loader';
// import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import { useToasts } from 'react-toast-notifications';
// import ReactModal from 'react-modal-resizable-draggable';
import { CONTRACTS, isCreate, isDelete, isEdit, isView, MANAGE_CONTRACTS, MANAGE_OPPORTUNITIES, MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_AWARDS, PROPOSAL_TEMPLATE, PROPOSAL_TEMPLATE_APPROVAL, RFXS, roleHelper } from "../../../../utility/utility";
import ModalPopup from "../../../../components/ModalPopUp";
import 'chart.js/auto';
import { Pie,Chart} from 'react-chartjs-2';


const RfxList = (props) => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const[getPiechartdata,setGetPiechartdata]=useState();
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [RFxStatusData, setRFxStatusData] = useState([]);
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [proposalTemplate, setProposalTemplate] = useState(false);
    const [proposalExists, setProposalExists] = useState(false);
    const [awardExists, setAwardExists] = useState(false);
    const [contractExists, setContractExists] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const[displayFlag,setdisplayFlag]=useState(true);

    const [allOwners, setAllOwners] = useState([])
    const [timer, setTimer] = useState(0);

   
    const [formFields, setFormFields] = useState({
        businessUnitId: '', rfxClient: '', rfxTitle: '', rfxNumber: '', rfxStatus: '', rfxCategory: '', fromDate: undefined, toDate: undefined, owner: ''
    })
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    {/* Added this code for  WOII-120. Divya. November 07 2023.. Code Start */ }
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    {/* Divya. November 07 2023. Code End */ }
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToasts();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [goStatusButtonLoader, setGoStatusButtonLoader] = useState('')
    const chartOptions = {
        plugins: {
             title: {
                 display: true,
                 text: "Analysis of Total RFx's ",
                 color:'black',
                 padding:{
                     top:10,
                     bottom:40,
                 },
                 font: {
                     size: 32,
                     style: 'italic',
                     family: 'Helvetica Neue'
                   }
             }
        },

        maintainAspectRatio:false,
        legend: {
          labels: {
            fontSize: 32, 
          },
        },
        
      };

    useEffect(() => {
        var value = localStorage.getItem("rfxlist_page")
        var final_data = value && JSON.parse(value);

        getRFxList(final_data ? final_data.page : currentPage);
      
        getBusinessUnitList()
        getRFxStatusList()
        getRfxCategoryList()
    }, [rowsPerPage, currentPage])


    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1000);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // var access_Proposal_template = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE)
    // var access_Proposal_actions = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL)
    // var access_award_action = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_AWARDS)
    // var access_contract_action = roleHelper(MANAGE_CONTRACTS, CONTRACTS)
    // var access_rfx = roleHelper(MANAGE_OPPORTUNITIES, RFXS)

    function formatDuration(durationInMilliseconds) {
        const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
        const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
        const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);
        const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));

        const formattedDays = days.toString().padStart(2, '0');
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    const reset_filter=()=>{
        formFields.businessUnitId=" ";
        formFields.rfxStatus=" ";
        formFields.fromDate=undefined;
        formFields.toDate=undefined;
    }

    const generateRandomColor = () => {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
      };
    const DisplayingResult=(RFxdatares)=>{
      
        setdisplayFlag(false);
        const rfxStatuschartData = RFxdatares.map(item => item.rfx_status_name);

            const statusCounts = rfxStatuschartData.reduce((acc, status) => {
                acc[status] = (acc[status] || 0) + 1;
                return acc;
            }, {});
            
            const chartData = {
                labels: Object.keys(statusCounts),
                datasets: [
                  {
                    data: Object.values(statusCounts),
                    backgroundColor: Object.keys(statusCounts).map(() => generateRandomColor()),
                  },
                ],
             
              };
            setGetPiechartdata(chartData);
        
    }
      const handlePieClick=(event,array)=>{
        if(array.length>0){
            var clickedval=''
            var filter_start_date = ''
            if (formFields.fromDate) {
                filter_start_date = moment(formFields.fromDate).format('DD-MM-YYYY') == moment(formFields.toDate).format('DD-MM-YYYY') ? '' : moment(formFields.fromDate).format('DD-MM-YYYY')
            }
            const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('DD-MM-YYYY')
   
            const clickedSliceIndex = array[0].index;
            const clickedSliceName = getPiechartdata.labels[clickedSliceIndex];
        
           
            for (let i = 0; i < RFxStatusData.length; i++) {
                if (RFxStatusData[i].name === clickedSliceName) {
                clickedval= RFxStatusData[i].id;
                break; 
                }
            }
  
            history.push({
                pathname: `/rfx-list`,
               page:'dashboard-rfx',
               details:{businessUnitId:formFields.businessUnitId,fromdate:formFields.fromDate==undefined?'': filter_start_date ,todate:formFields.toDate==undefined?'':filter_end_date,clickedval:clickedval}
             } );


        }

      }
    const getRFxList = (page, key) => {
        setLoading(true)
        localStorage.removeItem("rfxlist_page");
        const new_key = key === undefined ? '' : key
        var method = 'GET'
        var filter_start_date = ''
        if (formFields.fromDate) {
            filter_start_date = moment(formFields.fromDate).format('DD-MM-YYYY') == moment(formFields.toDate).format('DD-MM-YYYY') ? '' : moment(formFields.fromDate).format('DD-MM-YYYY')
        }
        const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('DD-MM-YYYY')
   
       
        const apifetcherObj = APIMethod(`/pre-rfx/rfx/list/${page}?isApproval=false&is_proposal=false&client=${formFields.rfxClient}&rfx_number=${formFields.rfxNumber}&business_unit_id=${formFields.businessUnitId}&category=${formFields.rfxCategory}&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&status=${formFields.rfxStatus}&owner=${formFields.owner}&is_proposal=${proposalExists}&is_template=${proposalTemplate}&is_award=${awardExists}&is_contract=${contractExists}&limit=${" "}&keyword=${new_key || formFields.rfxTitle}`, null, method)
       
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    console.log(data.list)
                    setGetListData(data.list)
                    DisplayingResult(data.list)

                    var md = data.list[0].owner.split("$-$");
                    setAllOwners(md[1].split("$$$"))

                    if (page === 1) {
                        setPageCount(data.count)
                        localStorage.setItem("rfxlist_count", JSON.stringify({ pageCount: data.count }))
                    }
                    else {
                        const value = localStorage.getItem("rfxlist_count")
                        const final_data = value && JSON.parse(value)
                        setPageCount(final_data.pageCount);
          
                    }
                    setCurrentPage(page);
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber) => {
     
        setCurrentPage(pageNumber)
       
    }

    
    const handleRowsPerPageChange = (selectedValue) => {
        setRowsPerPage(selectedValue);
    }
   

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRFxStatusList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-statuses/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                if (statusCode === 200) {
                    setRFxStatusData(data)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const setFromDate = (date) => {
        const formFieldsObject = {
            ...formFields,
            fromDate: date
        }
        setFormFields(formFieldsObject);
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const _onFilter = () => {
        getRFxList(1)
        handleClose()
    }

    const Search = (key) => {
        getRFxList(1, key)
    }


    const dateValidation = (date) => {
        if (formFields.fromDate) {
            const formFieldsObject = {
                ...formFields,
                toDate: date
            }
            setFormFields(formFieldsObject);
        } else {
            addToast('Please select RFx closing from date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }


    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const getData = () => {
        getRFxList(currentPage);
    }



    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Loader
                        isLoading={buttonLoader}
                        type={'FULLPAGE'}
                    />
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-4 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">RFx Totals</h2>
                        </div>


                        <div className="col-lg-7 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">

                                <input type="search" className="form-control" placeholder="Search by RFx title" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            <NavLink to="/parse-rfx" className="btn btn-brand-1 bg-info px-3" style={{ minWidth: "130px", height: "40px" }}>Parse an RFx</NavLink>
                        </div>

                    </div>

                    <div className="">
                    <table className="table table-style-1 mb-4 ">
                        <thead>
                        <tr className="text-left cols-2"> 
                            <th style={{ textTransform: 'none' }}colSpan={2}>Filter RFx Totals</th>
                        </tr>
                        </thead>
                        <ModalPopup
                        showModalPopup={showModalPopup}
                        onPopupClose={isShowPopup}
                        id={deleteId}
                        endPoint={'/pre-rfx/'}
                        updateList={getData}
                        ></ModalPopup>
                        <tbody>
                        <tr>
                            <td >
                            <div>
                            <div className="form-group">
                                <label>Business Unit</label>
                                <select
                                id="disabledSelect"
                                className="form-control custom-select"
                                onChange={handleChange}
                                name="businessUnitId"
                                value={formFields['businessUnitId']}
                                >
                                <option value="" >
                                    All
                                </option>
                                {businessUnitData.map((res, index) => (
                                    <option key={index} value={res.id}>
                                    {res.name}
                                    </option>
                                ))}
                                </select>
                            </div>
                            </div>
                            <div>
                            <div className="form-group">
                                <label>RFx Status</label>
                                <select
                                id="disabledSelect"
                                className="form-control custom-select "
                                onChange={handleChange}
                                name="rfxStatus"
                                value={formFields['rfxStatus']}
                                >
                                <option value="">
                                    All
                                </option>
                                {RFxStatusData.map((res, index) => (
                                    <option key={index} value={res.id}>
                                    {res.name}
                                    </option>
                                ))}
                                </select>
                            </div>
                            </div>
                            </td>
                            <td >
                            <div>
                            <div className="form-group">
                                <label>RFx Closing From Date</label>
                                <DatePicker
                                className="form-control"
                                selected={formFields.fromDate}
                                onChange={(date) => setFromDate(date)}
                                placeholder="select Date"
                                dateFormat="MM-dd-yyyy"
                                popperPlacement="bottom"
                                scrollableYearDropdown
                                showYearDropdown
                                />
                            </div>
                            </div>
                            <div>
                            <div className="form-group">
                                <label>RFx Closing To Date </label>
                                <DatePicker
                                className="form-control"
                                selected={formFields.toDate}
                                onChange={(date) => dateValidation(date)}
                                placeholder="select Date"
                                dateFormat="MM-dd-yyyy"
                                popperPlacement="bottom"
                                minDate={formFields.fromDate}
                                scrollableYearDropdown
                                showYearDropdown
                                />
                            </div>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={reset_filter}>Reset</button>
                        <button className="btn btn-lg btn-brand-1 bg-success" onClick={_onFilter}>Confirm</button>
                        {/* <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={DisplayingResult}>Display</button> */}
                    </div>
    
                    {getPiechartdata && getPiechartdata.labels && getListData.length>0 ? (
                        <div style={{marginLeft:'90px',marginTop:'20px'}}>
                        
                        <div style={{width:'880px',height:'630px',border:'2px solid black',borderRadius:'20px'}}>
       
                        <Pie data={getPiechartdata} options={{ ...chartOptions, onClick: handlePieClick }} /> 
                        </div>  
                        </div>
                    ) : 
                        // <p style={{textAlign:'center'}}>No data available for the Pie Chart</p>
                        displayFlag ? " ":<div style={{textAlign:'center'}}>No Data Available for your search input</div>

                    }
                   

                    </div>
                </div>
            </div>


        </>
    )
}
export default RfxList;