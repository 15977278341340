import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { generatePath } from "react-router-dom";
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Pagination from "../../../../components/Pagination";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import Loader from "../../../../components/Loader";
import ReactModal from "react-modal-resizable-draggable";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import {
  CONTRACTS,
  isCreate,
  isDelete,
  isEdit,
  isView,
  MANAGE_CONTRACTS,
  MANAGE_OPPORTUNITIES,
  MANAGE_PROPOSALS_AND_AWARDS,
  PROPOSAL_AWARDS,
  PROPOSAL_TEMPLATE,
  PROPOSAL_TEMPLATE_APPROVAL,
  RFXS,
  roleHelper,
  Pre_RFx_Approvals,
} from "../../../../utility/utility";

const PreRfxsApprovals = (props) => {
  const [loading, setLoading] = useState(false);
  // Add modal box
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [getListData, setGetListData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [RFxCategoryData, setRFxCategoryData] = useState([]);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //Divya. November 10 2023. Code End
  const history = useHistory();
  const { addToast } = useToasts();
  const [formFields, setFormFields] = useState({
    businessUnitId: "",
    rfxClient: "",
    rfxNumber: "",
    rfxTitle: "",
    preRfxStatus: "PENDING_APPROVAL",
    rfxCategory: "",
    createdBy: "",
    fromDate: undefined,
    toDate: undefined,
    preRfxFromDate: undefined,
    preRfxToDate: undefined,
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //getPreRFxList(1)
    //Commenting end. Divya. November 10 2023..
    //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    // var value = localStorage.getItem("prerfx_approval_list_page")
    // var final_data = value && JSON.parse(value);
    // getPreRFxList(final_data ? final_data.page : currentPage)
    getPreRFxList(currentPage);
    //Divya. November 10 2023. Code End
    getUserList();
    getBusinessUnitList();
    getRfxCategoryList();
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 10 2023..
    //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  //Divya. November 10 2023. Code End
  var access_prerfx_approval = roleHelper(
    MANAGE_OPPORTUNITIES,
    Pre_RFx_Approvals
  );

  const getPreRFxList = (page, key) => {
    setLoading(true);
    localStorage.removeItem("prerfx_approval_list_page");
    const new_key = key === undefined ? "" : key;
    var filter_start_date = "";
    if (formFields.fromDate) {
      filter_start_date =
        moment(formFields.fromDate).format("DD-MM-YYYY") ==
        moment(formFields.toDate).format("DD-MM-YYYY")
          ? ""
          : moment(formFields.fromDate).format("DD-MM-YYYY");
    }
    const filter_end_date =
      formFields.toDate === undefined
        ? ""
        : moment(formFields.toDate).format("DD-MM-YYYY");
    var filter_pre_start_date = "";
    if (formFields.preRfxFromDate) {
      filter_pre_start_date =
        moment(formFields.preRfxFromDate).format("DD-MM-YYYY") ==
        moment(formFields.preRfxToDate).format("DD-MM-YYYY")
          ? ""
          : moment(formFields.preRfxFromDate).format("DD-MM-YYYY");
    }
    const filter_pre_end_date =
      formFields.preRfxToDate === undefined
        ? ""
        : moment(formFields.preRfxToDate).format("DD-MM-YYYY");
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //const apifetcherObj = APIMethod(`/pre-rfx/approval/list/${page}?is_proposal=false&client=${formFields.rfxClient}&business_unit_id=${formFields.businessUnitId}&rfx_number=${formFields.rfxNumber}&category=${formFields.rfxCategory}&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&created_from_date=${filter_pre_start_date}&created_to_date=${filter_pre_end_date}&created_by=${formFields.createdBy}&pre_rfx_status=${formFields.preRfxStatus}&keyword=${new_key || formFields.rfxTitle}`, null, method)
    //Commenting end. Divya. November 10 2023..
    //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    const apifetcherObj = APIMethod(
      `/pre-rfx/approval/list/${page}?is_proposal=false&client=${
        formFields.rfxClient
      }&business_unit_id=${formFields.businessUnitId}&rfx_number=${
        formFields.rfxNumber
      }&category=${
        formFields.rfxCategory
      }&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&created_from_date=${filter_pre_start_date}&created_to_date=${filter_pre_end_date}&created_by=${
        formFields.createdBy
      }&pre_rfx_status=${
        formFields.preRfxStatus
      }&limit=${rowsPerPage}&keyword=${new_key || formFields.rfxTitle}`,
      null,
      method
    );
    //Divya. November 10 2023. Code End
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
            localStorage.setItem(
              "prerfx_approval_list_page",
              JSON.stringify({ pageCount: data.count })
            );
          } else {
            const value = localStorage.getItem("prerfx_approval_list_page");
            const final_data = value && JSON.parse(value);
            setPageCount(final_data.pageCount);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getUserList = () => {
    var method = "GET";
    const apifetcherObj = APIMethod(`/users/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setUserData(data);
        }
      })
      .catch((error) => {});
  };

  const getBusinessUnitList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/business-units/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setBusinessUnitData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getRfxCategoryList = () => {
    setLoading(true);
    var method = "GET";
    const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method);
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setRFxCategoryData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //getPreRFxList(pageNumber)
    //Commenting end. Divya. November 10 2023..
    //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    setCurrentPage(pageNumber);
    //Divya. November 10 2023. Code End
  };

  {
    /* Added this code for  WOII-120. Divya. November 10 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 10 2023. Code End */
  }

  const _onEdit = (val) => {
    val.id &&
      history.push(generatePath("/edit-pre-rfx-approval/:id", { id: val.id }));
  };

  const rfxDateValidation = (date) => {
    if (formFields.fromDate) {
      const formFieldsObject = {
        ...formFields,
        toDate: date,
      };
      setFormFields(formFieldsObject);
    } else {
      addToast("Please select RFx closing from date first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  const preRfxDateValidation = (date) => {
    if (formFields.preRfxFromDate) {
      const formFieldsObject = {
        ...formFields,
        preRfxToDate: date,
      };
      setFormFields(formFieldsObject);
    } else {
      addToast("Please select RFx closing from date first", {
        autoDismissTimeout: TOAST_TIMEOUT,
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onFilter = () => {
    getPreRFxList(1);
    handleClose();
  };

  const setFromDate = (date) => {
    const formFieldsObject = {
      ...formFields,
      fromDate: date,
    };
    setFormFields(formFieldsObject);
  };

  const setPreRfxFromDate = (date) => {
    const formFieldsObject = {
      ...formFields,
      preRfxFromDate: date,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "BUSINESS_UNIT_TYPE") {
      formFields.businessUnitId = "";
    } else if (type === "RFX_CLIENT") {
      formFields.rfxClient = "";
    } else if (type === "RFX_NUMBER") {
      formFields.rfxNumber = "";
    } else if (type === "RFX_TITLE") {
      formFields.rfxTitle = "";
    } else if (type === "PRE_RFX_STATUS") {
      formFields.preRfxStatus = "";
    } else if (type === "CREATED_BY") {
      formFields.createdBy = "";
    } else if (type === "RFX_CATEGORY") {
      formFields.rfxCategory = "";
    } else if (type === "FROM_DATE") {
      formFields.fromDate = undefined;
    } else if (type === "TO_DATE") {
      formFields.toDate = undefined;
    } else if (type === "PRE_FROM_Date") {
      formFields.preRfxFromDate = undefined;
    } else if (type === "PRE_TO_DATE") {
      formFields.preRfxToDate = undefined;
    } else {
      formFields.businessUnitId = "";
      formFields.rfxClient = "";
      formFields.rfxNumber = "";
      formFields.rfxTitle = "";
      formFields.preRfxStatus = "";
      formFields.createdBy = "";
      formFields.rfxStatus = "";
      formFields.rfxCategory = "";
      formFields.fromDate = undefined;
      formFields.toDate = undefined;
      formFields.preRfxFromDate = undefined;
      formFields.preRfxToDate = undefined;
      handleClose();
    }
    getPreRFxList(1);
  };

  const Search = (key) => {
    getPreRFxList(1, key);
  };

  const getFormatedDateAndTime = (dt) => {
    return moment
      .utc(dt, "DD/MM/YYYY HH:mm:ss")
      .local()
      .format("DD MMM YYYY, hh:mm A");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-4 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Pre-RFx Approvals</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by RFx title"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={handleShow}
              >
                <i className="icofont-filter mr-2"></i>Filter{" "}
              </button>
            </div>
          </div>

          <div className="mb-2 filter-by">
            {formFields.businessUnitId ? (
              <div className="badge">
                <span className="lable">Business Unit</span>
                {businessUnitData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.businessUnitId == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("BUSINESS_UNIT_TYPE")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxClient ? (
              <div className="badge">
                <span className="lable">Client / Agency</span>
                <span className="value">{formFields.rfxClient}</span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("RFX_CLIENT")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxNumber ? (
              <div className="badge">
                <span className="lable">RFx Number</span>
                <span className="value">{formFields.rfxNumber}</span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("RFX_NUMBER")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxTitle ? (
              <div className="badge">
                <span className="lable">RFx Title</span>
                <span className="value">{formFields.rfxTitle}</span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("RFX_TITLE")}
                ></i>
              </div>
            ) : null}
            {formFields.preRfxStatus ? (
              <div className="badge">
                <span className="lable">Pre-RFx Status</span>
                <span className="value">
                  {formFields.preRfxStatus == "APPROVED"
                    ? "GO"
                    : formFields.preRfxStatus == "PUSH_BACK"
                    ? "Pushed back"
                    : formFields.preRfxStatus == "PENDING_APPROVAL"
                    ? "Pending Approval"
                    : formFields.preRfxStatus}
                </span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("PRE_RFX_STATUS")}
                ></i>
              </div>
            ) : null}
            {formFields.createdBy ? (
              <div className="badge">
                <span className="lable">Created By</span>
                {userData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.createdBy == res.id
                        ? `${res.first_name} ${res.last_name}`
                        : null}
                    </span>
                  );
                })}
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("CREATED_BY")}
                ></i>
              </div>
            ) : null}
            {formFields.rfxCategory ? (
              <div className="badge">
                <span className="lable">RFx Category</span>
                {RFxCategoryData.map((res, index) => {
                  return (
                    <span key={index} className="value">
                      {formFields.rfxCategory == res.id ? res.name : null}
                    </span>
                  );
                })}
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("RFX_CATEGORY")}
                ></i>
              </div>
            ) : null}
            {formFields.fromDate ? (
              <div className="badge">
                <span className="lable">RFx Closing From Date</span>
                <span className="value">
                  {moment(formFields.fromDate).format("MM-DD-YYYY")}
                </span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("FROM_DATE")}
                ></i>
              </div>
            ) : null}
            {formFields.toDate ? (
              <div className="badge">
                <span className="lable">RFx Closing To Date</span>
                <span className="value">
                  {moment(formFields.toDate).format("MM-DD-YYYY")}
                </span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("TO_DATE")}
                ></i>
              </div>
            ) : null}

            {formFields.preRfxFromDate ? (
              <div className="badge">
                <span className="lable">Pre-RFx Created date From</span>
                <span className="value">
                  {moment(formFields.preRfxFromDate).format("MM-DD-YYYY")}
                </span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("PRE_FROM_Date")}
                ></i>
              </div>
            ) : null}
            {formFields.preRfxToDate ? (
              <div className="badge">
                <span className="lable">Pre-RFx Created date To</span>
                <span className="value">
                  {moment(formFields.preRfxToDate).format("MM-DD-YYYY")}
                </span>
                <i
                  class="icofont-close"
                  onClick={() => _onResetFilter("PRE_TO_DATE")}
                ></i>
              </div>
            ) : null}
          </div>

          <div
            className="table-responsive "
            style={{
              height: "50vh",
              overflowY: "auto",
              marginBottom: "20px",
              resize: "vertical",
              overflow: "auto",
              maxHeight: "110vh"
            }}
          >
            <table className="table table-style-1 mb-4 table-responsive" id="">
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col">BU</th>
                  <th
                    scope="col"
                    style={{ textTransform: "none" }}
                    width="240px"
                  >
                    TITLE
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx TYPE
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    STATUS
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    CATEGORY
                  </th>
                  <th scope="col" width="240px">
                    CLIENT
                  </th>
                  <th scope="col">State</th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx PUBLISHED
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    RFx DUE
                  </th>
                  <th scope="col" className="text-center" width="160px">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === false
                  ? getListData.map((res, index) => {
                      return (
                        <tr key={index}>
                          <td>{res.business_name}</td>
                          <td style={{ width: 150 }} title={res.title}>
                            {res.title && res.title.length > 27
                              ? `${res.title.substring(0, 27)}...`
                              : res.title}
                          </td>
                          <td>{res.rfx_type_name}</td>
                          <td>
                            {res.rfx_status === "PENDING_APPROVAL"
                              ? "Pending Approval"
                              : res.rfx_status === "PUSH_BACK"
                              ? "Pushed Back"
                              : res.rfx_status === "PENDING"
                              ? "Pending"
                              : res.rfx_status === "APPROVED"
                              ? "Go"
                              : res.rfx_status === "DECLINED"
                              ? "No-Go"
                              : null}
                          </td>
                          <td>{res.rfx_category_name}</td>
                          <td>{res.rfx_client_agency}</td>
                          <td>{res.state_name}</td>
                          <td>
                            {moment(res.published, "DD/MM/YYYY").format(
                              "MM-DD-YYYY"
                            )}
                          </td>
                          <td>
                            {moment(res.due, "DD/MM/YYYY hh:mm:ss").format(
                              "MM-DD-YYYY hh:mm:ss"
                            )}
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-action edit mr-1"
                              disabled={
                                (res.rfx_status == "APPROVED" ? true : false) ||
                                !isEdit(access_prerfx_approval)
                              }
                              onClick={() => {
                                localStorage.setItem(
                                  "prerfx_approval_list_page",
                                  JSON.stringify({ page: currentPage })
                                );
                                _onEdit(res);
                              }}
                            >
                              <i className="icofont-pencil-alt-2"></i>
                            </button>
                            <button
                              disabled={!isView(access_prerfx_approval)}
                              type="button"
                              className="btn btn-action"
                              onClick={() => {
                                localStorage.setItem(
                                  "prerfx_approval_list_page",
                                  JSON.stringify({ page: currentPage })
                                );
                                history.push({
                                  pathname: `/view-pre-rfx-approval/${res.id}`,
                                  state: { detail: res.id, page: "View" },
                                });
                              }}
                            >
                              <i className="icofont-eye-alt"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {getListData.length === 0 && loading === false && global.new_key ? (
              <SearchNoDataFound message={"No Pre RFx Approval Found"} />
            ) : getListData.length === 0 && loading === false ? (
              <SearchNoDataFound message={"No Pre RFx Approval Found"} />
            ) : null}
            {<Loader isLoading={loading} />}
          </div>
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
                // Divya. November 10 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <ReactModal
        initWidth={550}
        initHeight={675}
        top={132.5}
        className={"contained-modal-title-vcenter"}
        onRequestClose={handleClose}
        isOpen={show}
        disableResize={true}
        disableKeystroke={true}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="">
            Filter Pre-RFx Approvals
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Business Unit</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                  onChange={handleChange}
                  name="businessUnitId"
                  value={formFields["businessUnitId"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {businessUnitData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Client / Agency</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxClient"
                  value={formFields["rfxClient"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Number</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxNumber"
                  value={formFields["rfxNumber"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mr-3">
                <label>RFx Category</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="rfxCategory"
                  value={formFields["rfxCategory"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  {RFxCategoryData.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>RFx Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="rfxTitle"
                  value={formFields["rfxTitle"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Pre-RFx Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="preRfxStatus"
                  value={formFields["preRfxStatus"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value={"APPROVED"}>Go</option>
                  <option value={"PENDING_APPROVAL"}>Pending Approval</option>
                  <option value={"PUSH_BACK"}>Pushed back</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 d-md-flex">
              <div className="">
                <div className="form-group">
                  <label>Created By</label>
                  <select
                    id="disabledSelect"
                    className="form-control custom-select "
                    onChange={handleChange}
                    name="createdBy"
                    value={formFields["createdBy"]}
                  >
                    <option value="" disabled>
                      Select your option
                    </option>
                    {userData.map((res, index) => {
                      return (
                        <option key={index} value={res.id}>
                          {res.first_name} {res.last_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Closing From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  onChange={(date) => setFromDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Closing To Date </label>
                <DatePicker
                  selected={formFields.toDate}
                  onChange={(date) => rfxDateValidation(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={formFields.fromDate}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Pre-RFx Created date From</label>
                <DatePicker
                  selected={formFields.preRfxFromDate}
                  onChange={(date) => setPreRfxFromDate(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Pre-RFx Created date To</label>
                <DatePicker
                  selected={formFields.preRfxToDate}
                  onChange={(date) => preRfxDateValidation(date)}
                  className="form-control"
                  placeholder="select Date"
                  dateFormat="MM-dd-yyyy"
                  minDate={formFields.preRfxFromDate}
                  scrollableYearDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
      </ReactModal>
    </>
  );
};
export default PreRfxsApprovals;
