import React, { useState } from "react";
import { useEffect } from "react";
import { APIMethod } from "../../../API/APIClient";

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState()

    useEffect(() => {
        getDashboardData()
    }, [])


    const getDashboardData = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/dashboard`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    console.log("data", data)
                    setDashboardData(data)
                }
            })
            .catch(error => {

            })
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <h2 className="page-title mb-md-0">Dashboard</h2>
                    <div className="col-12 d-flex m-0 p-0">
                        <div className="col-6">
                            <h4 className="dashboard-subtitle mb-md-0 mt-4">RFx Totals By Status and By Date Range</h4>
                            <div className="col-12 d-flex m-0 p-0">
                                <div className="col-6 m-0 ">
                                    <div className="dashboard-box" style={{background: "linear-gradient(90deg, #004ff9 0%, #fff94c 100%)" }}>
                                        <div className="dashboard-box-title">RFx Title</div>
                                        <div className="dashboard-box-count">{dashboardData?.rfx_title}</div>
                                    </div>
                                </div>
                                <div className="col-6 ">
                                    <div className="dashboard-box" style={{background: "linear-gradient(90deg, #6A9113 0%, #141517 100%)" }}>
                                        <div className="dashboard-box-title">Client</div>
                                        <div className="dashboard-box-count">{dashboardData?.client}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <h4 className="dashboard-subtitle mb-md-0 mt-4">RFx By RFx Type</h4>
                            <div className="col-12 d-flex m-0 p-0">
                                <div className="col-6 m-0">
                                    <div className="dashboard-box" style={{background: "linear-gradient(90deg, #F1F2B5 0%, #135058 100%)" }}>
                                        <div className="dashboard-box-title">No. of RFx</div>
                                        <div className="dashboard-box-count">{dashboardData?.no_rfx}</div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="dashboard-box" style={{background: "linear-gradient(90deg, #D1913C 0%, #FFD194 100%)" }}>
                                        <div className="dashboard-box-title">RFP TITLE</div>
                                        <div className="dashboard-box-count">{dashboardData?.rfp_title}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ml-1">
                        <h4 className="dashboard-subtitle mb-md-0 mt-4">Processing Times By Date Range</h4>
                        <div className="col-12 d-flex m-o p-0 flex-wrap">
                            <div className="col-3">
                                <div className="dashboard-box" style={{background: "linear-gradient(90deg, #7b4397 0%, #dc2430 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For RFx - Proposal Completion</div>
                                    <div className="dashboard-box-count">{dashboardData?.proposal_completion}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box" style={{background: "linear-gradient(90deg, #8e9eab 0%, #eef2f3 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For RFx Published To Creation</div>
                                    <div className="dashboard-box-count">{dashboardData?.published}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box" style={{background: "linear-gradient(90deg, #136a8a 0%, #267871 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For Creation To Send For Approval</div>
                                    <div className="dashboard-box-count">{dashboardData?.send_approval}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box" style={{background: "linear-gradient(90deg, #00bf8f 0%, #001510 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For Pre-RFx Approval</div>
                                    <div className="dashboard-box-count">{dashboardData?.rfx_approval}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box mt-4" style={{background: "linear-gradient(90deg, #ff0084 0%, #33001b 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For Pre-RFx Approval To Template Creation</div>
                                    <div className="dashboard-box-count">{dashboardData?.template_creation}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box mt-4 " style={{background: "linear-gradient(90deg, #833ab4 0%, #fd1d1d 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For Template Approval</div>
                                    <div className="dashboard-box-count">{dashboardData?.approval_template}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box mt-4" style={{background: "linear-gradient(90deg, #FEAC5E 0%, #C779D0 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For Template Approval To Proposal Creation</div>
                                    <div className="dashboard-box-count">{dashboardData?.approved_template}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box mt-4" style={{background: "linear-gradient(90deg, #6441A5 0%, #2a0845 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time For Proposal Approval</div>
                                    <div className="dashboard-box-count">{dashboardData?.proposal_approval}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="dashboard-box mt-4" style={{background: "linear-gradient(90deg, #43cea2 0%, #185a9d 100%)" }}>
                                    <div className="dashboard-box-title">Avg. Time for Proposal Approval to Proposal Completion</div>
                                    <div className="dashboard-box-count">{dashboardData?.proposal_approved}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;