import React, { useState, useEffect } from "react";
import Leftnavbar from "../../../../components/Leftnavbar";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";

const ProposalAwards = (props) => {
  const [loading, setLoading] = useState(false);
  const [getListData, setGetListData] = useState([]);
  // Add modal box
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Edit
  const [edit, setEdit] = useState("");
  const [id, setId] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //Divya. November 10 2023. Code End
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //getProposalAwardsList(1)
    //Commenting end. Divya. November 10 2023..
    // Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    getProposalAwardsList(currentPage);
    {
      /* Divya. November 10 2023. Code End */
    }
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 10 2023..
    // Added this code for  WOII-120. Divya. November 10 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 10 2023. Code End */
  }

  const checkPermission = (data) => {
    const resObject = data.find(
      (item) => item.name === "Manage Proposals & Awards"
    );
    const valObject = resObject.access.find(
      (item) => item.name === "Proposal Awards"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getProposalAwardsList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //const apifetcherObj = APIMethod(`/proposal-award/list/${page}?keyword=` + new_key, null, method)
    //Commenting end. Divya. November 10 2023..
    // Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    const apifetcherObj = APIMethod(
      `/proposal-award/list/${page}?limit=${rowsPerPage}&keyword=` + new_key,
      null,
      method
    );
    {
      /* Divya. November 10 2023. Code End */
    }
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setGetListData(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const Search = (key) => {
    getProposalAwardsList(1, key);
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 10 2023.. Code Start
    //getProposalAwardsList(pageNumber)
    //Commenting end. Divya. November 10 2023..
    // Added this code for  WOII-120. Divya. November 10 2023.. Code Start
    setCurrentPage(pageNumber);
    {
      /* Divya. November 10 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 10 2023.. Code Start */
  }
  // const handleRowsPerPageChange = (selectedValue) => {
  //   setRowsPerPage(selectedValue);
  // };
  {
    /* Divya. November 10 2023. Code End */
  }

  /* Added this code for  WOII-262. Prasanna. October 23 2024.. Code Start */
  useEffect(() => {
    const currentPath = window.location.pathname;
    const savedRowsPerPage = localStorage.getItem(`rowsPerPage_${currentPath}`);
    if (savedRowsPerPage) {
      setRowsPerPage(Number(savedRowsPerPage));
    }
  }, []);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);

    const currentPath = window.location.pathname;
    localStorage.setItem(`rowsPerPage_${currentPath}`, newRowsPerPage);
  };

  /* Prasanna. October 23 2024. Code End */

  const _onEdit = (res) => {
    history.push({
      pathname: "/edit-proposal-awards",
      state: { detail: res, page: "Edit", from: "Proposal Awards" },
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Proposal Awards</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by RFx Title"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
            </div>
          </div>

          <div
            className="table-responsive "
            style={{
              height: "60vh",
              overflowY: "auto",
              marginBottom: "20px",
              resize: "vertical",
              overflow: "auto",
              maxHeight: "110vh"
            }}
          >
            <table
              className=" table table-style-1 table-responsive-lg mb-4 table reports"
              id="reportTable"
            >
              <thead className="sticky-top" style={{ zIndex: 10 }}>
                <tr>
                  <th scope="col" style={{ textTransform: "none" }}>
                    BU
                  </th>
                  <th scope="col" style={{ textTransform: "none" }}>
                    TITLE
                  </th>
                  <th scope="col">Client</th>
                  <th scope="col">State</th>
                  <th scope="col">Awarded? </th>
                  <th scope="col">Notice?</th>
                  <th scope="col" className="text-center" width="110">
                    Action
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/proposal-award/"}
                updateList={getProposalAwardsList}
              ></ModalPopup>
              <tbody>
                {getListData.map((res, index) => {
                  return (
                    <tr key={index}>
                      <td>{res.business_unit_name}</td>
                      <td title={res.rfx_title}>{res.rfx_title}</td>
                      <td>{res.client_name}</td>
                      <td>{res.state_name}</td>
                      <td>{res.is_awarded === false ? "N" : "Y"}</td>
                      <td>{res.is_award_notice === false ? "N" : "Y"}</td>
                      <td className="text-center">
                        {editing === true ? (
                          <button
                            type="button"
                            className="btn btn-action edit mr-1"
                            onClick={() => _onEdit(res)}
                          >
                            <i className="icofont-pencil-alt-2"></i>
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {getListData.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Proposal Award Found"} />
          ) : getListData.length === 0 && loading === false ? (
            <SearchNoDataFound message={"No Proposal Award Found"} />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                /* Added this code for  WOII-262. Prasanna. October 23 2024.. Code Start */
                rowsPerPage={rowsPerPage}
                onrowsPerPageChange={handleRowsPerPageChange}
              /* Prasanna. October 23 2024. Code End */
                //Added this code for  WOII-120. Divya. November 10 2023.. Code Start
                //rowsPerPage={handleRowsPerPageChange}
                // Divya. November 10 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Filter Modal Box */}
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header px-md-5">
          <h5 className="modal-title" id="">
            Filter Search Proposals Awards
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <Modal.Body>
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Business Unit</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                >
                  <option>US</option>
                  <option>CANADA</option>
                  <option></option>
                  <option></option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Client</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>RFx Number</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Title</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Proposal Template Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                >
                  <option>Pending</option>
                  <option>Go</option>
                  <option>No-Go</option>
                  <option>Pushback</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-lg btn-brand-1">
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ProposalAwards;
