import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { APIMethod, tiny_key } from "../API/APIClient";
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from "../utility/utility";

const Comment = (props) => {
    const commentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const [flag, setFlag] = useState(false);
    const [commentData, setCommentData] = useState([]);
    {/* Commenting the following code for WOII-64. Divya. September 01 2023.. Code */}
   {/* const [formFields, setFormFields] = useState({
        comment: '', id: ''
    })*/}
     {/* Commenting end. Divya. September 01 2023.. */}
    {/* Added this code for  WOII-64. Divya. September 01 2023.. Code Start */}
    const [formFields, setFormFields] = useState({
        comment: '', id: '', isComment:false
    })
    {/* Divya. September 01 2023. Code End */}


    useEffect(() => {
        getComments()
    }, [])


    const getComments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/comments/${props.id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setCommentData(data)
                    props.updateCommentList();
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }
    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }
    const addComment = () => {
        setLoading(true)
        {/* Commenting the following code for WOII-64. Divya. September 01 2023.. Code */}
       // if (checkEmptyString(commentRef.current.getContent())) {
         {/* Commenting end. Divya. September 01 2023.. */}
    {/* Added this code for  WOII-64. Divya. September 01 2023.. Code Start */}
        if (formFields.comment == '') {
     {/* Divya. September 01 2023. Code End */}
            setFlag(true)
            setLoading(false)
            addToast('Please enter comment', { appearance: 'error', autoDismiss: true });
        } else {
            {/* Added this code for  WOII-64. Divya. September 01 2023.. Code Start */}
            setLoading(true)
            {/* Divya. September 01 2023. Code End */}
            var method = 'POST'
            var params = JSON.stringify({
                  //Commenting the following code for WOII-64. Divya. September 01 2023.. Code
                //comment: commentRef.current.getContent(),
                 //Commenting end. Divya. September 01 2023.. 
                //Added this code for  WOII-64. Divya. September 01 2023.. Code Start 
                comment: formFields.comment,
                //Divya. September 01 2023. Code End
                pre_rfx_id: props.id,
            })
            const apifetcherObj = APIMethod(`/pre-rfx/post/comment?comment_type=PRE_RFX`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    if (statusCode === 200) {
                        getComments()
                        //Commenting the following code for WOII-64. Divya. September 01 2023.. Code
                        //UnCommenting the following code for WOII-64. Divya. September 04 2023.. Code
                        setFormFields({ ...formFields, comment: '' })
                        //UnCommenting end. Divya. September 04 2023..
                         //Commenting end. Divya. September 01 2023.. 
                         //Added this code for  WOII-64. Divya. September 01 2023.. Code Start 
                         //Commenting the following code for WOII-64. Divya. September 04 2023.. Code
                        //formFields.comment = ''
                        //Commenting end. Divya. September 04 2023..
                        //Divya. September 01 2023. Code End
                        setLoading(false)
                        //  addToast(data.message, { appearance: 'success', autoDismiss: true });
                        //Commenting the following code for WOII-64. Divya. September 01 2023.. Code
                        //UnCommenting the following code for WOII-64. Divya. September 04 2023.. Code
                        props.enableComment()
                        //UnCommenting end. Divya. September 04 2023..
                        //Commenting end. Divya. September 01 2023.. 
                         //Added this code for  WOII-64. Divya. September 01 2023.. Code Start
                         //Commenting the following code for WOII-64. Divya. September 04 2023.. Code 
                        //formFields.isComment = true
                        //Commenting end. Divya. September 04 2023..
                        //Divya. September 01 2023. Code End
                        
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    return (


        <div className="form-group form-style">
            <label>Add Comment</label>
            {/* Commenting the following code for WOII-64. Divya. August 28 2023.. Code */}
            {/* {
                props.editor === true ?
                    <div className="mb-3">
                        <Editor
                            apiKey={tiny_key}
                            onInit={(evt, editor) => commentRef.current = editor}
                            initialValue={formFields.comment}
                            init={{
                                height: 500,
                                menubar: 'file edit view insert format tools table tc help',
                                selector: 'textarea#full-featured',
                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                            }}
                        />
                    </div>
                    :
                    <textarea className="form-control h-auto mb-2" rows="2" placeholder="Write here.." onChange={handleChange} name="comment" value={formFields['comment']}></textarea>
            }
        */}
        {/* Commenting end. Divya. August 28 2023.. */}
        {/* Added this code for  WOII-64. Divya. August 29 2023.. Code Start */}
        <textarea 
        className="form-control h-auto mb-2" 
        rows="5" 
        placeholder="Write here.." 
        onChange={handleChange} 
        name="comment" 
        value={formFields['comment']}>
    </textarea>
    {/* Divya. August 29 2023. Code End */}

            <button type="button" className="btn btn-brand-1 btn-sm" onClick={() => addComment()} disabled={loading}>
                {loading && (
                    <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                )}
                Post <i class="icofont-paper-plane"></i></button>
        </div>

    )

}
export default Comment;