import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, generatePath } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APIMethod } from "../../../../API/APIClient";
import Loader from "../../../../components/Loader";
import ModalPopup from "../../../../components/ModalPopUp";
import Pagination from "../../../../components/Pagination";
import { useHistory } from "react-router";
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";

const Companies = (props) => {
  const [loading, setLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [businessUnit, setBusinessUnit] = useState("");
  const [businessUnitError, setBusinessUnitError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  // Delete
  const [deleteId, setDeleteId] = useState("");
  const [showModalPopup, setShowModalPopup] = useState(false);
  // page
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  {
    /* Added this code for  WOII-120. Divya. November 09 2023.. Code Start */
  }
  const [rowsPerPage, setRowsPerPage] = useState(10);
  {
    /* Divya. November 09 2023. Code End */
  }
  // Add modal box
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // Filter modal box
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  //--------date-Piicker----------
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();
  const [adding, setAdding] = useState();
  const [editing, setEditing] = useState();
  const [deleting, setDeleting] = useState();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // Added this code for  WOII-185. Divya. March 20 2024.. Code Start
  const [formFields, setFormFields] = useState({
    name: "",
    status: "ACTIVE",
  });
  //Divya. March 20 2024. Code End

  useEffect(() => {
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //getCompaniesList(1)
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
    getCompaniesList(currentPage);
    {
      /* Divya. November 09 2023. Code End */
    }
    var data = JSON.parse(localStorage.getItem("user_Role"));
    checkPermission(data);
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //}, [])
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
  }, [rowsPerPage, currentPage]);
  {
    /* Divya. November 09 2023. Code End */
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkPermission = (data) => {
    const resObject = data.find((item) => item.name === "Organizational Setup");
    const valObject = resObject.access.find(
      (item) => item.name === "Companies & Capabilities"
    );
    setAdding(valObject.is_create);
    setEditing(valObject.is_update);
    setDeleting(valObject.is_delete);
  };

  const getCompaniesList = (page, key) => {
    setLoading(true);
    const new_key = key === undefined ? "" : key;
    global.new_key = key === undefined ? "" : key;
    var method = "GET";
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //const apifetcherObj = APIMethod(`/company-details/list/${page}?keyword=${new_key}`, null, method)
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
    //const apifetcherObj = APIMethod(`/company-details/list/${page}?limit=${rowsPerPage}&keyword=${new_key}`, null, method)
    {
      /* Divya. November 09 2023. Code End */
    }
    // Added "name and status" parameters for WOII-185 by Divya
    const apifetcherObj = APIMethod(
      `/company-details/list/${page}?name=${formFields.name}&status=${formFields.status}&limit=${rowsPerPage}&keyword=${new_key}`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          setCompaniesList(data.list);
          if (page === 1) {
            setPageCount(data.count);
          }
          setCurrentPage(page);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const save = () => {
    {
      // eslint-disable-line
      businessUnit === ""
        ? setBusinessUnitError("Please Enter Business Unit ")
        : setBusinessUnitError("");
    } // eslint-disable-line
    {
      // eslint-disable-line
      companyName === ""
        ? setCompanyNameError("Please Enter Company Name")
        : setCompanyNameError("");
    } // eslint-disable-line
  };

  const _onDelete = (id) => {
    setDeleteId(id);
    isShowPopup(true);
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
  };

  const nextPage = (pageNumber) => {
    //Commenting the following code for WOII-120. Divya. November 09 2023.. Code Start
    //getCompaniesList(pageNumber)
    //Commenting end. Divya. November 09 2023..
    //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
    setCurrentPage(pageNumber);
    {
      /* Divya. November 09 2023. Code End */
    }
  };

  {
    /* Added this code for  WOII-120. Divya. November 09 2023.. Code Start */
  }
  const handleRowsPerPageChange = (selectedValue) => {
    setRowsPerPage(selectedValue);
  };
  {
    /* Divya. November 09 2023. Code End */
  }

  const Search = (key) => {
    getCompaniesList(1, key);
  };

  const _onEdit = (val) => {
    val && history.push(generatePath("/edit-company-details/:val", { val }));
  };

  const getData = () => {
    getCompaniesList(1);
  };

  // Added this code for  WOII-185. Divya. March 20 2024.. Code Start
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value,
    };
    setFormFields(formFieldsObject);
  };

  const _onResetFilter = (type) => {
    if (type === "NAME") {
      formFields.name = "";
    } else if (type === "STATUS") {
      formFields.status = "";
    } else {
      formFields.name = "";
      formFields.status = "";
      handleCloseFilter();
    }
    getCompaniesList(1, "");
  };

  const _onFilter = () => {
    getCompaniesList(1, "");
    handleCloseFilter();
  };

  const enableRow = (id) => {
    var method = "PUT";
    const apifetcherObj = APIMethod(
      `/company-details/${id}/enable`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getCompaniesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error enabling companiess:", error);
      });
  };

  const deleteRow = (id) => {
    var method = "DELETE";
    const apifetcherObj = APIMethod(
      `/company-details/${id}/delete`,
      null,
      method
    );
    apifetcherObj
      .then((response) => {
        return Promise.all([response.status, response.json()]);
      })
      .then((res) => {
        let statusCode = res[0];
        let data = res[1];

        if (statusCode === 200) {
          getCompaniesList(currentPage);
          isShowPopup(false);
        }
      })
      .catch((error) => {
        console.error("Error in deleting companies:", error);
      });
  };
  //Divya. March 20 2024. Code End
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mb-3 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
              <h2 className="page-title mb-md-0">Companies & Capabilities</h2>
            </div>
            <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
              <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by company name"
                  onChange={(event) => Search(event.target.value)}
                />
                <span className="input-group-text text-secondary">
                  <i className="icofont-search-1"></i>
                </span>
              </div>
              {/* Added this code for  WOII-185. Divya. March 20 2024.. Code Start */}
              <button
                type="button"
                className="btn btn-brand-1 btn-h-40 mr-3"
                onClick={() => setShowFilter(true)}
              >
                <i className="icofont-filter mr-2"></i> Filter
              </button>
              {/* Divya. March 20 2024. Code End  */}
              {adding === true ? (
                <NavLink
                  to="/add-company-details"
                  className="btn btn-brand-1 btn-h-40 bg-success px-4"
                >
                  + Add
                </NavLink>
              ) : null}
            </div>
          </div>

          {/* Added this code for  WOII-185. Divya. March 20 2024.. Code Start */}
          <div className="mb-2 filter-by">
            {formFields.name ? (
              <div className="badge">
                <span className="lable">Company Name </span>
                <span className="value">{formFields.name}</span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("NAME")}
                ></i>
              </div>
            ) : null}
            {formFields.status ? (
              <div className="badge">
                <span className="lable">Status</span>
                <span className="value">
                  {formFields.status == "ACTIVE"
                    ? "Active"
                    : formFields.status == "DELETED"
                      ? "Archived"
                      : formFields.status}
                </span>
                <i
                  className="icofont-close"
                  onClick={() => _onResetFilter("STATUS")}
                ></i>
              </div>
            ) : null}
          </div>
          {/* Divya. March 20 2024. Code End  */}

          <div
            className="table-responsive"
            style={{
              height: "30vh",
              overflowY: "auto",
              marginBottom: "20px",
              resize: "vertical",
              overflow: "auto",
              maxHeight: "110vh"
            }}
          >
            <table
              className="table table-style-1 table-responsive-lg mb-4 table reports"
              id="reportTable"
            >
              <thead
                className="sticky-top"
                style={{
                  zIndex: 10,
                }}
              >
                <tr>
                  <th scope="col">Business Unit </th>
                  {/* Commenting the following code for WOII-163. Divya. January 11 2024.. Code Start  */}
                  {/* <th scope="col">BU Type </th> */}
                  {/* Commenting end. Divya. January 11 2024.. 
                                                        Added this code for  WOII-163. Divya. January 11 2024.. Code Start */}
                  <th scope="col">Company Type </th>
                  {/* Divya. January 11 2024. Code End */}
                  <th scope="col">Company Name </th>
                  <th scope="col" className="text-center" width="110">
                    {editing === true || deleting === true ? "Action" : null}
                  </th>
                </tr>
              </thead>
              <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={"/company-details/"}
                updateList={getData}
              ></ModalPopup>
              <tbody>
                {loading === false
                  ? companiesList.map((res, index) => {
                    {
                      /* Added this code for  WOII-185. Divya. March 20 2024.. Code Start */
                    }
                    const isRowArchived = res.status === "DELETED";
                    {
                      /* Divya. March 20 2024. Code End  */
                    }
                    return (
                      // Commenting the following code for WOII-185. Divya. March 20 2024.. Code Start
                      // <tr key={index}>
                      //Commenting end. Divya  March 20 2024..
                      // Added this code for  WOII-185. Divya. March 20 2024.. Code Start
                      <tr
                        key={res.id}
                        className={isRowArchived ? "archived" : ""}
                      >
                        {/* Divya. March 20 2024. Code End  */}
                        <td>{res.business_name}</td>
                        <td>{res.business_unit_type_id_name}</td>
                        <td>{res.name}</td>
                        <td className="text-center">
                          {/* Commenting the following code for WOII-185. Divya. March 20 2024.. Code Start */}
                          {/* <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res.id)}><i className="icofont-pencil-alt-2"></i></button>
                                                    <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button> */}
                          {/* Commenting end. Divya  March 20 2024..
                                                    Added this code for  WOII-185. Divya. March 20 2024.. Code Start */}
                          {isRowArchived ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-action enable"
                                onClick={() => enableRow(res.id)}
                              >
                                <i
                                  className="icofont-check"
                                  style={{ fontSize: "18px" }}
                                ></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-action delete ml-1"
                                onClick={() => deleteRow(res.id)}
                              >
                                <i className="icofont-archive"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-action edit mr-1"
                                onClick={() => _onEdit(res.id)}
                              >
                                <i className="icofont-pencil-alt-2"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-action delete"
                                onClick={() => _onDelete(res.id)}
                              >
                                <i className="icofont-archive"></i>
                              </button>
                            </>
                          )}
                          {/* Divya. March 20 2024. Code End  */}
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
          {companiesList.length === 0 && loading === false && global.new_key ? (
            <SearchNoDataFound message={"No Companies Found"} />
          ) : companiesList.length === 0 && loading === false ? (
            <NoData />
          ) : null}
          {<Loader isLoading={loading} />}
          <div className="text-md-right">
            <nav aria-label="Page navigation">
              <Pagination
                pages={pageCount}
                endPoint={"/company-details/list/"}
                nextPage={nextPage}
                currentPage={currentPage}
                pageCount={pageCount}
                //Added this code for  WOII-120. Divya. November 09 2023.. Code Start
                rowsPerPage={handleRowsPerPageChange}
              // Divya. November 09 2023. Code End
              />
            </nav>
          </div>
        </div>
      </div>

      {/* Add Modal Box */}
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header px-md-5">
          <h5 className="modal-title" id="">
            Add Company
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <i className="icofont-close"></i>
          </button>
        </div>
        <Modal.Body>
          <div className="row form-style">
            <div className="col-md-12">
              <div className="form-group">
                <label>Business Unit </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setBusinessUnit(event.target.value);
                  }}
                  value={businessUnit}
                />
                <span className="text-danger">{businessUnitError}</span>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Business Unit Type</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select"
                >
                  <option> </option>
                  <option>Department</option>
                  <option>Partner</option>
                  <option>Sub-Contractor</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setCompanyName(event.target.value);
                  }}
                  value={companyName}
                />
                <span className="text-danger">{companyNameError}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1"
            onClick={() => save()}
          >
            Save
          </button>
        </div>
      </Modal>

      {/* Filter Modal Box */}
      <Modal
        show={showFilter}
        onHide={handleCloseFilter}
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header px-md-5">
          <h5 className="modal-title" id="">
            Filters
          </h5>
          <button type="button" className="close" onClick={handleCloseFilter}>
            <i className="icofont-close"></i>
          </button>
        </div>
        {/* Commenting the following code for WOII-185. Divya. March 20 2024.. Code Start */}
        {/* <Modal.Body>
                    <div className="row form-style">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit Code</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option> </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit Name</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Business Unit Type</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option> </option>
                                    <option>--</option>
                                    <option>--</option>
                                    <option>--</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Company Name :</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status</label>
                                <select id="disabledSelect" className="form-control custom-select">
                                    <option> </option>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                    <option>Close</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Date From</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Date To</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1">Apply</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleCloseFilter}>Cancel</button>

                </div> */}
        {/* Commenting end. Divya  March 20 2024.. 
                 Added this code for  WOII-185. Divya. March 20 2024.. Code Start */}
        <div className="modal-body">
          <div className="row form-style">
            <div className="col-md-6">
              <div className="form-group">
                <label>Comapny Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="name"
                  value={formFields["name"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select
                  id="disabledSelect"
                  className="form-control custom-select "
                  onChange={handleChange}
                  name="status"
                  value={formFields["status"]}
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="ACTIVE">Active</option>
                  <option Value="DELETED">Archived</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer ">
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-danger"
            onClick={handleCloseFilter}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-brand-1"
            onClick={() => _onResetFilter()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-lg btn-brand-1 bg-success"
            onClick={() => _onFilter()}
          >
            Search
          </button>
        </div>
        {/* Divya. March 20 2024. Code End  */}
      </Modal>
    </>
  );
};
export default Companies;
