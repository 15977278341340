import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Tab,
    Nav,
    Row,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useParams } from "react-router-dom";
import {
    APIMethod,
    TOAST_TIMEOUT,
    FileUploadMethod
} from "../../../../API/APIClient";
import moment from "moment";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import ReactModal from "react-modal-resizable-draggable";
import { Chrono } from "react-chrono"
import { useRef } from "react";

const EditProposalDispatch = (props) => {
    /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
    const [isLoding, setLoading] = useState(false);
    / Commenting end. Dharma. April 16 2024..**/
    /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingDispatch, setIsLoadingDispatch] = useState(false);
    /*Dharma. April 16 2024. Code End */
    const { id } = useParams();
    const [commentData, setCommentData] = useState([]);
    const history = useHistory();
    const [formFields, setFormFields] = useState({
        rfxCategory: "",
        question: "",
        tag: "",
        preRfxId: "",
        sendForApprovalLoader: false,
        page: "",
        keyword: "",
        modalQuestion: "",
        modalAnswer: "",
        comment: "",
        commentAddLoader: false,
        isComment: false,
        document_type: "",
        version: "",
        description: "",
        predefine_sections: "",
        submitButtonLoader: false,
        saveButtonLoader: false,
    });
    {
        /* Added this code for WI-2. Divya. June 19 2023.. Code Start */
    }
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        getProposalSectionComments()
    };
    {
        /* Divya. June 19 2023. Code End */
    }
    //Goto back
    const goBack = () => {
        window.history.back();
    };
    // Toast
    const { addToast } = useToasts();
    //--------date-Piicker----------
    const [showProposalTracking, setShowProposalTracking] = useState(false);

    // state variables ////////////////////////////////////////
    const location = useLocation();
    const [preRfxData, setPreRfxData] = useState();
    const [dispatchMethod, setDispatchMethod] = useState(location?.state?.dispatchMethod ? location?.state?.dispatchMethod : "N/A");
    const [URL, setURL] = useState(location?.state?.client_url);
    const [email, setEmail] = useState(location?.state?.email);
    const [carrier, setCarrier] = useState(location?.state?.carrier ? location?.state?.carrier : "N/A");
    const [trackingNo, setTrackingNo] = useState(location?.state?.trackingId ? location?.state?.trackingId : "")
    const [comment, setComment] = useState("");
    const [isCommentPosted, setIsCommentPosted] = useState(false)
    const [isTracking, setIsTracking] = useState(false)
    const [eventCards, setEventCards] = useState([]);
    const [isCommenting, setIsCommenting] = useState(false)
    const [file, setFile] = useState();
    const [fileBool, setFileBool] = useState(false)
    const [addDocuments, setAddDocuments] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [fileInputInit, setFileInputInit] = useState(false);

    const intervalRef = useRef()

    useEffect(() => {
        // if (!location.state?.rfx_num || !location.state?.status || !location.state?.rfx_name || !location.state?.client_url) {
        getPreRfxData();
        getProposalDocuments();
        getDocumentTypeList();
        console.log(documentList)
        // }
    }, []);

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        uploadDocument(selectedFile)
        setFileBool(false)
    }

    const handleRemoveFile = () => {
        setFile(undefined)
        setFileBool(false)
    }

    const getProposalDocuments = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/proposal/document/list/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setAddDocuments(data)
                    processData(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setFileInputInit(true);
                }
            })
            .catch(error => {
                setFileInputInit(true);
                addToast("Something Went Wrong", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    async function processData(data) {
        for (let i = data.length - 1; i >= 0; i--) {
            const element = data[i];
            if (element.document_type_name === "Proposal") {
                const fileBlob = await fetch(element.file).then(res => res.blob());
                const file = new File([fileBlob], element.file_original_name.replace(/^[0-9_ -]+/, ''), { type: fileBlob.type });
                setFile(file);
                setFileBool(true);
                break;
            }
        }
        setFileInputInit(true);
    }

    const getDocumentTypeList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const sendEmail = async () => {
        var method = 'POST'
        const formData = new FormData();
        formData.append('file', file);
        formData.append('to', email);
        formData.append('id', id);

        const apifetcherObj = await FileUploadMethod(`/proposal/send-mail`, formData, method);
        const [responseStatus, responseData] = await Promise.all([apifetcherObj.status, apifetcherObj.json()]);
        if (responseStatus !== 200 ) {
            addToast("Something went wrong while sending email", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
        }
    }

    const uploadDocument = async (selectedFile) => {
        try {
            const method = 'POST';
            const form = new FormData();
            form.append('file', selectedFile);
            const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method);
            const response = await apifetcherObj;
            const responseBody = await response.text();
            const status = response.status;
            const data = responseBody ? JSON.parse(responseBody) : null;
            
            if (status === 200) {
                const obj = {
                document_id: documentList.find(item => item.name.trim() === 'Proposal').id,
                document_type_name: 'Proposal',
                file_name: selectedFile.name,
                file: data.file,
                description: "Uploaded On Proposal Dispatch Page"
                };
                
                addDocuments.push(obj);
                setAddDocuments([...addDocuments]);
            } else {
                addToast(data.message, {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: 'error',
                autoDismiss: true
                });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            addToast('Error uploading file', {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    const onSaveProposal = (onSave = false) => {
        var method = 'POST'
        var params = JSON.stringify(addDocuments)
        const apifetcherObj = APIMethod(`/proposal/document/${id}`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });      
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
                if (onSave) {
                    setIsLoadingSave(false);
                } else {
                    setIsLoadingDispatch(false);
                    history.push({ pathname: '/proposal-dispatches'})
                }
            })
            .catch(error => {
                addToast("Something went wrong while uploading the documents", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getPreRfxData = () => {
        if (props.location.state == null) {
        } else {
            formFields.preRfxId = props.location.state.detail;
            formFields.page = props.location.state.page;
        }
        var method = "GET";
        const apifetcherObj = APIMethod(`/pre-rfx/proposal-dispatch/list/${1}?id=${id}`, null, method);
        apifetcherObj
            .then((response) => {
                return Promise.all([response.status, response.json()]);
            })
            .then(async (res) => {
                let statusCode = res[0];
                let data = res[1];
                if (statusCode === 200) {
                    setDispatchMethod(data.list[0].dispatch_method)
                    setURL(!data.list[0].client_url ? "" : data.list[0].client_url)
                    setCarrier(!data.list[0].carrier ? "N/A" : data.list[0].carrier)
                    setEmail(!data.list[0].reciever_email ? "" : data.list[0].reciever_email)

                    // setPreRfxData(data);
                    // setURL(data?.client_url)
                    // setLoading(false);
                } else {
                    addToast(data.message, { appearance: "error", autoDismiss: true });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleVisitLink = () => {
        if (URL.includes("https://") || URL.includes("http://")) {
            window.open(URL, "_blank")
        }
        window.open("https://" + URL, "_blank")
    }

    const getProposalSectionComments = (fromPost = false) => {
        if (commentData.length == 0 || fromPost) {
            var method = "GET";
            const apifetcherObj = APIMethod(
                `/template-content/comments/list/${id}?comment_type=DISPATCH_COMMENT`,
                null,
                method
            );
            apifetcherObj
                .then((response) => {
                    return Promise.all([response.status, response.json()]);
                })
                .then((res) => {
                    let statusCode = res[0];
                    let data = res[1];

                    if (statusCode === 200) {
                        setCommentData(data);
                    } else {
                        addToast(data.message, {
                            autoDismissTimeout: TOAST_TIMEOUT,
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                })
                .catch((error) => {
                    addToast(error, {
                        autoDismissTimeout: TOAST_TIMEOUT,
                        appearance: "error",
                        autoDismiss: true,
                    });
                });
        }
    };

    const addComment = () => {
        if (comment == "") {
            addToast("Please enter comment", {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "error",
                autoDismiss: true,
            });
        } else {
            setIsCommenting(true);
            var method = "POST";
            var params = JSON.stringify({
                comment: comment,
                pre_rfx_id: id,
                type: "DISPATCH_COMMENT",
            });
            const apifetcherObj = APIMethod(
                `/template-content/post/comment`,
                params,
                method
            );
            apifetcherObj
                .then((response) => {
                    return Promise.all([response.status, response.json()]);
                })
                .then((res) => {
                    let statusCode = res[0];
                    let data = res[1];
                    if (statusCode == 200) {
                        // addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                        getProposalSectionComments(true);
                        setIsCommenting(false)
                        formFields.isComment = true;
                        formFields.comment = "";
                        setComment("")
                        setIsCommentPosted(true)
                    } else {
                        addToast(data.message, {
                            autoDismissTimeout: TOAST_TIMEOUT,
                            appearance: "error",
                            autoDismiss: true,
                        });
                        setIsCommenting(false)
                    }
                })
                .catch((error) => {
                    addToast(error, {
                        autoDismissTimeout: TOAST_TIMEOUT,
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setIsCommenting(false)
                });
        }
    };

    const onCancelModal = () => {
        window.clearInterval(intervalRef.current)
        setFormFields({
            ...formFields,
            document_type: "",
            version: "",
            description: "",
        });
        setShowProposalTracking(!showProposalTracking);
    };

    const onDispatch = (onSave = false) => {
        if (!dispatchMethod || dispatchMethod == "" || dispatchMethod == "N/A") {
            addToast("Dispatch Method is Empty", {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "error",
                autoDismiss: true
            })
            return;
        }
        if (dispatchMethod == "Mail") {
            if (!carrier || carrier == "N/A") {
                addToast("Proposal Carrier is Empty", {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true
                })
                return;
            } else if (!trackingNo) {
                addToast("Tracking Number is Empty", {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true
                })
                return;
            }
        } else if (dispatchMethod == "E-Mail") {
            if (!email) {
                addToast("Email is Empty", {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true
                })
                return;
            }
            if (!file) {
                addToast("Please Upload Proposal Document", {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true
                })
                return;
            }
        } else if (dispatchMethod == "Online_Submission") {
            if (!URL) {
                addToast("URL is Empty", {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true
                })
                return;
            }
        }
        if (onSave) {
            if (!isCommentPosted) {
                addToast("Please Post a comment", {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true
                })
                return;
            }
            setIsCommentPosted(false)
        }
        /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
        if (onSave) {
            setIsLoadingSave(true);
        } else {
            setIsLoadingDispatch(true);
        }
        /*Dharma. April 16 2024. Code End */
        var method = "PUT";
        /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
        setLoading(true);
        / Commenting end. Dharma. April 16 2024..**/
        const apifetcherObj = APIMethod(
            `/action/event/PROPOSAL_DISPATCHED/${id}?dispatchMethod=${dispatchMethod}&carrier=${carrier}&trackingId=${trackingNo}&email=${email}&onSave=${onSave}`,
            null,
            method
        );
        apifetcherObj
            .then((response) => {
                return Promise.all([response.status, response.json()]);
            })
            .then((res) => {
                let statusCode = res[0];
                let data = res[1];
                if (statusCode == 200) {
                    onSaveProposal(onSave)
                    if (onSave) {
                        addToast("Saved Successfully", {
                            autoDismissTimeout: TOAST_TIMEOUT,
                            appearance: "success",
                            autoDismiss: true,
                        });
                        setIsCommentPosted(false)
                    } else {
                        sendEmail()
                        addToast("Proposal is Dispatched", {
                            autoDismissTimeout: TOAST_TIMEOUT,
                            appearance: "success",
                            autoDismiss: true,
                        });
                    }
                } else {
                    addToast(data.message, {
                        autoDismissTimeout: TOAST_TIMEOUT,
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => { })
            .finally(() => {
                /**Commenting the following code for WOII-195. Dharma. April 16 2024.. Code Start
                setLoading(false);
                / Commenting end. Dharma. April 16 2024..**/
                /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */
                /*Dharma. April 16 2024. Code End */
            });
    };

    const onTrackProposal = () => {
        if (carrier == "N/A") {
            addToast("Please select a Carrier", {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        if (carrier != "Fedex") {
            addToast("Service not available. Please contact Administrator", {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        if (trackingNo == "") {
            addToast("Tracking Number is Empty", {
                autoDismissTimeout: TOAST_TIMEOUT,
                appearance: "error",
                autoDismiss: true,
            });
            return;
        }
        setIsTracking(true)
        var method = "POST"
        const apifetcherObj = APIMethod(
            `/track/proposal?trackingNo=${trackingNo}`,
            null,
            method
        );
        apifetcherObj.then((response) => {
            return Promise.all([response.status, response.json()]);
        }).then((res) => {
            let status = res[0]
            setIsTracking(false)
            if (status == 200) {
                const events = res[1]["id"]["events"]
                const cards = []
                events.map((event) => {
                    const eventCard = {}
                    const time = event.date.split(" ")
                    eventCard["date"] = event.date
                    eventCard["cardTitle"] = event.message
                    eventCard["cardSubtitle"] = event.event_description
                    eventCard["cardDetailedText"] = event.location + " " + time[1] + " " + time[2]
                    cards.push(eventCard)
                })
                setEventCards(cards)
            } else {
                addToast(res[1]["id"]["message"], {
                    autoDismissTimeout: TOAST_TIMEOUT,
                    appearance: "error",
                    autoDismiss: true,
                });
                setShowProposalTracking(false)
                return;
            }
        })
        setShowProposalTracking(!showProposalTracking)
        intervalRef.current = window.setInterval(function () {
            const elem = document.getElementById('timeline-main-wrapper');
            if (elem) {
                elem.style.scrollBehavior = "smooth"
                elem.scrollTop = elem.scrollHeight
            }
        }, 1150);
    }

    return (
        <>
            <div className="page-wrapper form-style">
                <div className="page-content">
                    <div className="mb-3">
                        {location.state && (
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => goBack()}>
                                    {location.state.from}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Edit Proposal Dispatch</Breadcrumb.Item>
                            </Breadcrumb>
                        )}
                    </div>
                    <div className="row col-12">
                        <div className="col-3">
                            <h2 className="page-title">Dispatch Proposal</h2>
                        </div>
                        <div className="col-9 row">
                            <h4 className="font-16 font-medium">
                                RFx No. :{" "}
                                <span className="text-secondary">
                                    {location.state ?
                                        (location.state.rfx_num ? location.state.rfx_num :
                                            (preRfxData?.rfx_number) ?
                                                preRfxData?.rfx_number : "-") : preRfxData?.rfx_number}

                                </span>
                            </h4>
                            <h4 className="font-16 font-medium ml-5">
                                RFx Title :{" "}
                                <span className="text-secondary">
                                    {location.state ?
                                        (location.state.rfx_name ? location.state.rfx_name :
                                            preRfxData?.title ?
                                                preRfxData?.title : "-") : preRfxData?.title}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className="d-flex overflow-auto pb-4 mt-4">
                        <div className="tab-style-2 mb-4" id="PraposalTemp">
                            <Tab.Container
                                id="controlled-tab-example2"
                                defaultActiveKey="proposalDispatch"
                            >
                                <Row style={{ width: "1300px" }} >
                                    <div className="tab-style-1  col-12 d-flex">
                                        <Nav className="d-flex  nav nav-tabs ">
                                            <Nav.Item className="mr-3">
                                                <img src={require('../../../../assets/css/images/search.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton1' title="Search Content Repo" width={30} height={30} />
                                                <div id='hideCollapseText1'>
                                                    <Nav.Link style={{ "zIndex": 0 }} eventKey="proposalDispatch" >Proposal Dispatch</Nav.Link>
                                                </div>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className="tab-style-2" style={{ width: "100%" }}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="proposalDispatch">
                                                <div className="form-wraper form-style mb-4">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12  mb-4">
                                                            <div className="row">
                                                                <div className="form-group col-lg-6">
                                                                    <label className="font-bold">
                                                                        Dispatch Method
                                                                    </label>
                                                                    <select
                                                                        id="disabledSelect"
                                                                        className="form-control custom-select"
                                                                        value={dispatchMethod}
                                                                        name="rfxCategory"
                                                                        onChange={(event) =>
                                                                            setDispatchMethod(event.target.value)
                                                                        }
                                                                    >
                                                                        <option value="N/A" disabled>
                                                                            Select your option
                                                                        </option>
                                                                        <option value="Online_Submission" >Online Submission</option>
                                                                        <option value='Mail' >Mail</option>
                                                                        <option value='E-Mail' >E-Mail</option>
                                                                        <option value='Others' >Other</option>
                                                                    </select>
                                                                </div>
                                                                {dispatchMethod == "Online_Submission" && (
                                                                    <div className="text-center mt-4 ">
                                                                        <button
                                                                            className="btn btn-lg btn-brand-1  mx-auto"
                                                                            onClick={handleVisitLink}

                                                                        >
                                                                            Visit the link{" "}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                {(dispatchMethod == "Mail" && carrier != "Others") && (
                                                                    <div className="text-center mt-4 ">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-lg btn-brand-1  mx-auto"
                                                                            onClick={onTrackProposal}
                                                                        >
                                                                            Track Proposal{" "}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                {dispatchMethod === "E-Mail" && (
                                                                <div className="mt-4">
                                                                    <div className="custom-file"
                                                                     style={{ 
                                                                            width: '400px',
                                                                            whiteSpace: 'nowrap', 
                                                                            overflow: 'hidden', 
                                                                            textOverflow: 'ellipsis',
                                                                            marginTop: '5px'
                                                                        }}>     
                                                                        <input 
                                                                            type="file" 
                                                                            className="custom-file-input" 
                                                                            id="inputGroupFile02" 
                                                                            accept=".pdf, .docx, .doc" 
                                                                            onChange={handleFileSelect} 
                                                                            disabled={!fileInputInit}
                                                                        />
                                                                        <label 
                                                                            className="custom-file-label" 
                                                                            htmlFor="inputGroupFile02" 
                                                                            aria-describedby="inputGroupFileAddon02"
                                                                            >
                                                                            {!fileInputInit ? (
                                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                            ) : (
                                                                                file === undefined ? 'Choose Proposal Document' : file.name.replace(/\d{13}-\d{13}_/, '')
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                    {file && (
                                                                            <div>
                                                                            <button onClick={handleRemoveFile} className="btn btn-brand-1 bg-danger btn-sm" style={{marginTop: '5px'}}>
                                                                                Remove
                                                                            </button>
                                                                            </div>
                                                                    )}
                                                                </div>    
                                                                )}

                                                                {dispatchMethod == "Online_Submission" && (
                                                                    <div className="form-group col-lg-12">
                                                                        <label className="font-bold">URL</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control mb-4"
                                                                            value={URL ? URL : preRfxData?.client_url}
                                                                            name="question"
                                                                            onChange={(event) => setURL(event.target.value)}
                                                                            placeholder="Enter URL"
                                                                        />
                                                                    </div>
                                                                )}
                                                                {dispatchMethod == "Mail" && (
                                                                    <div className="row col-lg-12">
                                                                        <div className="form-group col-lg-6">
                                                                            <label className="font-bold">
                                                                                Carrier
                                                                            </label>
                                                                            <select
                                                                                id="disabledSelect"
                                                                                className="form-control custom-select"
                                                                                value={carrier}
                                                                                name="carrier"
                                                                                onChange={(event) =>
                                                                                    setCarrier(event.target.value)
                                                                                }
                                                                            >
                                                                                <option value="N/A" disabled>
                                                                                    Select your option
                                                                                </option>
                                                                                <option>Fedex</option>
                                                                                <option>UPS</option>
                                                                                <option>USPS</option>
                                                                                <option>Others</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-lg-6">
                                                                            <label className="font-bold">
                                                                                {(carrier != "Others" && carrier != "N/A") && carrier}{" "}
                                                                                Tracking Number
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control mb-4"
                                                                                value={trackingNo}
                                                                                name="question"
                                                                                onChange={(event) => setTrackingNo(event.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {dispatchMethod == "E-Mail" && (
                                                                    <div className="form-group col-lg-12">
                                                                        <label className="font-bold">Email</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control mb-4"
                                                                            value={email ? email : preRfxData?.email}
                                                                            name="question"
                                                                            onChange={(event) => setEmail(event.target.value)}
                                                                            placeholder="Enter the Reciever E-Mail"
                                                                        />
                                                                    </div>                                                                
                                                                )}

                                                            </div>
                                                            <div className="text-center mb-4">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-lg btn-brand-1 mx-2"
                                                                    onClick={() => onDispatch(true)}
                                                                >
                                                                    {/* /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start  */}
                                                                    {isLoadingSave && (
                                                                    <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                    )}{" "}
                                                                    {/* /*Dharma. April 16 2024. Code End */}
                                                                    Save
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    disabled={location?.state?.status ==
                                                                        "Proposal Is Disdpatched" || location.state?.status == "PID"}
                                                                    className="btn btn-lg btn-brand-1 bg-success mx-auto"
                                                                    onClick={() => onDispatch()}
                                                                >
                                                                    {/* /*Added this code for  WOII-195. Dharma. April 16 2024.. Code Start */}
                                                                    {isLoadingDispatch && (
                                                                        <img
                                                                            src={require("../../../../assets/css/images/button_loader.gif").default}
                                                                            alt="loading..."
                                                                            style={{ marginLeft: 5 }}
                                                                        />
                                                                    )}
                                                                    {/* /*Dharma. April 16 2024. Code End */}
                                                                    {(location?.state?.status ==
                                                                        "Proposal Is Dispatched" || location.state?.status == "PID")
                                                                        ? "Dispatch"
                                                                        : "Dispatch"}
                                                                </button>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="card">
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <h4 className="font-16 mb-0">Comments</h4>
                                                                        <button
                                                                            className="btn btn-sm"
                                                                            type="button"
                                                                            style={{
                                                                                fontSize: "20px",
                                                                                border: "solid",
                                                                            }}
                                                                            onClick={handleToggleCollapse}
                                                                        >
                                                                            {isCollapsed ? "+" : "-"}
                                                                        </button>
                                                                    </div>
                                                                    {!isCollapsed && (
                                                                        <div className="card-body bg-white">
                                                                            {commentData.map((res, index) => {
                                                                                return (
                                                                                    <div
                                                                                        key={index}
                                                                                        className="bg-light p-2 rounded mb-2"
                                                                                    >
                                                                                        <p className="mb-1">
                                                                                            <span className="font-medium mr-2">
                                                                                                {res.user.first_name}{" "}
                                                                                                {res.user.last_name}
                                                                                            </span>
                                                                                            <span className="text-secondary font-12 mr-3">
                                                                                                {moment(
                                                                                                    res.created_date
                                                                                                ).format("D-MMM-YYYY h:mm a")}
                                                                                            </span>
                                                                                        </p>
                                                                                        <p className="font-14 mb-0 ">
                                                                                            {res.comment}
                                                                                        </p>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            <div className="form-group form-style">
                                                                                <label>Add Comment</label>
                                                                                <textarea
                                                                                    className="form-control h-auto mb-2"
                                                                                    rows="5"
                                                                                    placeholder="Write here.."
                                                                                    onChange={(event) =>
                                                                                        setComment(event.target.value)
                                                                                    }
                                                                                    name="comment"
                                                                                    value={comment}
                                                                                ></textarea>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-brand-1 btn-sm"
                                                                                    onClick={() => addComment()}
                                                                                    disabled={
                                                                                        isCommenting ||
                                                                                        (props.location.state &&
                                                                                            props.location.state.page ==
                                                                                            "View")
                                                                                    }
                                                                                >
                                                                                    {isCommenting && (
                                                                                        <img
                                                                                            src={
                                                                                                require("../../../../assets/css/images/button_loader.gif")
                                                                                                    .default
                                                                                            }
                                                                                            alt="loading..."
                                                                                            style={{ marginLeft: 5 }}
                                                                                        />
                                                                                    )}
                                                                                    Post{" "}
                                                                                    <i className="icofont-paper-plane"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal
                initWidth={700}
                initHeight={'auto'}
                top={100}
                disableKeystroke={true}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setShowProposalTracking(!showProposalTracking)}
                isOpen={showProposalTracking}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">Proposal Tracking</h5>
                    <button type="button" className="close" onClick={() => onCancelModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div style={{ height: "600px" }} className=" modal-body">
                    {carrier != "Fedex" ? <h5 className="modal-body form-style">Service not available right now</h5> :
                        isTracking ? <h5 className="modal-body form-style">Tracking...</h5> :
                            <Chrono id="timeline" cardHeight={50} items={eventCards} disableAutoScrollOnClick mode="VERTICAL_ALTERNATING" onScrollEnd={() => { window.clearInterval(intervalRef.current) }} allowDynamicUpdate scrollable activeItemIndex={eventCards.length - 1} />
                    }
                </div>
            </ReactModal>

        </>
    );
};
export default EditProposalDispatch;
