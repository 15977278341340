import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { APIMethod } from "../API/APIClient";
import { useHistory } from "react-router";
import dashboardIcon from "../assets/css/images/dashboard-icon.png";
import { useLayoutEffect } from "react";
import ReactTooltip from "react-tooltip";

const Leftnavbar = (props) => {
  const [menuList, setMenuList] = useState([]);
  const [locationPath, setLocationPath] = useState("");
  const history = useHistory();
  var id = localStorage.getItem("userId");
  const [domStatus, setDomStatus] = useState(false);

  useEffect(() => {
    getMenus();
    history.listen((location) => {
      if (location.pathname) {
        if (
          location.pathname.indexOf("/edit-q-a/") != -1 ||
          location.pathname.indexOf("/add-q-a") != -1
        ) {
          setLocationPath("/setup-q-a");
        } else if (
          location.pathname.indexOf("/add-company-details") != -1 ||
          location.pathname.indexOf("/edit-company-details/") != -1 ||
          location.pathname.indexOf("/edit-company-capability") != -1 ||
          location.pathname.indexOf("/add-product") != -1 ||
          location.pathname.indexOf("/edit-product/") != -1 ||
          location.pathname.indexOf("/add-service") != -1 ||
          location.pathname.indexOf("/edit-service/") != -1 ||
          location.pathname.indexOf("/add-process") != -1 ||
          location.pathname.indexOf("/edit-process/") != -1 ||
          location.pathname.indexOf("/add-methodology") != -1 ||
          location.pathname.indexOf("/edit-methodology/") != -1 ||
          location.pathname.indexOf("/add-qc") != -1 ||
          location.pathname.indexOf("/edit-qc/") != -1 ||
          location.pathname.indexOf("/add-security") != -1 ||
          location.pathname.indexOf("/edit-security/") != -1
        ) {
          setLocationPath("/companies");
        } else if (location.pathname.indexOf("/add-doc") != -1) {
          setLocationPath("/document-entries");
        } else if (location.pathname.indexOf("/add-keyword") != -1) {
          setLocationPath("/keyword-entries");
        } else if (
          location.pathname.indexOf("/add-user") != -1 ||
          location.pathname.indexOf("/edit-user-role/") != -1
        ) {
          setLocationPath("/manage-users");
        } else if (
          location.pathname.indexOf("/add-role") != -1 ||
          location.pathname.indexOf("/edit-role/") != -1
        ) {
          setLocationPath("/role");
        } else if (
          location.pathname.indexOf("/add-pre-rfx") != -1 ||
          location.pathname.indexOf("/edit-pre-rfx/") != -1
        ) {
          setLocationPath("/pre-rfx");
        } else if (location.pathname.indexOf("/edit-pre-rfx-approval/") != -1) {
          setLocationPath("/pre-rfx-approvals-filters");
        } else if (
          location.pathname.indexOf("/parse-rfx-detail") != -1 ||
          location.pathname.indexOf("/view-parse-rfx-detail") != -1 ||
          location.pathname.indexOf("/add-proposal-template") != -1
        ) {
          setLocationPath("/rfx-list");
        } else if (location.pathname.indexOf("/edit-proposal-template") != -1) {
          setLocationPath("/manage-proposal-templates");
        } else if (
          location.pathname.indexOf("/edit-approval-proposal-template") != -1
        ) {
          setLocationPath("/manage-proposal-templates-approval");
        } else if (location.pathname.indexOf("/mp-genrate-proposal") != -1) {
          setLocationPath("/manage-proposal");
        } else if (
          location.pathname.indexOf("/mp-approval-genrate-proposal") != -1
        ) {
          setLocationPath("/manage-proposal-approval");
        } else if (
          location.pathname.indexOf("/edit-contract") != -1 ||
          location.pathname.indexOf("/parse-contract") != -1
        ) {
          setLocationPath("/contracts");
        } else if (location.pathname.indexOf("/edit-proposal-awards") != -1) {
          setLocationPath("/manage-proposal-awards");
        } else if (
          location.pathname.indexOf("/edit-compliance") != -1 ||
          location.pathname.indexOf("/create-compliance") != -1 ||
          location.pathname.indexOf("/view-compliance") != -1
        ) {
          setLocationPath("/compliance");
        } else {
          setLocationPath(location.pathname);
        }
      }
    });
  }, []);

  useLayoutEffect(() => {
    const element = document.getElementById("main-wraper");
    if (!element) return;
    const observe = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const isToggled = element.classList.contains("toggled");
          setDomStatus(isToggled);
          // console.log(isToggled);
        }
      }
    });
    observe.observe(element, { attributes: true, attributeFilter: ["class"] });
    return () => {
      observe.disconnect();
    };
  }, []);

  const getMenus = () => {
    if (menuList.length <= 0) {
      var method = "GET";
      const apifetcherObj = APIMethod(`/users/left-menu`, null, method);
      apifetcherObj
        .then((response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then((res) => {
          let statusCode = res[0];
          let data = res[1];
          if (statusCode === 200) {
            checkMenu(data);
            localStorage.setItem("user_Role", JSON.stringify(data));
          }
        })
        .catch((error) => {});
    } else {
      checkMenu(menuList);
    }
  };

  const checkMenu = (data) => {
    let value = "";
    let hasAccess = false;
    var url = window.location.pathname;

    for (var index in data) {
      if (data[index].access) {
        for (var j in data[index].access) {
          if (
            !value &&
            data[index].access[j].path &&
            data[index].access[j].is_view == true
          ) {
            value = data[index].access[j].path;
          }

          if (data[index].access[j].is_view == true) {
            data[index].is_view = true;
            if (data[index].access[j].path == url) {
              hasAccess = true;
            }
          }
        }
      }

      if (!value && data[index].path && data[index].is_view == true) {
        value = data[index].path;
      }

      if (data[index].path == url && data[index].is_view == true) {
        hasAccess = true;
      }
      data[index].isExpand = false;
    }

    if (!hasAccess) {
      const data = localStorage.getItem("Login_time");
      if (value && data) {
        history.push(value);
        localStorage.removeItem("Login_time");
      } else {
        //  props.logoutHandler()
      }
    }
    setMenuList(data);
  };

  const handleClick = (index) => {
    var temp = [];
    for (var i in menuList) {
      if (index == i) {
        menuList[index].isExpand = !menuList[index].isExpand;
      }

      temp.push(menuList[i]);
    }
    setMenuList(temp);
  };

  return (
    <>
      <aside className="left-sidebar sticky-top" id="mob_sidebar">
        <div className="scroll-sidebar ">
          <nav className="sidebar-nav">
            <ul className="nav-bar pl-0" id="leftNav">
              {/* {id == '1' &&
                                <li className="nav-item dd-item">
                                    <a className={"nav-link collapsed dd-link mb-2"} onClick={() => history.push('/dashboard')}>
                                        <img src={dashboardIcon} width="20" className="side-nav-icon" /><span className="hide-menu">Dashboard </span>
                                    </a>
                                </li>
                            } */}
              {menuList?.map((res, i) => {
                // console.log(domStatus);
                return (
                  <div key={i}>
                    {res.is_view === true ? (
                      <li className="nav-item dd-item">
                        <a
                          className={
                            res.isExpand == true || i == 0
                              ? "nav-link collapsed dd-link mb-2 "
                              : "nav-link collapsed dd-link mb-2"
                          }
                          onClick={() => handleClick(i)}
                          aria-controls="collapseMenu_1"
                          aria-expanded={res.isExpand}
                        >
                          {domStatus ? (
                            <>
                              <ReactTooltip id={"tooltip" + i}>
                                {res.name}
                              </ReactTooltip>
                              <img
                                src={res.icon}
                                width="20"
                                className="side-nav-icon"
                                data-tip
                                data-for={"tooltip" + i}
                              />
                            </>
                          ) : (
                            <img
                              src={res.icon}
                              width="20"
                              className="side-nav-icon"
                            />
                          )}
                          <span className="hide-menu">{res.name} </span>{" "}
                          <img
                            src="/images/down-arrow.svg"
                            width="12"
                            className="arrow-icon"
                          />
                        </a>
                      </li>
                    ) : null}
                    {res.access.map((val, j) => {
                      return (
                        <div key={j}>
                          <Collapse in={res.isExpand}>
                            {val.is_view === true ? (
                              <div className=" sub-nav" id="collapseMenu_1">
                                <ul className="nav d-block">
                                  {domStatus ? (
                                    <button
                                      className="nav-link"
                                      style={{
                                        background: "transparent",
                                        border: 0,
                                        width: "100%",
                                        textAlign: "left",
                                        fontWeight:
                                          val.path == locationPath
                                            ? "bold"
                                            : "normal",
                                      }}
                                      onClick={() =>
                                        history.push({ pathname: val.path })
                                      }
                                    >
                                      <ReactTooltip
                                        id={"tt" + j + i}
                                        place="right"
                                        effect="solid"
                                      >
                                        {val.name}
                                      </ReactTooltip>
                                      <img
                                        src="/images/right-circle-arrow.svg"
                                        width="17"
                                        className="side-nav-icon"
                                        data-tip
                                        data-for={"tt" + j + i}
                                      />
                                      <span className="hide-menu">
                                        {val.name}
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      className="nav-link"
                                      style={{
                                        background: "transparent",
                                        border: 0,
                                        width: "100%",
                                        textAlign: "left",
                                        fontWeight:
                                          val.path == locationPath
                                            ? "bold"
                                            : "normal",
                                      }}
                                      onClick={() =>
                                        history.push({ pathname: val.path })
                                      }
                                    >
                                      <img
                                        src="/images/right-circle-arrow.svg"
                                        width="17"
                                        className="side-nav-icon"
                                      />
                                      <span className="hide-menu">
                                        {val.name}
                                      </span>
                                    </button>
                                  )}

                                  {/* <a className={val.path == locationPath ? "nav-link active" : "nav-link"} href={val.path}  ><img src="/images/right-circle-arrow.svg" width="17" className="side-nav-icon" /><span className="hide-menu">{val.name}</span></a> */}
                                </ul>
                              </div>
                            ) : (
                              <span></span>
                            )}
                          </Collapse>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};
export default Leftnavbar;
