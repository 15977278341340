import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { APIMethod, tiny_key, TOAST_TIMEOUT } from "../../../../../API/APIClient";
import Loader from '../../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";
import ModalPopup from '../../../../../components/ModalPopUp';
import NoData from '../../../../../components/NoDataFound';
import SearchNoDataFound from '../../../../../components/SearchNoDataFound';
import ReactModal from 'react-modal-resizable-draggable';
import { Editor } from '@tinymce/tinymce-react';
import { checkEmptyString } from '../../../../../utility/utility';

const AddQc = (props) => {
    const detailsRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [moduleLoader, setModuleLoader] = useState(false);
    const [id, setId] = useState();
    const [moduleData, setModuleData] = useState([]);
    const [formFields, setFormFields] = useState({
        name: '', certifications: '', details: '', qualityControlModuleName: '', qualityControlModuleFunction: '',
        qualityControlModuleCode: '', qualityControlModuleDescription: '', companyId: ''
    });
    // Edit
    const [edit, setEdit] = useState('');
    const [editId, setEditId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [flag, setFlag] = useState(false);
    // Add modal box
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setEditId('')
        setFormFields({
            ...formFields, qualityControlModuleName: '', qualityControlModuleFunction: '',
            qualityControlModuleCode: '', qualityControlModuleDescription: ''
        })
        setShow(false);
    }
    const handleShow = () => setShow(true);
    // Toast
    const { addToast } = useToasts();

    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }

    useEffect(() => {
        getModuleList()
        check()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const check = () => {
        if (props.location.state === null) {
        } else {
            if (props.location.state.details == undefined) {
                setFormFields({ ...formFields, companyId: props.location.state.company_id })
            } else {
                setId(props.location.state.details.id)
                setFormFields({ ...formFields, name: props.location.state.details.name, certifications: props.location.state.details.certifcations, details: props.location.state.details.detail, companyId: props.location.state.company_id })
                getModuleList(props.location.state.details.id)
            }
        }
    }

    const getModuleList = (id) => {
        if (id === undefined) {

        } else {
            setLoading(true)
            var method = 'GET'
            //    var fromBackId = props.location.state.details.id
            //    var newId = id === null ? fromBackId : id
            const apifetcherObj = APIMethod(`/module/list/QUALITY_CONTROL/${id}/1`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setModuleData(data.list)
                        setLoading(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const save = () => {
        if (formFields.name === '' || formFields.certifications === '' || checkEmptyString(detailsRef.current.getContent())) {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setButtonLoader(true)
            if (props.location.state.details == undefined) {
                var method = 'POST'
                var params = JSON.stringify({
                    name: formFields.name,
                    detail: detailsRef.current.getContent(),
                    certifcations: formFields.certifications,
                    company_id: formFields.companyId
                })
            } else {
                method = 'PUT'
                params = JSON.stringify({
                    id: props.location.state.details.id,
                    name: formFields.name,
                    detail: detailsRef.current.getContent(),
                    certifcations: formFields.certifications,
                    company_id: formFields.companyId
                })
            }
            const apifetcherObj = APIMethod(`/quality-control`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setId(data.id)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setButtonLoader(false)
                        setFlag(false)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const AddModule = () => {
        if (formFields.qualityControlModuleName === '' || formFields.qualityControlModuleFunction === '' || formFields.qualityControlModuleCode === '' || formFields.qualityControlModuleDescription === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setModuleLoader(true)
            if (edit === '/') {
                var method = 'PUT'
                var params = JSON.stringify({
                    id: editId,
                    name: formFields.qualityControlModuleName,
                    description: formFields.qualityControlModuleDescription,
                    code: formFields.qualityControlModuleCode,
                    refference: id,
                    type: "QUALITY_CONTROL",
                    function: formFields.qualityControlModuleFunction,
                })
            } else {
                method = 'POST'
                params = JSON.stringify({
                    name: formFields.qualityControlModuleName,
                    description: formFields.qualityControlModuleDescription,
                    code: formFields.qualityControlModuleCode,
                    refference: id,
                    type: "QUALITY_CONTROL",
                    function: formFields.qualityControlModuleFunction,
                })
            }
            const apifetcherObj = APIMethod(`/module`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        setFormFields({ ...formFields, qualityControlModuleName: '', qualityControlModuleFunction: '', qualityControlModuleCode: '', qualityControlModuleDescription: '' })
                        setModuleLoader(false)
                        handleClose()
                        getModuleList(id)
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setModuleLoader(false)
                    }
                })
                .catch(error => {
                    setModuleLoader(false)
                })
        }
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const _onEdit = (val) => {
        handleShow()
        setFormFields({ ...formFields, qualityControlModuleName: val.name, qualityControlModuleFunction: val.function, qualityControlModuleCode: val.code, qualityControlModuleDescription: val.description })
        setEdit('/')
        setEditId(val.id)
    }

    const showError = () => addToast("Please Add Quality Control First", { autoDismissTimeout: TOAST_TIMEOUT, autoDismiss: true });

    const onEditorChange = (data, key) => {
        const formFieldsObject = {
            ...formFields,
            [key]: data
        }
        setFormFields(formFieldsObject);
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Org. Setup</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Company Capabilities
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>
                                Quality Control
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{id ? 'Edit' : 'Add'} Quality Control</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center" >
                        <span className="add-title ">{id ? 'Edit Quality Control' : 'Add Quality Control'}</span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Quality Control Name</label>
                                    <input type="text" className="form-control" name="name" value={formFields['name']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && formFields.name === '' ? 'Please Enter Quality Control Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Certifcations</label>
                                    <input type="text" className="form-control" name="certifications" value={formFields['certifications']} onChange={handleChange} />
                                    <span className="text-danger">{flag === true && formFields.certifications === '' ? 'Please Enter Certifications' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Quality Control  Details</label>
                                    <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => detailsRef.current = editor}
                                        initialValue={formFields.details}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.details === '' ? 'Please Enter Quality Control Details' : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger " onClick={goBack}>Cancel</button>
                            <button type="button" className="btn btn-lg btn-brand-1 mx-3 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                {buttonLoader && (
                                    <img src={require('../../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}
                                <span>Save</span>
                            </button>

                            {/* <NavLink to="/add-qc-module" className="btn btn-brand-1 bg-success px-4">+ Add QC Module </NavLink> */}
                            <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4" onClick={id === undefined ? showError : handleShow}>+ Add QC Module </button>
                        </div>
                    </div>
                    {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                    {/*<h2 className="font-18 font-medium">QC Modules Added</h2>*/}
                    {/* Commenting end. Divya. September 07 2023.. */}
                    {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                    <h2 className="font-20 font-bold">QC Modules Added</h2>
                    {/* Divya. September 07 2023. Code End */}
                    <table className="table table-style-1 table-responsive-lg mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                <th scope="col">QC Module</th>
                                <th scope="col">Function</th>
                                <th scope="col">Description</th>
                                <th scope="col" className="text-center" width="110">Action</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/module/'}
                            updateList={getModuleList}
                        ></ModalPopup>
                        <tbody>
                            {
                                loading === false ? moduleData.map((res) => {
                                    return (
                                        <tr>
                                            <td>{res.name}</td>
                                            <td>{res.function}</td>
                                            <td>{res.description}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(res)}><i className="icofont-pencil-alt-2"></i></button>
                                                <button type="button" className="btn btn-action delete" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                    {
                        moduleData.length === 0 && loading === false ?
                            <SearchNoDataFound message={'No QC Modules Found'} />
                            : ''
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                </div>
            </div>

            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={116.5}
                disableKeystroke={true}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editId ? 'Edit' : 'Add'} Quality Control Module</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Quality Control Module Name</label>
                                <input type="text" className="form-control" onChange={handleChange} name="qualityControlModuleName" value={formFields['qualityControlModuleName']} />
                                <span className="text-danger">{flag === true && formFields.qualityControlModuleName === '' ? 'Please Enter Quality Control Module Name' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Quality Control Module Function</label>
                                <input type="text" className="form-control" onChange={handleChange} name="qualityControlModuleFunction" value={formFields['qualityControlModuleFunction']} />
                                <span className="text-danger">{flag === true && formFields.qualityControlModuleFunction === '' ? 'Please Enter Quality Control Module Function' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>NIGP/UNSPSC Codes</label>
                                <input type="text" className="form-control" onChange={handleChange} name="qualityControlModuleCode" value={formFields['qualityControlModuleCode']} />
                                <span className="text-danger">{flag === true && formFields.qualityControlModuleCode === '' ? 'Please Enter NIGP/UNSPSC Codes' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Quality Control Module Description</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="qualityControlModuleDescription" value={formFields['qualityControlModuleDescription']}></textarea>
                                <span className="text-danger">{flag === true && formFields.qualityControlModuleDescription === '' ? 'Please Enter Quality Control Module Description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => AddModule()} disabled={moduleLoader}>
                        {moduleLoader && (
                            <img src={require('../../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>

        </>
    )
}
export default AddQc;