import React, { useEffect, useState } from "react";
import Leftnavbar from '../../../../components/Leftnavbar';
import { Breadcrumb } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import moment from "moment";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";


const CreateCompliance = (props) => {
    const [loading, setLoading] = useState(false);
    const [formFields, setFormFields] = useState({ complianceDescription: '', nonCompliancePenalty: '', code: '', name: '', compliance_due_date: undefined })
    const [data, setData] = useState()
    const [flag, setFlag] = useState(false);
    // Toast
    const { addToast } = useToasts();
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    //---Date Time picker
    const [startDateTime, setStartDateTime] = useState(new Date());
    const { id } = useParams();
    const [showChatModal, setShowChatModal] = useState(false)
    useEffect(() => {
        getContractData()
    }, [])

    const formatDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    const getContractData = () => {
        if (props.location.state == null) {
        } else {
            setData(props.location.state.detail)
        }
    }

    const save = () => {
        var dueDate = moment(formFields.compliance_due_date).format('YYYY-MM-DD')
        if (formFields.complianceDescription === '' || formFields.nonCompliancePenalty === '' || formFields.code === '' || formFields.name === '' || formFields.compliance_due_date === undefined) {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setLoading(true)
            var method = 'POST'
            var params = JSON.stringify({
                client_name: data?.rfx_client_agency,
                code: formFields.code,
                compliance_name: formFields.name,
                contract_id: data?.contract_id,
                description: formFields.complianceDescription,
                penalty: formFields.nonCompliancePenalty,
                due_date: dueDate
            })
            const apifetcherObj = APIMethod(`/contract-compliance`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setLoading(false)
                        setFormFields({ ...formFields, name: '', code: '', complianceDescription: '', nonCompliancePenalty: '' })

                        setFlag(false)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const handleDate = (date) => {
        const obj = {
            ...formFields,
            compliance_due_date: date
        }
        setFormFields(obj);
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Compliance</Breadcrumb.Item>
                            <Breadcrumb.Item active>Create Compliance </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center">
                        <span className="add-title ">Create Compliance   </span>
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Contract ID</label>
                                    <p>{data?.contract_number}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Name </label>
                                    <p>{data?.rfx_client_agency}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Compliance Code</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="code" value={formFields['code']} />
                                    <span className="text-danger">{flag === true && formFields.code === '' ? 'Please Enter Compliance Code' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Compliance Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="name" value={formFields['name']} />
                                    <span className="text-danger">{flag === true && formFields.name === '' ? 'Please Enter Compliance Name' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Compliance Description </label>
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="complianceDescription" value={formFields['complianceDescription']}></textarea>
                                    <span className="text-danger">{flag === true && formFields.complianceDescription === '' ? 'Please Enter Compliance Description' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Non Compliance Penalty </label>
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="nonCompliancePenalty" value={formFields['nonCompliancePenalty']}></textarea>
                                    <span className="text-danger">{flag === true && formFields.nonCompliancePenalty === '' ? 'Please Enter Non Compliance Penalty' : null}</span>
                                </div>
                            </div>



                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label>Compliance Due Date </label>
                                    <DatePicker
                                        selected={formFields.compliance_due_date}
                                        onChange={(date) => handleDate(date)}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => save()} disabled={loading}>
                                    {loading && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}Save</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showChatModal === false && props.location.state && props.location.state.rfx_data && props.location.state.rfx_data.id && (
                    <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                        {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                        {/*<img src={chatIcon} />*/}
                        {/* Commenting end. Divya. October 06 2023.. */}
                        {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                        <img src={chatIcon}  className="chat-icon"/>
                        {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                        {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                        {/* Commenting end. Divya. November 14 2023.. */}
                        {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                        <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                        {/* Divya. November 14 2023. Code End */}
                        {/* Divya. October 06 2023. Code End */}
                    </div>
                )
            }
            {
                showChatModal &&
                <Chat
                    id={props.location.state.rfx_data.id}
                    title={props.location.state.rfx_data.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default CreateCompliance;