import React, { useEffect } from "react";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { APIMethod, tiny_key, TOAST_TIMEOUT } from "../API/APIClient";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReactModal from 'react-modal-resizable-draggable';
import ModalPopup from '../components/ModalPopUp';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from "react";
import { checkEmptyString, isView } from "../utility/utility";
import saveIcon from '../assets/css/images/save.png';


const SectionizedRFx = (props) => {
    const descriptionRef = useRef(null);
    const [rfxData, setRfxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { addToast } = useToasts();
    const [url, setUrl] = useState("");
    // Add modal box
    const [show, setShow] = useState(false);
    const [formFields, setFormFields] = useState({ heading: '', description: '' })
    const [flag, setFlag] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false)
    const [id, setId] = useState('')
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [modalType, setModalType] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [addIndex, setAddIndex] = useState('');


    useEffect(() => {
       /*Commenting the code for WOII-204 June 11 2024..Code start*/
       // getRfxData()
       /*Ajay Krishna. June 11 2024..Code End */
    }, [])
    const isViewPath = /^\/view-approval-proposal-template\/\d+$/.test(window.location.pathname);
    const isViewPath2 = /^\/view-mp-approval-genrate-proposal\/\d+$/.test(window.location.pathname);

    const getRfxData = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${props.rfx_id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    let sections = []
                    for (let i in data.document_section) {
                        if(data.document_section[i].section) {
                            sections.push(data.document_section[i])
                        }
                    }
                    setRfxData(sections)
                    setUrl(data.url)
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const handleClose = () => {
        setShow(false)
        setId('')
        setFormFields({ ...formFields, heading: "", description: "" })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const onsubmit = () => {
        if (rfxData.length > 0) {
            for (var i in rfxData) {
                rfxData[i].priority = parseInt(i)
                if (!rfxData[i].section) {
                    addToast('Please add all the titles', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    return;
                }
                if (!rfxData[i].content) {
                    addToast('Please add all the descriptions', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    return;
                }
            }
            setButtonLoader(true)
            var method = props.rfx_id ? 'PUT' : 'POST'
            var params = JSON.stringify(rfxData)
            const apifetcherObj = APIMethod(`/document-section/${props.rfx_id}`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        addToast(data.message, { appearance: 'success', autoDismiss: true });
                        setButtonLoader(false)
                        setFlag(false)
                    } else {
                        addToast(data.message, { appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const onEdit = (res, index) => {
        setFormFields({ ...formFields, heading: res.section, description: res.content })
        setShow(!show)
        setId(res.id)
        setEditIndex(index)
        setModalType('Edit')
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };


    const onSave = () => {
        if (formFields.heading == '' || checkEmptyString(descriptionRef.current)) {
            addToast('Please Fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            setFlag(true)
        } else {
            if (modalType == 'Edit') {
                rfxData[editIndex].section = formFields.heading
                // for (var i in rfxData) {
                //     if (i == editIndex) {
                //         rfxData[i].section = formFields.heading
                //     }
                // }
                // setRfxData([...rfxData])
                rfxData[editIndex].content = descriptionRef.current

                // for (var i in rfxData) {
                //     if (i == editIndex) {
                //         rfxData[i].content = descriptionRef.current.getContent()
                //     }
                // }
                setRfxData([...rfxData])
                handleClose()
                setEditIndex(-1)
                formFields.heading = '';
                // descriptionRef.current = '';
            } else {
                rfxData.splice(addIndex + 1, 0, { section: formFields.heading, content: descriptionRef.current.getContent(), priority: '', rfx_id: Number(`${props.rfx_id}`) })
                setRfxData([...rfxData])
                handleClose()
                formFields.heading = '';
                // descriptionRef.current = '';
                setAddIndex('')
            }
        }
    }

    const onAdd = (index) => {
        setModalType('Add')
        setAddIndex(index)
        setShow(!show)
    }

    const onDelete = (index) => {
        var array = rfxData;
        array.splice(index, 1)
        setRfxData(array)
        setRfxData([...rfxData])
    }


    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/document-section/'}
                updateList={getRfxData}
            ></ModalPopup>

            {/* Commenting the following code for WOI-204. June 6 2024.. Code Start */}
            {/* <Tab.Pane eventKey="SectionizedRFx" className="mt-4">
                <Tab.Container id="left-tab-example" defaultActiveKey="0">
                    <Row>
                        {
                            rfxData?.length === 0 ?
                                <div className="col-12 text-center">
                                    <span>No Sectionized RFx Found</span>
                                    <div style={{ textAlign: 'end' }}>
                                        <button type="button" className="btn btn-lg btn-brand-1 bg-success mb-3" onClick={() => setShow(!show)} disabled={(props.page == '/view-parse-rfx-detail' ? true : false) || isViewPath || isViewPath2}>Add</button>
                                    </div>
                                </div>
                                :
                                <Col md={3} style={{ height: props.height ? 1000 : 450, overflow: 'scroll' }}>
                                    <Nav variant="pills" className="flex-column vertical-tabs">
                                        {
                                            rfxData?.map((res, i) => {
                                                if (res.section) {
                                                    return (
                                                        <Nav.Item style={{ flexDirection: 'row' }}>
                                                            <Nav.Link eventKey={i}>{res.section}</Nav.Link>
                                                            <div>
                                                                <button type="button" className="btn btn-action edit mr-1" onClick={() => onEdit(res, i)} disabled={(props.page == '/view-parse-rfx-detail' ? true : false) || isViewPath || isViewPath2}><i className="icofont-pencil-alt-2"></i></button>
                                                                <button type="button" className="btn" style={{ padding: 0 }} onClick={() => onAdd(i)} disabled={(props.page == '/view-parse-rfx-detail' ? true : false) || isViewPath || isViewPath2}><i className="icofont-plus-square"></i></button>
                                                                <button type="button" className="btn btn-action delete" onClick={() => onDelete(i)} disabled={(props.page == '/view-parse-rfx-detail' ? true : false) || isViewPath || isViewPath2}><i className="icofont-archive"></i></button>
                                                                <button type="button" className="btn" disabled={(editIndex != -1 ? (editIndex == i ? false : true) : true) || isViewPath || isViewPath2}><img src={saveIcon} height={20} width={20} onClick={() => onSave()} /></button>
                                                            </div>
                                                        </Nav.Item>
                                                    )
                                                }
                                            })
                                        }
                                    </Nav>
                                </Col>
                        }

                        {
                            rfxData?.length === 0 ?
                                null
                                :
                                <Col sm={9}>
                                    <Tab.Content className="bg-white py-4 px-lg-5 px-md-4 border rounded-theme form-style" >
                                        {
                                            rfxData?.map((res, j) => {
                                                var data = res.content
                                                return (
                                                    <Tab.Pane eventKey={j}>
                                                        {editIndex != -1 ? (editIndex == j ? <input type="text" className="form-control mb-4" onChange={handleChange} name="heading" value={formFields['heading']} /> : null) : null}
                                                        <Editor
                                                            apiKey={tiny_key}
                                                            onInit={(evt, editor) => descriptionRef.current = evt}
                                                            initialValue={data}
                                                            disabled={editIndex == -1 || editIndex != j}
                                                            init={{
                                                                // Commenting the following code for WOII-43. Divya. August 19 2023.. Code Start 
                                                                //height: 800,
                                                                // Commenting end. Divya. August 19 2023.. 
                                                                // Added this code for  WOII-43. Divya. August 19 2023.. Code Start 
                                                                height: 940,
                                                                //Divya. August 19 2023. Code End

                                                                menubar: 'file edit view insert format tools table tc help',
                                                                selector: 'textarea#full-featured',
                                                                plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                                                toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                            }}
                                                        />
                                                    </Tab.Pane>
                                                )
                                            })
                                        }
                                    </Tab.Content>
                                </Col>
                        }
                    </Row>
                </Tab.Container>
                <div>
                    {
                        rfxData?.length > 0 &&
                        <div className="col-12 justify-content-center text-center">
                            <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleClose()}>Cancel</button>
                            <button type="button" className="btn btn-lg btn-brand-1 bg-success ml-3" onClick={() => onsubmit()} disabled={buttonLoader || isViewPath  || isViewPath2}>
                                {buttonLoader && (
                                    <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                )}Save</button>
                        </div>
                    }
                </div>
            </Tab.Pane> */}
            {/* Ajay Krishna. June 6 2024. Code End */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{id ? 'Edit' : 'Add'} Sectionized RFx</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Heading</label>
                                <input type="text" className="form-control" onChange={handleChange} name="heading" value={formFields['heading']} />
                                <span className="text-danger">{flag === true && formFields.heading === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                {/* <Editor
                                    apiKey={tiny_key}
                                    onInit={(evt, editor) => descriptionRef.current = editor}
                                    initialValue={formFields.description}
                                    init={{
                                        height: 200,
                                        menubar: 'file edit view insert format tools table tc help',
                                        selector: 'textarea#full-featured',
                                        plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image lists table ',
                                        toolbar: 'undo redo |blocks|  bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                /> */}
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleClose()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onSave()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                </div>
            </ReactModal>

        </>
    )
}
export default SectionizedRFx;