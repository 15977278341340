{/* Commenting the following code for WOII-120. Divya. November 02 2023.. Code Start */ }
//import React from 'react'
{/* Commenting end. Divya. November 02 2023.. */ }
{/* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */ }
import React, { useEffect, useState } from 'react'
{/* Divya. November 02 2023. Code End */ }

const Pagination = (props) => {
    {/* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */ }
    const [rowsPerPage, setRowsPerPage] = useState(10);
    {/* Divya. November 02 2023. Code End */ }

    const pageLinks = []

    const PageChange = (pageNumber) => {
        props.nextPage(pageNumber);
    }


    {/* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */ }
    // const rowchange = (event) => {
    //     const selectedValue = parseInt(event.target.value);
    //     setRowsPerPage(selectedValue);
    //     props.nextPage(1);
    //     props.rowsPerPage(selectedValue)
    //     console.log(".........."+selectedValue);
    //   };
    {/* Divya. November 02 2023. Code End */ }

    /* Added this code for WOII-262. Prasanna. October 23 2024.. Code Start */
    useEffect(() => {
        const currentPath = window.location.pathname;
        const savedRowsPerPage = localStorage.getItem(`rowsPerPage_${currentPath}`);
        if (savedRowsPerPage) {
            setRowsPerPage(Number(savedRowsPerPage));
        }
    }, []);

    const rowchange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setRowsPerPage(selectedValue);

        const currentPath = window.location.pathname;
        localStorage.setItem(`rowsPerPage_${currentPath}`, selectedValue);

        if ([
            '/clients',
            '/rfx-statuses',
            '/proposal-statuses',
            '/setup-q-a',
            '/manage-q-a-approvals',
            '/manage-users',
            '/role',
            '/pre-rfx',
            '/rfx-list',
            '/parse-rfx',
            '/manage-proposal-templates',
            '/manage-proposal-templates-approval',
            '/manage-proposal',
            '/manage-proposal-approval',
            '/proposal-dispatches',
            '/manage-proposal-awards'
        ].includes(currentPath)) {
            window.location.reload();
            props.nextPage(1);
            props.rowsPerPage(selectedValue);
        }
    };
    /* Prasanna. October 23 2024. Code End */


    let currentPage = props.currentPage ? props.currentPage : 1;
    {/* Commenting the following code for WOII-120. Divya. November 06 2023.. Code Start */ }
    //let totalPages = parseInt(props.pages / 10 + (props.pages % 10 === 0 ? 0 : 1));
    {/* Commenting end. Divya. November 06 2023.. */ }
    //Added this code for  WOII-120. Divya. November 06 2023.. Code Start
    let totalPages = parseInt(props.pages / rowsPerPage + (props.pages % rowsPerPage === 0 ? 0 : 1));
    // Divya. November 06 2023. Code End 
    //Added this code for  WOII-120. Divya. November 16 2023.. Code Start
    let totalCount = parseInt(props.pages * 10 / 10);
    let startRange = (currentPage - 1) * rowsPerPage + 1;
    let endRange = Math.min(currentPage * rowsPerPage, props.pages);
    // Divya. November 16 2023. Code End 

    let startPage, endPage;
    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    for (let i = startPage; i <= endPage; i++) {
        let active = currentPage === i ? 'active' : '';
        pageLinks.push(
            <ul key={i} className="pagination justify-content-md-end">
                <li className={`page-item ${active}`} key={i} onClick={() => PageChange(i)}><a className='page-link'>{i}</a></li>
            </ul>
        )
    }


    return (

        <div className="text-md-right">
            {
                props.pageCount <= 10 ?
                    null
                    :
                    <>
                        {/* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div style={{ marginRight: '50px', marginTop: '10px' }}>
                                <label>Rows per page : </label>
                                <select value={rowsPerPage} onChange={rowchange}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={75}>75</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                            {/* Divya. November 02 2023. Code End */}
                            {/* Commenting the following code for WOII-120. Divya. November 02 2023.. Code Start */}
                            {/*<div className='row justify-content-md-end' style={{ marginTop: 40, marginRight: 1 }}>
                            <ul className="pagination justify-content-md-end">
                                <li className="page-item" onClick={props.currentPage === 1 ? null : () => PageChange(props.currentPage - 1)}>
                                    <a className="page-link" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                    </a>
                                </li>
                            </ul>
                            <ul className='pagination'>
                                {pageLinks}
                            </ul>
                            <ul className="pagination justify-content-md-end">
                                <li className="page-item" onClick={props.currentPage === Math.ceil(props.pageCount / 10) ? null : () => PageChange(props.currentPage + 1)}>
                                    <a className="page-link" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                    </a>
                                </li>
                            </ul>
                            </div>
                            <div style={{color: 'grey'}}>{currentPage} - {totalPages} of {totalPages}</div>*/}
                            {/* Commenting end. Divya. November 02 2023.. */}
                            {/* Added this code for  WOII-120. Divya. November 02 2023.. Code Start */}
                            {/* Commenting the following code for WOII-120. Divya. November 16 2023.. Code Start */}
                            {/*<div style={{color: 'grey',marginRight:'30px'}}>{currentPage} - {rowsPerPage} of {totalPages}</div>*/}
                            {/* Commenting end. Divya. November 16 2023.. */}
                            {/*Added this code for  WOII-120. Divya. November 16 2023.. Code Start*/}
                            <div style={{ color: 'grey', marginRight: '30px' }}>{startRange} - {Math.min(endRange, props.pages)} of {totalCount}</div>
                            {/* Divya. November 16 2023. Code End */}
                            <div>
                                <ul className="pagination justify-content-md-end">
                                    <li className="page-item" style={{ marginRight: '30px', marginTop: '15px', cursor: props.currentPage === 1 ? 'not-allowed' : 'pointer' }} onClick={props.currentPage === 1 ? null : () => PageChange(props.currentPage - 1)}>
                                        <span aria-hidden="true">&lt;</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul className="pagination justify-content-md-end">
                                    {/* Commenting the following code for WOII-120. Divya. November 16 2023.. Code Start */}
                                    {/*<li className="page-item" style={{marginRight:'30px',marginTop:'15px', cursor:props.currentPage === Math.ceil(props.pageCount / rowsPerPage) ? 'not-allowed' : 'pointer'}} onClick={props.currentPage === Math.ceil(props.pageCount / 10) ? null : () => PageChange(props.currentPage + 1,rowsPerPage)}>*/}
                                    {/* Commenting end. Divya. November 16 2023.. */}
                                    {/*Added this code for  WOII-120. Divya. November 16 2023.. Code Start*/}
                                    <li className="page-item" style={{ marginRight: '30px', marginTop: '15px', cursor: endRange === totalCount ? 'not-allowed' : 'pointer' }} onClick={endRange === totalCount ? null : () => PageChange(currentPage + 1, rowsPerPage)}>
                                        {/* Divya. November 16 2023. Code End */}
                                        <span aria-hidden="true">&gt;</span>
                                    </li>
                                </ul>
                            </div>
                            {/* Divya. November 02 2023. Code End */}
                        </div>
                    </>
            }

        </div>
    )
}

export default Pagination;