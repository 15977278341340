import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"
import { Editor } from '@tinymce/tinymce-react';
import { APIMethod, FileUploadMethod, tiny_key, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Pagination from '../../../../components/Pagination';
import { useHistory } from "react-router";
import { useToasts } from 'react-toast-notifications';
import {CKEditor} from 'ckeditor4-react';



const PartnerCapability = (props) => {

    const descriptionRef = useRef(null);
    const [loading, setLoading] = useState(false);
    
    // file
    const [file, setFile] = useState();
    const [fileResponse, setFileResponse] = useState();
    // Add modal box
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const [editIndex, setEditIndex] = useState(-1);
    const [role, setRole] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [qualitycontrolList, setQualityControlList] = useState([]);

    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProductDescription, setTotalProductDescription] = useState('')
    const [totalServiceDescription, setTotalServiceDescription] = useState('')
    const [totalQualityDescription, setTotalQualityDescription] = useState('')
    const [requirements, setRequirements] = useState('');
    const [myCompanyCap,setMyCompanyCap] = useState({});
    const [fiveCompaniesCap, setFiveCompaniesCap] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [fiveProcessing, setFiveProcessing] = useState(false);
    const [notProcessed, setNotProcessed] = useState(true);
    var completeRequirements = "";
    var fiveCompanyList = [];
        
    const [flag, setFlag] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    // Toast
    const { addToast } = useToasts();
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);

    //--------date-Piicker----------
    const [startDate, setStartDate] = useState();
    //---Date Time picker
    const [startDateTime, setStartDateTime] = useState();
    // const { id } = useParams();
    const [showChatModal, setShowChatModal] = useState(false)

    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        // completeRequirements = '';
        setRole(userRole)
    }, [])


    const getCompaniesList = async (page, key, company) => {
        setLoading(true);
        const new_key = key === undefined ? '' : key;
        global.new_key = key === undefined ? '' : key;
        var method = 'GET';
        try {
            const response = await APIMethod(`/company-details/list/${page}?limit=10000&keyword=${new_key}`, null, method);
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                setCompaniesList(data.list);
                const companiesList = data.list;
                if(company === "Self")
                {
                    setProcessing(true);
                    const selfCompany = companiesList.find(company => company.business_unit_type_id_name.toLowerCase() === "self");
        
                    if (selfCompany) {
                        const selfCompanyId = selfCompany.id;
                        const pList = await fetchProductList(selfCompany.id);
                        const sList = await fetchServicesList(selfCompany.id);
                        const qList = await fetchQualityControlList(selfCompany.id);
                        const concatenatedDescription = await concatenateDescriptions(pList, sList, qList);
                        // console.log(concatenatedDescription)
                        capabilityMatch(concatenatedDescription[0], concatenatedDescription[1], concatenatedDescription[2], concatenatedDescription[3],"Self", selfCompany.name).then(() => {
                            setProcessing(false);
                            setNotProcessed(false)

                        })
                        // console.log(`Company ID with 'business_unit_type_id_name' equal to 'Self': ${selfCompanyId}`);
                    } else {
                        addToast("No company with 'business type' of Self", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
                        console.log("No company with 'business_unit_type_id_name' equal to 'Self' found.");
                        setProcessing(false);
                    }
                }
                else
                {
                    fiveCompanyList = []
                    setFiveProcessing(true)
                    const filteredCompanies = companiesList
                    .filter(company => company.business_unit_type_id_name.toLowerCase() !== 'self')
                    .slice(0, 5);
                    if(filteredCompanies.length !== 0){
                        const promisearray = await Promise.all(filteredCompanies.map(async (company) => {
                            const companyId = company.id;
                        
                            const pList = await fetchProductList(companyId);
                            const sList = await fetchServicesList(companyId);
                            const qList = await fetchQualityControlList(companyId)
                            const concatenatedDescriptionList = await concatenateDescriptions(pList, sList, qList);
                            // console.log(concatenatedDescriptionList);
                            const matchPercent = await capabilityMatch(concatenatedDescriptionList[0], concatenatedDescriptionList[1], concatenatedDescriptionList[2], concatenatedDescriptionList[3], company.business_unit_type_id_name, company.name);
                            // console.log(matchPercent)
                        })
                        );
                        console.log(fiveCompanyList);
                        setFiveCompaniesCap(fiveCompanyList);
                        setFiveProcessing(false)
                    }
                    else{
                        addToast("No other companies are listed.", { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
                        console.log("there are no other companies");
                        setFiveProcessing(false);
                    }
                    // Wait for all descriptions to be concatenated
                    // await Promise.all(descriptionsPromises);
                    // const concatenatedDescriptions = await Promise.all(descriptionsPromises);

                }

                if (page === 1) {
                    setPageCount(data.count);
                }
                setCurrentPage(page);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // Handle any errors that may occur during the API calls or processing
            setProcessing(false);
            setFiveProcessing(false);
            console.error(error);
        }
    };



    const fetchProductList = async (companyId) => {
        try {
            var method = 'GET';
            const key_word = '';
            const apifetcherObj = APIMethod(`/product/list/1?keyword=${key_word}&company_id=${companyId}`, null, method);

            // Await the response and convert it to JSON
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                // Use Promise.all to map submodules asynchronously
                const productListWithSubmodules = await Promise.all(
                    data.list.map(async (product) => {
                        const product_data = await fetchProductSubmodules(product.id);
                        // console.log(product_data);
                        product.submodules = product_data;
                        return product;
                    })
                );

                // Return the result to resolve the promise
                setProductList(productListWithSubmodules);
                // console.log(productListWithSubmodules)
                return productListWithSubmodules;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                // Return an empty array or handle the error as needed
                return [];
            }
        } catch (error) {
            alert(error);
            // Handle the error and return an empty array or handle it as needed
            return [];
        }
    };


    const fetchServicesList = async (companyId) => {
        try {
            var method = 'GET';
            const key_word = '';
            const apifetcherObj = APIMethod(`/services/list/1?keyword=${key_word}&company_id=${companyId}`, null, method);

            // Await the response and convert it to JSON
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                // Use Promise.all to map submodules asynchronously
                const serviceListWithSubmodules = await Promise.all(
                    data.list.map(async (service) => {
                        const service_data = await fetchServiceSubmodules(service.id);
                        // console.log(service_data);
                        service.submodules = service_data;
                        return service;
                    })
                );

                // Return the result to resolve the promise
                setServiceList(serviceListWithSubmodules);
                // console.log(serviceListWithSubmodules);
                return serviceListWithSubmodules;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                // Return an empty array or handle the error as needed
                return [];
            }
        } catch (error) {
            alert(error);
            // Handle the error and return an empty array or handle it as needed
            return [];
        }
    };


    const fetchQualityControlList = async (companyId) => {
        try {
            var method = 'GET';
            const key_word = '';
            const apifetcherObj = APIMethod(`/quality-control/list/1?keyword=${key_word}&company_id=${companyId}`, null, method);

            // Await the response and convert it to JSON
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                // Use Promise.all to map submodules asynchronously
                const qualityListWithSubmodules = await Promise.all(
                    data.list.map(async (quality) => {
                        const quality_data = await fetchQualityControlSubmodules(quality.id);
                        // console.log(quality_data);
                        quality.submodules = quality_data;
                        return quality;
                    })
                );

                // Return the result to resolve the promise
                setQualityControlList(qualityListWithSubmodules);
                // console.log(qualityListWithSubmodules);
                return qualityListWithSubmodules;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                // Return an empty array or handle the error as needed
                return [];
            }
        } catch (error) {
            alert(error);
            // Handle the error and return an empty array or handle it as needed
            return [];
        }
    };


    const fetchProductSubmodules = async (id) => {
        try {
            var method = 'GET';
            const apifetcherObj = APIMethod(`/module/list/PRODUCT/${id}/1`, null, method);
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                return data.list;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                return []; // Return an empty array or handle the error as needed
            }
        } catch (error) {
            console.error("Error fetching product submodules:", error);
            alert(error);
            return []; // Return an empty array or handle the error as needed
        }
    };


    const fetchServiceSubmodules = async (id) => {
        try {
            var method = 'GET';
            const apifetcherObj = APIMethod(`/module/list/SERVICE/${id}/1`, null, method);
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                return data.list;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                return []; // Return an empty array or handle the error as needed
            }
        } catch (error) {
            console.error("Error fetching service submodules:", error);
            alert(error);
            return []; // Return an empty array or handle the error as needed
        }
    };    

    const fetchQualityControlSubmodules = async (id) => {
        try {
            var method = 'GET';
            const apifetcherObj = APIMethod(`/module/list/QUALITY_CONTROL/${id}/1`, null, method);
            const response = await apifetcherObj;
            const [statusCode, data] = await Promise.all([response.status, response.json()]);

            if (statusCode === 200) {
                return data.list;
            } else {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false);
                return []; // Return an empty array or handle the error as needed
            }
        } catch (error) {
            console.error("Error fetching quality control submodules:", error);
            alert(error);
            return []; // Return an empty array or handle the error as needed
        }
    };

    const concatenateDescriptions = (pList, sList, qList) => {
        // Initialize variables to store concatenated descriptions
        return new Promise((resolve, reject) => {

            let productDescription = "";
            let serviceDescription = "";
            let qualityControlDescription = "";
            // console.log(pList)
            // console.log(sList)
            // console.log(qList)
            // console.log("in concat");
            // console.log(productList);
            pList.forEach((item) => 
            {
                if (item.description) {
                    productDescription += `${item.description}\n`;
                }
                if (item.submodules && Array.isArray(item.submodules)) {
                    item.submodules.forEach(submodule => {
                        if (submodule.description) {
                            productDescription += `${submodule.description}\n`;
                        }
                    });
                }
        
            });
            setTotalProductDescription(productDescription);

            sList.forEach((item) => 
            {
                if (item.description) {
                    serviceDescription += `${item.description}\n`;
                }
                if (item.submodules && Array.isArray(item.submodules)) {
                    item.submodules.forEach(submodule => {
                        if (submodule.description) {
                            serviceDescription += `${submodule.description}\n`;
                        }
                    });
                }
        
            });
            setTotalServiceDescription(serviceDescription);

            qList.forEach((item) => 
            {
                if (item.detail) {
                    const withoutHtml = item.detail.replace(/<\/?[^>]+(>|$)/g, "");
                    const withoutNbsp = withoutHtml.replace(/&nbsp;/g, " ");
                    qualityControlDescription += `${withoutNbsp}\n`;
                }
                if (item.submodules && Array.isArray(item.submodules)) {
                    item.submodules.forEach(submodule => {
                        if (submodule.description) {
                            qualityControlDescription += `${submodule.description}\n`;
                        }
                    });
                }
        
            });
            setTotalQualityDescription(qualityControlDescription);

            // console.log(productDescription);
            // console.log(serviceDescription);
            // console.log(qualityControlDescription);
        
            completeRequirements = descriptionRef.current.getData();

            let textWithoutHtml = completeRequirements.replace(/<[^>]*>/g, '');

            // Remove HTML entities like &nbsp;
            completeRequirements = textWithoutHtml.replace(/&[^\s]*;/g, '');
            completeRequirements = completeRequirements.replace(/\n+/g, '\n');
            // console.log(completeRequirements);
            // console.log(productDescription)
            // resolve([completeRequirements.replace(/\n/g, ''), productDescription.replace(/\n/g, ''), serviceDescription.replace(/\n/g, ''), qualityControlDescription.replace(/\n/g, '')]);
            // console.log(completeRequirements)
            // console.log(productDescription)
            // console.log(serviceDescription)
            // console.log(qualityControlDescription)
            textWithoutHtml = productDescription.replace(/<[^>]*>/g, '');
            productDescription = textWithoutHtml.replace(/&[^\s]*;/g, '');
            productDescription = productDescription.replace(/\n+/g, '\n');
            
            textWithoutHtml = serviceDescription.replace(/<[^>]*>/g, '');
            serviceDescription = textWithoutHtml.replace(/&[^\s]*;/g, '');
            serviceDescription = serviceDescription.replace(/\n+/g, '\n');

            textWithoutHtml = qualityControlDescription.replace(/<[^>]*>/g, '');
            qualityControlDescription = textWithoutHtml.replace(/&[^\s]*;/g, '');
            qualityControlDescription = qualityControlDescription.replace(/\n+/g, '\n');

            // console.log(completeRequirements)
            // console.log(productDescription)
            // console.log(serviceDescription)
            // console.log(qualityControlDescription)
            
            resolve([completeRequirements, productDescription, serviceDescription, qualityControlDescription]);
        });
    }

    const capabilityMatch = async (completeRequirements, productDescription, serviceDescription, qualityControlDescription, compType, compName) => {
        return new Promise((resolve, reject) => {
            var method = 'POST'
            const requestData = JSON.stringify({
                doctext_key: completeRequirements,
                producttext_key: productDescription,
                servicetext_key: serviceDescription,
                qualitytext_key: qualityControlDescription,
            });
            console.log(completeRequirements)
            console.log(productDescription)
            console.log(serviceDescription)
            console.log(qualityControlDescription)
            console.log(compName)
            console.log(compType)
            const apifetcherObj = APIMethod(`/pre-rfx/textmatch`, requestData, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]
                    if (statusCode === 200) {
                        if(compType === "Self")
                        {
                            data.result.type = compType;
                            data.result.name = compName
                            console.log(data.result);
                            setMyCompanyCap(data.result);
                            resolve();
                        }
                        else
                        {
                            // fiveCompanyList.push(data);
                            data.result.type = compType;
                            data.result.name = compName;
                            console.log(data.result);
                            const updatedCompanies = [...fiveCompaniesCap, data.result]
                            fiveCompanyList.push(data.result)
                            // console.log(fiveCompanyList)
                            // setFiveCompaniesCap(updatedCompanies);
                            resolve()
                        }
                    }
                })
                .catch(error => {
                    console.error('API call error:', error);
                    setProcessing(false);
                    setFiveProcessing(false);
                })
        });
    };


    return (
        <> 
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3" >
                        <Breadcrumb>
                            <Breadcrumb.Item>Partner Capability Match</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-header text-center" style={{ marginTop: 40 }}>
                        <span className="add-title ">Capability Match</span>
                    </div>
                    <div className="form-wraper form-style mb-4" >
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="form-group">
                                     {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                    {/*<label>RFx Scope/Requirements</label>*/}
                                    {/* Commenting end. Divya. September 01 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                    <p className="font-20 font-bold">RFx Scope/Requirements</p>
                                    {/* Divya. September 01 2023. Code End */}
                                    {/* <Editor
                                        apiKey={tiny_key}
                                        onInit={(evt, editor) => descriptionRef.current = editor}
                                        // disabled={isViewPath}
                                        init={{
                                            height: 500,
                                            menubar: 'file edit view insert format tools table tc help',
                                            selector: 'textarea#full-featured',
                                            plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                            toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                        }}
                                    /> */}
                                    <CKEditor   
                                        onInstanceReady={(event) => descriptionRef.current = event.editor}
                                        name="partner_match"
                                        config={{
                                            height: 500,
                                            versionCheck: false,
                                            toolbar: [
                                            { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                            { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                            { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                            { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                            { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                            { name: 'colors', items: ['TextColor', 'BGColor'] },
                                            { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                            ],
                                        }}
                                    />
                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description/Scope' : null}</span>
                                </div>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Company</th>
                                        {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                                        {/* <th>Bus. Type</th> */}
                                        {/* Commenting end. Divya. January 08 2024.. 
	                                    Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                                        <th>Company Type</th>
                                        {/* Divya. January 08 2024. Code End */}
                                        <th>Product Match</th>
                                        <th>Service Match</th>
                                        <th>Quality Match</th>
                                    </tr>
                                        {processing === true ? <tr><td colSpan="5" className="text-center"><span className="spinner-border spinner-border-sm text-center" role="status" aria-hidden="true"/></td></tr>:
                                            <tr>
                                            <td>{(myCompanyCap?.name === undefined) ? (notProcessed === false ? "N/A" : '') : myCompanyCap?.name}</td>
                                            <td>{(myCompanyCap?.type === undefined) ? (notProcessed === false ? "N/A" : '') : myCompanyCap?.type}</td>
                                            <td>{(myCompanyCap['01 productPercentage'] === undefined) ? (notProcessed === false ? "N/A" : '') :`${myCompanyCap['01 productPercentage']}%`}</td>
                                            <td>{(myCompanyCap['02 servicePercentage'] === undefined) ? (notProcessed === false ? "N/A" : '') :`${myCompanyCap['02 servicePercentage']}%`}</td>
                                            <td>{(myCompanyCap['03 qualityPercentage'] === undefined) ? (notProcessed === false ? "N/A" : '') :`${myCompanyCap['03 qualityPercentage']}%`}</td>
                                            </tr>
                                        }
                                </table>
                            </div>

                            {/* Commenting the following code for WOII-86. Divya. September 11 2023.. Code Start */}
                            {/*<div className="col-12 mt-4 row" style={{ justifyContent: 'center', width: 620 }}>*/}
                            {/* Commenting end. Divya. September 11 2023.. */}
                            {/* Added this code for  WOII-86. Divya. September 11 2023.. Code Start */}
                            <div className="col-12 mt-2 row" style={{ justifyContent: 'center', width: 620, height: '60px' }}>
                            {/* Divya. September 11 2023. Code End */}
                            <button
                            type="button"
                            className='btn btn-lg btn-brand-1'
                            onClick={() => getCompaniesList(1, undefined, "Self")}
                            disabled={processing}
                            style={{ '&:focus': { backgroundColor: '#218838' }, minWidth:"350px" }}
                            >
                            {processing ? (
                                
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                            ) : (
                                'Match My Company Capabilities Again'
                            )}
                            </button>  
                            </div>
                            <div className="col-12 mt-2 row" style={{ justifyContent: 'center', width: 620, height: '60px' }}>                              
                            <button
                            type="button"
                            className='btn btn-lg btn-brand-1'
                            onClick={() => getCompaniesList(1)}
                            disabled={fiveProcessing}
                            style={{ '&:focus': { backgroundColor: '#218838' }, minWidth:"350px" }}
                            >
                            {fiveProcessing ? (
                                
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                            ) : (
                                'Match Other Companies'
                            )}
                            </button>
                            </div>

                            <div className="col-12 my-3">
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">

                                    <tr>
                                        <th>Company</th>
                                        {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                                        {/* <th>Bus. Type</th> */}
                                        {/* Commenting end. Divya. January 08 2024.. 
	                                    Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                                        <th>Company Type</th>
                                        {/* Divya. January 08 2024. Code End */}
                                        <th>Product Match</th>
                                        <th>Service Match</th>
                                        <th>Quality Match</th>
                                    </tr>
                                    {
                                        fiveCompaniesCap.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res?.name || "N/A"}</td>
                                                    <td>{res?.type || "N/A"}</td>
                                                    <td>{res['01 productPercentage'] !== undefined ? `${res['01 productPercentage']}%` : "N/A"}</td>
                                                    <td>{res['02 servicePercentage'] !== undefined ? `${res['02 servicePercentage']}%` : "N/A"}</td>
                                                    <td>{res['03 qualityPercentage'] !== undefined ? `${res['03 qualityPercentage']}%` : "N/A"}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default PartnerCapability;