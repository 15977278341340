import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink, generatePath } from "react-router-dom";
import Pagination from '../../../../components/Pagination';
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Loader from '../../../../components/Loader';
import ModalPopup from '../../../../components/ModalPopUp';
import { useHistory } from "react-router";
import { useToasts } from 'react-toast-notifications';
import moment from "moment";
import 'chart.js/auto';
import { Pie,Chart} from 'react-chartjs-2';



const PreRfx = (props) => {
    const [loading, setLoading] = useState(false);
    const [getListData, setGetListData] = useState([]);
    const[getPiechartdata,setGetPiechartdata]=useState();
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();
    const [RFxCategoryData, setRFxCategoryData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [selectAllValue, setSelectAllValue] = useState(false);
    const[displayFlag,setdisplayFlag]=useState(true);
    const Pieselectedvalues=['Open','PENDING','APPROVED','DECLINED','PUSH_BACK','PENDING_APPROVAL']
    const [formFields, setFormFields] = useState({
        businessUnitId: '', rfxClient: '', rfxNumber: '', rfxTitle: '', preRfxStatus: '', rfxCategory: '', fromDate: undefined, toDate: undefined,
    })
    const { addToast } = useToasts();
    const chartOptions = {
        plugins: {
            title: {
                display: true,
                text: "Analysis of Total Pre-RFx's ",
                color:'black',
                padding:{
                    top:10,
                    bottom:40,
                },
                font: {
                    size: 32,
                    style: 'italic',
                    family: 'Helvetica Neue'
                  }
            }
        },
        maintainAspectRatio:false,
        legend: {
          labels: {
            fontSize: 32, 
          },
        },
        
    };

    useEffect(() => {
        var value = localStorage.getItem('Pre_rfx_page')
        var final_data = value && JSON.parse(value)
        var data = JSON.parse(localStorage.getItem("user_Role"))
        getPreRFxList(final_data ? final_data.page : currentPage)
        if (final_data && final_data.pageCount) {
            setPageCount(final_data.pageCount)
        }
        checkPermission(data)
        getRfxCategoryList()
        getBusinessUnitList()
        var filters = localStorage.getItem('pre_rfx_filter')
        var filters_data = filters && JSON.parse(filters)
        // if (filters_data) {
        //     formFields.rfxClient = filters_data.rfxClient
        //     formFields.businessUnitId = filters_data.businessUnitId
        //     formFields.rfxNumber = filters_data.rfxNumber
        //     formFields.rfxCategory = filters_data.rfxCategory
        //     formFields.fromDate = filters_data.filter_start_date
        //     formFields.toDate = filters_data.filter_end_date
        //     formFields.rfxTitle = filters_data.rfxTitle
        //     formFields.preRfxStatus = filters_data.preRfxStatus ? filters_data.preRfxStatus : ''
        // }
    }, [currentPage])

    const checkPermission = (data) => {
        const resObject = data.find(item => item.name === "Manage Opportunities")
        const valObject = resObject.access.find(item => item.name === "Pre-RFxs")
        setAdding(valObject.is_create)
        setEditing(valObject.is_update)
        setDeleting(valObject.is_delete)
    }

    const generateRandomColor = () => {
        // return '#' + Math.floor(Math.random()*16777215).toString(16);
        return `#${Math.floor(Math.random() * 0xCCCCCC).toString(16)}`;
      };

   const DisplayingResult=(RFxresultdata)=>{
    
        setdisplayFlag(false);
        const rfxStatusData = RFxresultdata.map(item => item.rfx_status);

            const statusCounts = rfxStatusData.reduce((acc, status) => {
                acc[status] = (acc[status] || 0) + 1;
                return acc;
            }, {});
            
            const chartData = {
                labels: Object.keys(statusCounts),
                datasets: [
                  {
                    data: Object.values(statusCounts),
                    backgroundColor: Object.keys(statusCounts).map(() => generateRandomColor()),
                  },
                ],
             
              };
            setGetPiechartdata(chartData);
    }


    const handlePieClick=(event,array)=>{
        if(array.length>0){
            var clickedval=''
            var filter_start_date = ''
            if (formFields.fromDate) {
                filter_start_date = moment(formFields.fromDate).format('DD-MM-YYYY') == moment(formFields.toDate).format('DD-MM-YYYY') ? '' : moment(formFields.fromDate).format('DD-MM-YYYY')
            }
            const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('DD-MM-YYYY')
   
            const clickedSliceIndex = array[0].index;
            const clickedSliceName = getPiechartdata.labels[clickedSliceIndex];
     
           
            for (let i = 0; i < Pieselectedvalues.length; i++) {
                if (Pieselectedvalues[i] === clickedSliceName) {
                clickedval= Pieselectedvalues[i];
                break; 
                }
            }
  
            history.push({
                pathname: `/pre-rfx`,
               page:'dashboard-pre-rfx',
               details:{businessUnitId:formFields.businessUnitId,fromdate:formFields.fromDate==undefined?'':filter_start_date,todate:formFields.toDate==undefined?'':filter_end_date,clickedval:clickedval}
             } );


        }

      }
    const getPreRFxList = (page, key, type) => {
        localStorage.removeItem("Pre_rfx_page")
        setLoading(true)
        const new_key = key === undefined ? '' : key
        var filter_start_date = ''
        if (formFields.fromDate) {
            filter_start_date = moment(formFields.fromDate).format('DD-MM-YYYY') == moment(formFields.toDate).format('DD-MM-YYYY') ? '' : moment(formFields.fromDate).format('DD-MM-YYYY')
        }

        const filter_end_date = formFields.toDate === undefined ? '' : moment(formFields.toDate).format('DD-MM-YYYY')
        // if (type === 'FILTER') {
        //     var filterObject = {
        //         rfxClient: formFields.rfxClient,
        //         businessUnitId: formFields.businessUnitId,
        //         rfxNumber: formFields.rfxNumber,
        //         rfxCategory: formFields.rfxCategory,
        //         filter_start_date: formFields.fromDate,
        //         filter_end_date: formFields.toDate,
        //         rfxTitle: formFields.rfxTitle,
        //         preRfxStatus: formFields.preRfxStatus
        //     };
        //     localStorage.setItem('pre_rfx_filter', JSON.stringify(filterObject));
        // }
        console.log("***********",formFields.businessUnitId)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/list/${page}?client=${formFields.rfxClient}&business_unit_id=${formFields.businessUnitId}&rfx_number=${formFields.rfxNumber}&category=${formFields.rfxCategory}&closing_from_date=${filter_start_date}&closing_to_date=${filter_end_date}&pre_rfx_status=${formFields.preRfxStatus}&limit=${" "}&keyword=` + encodeURIComponent(new_key || formFields.rfxTitle), null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    console.log(data.list)
                    setGetListData(data.list)
                    DisplayingResult(data.list);
                    for (var i in data.list) {
                        data.list[i].isSelected = false;
                    }
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getRfxCategoryList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-categories/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRFxCategoryData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getBusinessUnitList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/business-units/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setBusinessUnitData(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const nextPage = (pageNumber, selectedValue) => {
        setCurrentPage(pageNumber);
        // getPreRFxList(pageNumber)
        if (selectAllValue === true) {
            setSelectAllValue(false)
        }
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const _onEdit = (val) => {
        localStorage.setItem('Pre_rfx_page', JSON.stringify({ page: currentPage, pageCount: pageCount }))
        val.id && history.push(generatePath("/edit-pre-rfx/:id", { id: val.id }));
    }

    const Search = (key) => {
        getPreRFxList(1, key)
    }


    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const dateValidation = (date) => {
        if (formFields.fromDate) {
            const formFieldsObject = {
                ...formFields,
                toDate: date
            }
            setFormFields(formFieldsObject);
        } else {
            addToast('Please select RFx closing from date first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'warning', autoDismiss: true });
        }
    }

    const setFromDate = (date) => {
        const formFieldsObject = {
            ...formFields,
            fromDate: date
        }
        setFormFields(formFieldsObject);
    }
    const reset_filter=()=>{
        const formFieldsObject = {
            ...formFields,
            businessUnitId:" ",
            preRfxStatus:" ",
            fromDate: undefined,
            toDate:undefined
        }
        setFormFields(formFieldsObject);
    }


    const _onFilter = () => {
        getPreRFxList(1, '')
        handleClose()
    }

    const getData = () => {
        getPreRFxList(currentPage)
    }

    const selectedPrerfx = (id) => {
        setSelectAllValue(!selectAllValue)
        for (var i in getListData) {
            if (id == getListData[i].id) {
                getListData[i].isSelected = !getListData[i].isSelected
            }
        }
        setGetListData([...getListData])
    }

    const onDeleteAll = () => {
        var array = [];
        var count = 0;
        for (var i in getListData) {
            if (getListData[i].isSelected == true) {
                count = count + 1;
                array.push(getListData[i].id)
            }
        }
        if (count == 0) {
            addToast('Please select first', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setLoading(true)
            var method = 'DELETE'
            var body = JSON.stringify(array);
            const apifetcherObj = APIMethod('/pre-rfx/delete/bulk', body, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setSelectAllValue(false)
                        setLoading(false)
                        getPreRFxList(1)
                    } else {
                        setLoading(false)
                    }
                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }


    const onSelectAll = () => {
        for (var i in getListData) {
            getListData[i].isSelected = !getListData[i].isSelected
        }
        setSelectAllValue(true)
        setGetListData([...getListData])
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-4 align-items-center justify-content-between">
                        <div className="col-lg-3 col-md-12 d-md-flex mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <h2 className="page-title mb-md-0">Total Pre-RFxs</h2>
                        </div>
                        <div className="col-lg-6 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by RFx title" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            {adding === true ? <NavLink to={{ pathname: "/add-pre-rfx", state: { page: 'preRfx' } }} className="btn btn-brand-1 bg-success px-4">+ Add</NavLink> : null}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-style-1 mb-4 table-md-responsive" id="">
                            <thead>
                                <tr className="text-left cols-2">
                                    <th style={{ textTransform: 'none' }} colSpan={2}>Filter Pre-RFx Totals</th>
                                </tr>

                            </thead>
                            <ModalPopup
                                showModalPopup={showModalPopup}
                                onPopupClose={isShowPopup}
                                id={deleteId}
                                endPoint={'/pre-rfx/'}
                                updateList={getData}
                            ></ModalPopup>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="">
                                            <div className="form-group">
                                                <label>Business Unit</label>
                                                <select id="disabledSelect" className="form-control custom-select" onChange={handleChange} name="businessUnitId" value={formFields['businessUnitId']}>
                                                    <option value="">All</option>
                                                    {
                                                        businessUnitData.map((res, index) => {
                                                            return (
                                                                <option key={index} value={res.id}>{res.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-group">
                                                <label>Pre-RFx Status</label>
                                                <select id="disabledSelect" className="form-control custom-select " onChange={handleChange} name="preRfxStatus" value={formFields['preRfxStatus']}>
                                                    <option value="">All</option>
                                                    <option>Open</option>
                                                    <option>Pending</option>
                                                    <option value="APPROVED">Go</option>
                                                    <option Value="DECLINED">No-Go</option>
                                                    <option value="PUSH_BACK">Pushedback</option>
                                                    <option value="PENDING_APPROVAL">Sent for Approval</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="">
                                            <div className="form-group">
                                                <label>RFx Closing From Date</label>
                                                <DatePicker
                                                    selected={formFields.fromDate}
                                                    onChange={(date) => setFromDate(date)}
                                                    className="form-control"
                                                    placeholder="select Date"
                                                    dateFormat="MM-dd-yyyy"
                                                    scrollableYearDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="form-group">
                                                <label>RFx Closing To Date </label>
                                                <DatePicker
                                                    selected={formFields.toDate}
                                                    onChange={(date) => dateValidation(date)}
                                                    className="form-control"
                                                    placeholder="select Date"
                                                    dateFormat="MM-dd-yyyy"
                                                    minDate={formFields.fromDate}
                                                    scrollableYearDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={reset_filter}>Reset</button>
                        <button className="btn btn-lg btn-brand-1 bg-success" onClick={_onFilter}>Confirm</button>
                        {/* <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={DisplayingResult}>Display</button> */}
                    </div>
                    {getPiechartdata && getPiechartdata.labels && getListData.length>0 ? (
                        <div style={{marginLeft:'80px',marginTop:'20px'}}>
                        
                        <div style={{width:'1000px',height:'630px',border:'2px solid black',borderRadius:'20px'}}>
                        <Pie data={getPiechartdata} options={{ ...chartOptions, onClick: handlePieClick }} /> 
                        </div>  
                        </div>
                    ) : 
                     displayFlag? " ":<div style={{textAlign:'center'}}>No Data Available for your search input</div>

                    }

                    </div>


                </div>
            </div>


        </>
    )
}
export default PreRfx;

