// LOCAL
// export const BASE_URL = "http://54.177.201.37";
// export const BASE_URL = 'http://localhost:8080';

// DEV SERVER 
//  export const BASE_URL = "http://devapi.winopps.com";

// STAGING SERVER
// export const BASE_URL = "http://stagapi.winopps.com";

// PRODUCTION SERVER
export const BASE_URL = "https://api.winopps.com/java/";
 

// TINY EDITOR KEY
export const tiny_key = "csxqwgy4fwue418vmbfl7ce2z75gcpmm0woqo2r0lb93es2c"

export const APIConstants = {
    AuthenticationType: {
        BEARER: 'Bearer'
    },
    ContentType: {
        JSON: "application/json",
        MultiPart: "multipart/form-data",
        URLEncoded: "application/x-www-form-urlencoded"
    }
}

export const TOAST_TIMEOUT = 2500; 

const userData = localStorage.getItem('token')

export const APIMethod = (url, params, method) => {
    var final_url = `${BASE_URL}${url}`;
    const config = {
        method: method,
        headers: {
            "Accept": APIConstants.ContentType.JSON,
            "Content-Type": APIConstants.ContentType.JSON,
            "Authorization": url === '/oauth/token' ? 'Basic d2lub3BwczpTcEghblg=' : "Bearer " + localStorage.getItem('token')
        },
        body: params
    };

    return fetch(final_url, config)
}

export const FileUploadMethod = (url, params, method) => {
    var final_url = `${BASE_URL}${url}`;
    const config = {
        method: method,
        headers: {
            "Authorization": url === '/oauth/token' ? 'Basic d2lub3BwczpTcEghblg=' : "Bearer " + localStorage.getItem('token')
        },
        body: params
    };
    
    return fetch(final_url, config).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res;
        } else {
            throw res
        }
    }).catch(error => {
        throw error
    })
}
