import React, { useEffect, useState } from "react";
import { Breadcrumb, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { APIMethod, FileUploadMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import moment from "moment";
import ModalPopup from '../../../../components/ModalPopUp';
import ReactModal from 'react-modal-resizable-draggable';
import { isView, MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_AWARDS, PROPOSAL_TEMPLATE_APPROVAL, roleHelper } from "../../../../utility/utility";
import DeleteModal from "../../../../components/DeleteModal";
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from "../../../../components/Chat";


const AddContract = (props) => {
    const [formFields, setFormFields] = useState({
        contractNumber: '', contractTitle: '', clientName: props.location.state.detail.rfx_client_agency, contractDescription: '', contractComments: '',
        // version: '',
         description: '', document_type: '', contract_start_date: undefined, contract_end_date: undefined, contract_renewal_date: undefined, editDocumentid: '', isParse: false
    })

    const [flag, setFlag] = useState(false);
    const [rfxData, setRFxData] = useState([])
    const [documentList, setDocumentList] = useState([]);
    const [buttonLoader, setButtonLoader] = useState('');
    const [isLoading, setLoading] = useState('');
    // Toast
    const { addToast } = useToasts();
    //Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    // file
    const [file, setFile] = useState();
    // Add modal box
    const [show, setShow] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [modalButtonLoader, SetModalButtonLoader] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [addDocuments, setAddDocuments] = useState([]);
    const [contractId, setContractId] = useState('');
    const [contractStatusId, setContractStatusId] = useState('');
    // Delete
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState('');
    const [showChatModal, setShowChatModal] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    var access_Proposal_actions = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE_APPROVAL)

    useEffect(() => {
        getRfxData()
        getDocumentTypeList()
        { window.location.pathname === '/edit-contract' ? getContractDetail() : null }
    }, [])

    const getRfxData = () => {
        if (props.location.state == null) {
        } else {
            var method = 'GET'
            const apifetcherObj = APIMethod(`/pre-rfx/${props.location.state.detail.id}`, null, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setRFxData(data)
                    }
                })
        }
    }

    const getDocumentTypeList = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfx-document-type/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setDocumentList(data)
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const getContractDetail = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/${props.location.state.detail.contract_id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                // console.log(data);
                // console.log(data.client);

                if (statusCode === 200) {
                    console.log(data);
                    setFormFields({
                        ...formFields, contractNumber: data.contract_number, contractTitle: data.contract_title, clientName: props.location.state.detail.rfx_client_agency, contractDescription: data.description,
                        contractComments: data.comment, contract_start_date: moment(data.start_date, 'YYYY-MM-DD').toDate(), contract_end_date: moment(data.end_date, 'YYYY-MM-DD').toDate(),
                        contract_renewal_date: moment(data.renewal_date, 'YYYY-MM-DD').toDate(), isParse: data.is_parse
                    })
                    setAddDocuments(data.attachments)
                    setContractId(data.id)
                    setContractStatusId(data.contract_status_id)
                    if (data.contract_status_id == '3' || data.contract_status_id == '2') {
                        setIsDisable(true)
                    }
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            })
    }

    const save = () => {
        var isRfx = false;
        var start_date = moment(formFields.contract_start_date).format('YYYY-MM-DD')
        var end_date = moment(formFields.contract_end_date).format('YYYY-MM-DD')
        var renewal_date = moment(formFields.contract_renewal_date).format('YYYY-MM-DD')
        if (formFields.contractNumber === '' || formFields.contractTitle === '' || formFields.contractDescription === '' || formFields.contractComments === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else if (addDocuments.length > 0) {
            for (var i in addDocuments) {
                if (addDocuments[i].document_id == '140') {
                    isRfx = true;
                }
            }
            if (isRfx) {
                setButtonLoader(true)
                var method = window.location.pathname === '/edit-contract' ? 'PUT' : 'POST'
                var params = JSON.stringify({
                    id: contractId,
                    pre_rfx_id: props.location.state.detail.id,
                    rfx_title: rfxData.title,
                    rfx_number: rfxData.rfx_number,
                    contract_number: formFields.contractNumber,
                    contract_title: formFields.contractTitle,
                    
                    start_date: start_date,
                    end_date: end_date,
                    renewal_date: renewal_date,
                    description: formFields.contractDescription,
                    comment: formFields.contractComments,
                    attachments: addDocuments,
                })
                const apifetcherObj = APIMethod(`/contract`, params, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]

                        if (statusCode === 200) {
                            setButtonLoader(false)
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                            goBack()
                            setFormFields({ ...formFields, contractNumber: '', contractTitle: '',clientName:'', contractDescription: '', contractComments: '' })
                            onUpdateStatus('CONTRACT')
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                            setButtonLoader(false)
                        }
                    })
                    .catch(error => {
                        setButtonLoader(false)
                    })
            }
            else {
                addToast(`Please Add CONTRACT Document it's mandatory`, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
            }
        } else {
            addToast(`Please Add CONTRACT Document it's mandatory`, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const handleDate = (date, type) => {
        const obj = {
            ...formFields,
            [type]: date
        }
        setFormFields(obj);
    }

    const filehandler = event => {
        setFile(event.target.files[0])
    }

    const _onAddDocument = () => {
        if (
            // formFields.version === '' ||
             formFields.description === '' || file === undefined || formFields.document_type === '') {
            setModalFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (file.type) {
                SetModalButtonLoader(true)
                var method = 'POST'
                var form = new FormData();
                form.append('file', file);
                const apifetcherObj = FileUploadMethod(`/document/attachment/upload`, form, method)
                apifetcherObj
                    .then(response => { return Promise.all([response.status, response.json()]) })
                    .then(res => {
                        let statusCode = res[0]
                        let data = res[1]
                        var name = ''
                        if (statusCode === 200) {
                            for (var i in documentList) {
                                if (documentList[i].id == formFields.document_type) {
                                    name = documentList[i].name
                                }
                            }
                            var obj = {
                                document_id: formFields.document_type,
                                document_type_name: name,
                                file_name: file.name,
                                // version: formFields.version,
                                file: data.file,
                                description: formFields.description
                            }

                            if (editIndex >= 0) {
                                for (var index in addDocuments) {
                                    if (index == editIndex) {
                                        addDocuments.splice(editIndex, 1);
                                        addDocuments.splice(editIndex, 0, obj);
                                        setEditIndex(-1)
                                        setAddDocuments(addDocuments)
                                        break;
                                    }
                                }
                            } else {
                                if (addDocuments.length > 0) {
                                    for (var j in addDocuments) {
                                        if (addDocuments[j].document_id == obj.document_id) {
                                            alert('you can not add this with document type')
                                            SetModalButtonLoader(false)
                                            return;
                                        }
                                    }
                                }
                                addDocuments.push(obj)
                                setAddDocuments(addDocuments)
                            }
                            setShow(!show)
                            setEditIndex(-1)
                            setFile('')
                            setFormFields({ ...formFields, 
                                // version: '',
                                 description: '', document_type: '', editDocumentid: '' })
                            SetModalButtonLoader(false)
                        } else {
                            addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        }
                    })
                    .catch(error => {
                        SetModalButtonLoader(false)
                    })
            } else {
                var name = ''
                for (var i in documentList) {
                    if (documentList[i].id == formFields.document_type) {
                        name = documentList[i].name
                    }
                }
                for (var i in addDocuments) {
                    if (i == editIndex) {
                        addDocuments[i].document_id = formFields.document_type,
                            addDocuments[i].document_type_name = name,
                            addDocuments[i].file_name = file.name,
                            // addDocuments[i].version = formFields.version,
                            addDocuments[i].description = formFields.description
                    }
                }
                setShow(!show)
                setEditIndex(-1)
                setFormFields({ ...formFields,
                    //  version: '',
                      description: '', document_type: '', editDocumentid: '' })
                setFile('')
            }
        }
    }

    const _onEditDocument = (index, data) => {
        setFormFields({ ...formFields, document_type: data.document_id, 
            // version: data.version,
             description: data.description, editDocumentid: data.id })
        setShow(!show)
        setEditIndex(index)
        setFile({ name: data.file_name })
    }

    const onCancelModal = () => {
        setFormFields({ ...formFields, document_type: "",
        //  version: "", 
         description: "", editDocumentid: '' })
        setShow(!show)
    }

    const _onDeleteDocument = (id) => {
        if (id) {
            setDeleteId(id)
            isShowPopup(true)
        } else {
            setDeleteModal(!deleteModal)
        }

    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const onUpdateStatus = (key) => {
        setLoading(key)
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${props.location.state.detail.id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    setLoading('')
                    goBack()
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setLoading('')
                }
            })
            .catch(error => {
                setLoading('')
            })
    }

    const isDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const deleteLocalDocument = () => {
        setDeleteIndex(deleteIndex)
        addDocuments.splice(deleteIndex, 1);
        var temp = [];
        for (var j in addDocuments) {
            temp.push(addDocuments[j])
            if (j == addDocuments.length - 1) {
                setAddDocuments(temp)
                setDeleteModal(!deleteModal)
            }
        }
        if (addDocuments.length == 0) {
            setAddDocuments(temp)
            setDeleteModal(!deleteModal)
        }
        setDeleteIndex('')

    }

    return (
        <>

            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>{props.location.state.detail.page === 'Contract' ? 'Contracts' : 'RFxs'}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{window.location.pathname === '/edit-contract' ? 'Edit' : 'Add'} Contract</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="row col-12">
                        <h2 className="col-3 page-title ">{window.location.pathname === '/edit-contract' ? 'Edit' : 'Add'} Contract</h2>
                        <div className="col-9 row">
                            <h4 className="font-16 font-medium">RFx No. : <span className="text-secondary">{rfxData.rfx_number ? rfxData.rfx_number : '-'}</span></h4>
                            <h4 className="font-16 font-medium ml-5">RFx Title : <span className="text-secondary">{rfxData.title ? rfxData.title : '-'}</span></h4>
                        </div>
                    </div>
                    <div className="mb-3">

                        {!isView(access_Proposal_actions) || props.location.state.page == 'View' ? null : <NavLink to={{ pathname: `/mp-genrate-proposal/${props.location.state.detail.id}`, state: { detail: props.location.state.detail.id, rfx_number: rfxData.rfx_number, rfx_title: rfxData.title } }} className="btn btn-brand-1  px-4 mr-2">View Proposal</NavLink>}
                        {props.location.state.page == 'View' ? null : <NavLink to={{ pathname: "/parse-contract", state: { detail: props.location.state.detail, rfx_data: rfxData } }} className="btn btn-brand-1  px-4 mr-2">{formFields.isParse === true ? 'View Parsed Contract' : 'Parse Contract'}</NavLink>}
                        {props.location.state.detail.contract_id != 0 && props.location.state.page != 'View' ? <NavLink to={{ pathname: "/add-task-order", state: { detail: props.location.state.detail, rfx_data: rfxData } }} className="btn btn-brand-1  px-4 mr-2">Add Task Order</NavLink> : null}
                        {props.location.state.detail.contract_id != 0 && props.location.state.page != 'View' ? <NavLink to={{ pathname: "/create-compliance", state: { detail: props.location.state.detail, rfx_data: rfxData } }} className="btn btn-brand-1  px-4 mr-2">Create Compliance</NavLink> : null}
                        {props.location.state.detail.contract_id != 0 && props.location.state.page != 'View' ? <NavLink to={{ pathname: "/view-compliance", state: { detail: props.location.state.detail, rfx_data: rfxData } }} className="btn btn-brand-1  px-4">View Compliance/s</NavLink> : null}
                    </div>
                    <div className="form-wraper form-style mb-4">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Contract Number</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="contractNumber" value={formFields['contractNumber']} disabled={isDisable} />
                                    <span className="text-danger">{flag === true && formFields.contractNumber === '' ? 'Please Enter Contract Number' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Contract Title</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="contractTitle" value={formFields['contractTitle']} disabled={isDisable} />
                                    <span className="text-danger">{flag === true && formFields.contractTitle === '' ? 'Please Enter Contract Title' : null}</span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Contract Start date</label>
                                    <DatePicker
                                        selected={formFields.contract_start_date}
                                        onChange={(date) => handleDate(date, 'contract_start_date')}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                        // Commenting the following code for WOII-47. Divya. August 14 2023.. Code Start 
                                        //disabled={true}
                                        // Commenting end. Divya. August 14 2023.. 
                                        //Added this code for  WOII-47. Divya. August 14 2023.. Code Start 
                                        enabled={true}
                                        // Divya. August 14 2023. Code End 
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Contract End date</label>
                                    <DatePicker
                                        selected={formFields.contract_end_date}
                                        onChange={(date) => handleDate(date, 'contract_end_date')}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                        //Commenting the following code for WOII-47. Divya. August 14 2023.. Code Start
                                        //disabled={true}
                                        // Commenting end. Divya. August 14 2023.. 
                                        // Added this code for  WOII-47. Divya. August 14 2023.. Code Start 
                                        enabled={true}
                                        // Divya. August 14 2023. Code End 
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Contract Renewal date</label>
                                    <DatePicker
                                        selected={formFields.contract_renewal_date}
                                        onChange={(date) => handleDate(date, 'contract_renewal_date')}
                                        className="form-control"
                                        placeholder="select Date"
                                        dateFormat="MM-dd-yyyy"
                                        scrollableYearDropdown
                                        showYearDropdown
                                        // Commenting the following code for WOII-47. Divya. August 14 2023.. Code Start 
                                        //disabled={true}
                                        // Commenting end. Divya. August 14 2023.. 
                                        // Added this code for  WOII-47. Divya. August 14 2023.. Code Start 
                                        enabled={true}
                                        // Divya. August 14 2023. Code End 
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input type="text" className="form-control" onChange={handleChange} name="clientName" value={formFields['clientName']} disabled={true} />
                                    <span className="text-danger">{flag === true && formFields.clientName === '' ? 'Please Enter Client Name' : null}</span>
                                </div>
                            </div>


                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Contract Description</label>
                                    {/* Commenting the following code for WOII-47. Divya. August 14 2023.. Code Start */}
                                    {/*<textarea rows="5" className="form-control h-auto" onChange={handleChange} name="contractDescription" value={formFields['contractDescription']} disabled={true}></textarea>*/}
                                    {/* Commenting end. Divya. August 14 2023.. */}
                                    {/* Added this code for  WOII-47. Divya. August 14 2023.. Code Start */}
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="contractDescription" value={formFields['contractDescription']} enabled={true}></textarea>
                                    {/* Divya. August 14 2023. Code End */}
                                    <span className="text-danger">{flag === true && formFields.contractDescription === '' ? 'Please Enter Contract Description' : null}</span>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <label>Contract Comments</label>
                                    {/* Commenting the following code for WOII-47. Divya. August 14 2023.. Code Start */}
                                    {/*<textarea rows="5" className="form-control h-auto" onChange={handleChange} name="contractComments" value={formFields['contractComments']} disabled={true}></textarea>*/}
                                    {/* Commenting end. Divya. August 14 2023.. */}
                                    {/* Added this code for  WOII-47. Divya. August 14 2023.. Code Start */}
                                    <textarea rows="5" className="form-control h-auto" onChange={handleChange} name="contractComments" value={formFields['contractComments']} enabled={true}></textarea>
                                    {/* Divya. August 14 2023. Code End */}
                                    <span className="text-danger">{flag === true && formFields.contractComments === '' ? 'Please Enter Contract Comments' : null}</span>
                                </div>
                            </div>

                            <div className="col-12 my-3">
                                <div className="d-flex flex-row justify-content-between">
                                    {/* Commenting the following code for WOII-73. Divya. September 04 2023.. Code Start */}
                                    {/*<p className="font-medium">Contract Attachments</p>*/}
                                    {/* Commenting end. Divya. September 04 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 04 2023.. Code Start */}
                                    <p className="font-20 font-bold">Contract Attachments</p>
                                    {/* Divya. September 04 2023. Code End */}
                                    {
                                        props.location.state.page == 'View' ? null :
                                        // Commenting the following code for WOII-47. Divya. August 14 2023.. Code Start 
                                            //<button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4 mb-4" onClick={() => setShow(!show)} disabled={true}>+ Add</button>
                                        //Commenting end. Divya. August 14 2023..
                                        //Added this code for  WOII-47. Divya. August 14 2023.. Code Start 
                                            <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4 mb-4" onClick={() => setShow(!show)} enabled={true}>+ Add</button>
                                        // Divya. August 14 2023. Code End 
                                    }
                                </div>
                                <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                    <tr>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        {/* <th>Version</th> */}
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        addDocuments.map((res, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{res.document_type_name}</td>
                                                    <td>{res.file_name}</td>
                                                    {/* <td>{res.version}</td> */}
                                                    <td>{res.description}</td>
                                                    <td>
                                                        {/* Commenting the following code for WOII-78. Divya. September 01 2023.. Code Start */}
                                                        {/*<button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")} disabled={true}><i className="icofont-eye-alt"></i></button>*/}
                                                        {/* Commenting end. Divya. September 01 2023.. */}
                                                        {/* Added this code for  WOII-78. Divya. September 01 2023.. Code Start */}
                                                        <button type="button" className="btn btn-action" onClick={() => window.open(res.file, "_blank")} enabled={true}><i className="icofont-eye-alt"></i></button>
                                                        {/* Divya. September 01 2023. Code End */}
                                                        {/* Commenting the following code for WOII-78. Divya. September 01 2023.. Code Start */}
                                                        {/*<button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)} disabled={true}><i className="icofont-pencil-alt-2"></i></button>*/}
                                                        {/* Commenting end. Divya. September 01 2023.. */}
                                                        {/* Added this code for  WOII-78. Divya. September 01 2023.. Code Start */}
                                                        <button type="button" className="btn btn-action edit mr-1 ml-1" onClick={() => _onEditDocument(i, res)} enabled={true}><i className="icofont-pencil-alt-2"></i></button>
                                                         {/* Divya. September 01 2023. Code End */}
                                                        {/* Commenting the following code for WOII-78. Divya. September 01 2023.. Code Start */}
                                                        {/*<button type="button" className="btn btn-action delete" onClick={() => _onDeleteDocument(res.id, i)} disabled={true}><i className="icofont-trash"></i></button>*/}
                                                        {/* Commenting end. Divya. September 01 2023.. */}
                                                        {/* Added this code for  WOII-78. Divya. September 01 2023.. Code Start */}
                                                        <button type="button" className="btn btn-action delete" onClick={() => _onDeleteDocument(res.id, i)} enabled={true}><i className="icofont-archive"></i></button>
                                                         {/* Divya. September 01 2023. Code End */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table>
                            </div>
                            <div className="col-12 text-right mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                {
                                    props.location.state.page == 'View' || contractStatusId != '1' ? null :
                                        <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                            {buttonLoader && (
                                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                            )}
                                            <span>Save</span>
                                        </button>
                                }
                                {
                                    window.location.pathname === '/add-contract' &&
                                    <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => save()} disabled={buttonLoader}>
                                        {buttonLoader && (
                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}
                                        <span>Save</span>
                                    </button>

                                }
                                {
                                    (contractStatusId == '1' || contractStatusId == '4') && <button type="button" className="btn btn-lg btn-brand-1 mr-3" onClick={() => onUpdateStatus('CONTRACT_PENDING_APPROVAL')} disabled={isLoading == 'CONTRACT_PENDING_APPROVAL' ? true : false}>  {isLoading == 'CONTRACT_PENDING_APPROVAL' && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}Send for Approval</button>
                                }
                                {
                                    contractStatusId == '2' &&
                                    <>
                                        <button type="button" className="btn btn-lg btn-brand-1 mr-3 bg-success" onClick={() => onUpdateStatus('CONTRACT_APPROVED')} disabled={isLoading == 'CONTRACT_APPROVED' ? true : false}>  {isLoading == 'CONTRACT_APPROVED' && (
                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}Go</button>
                                        <button type="button" className="btn btn-brand-1  px-4" onClick={() => onUpdateStatus('CONTRACT_PUSHBACK')} disabled={isLoading == 'CONTRACT_PUSHBACK' ? true : false}>  {isLoading == 'CONTRACT_PUSHBACK' && (
                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                        )}Push Back</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* Delete Modal */}
            < ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/contract/document/'}
                updateList={getContractDetail}
            ></ModalPopup >

            <DeleteModal
                showModalPopup={deleteModal}
                onPopupClose={isDeleteModal}
                index={deleteIndex}
                deleteData={deleteLocalDocument} />

            {/* MODAL */}
            <ReactModal
                initWidth={500}
                initHeight={480}
                top={450}
                className={"contained-modal-title-vcenter"}
                onRequestClose={() => setShow(!show)}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    {/* Commenting the following code for WOII-78. Divya. September 01 2023.. Code Start */}
                    {/*<h5 className="modal-title" id="">{formFields.editDocumentid ? 'Add' : 'Edit'} Documents</h5>*/}
                    {/* Commenting end. Divya. September 01 2023.. */}
                    {/* Added this code for  WOII-78. Divya. September 01 2023.. Code Start */}
                    <h5 className="modal-title" id="">{editIndex === -1 ? 'Add' : 'Edit'} Documents</h5>
                    {/* Divya. September 01 2023. Code End */}
                    <button type="button" className="close" onClick={() => onCancelModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            {/* <div className="form-group">
                                <label>Version</label>
                                <input type="text" className="form-control" onChange={handleChange} name="version" value={formFields['version']} />
                                <span className="text-danger">{modalFlag === true && formFields.version === '' ? 'Please Enter Version' : null}</span>
                            </div> */}
                            <div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                    <span className="text-danger">{modalFlag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Document Type</label>
                                <select id="disabledSelect" className="form-control custom-select" value={formFields['document_type']} name="document_type" onChange={handleChange}>
                                    <option value="" disabled selected>Select your option</option>
                                    {
                                        documentList.map((res, index) => {
                                            return (
                                                <option key={index} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="text-danger">{modalFlag === true && formFields.document_type_id === '' ? 'Please Select Document Type' : null}</span>
                            </div>
                            <div className="form-group">
                                <label>Upload File</label>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={filehandler} />
                                    <label className="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{file === undefined ? 'Choose File' : file.name}</label>
                                    <span className="text-danger">{modalFlag === true && file === undefined ? 'Please Select file' : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => onCancelModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onAddDocument()} disabled={modalButtonLoader}>
                        {modalButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>
            {
                showChatModal == false && rfxData.id &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                    {/*<img src={chatIcon} />*/}
                    {/* Commenting end. Divya. October 06 2023.. */}
                    {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                    <img src={chatIcon}  className="chat-icon"/>
                    {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                    {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                    {/* Commenting end. Divya. November 14 2023.. */}
                    {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                    <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                    {/* Divya. November 14 2023. Code End */}
                    {/* Divya. October 06 2023. Code End */}
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={rfxData.id}
                    title={rfxData.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default AddContract;