import React, { useState, useEffect } from "react";
import { Breadcrumb, FormCheck, Modal, Button } from 'react-bootstrap';
import Loader from '../../../../components/Loader';
import { APIMethod, BASE_URL } from "../../../../API/APIClient";


const GenerateWordTemplate = (props) => {
    const [loading, setLoading] = useState(false);
    const [getWordTemplateListData, setWordTemplateListData] = useState([])
    const [flag, setFlag] = useState(false);
    const [formFields, setFormFields] = useState({ wordFormat: '' })
    const [wordFormatData, setwordFormatData] = useState([])
    // Goto back
    const goBack = () => {
        window.history.back();
        setFlag(false)
    }
    useEffect(() => {
        getWordTemplateList()
    }, [])

    const getWordTemplateList = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/word-template/all/list`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setWordTemplateListData(data.list)
                    setwordFormatData(data)
                    setLoading(false)
                    setFlag(false)
                } else {

                }
            }).catch(error => {
                setLoading(false)
            })
    }

    const save = () => {
        if (formFields.wordFormat === '') {
            setFlag(true)
        } else {
            setLoading(true)
            var method = 'POST'
            var params = JSON.stringify({
                name: formFields.wordFormat
            })
            const apifetcherObj = APIMethod(`/word-template`, params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setFormFields({ ...formFields, user: '' })
                        setLoading(false)
                    } else {

                    }
                })
                .catch(error => {
                    setLoading(false)
                })

        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const onSave = () => {
        window.open(`${BASE_URL}/word-template/export/file?template_id=${formFields.wordFormat}&pre_rfx_id=${props.location.state.id}`)
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="mb-3">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={goBack}>Proposals</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={goBack}>Generate Proposals</Breadcrumb.Item>
                            <Breadcrumb.Item active>Generate Proposal Document</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <h2 className="page-title">Choose MS Word Template </h2>

                    <div className="form-wraper form-style mb-4">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="form-group">
                                    {/* Commenting the following code for WOII-73. Divya. September 04 2023.. Code Start */}
                                    {/*<label>Word Formatting to be applied</label>*/}
                                    {/* Commenting end. Divya. September 04 2023.. */}
                                    {/* Added this code for  WOII-73. Divya. September 04 2023.. Code Start */}
                                    <p className="font-20 font-bold">Word Formatting to be applied</p>
                                    {/* Divya. September 04 2023. Code End */}
                                    <select id="disabledSelect" className="form-control custom-select" value={formFields['wordFormat']} name="wordFormat" onChange={handleChange}>
                                        <option value="" disabled selected>Select Template</option>
                                        {
                                            wordFormatData.map((res => {
                                                return (
                                                    <option value={res.id}>{res.name}</option>
                                                )
                                            }))
                                        }

                                    </select>
                                    <span className="text-danger">{flag === true && formFields.wordFormat === '' ? 'Please select' : null}</span>
                                </div>

                            </div>
                            <div className="col-12 text-center mt-4">
                                <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                                <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onSave()}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default GenerateWordTemplate;