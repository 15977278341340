import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { APIMethod, TOAST_TIMEOUT } from "../../../../API/APIClient";
import Pagination from '../../../../components/Pagination'
import ModalPopup from '../../../../components/ModalPopUp';
import Loader from '../../../../components/Loader';
import { useToasts } from 'react-toast-notifications';
import NoData from "../../../../components/NoDataFound";
import SearchNoDataFound from "../../../../components/SearchNoDataFound";
import ReactModal from 'react-modal-resizable-draggable';
import moment from "moment";

const BusninessUnitType = (props) => {
    //ADDED STATUS FORM FIELD TO BELOW CODE FOR WOII-185
    const [formFields, setFormFields] = useState({ description: '', businessUnitType: '', status: 'ACTIVE' })
    const [getListData, setGetListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [edit, setEdit] = useState('');
    const [id, setId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    // page
    const [pageCount, setPageCount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    {/* Added this code for  WOII-120. Divya. November 08 2023.. Code Start */ }
    const [rowsPerPage, setRowsPerPage] = useState(10);
    {/* Divya. November 08 2023. Code End */ }
    // Add modal box
    const [show, setShow] = useState(false);
    //ADDED BELOW CODE FOR WOII-185
    // Filter box
    const [showFilter, setShowFilter] = useState(false);
    const handleCloseFilter = () => setShowFilter(false)
    //CODE ENDED 21-03-2024 WOII-185
    const handleShow = () => {
        setStartDate(new Date())
        setShow(true);
    }

    //--------date-Piicker----------
    const [startDate, setStartDate] = useState(new Date());
    // Toast
    const { addToast } = useToasts();
    const [flag, setFlag] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [adding, setAdding] = useState();
    const [editing, setEditing] = useState();
    const [deleting, setDeleting] = useState();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    useEffect(() => {
        //Commenting the following code for WOII-120. Divya. November 08 2023.. Code Start 
        //getList(1)
        //Commenting end. Divya. November 08 2023.. 
        //Added this code for  WOII-120. Divya. November 08 2023.. Code Start
        getList(currentPage)
        {/* Divya. November 08 2023. Code End */ }
        var data = JSON.parse(localStorage.getItem("user_Role"))
        checkPermission(data)
        //Commenting the following code for WOII-120. Divya. November 08 2023.. Code Start 
        //}, [])
        //Commenting end. Divya. November 08 2023.. 
        //Added this code for  WOII-120. Divya. November 08 2023.. Code Start
    }, [rowsPerPage, currentPage])
    {/* Divya. November 08 2023. Code End */ }

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const checkPermission = (data) => {
        if (data != null) {
            const resObject = data.find(item => item.name === "Organizational Setup")
            //Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start 
            //const valObject = resObject.access.find(item => item.name === "Business Unit Types")
            //Commenting end. Divya. January 08 2024.. 
            //Added this code for  WOII-163. Divya. January 08 2024.. Code Start
            const valObject = resObject.access.find(item => item.name === "Company Types")
            //Divya. January 08 2024. Code End
            setAdding(valObject.is_create)
            setEditing(valObject.is_update)
            setDeleting(valObject.is_delete)
        }
    }

    const handleModal = () => {
        setShow(true);
        setCancelModal(!cancelModal)
    }

    const handleClose = () => {
        if (formFields.description === '' || formFields.businessUnitType === '') {
            setShow(false);
            setFlag(false)
            setId('');
            setEdit('');
            setFormFields({ ...formFields, description: '', businessUnitType: '' })
        } else {
            handleModal(!cancelModal);
            setShow(false);
            setFlag(false)
            setId('');
            setEdit('');
            setFormFields({ ...formFields, description: '', businessUnitType: '' })
        }

    }

    const getList = (page, key) => {
        const new_key = key === undefined ? '' : key
        global.new_key = key === undefined ? '' : key
        setLoading(true)
        var method = 'GET'
        //Commenting the following code for WOII-120. Divya. November 08 2023.. Code Start 
        //const apifetcherObj = APIMethod(`/business-unit-type/list/${page}?keyword=` + new_key, null, method)
        //Commenting end. Divya. November 08 2023.. 
        //Added this code for  WOII-120. Divya. November 08 2023.. Code Start
        //ADDED STATUS PARAMETER TO BELOW LINE FOR WOII-185
        const apifetcherObj = APIMethod(`/business-unit-type/list/${page}?limit=${rowsPerPage}&status=${formFields.status}&keyword=${formFields.businessUnitType || formFields.description}` + new_key, null, method)
        {/* Divya. November 08 2023. Code End */ }
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGetListData(data.list)
                    if (page === 1) {
                        setPageCount(data.count)
                    }
                    setCurrentPage(page);
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
            })

    }

    const Search = (key) => {
        getList(1, key)
    }

    const nextPage = (pageNumber) => {
        //Commenting the following code for WOII-120. Divya. November 08 2023.. Code Start 
        //getList(pageNumber)
        //Commenting end. Divya. November 08 2023.. 
        //Added this code for  WOII-120. Divya. November 08 2023.. Code Start
        setCurrentPage(pageNumber)
        {/* Divya. November 08 2023. Code End */ }
    }

    {/* Added this code for  WOII-120. Divya. November 08 2023.. Code Start */ }
    const handleRowsPerPageChange = (selectedValue) => {
        setRowsPerPage(selectedValue);
    }
    {/* Divya. November 08 2023. Code End */ }


    const formatDate = () => {
        var d = startDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const save = () => {
        if (formFields.description === '' || formFields.businessUnitType === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        }
        else {
            setButtonLoader(true)
            if (edit === '/') {
                var method = 'PUT'
                var params = JSON.stringify({
                    id: id,
                    name: formFields.businessUnitType,
                    description: formFields.description,
                    start_date: formatDate('Sun May 11,2014')
                })
            } else {
                method = 'POST'
                params = JSON.stringify({
                    name: formFields.businessUnitType,
                    description: formFields.description,
                    start_date: formatDate('Sun May 11,2014')
                })
            }
            const apifetcherObj = APIMethod('/business-unit-type', params, method)
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0]
                    let data = res[1]

                    if (statusCode === 200) {
                        setShow(false);
                        setFormFields({ ...formFields, description: '', businessUnitType: '' })
                        getList(currentPage)
                        setButtonLoader(false)
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                    } else {
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                        setButtonLoader(false)
                    }
                })
                .catch(error => {
                    setButtonLoader(false)
                })
        }
    }

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const _onEdit = (val) => {
        handleShow()
        setFormFields({ ...formFields, description: val.description, businessUnitType: val.name })
        setEdit('/')
        setId(val.id)
        setStartDate(moment(val.start_date, 'YYYY-MM-DD').toDate())
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const getData = () => {
        getList(1)
    }

    //ADDED THE BELOW CODE FOR WOII-185
    const _onResetFilter = (type) => {
        if (type === 'NAME') {
            formFields.businessUnitType = "";
        } else if (type === 'DESCRIPTION') {
            formFields.description = "";
        } else if (type = 'STATUS') {
            formFields.status = "";
        }
        else {
            formFields.businessUnitType = "";
            formFields.description = "";
            formFields.status = "";
            handleCloseFilter()
        }
        getList(1, '')
        handleCloseFilter()
    }
    //Added this code for  WOII-185. Divya. March 18 2024.. Code Start 
    const enableRow = (id) => {
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/business-unit-type/${id}/enable`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    getList(currentPage)
                    isShowPopup(false)
                }
            })
            .catch(error => {
                console.error('Error enabling Document entries:', error);
            });
    };

    const deleteRow = (id) => {
        var method = 'DELETE'
        const apifetcherObj = APIMethod(`/business-unit-type/${id}/delete`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    getList(currentPage)
                    isShowPopup(false)
                }
            })
            .catch(error => {
                console.error('Error in deleting Document entries:', error);
            });
    };
    //Divya. March 18 2024. Code End
    const _onFilter = () => {
        getList(1, '')
        handleCloseFilter()
    }
    //ADDED ABOVE CODE FOR WOII-185
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row mb-3">
                        <div className="col-7">
                            {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                            {/* <h2 className="page-title mb-md-0">Business Unit Types</h2> */}
                            {/* Commenting end. Divya. January 08 2024.. 
	                       Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                            <h2 className="page-title mb-md-0">Company Types</h2>
                            {/* Divya. January 08 2024. Code End */}
                        </div>
                        <div className="col-lg-5 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                            <div className="input-group page-cont-search mr-md-3 mb-md-0 mb-2">
                                <input type="search" className="form-control" placeholder="Search by type and description" onChange={event => Search(event.target.value)} />
                                <span className="input-group-text text-secondary"><i className="icofont-search-1"></i></span>
                            </div>
                            {/* ADDED BELOW LINE FOR WOII-185 */}
                            <button type="button" className="btn btn-brand-1 btn-h-40 mr-3" onClick={() => setShowFilter(true)}><i className="icofont-filter mr-2"></i> Filter</button>
                            {
                                adding === true ?
                                    <button type="button" className="btn btn-brand-1 btn-h-40 bg-success px-4" onClick={handleShow}>+ Add</button> : null
                            }
                        </div>
                    </div>
                    {/* ADDED BELOW CODE FOR WOII-185 */}
                    <div className="mb-2 filter-by">
                        {
                            formFields.businessUnitType ?
                                <div className="badge">
                                    <span className="lable">Company Type </span>
                                    <span className="value">{formFields.businessUnitType}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('NAME')}></i>
                                </div> : null
                        }
                        {
                            formFields.description ?
                                <div className="badge">
                                    <span className="lable">Description</span>
                                    <span className="value">{formFields.description}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('DESCRIPTION')}></i>
                                </div> : null
                        }
                        {
                            formFields.status ?
                                <div className="badge">
                                    <span className="lable">Status</span>
                                    <span className="value">{formFields.status == 'ACTIVE' ? 'Active' : formFields.status == 'DELETED' ? 'Archived' : formFields.status}</span>
                                    <i className="icofont-close" onClick={() => _onResetFilter('STATUS')}></i>
                                </div> : null
                        }
                    </div>
                    <div
                        className="table-responsive"
                        style={{
                            height: "50vh",
                            overflowY: "auto",
                            marginBottom: "20px",
                            resize: "vertical",
                            overflow: "auto",
                            maxHeight: "110vh"
                        }}
                    >
                    {/* ADDED ABOVE CODE FOR WOII-185 */}
                    <table className="table table-style-1 table-responsive-md mb-4 table reports" id="reportTable">
                        <thead>
                            <tr>
                                {/* Commenting the following code for WOII-163. Divya. January 11 2024.. Code Start  */}
                                {/* <th scope="col">Business Unit Type </th> */}
                                {/* Commenting end. Divya. January 11 2024.. 
	                            Added this code for  WOII-163. Divya. January 11 2024.. Code Start */}
                                <th scope="col">Company Type </th>
                                {/* Divya. January 11 2024. Code End */}
                                <th scope="col">Description</th>
                                <th scope="col" className="text-center" width="110">{editing === true || deleting === true ? 'Action' : null}</th>
                            </tr>
                        </thead>
                        <ModalPopup
                            showModalPopup={showModalPopup}
                            onPopupClose={isShowPopup}
                            id={deleteId}
                            endPoint={'/business-unit-type/'}
                            updateList={getData}
                        ></ModalPopup>
                        {
                            <tbody>
                                {
                                    loading === false ? getListData.map((val, index) => {
                                        //BELOW LINE IS ADDED FOR WOII-185
                                        const isRowArchived = val.status === 'DELETED';
                                        return (
                                            //BELOW CLASSNAME ATTRIBUTE ADDE FOR WOII-185
                                            <tr key={index} className={isRowArchived ? 'archived' : ''}>
                                                <td>{val.name}</td>
                                                <td title={val.description}>{val.description.length > 135 ? `${val.description.substring(0, 135)}...` : val.description}</td>
                                                <td className="text-center">
                                                    {/* Commenting the following code for WOII-185. Divya. March 18 2024.. Code Start
                                                    {
                                                        editing === true ?
                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(val)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                    }
                                                    {
                                                        deleting === true ?
                                                            <button type="button" className="btn btn-action delete" onClick={() => _onDelete(val.id)}><i className="icofont-archive"></i></button> : null
                                                    }
                                                    Commenting end. Divya  March 18 2024..*/}
                                                    {/* BELOW CODE IS ADDED FOR WOII-185 */}
                                                    {isRowArchived ? (
                                                        <>
                                                            <button type="button" className="btn btn-action enable" onClick={() => enableRow(val.id)}><i className="icofont-check" style={{ fontSize: '18px' }}></i></button>
                                                            <button type="button" className="btn btn-action delete ml-1" onClick={() => deleteRow(val.id)}><i className="icofont-archive"></i></button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                editing === true ?
                                                                    <button type="button" className="btn btn-action edit mr-1" onClick={() => _onEdit(val)}><i className="icofont-pencil-alt-2"></i></button> : null
                                                            }
                                                            {
                                                                deleting === true ?
                                                                    <button type="button" className="btn btn-action delete" onClick={() => _onDelete(val.id)}><i className="icofont-archive"></i></button> : null
                                                            }
                                                        </>
                                                    )
                                                    }
                                                    {/* ABOVE CODE IS ADDED FOR WOII-185 */}
                                                </td>
                                            </tr>
                                        )
                                    }) : null
                                }
                            </tbody>
                        }
                    </table>
                    </div>
                    {
                        getListData.length === 0 && loading === false && global.new_key ?
                            <SearchNoDataFound message={'No Business Unit Type Found'} />
                            : getListData.length === 0 && loading === false ?
                                <NoData /> : null
                    }
                    {
                        <Loader
                            isLoading={loading}
                        />
                    }
                    <div className="text-md-right">
                        <nav aria-label="Page navigation">
                            <Pagination
                                pages={pageCount}
                                endPoint={'/business-unit-type/list/'}
                                nextPage={nextPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                                //Added this code for  WOII-120. Divya. November 08 2023.. Code Start 
                                rowsPerPage={handleRowsPerPageChange}
                            // Divya. November 08 2023. Code End 
                            />
                        </nav>
                    </div>

                </div>

            </div>


            {/* Add Modal Box */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                top={100}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                    {/* <h5 className="modal-title" id="">{id ? 'Edit' : 'Add'} Business Units Type</h5> */}
                    {/* Commenting end. Divya. January 08 2024.. 
	                Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                    <h5 className="modal-title" id="">{id ? 'Edit' : 'Add'} Company Type</h5>
                    {/* Divya. January 08 2024. Code End */}
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">

                        <div className="col-md-12">
                            <div className="form-group">
                                {/* Commenting the following code for WOII-163. Divya. January 08 2024.. Code Start  */}
                                {/* <label>Business Unit Type</label> */}
                                {/* Commenting end. Divya. January 08 2024.. 
	                            Added this code for  WOII-163. Divya. January 08 2024.. Code Start */}
                                <label>Company Type</label>
                                {/* Divya. January 08 2024. Code End */}
                                <input type="text" className="form-control" onChange={handleChange} name="businessUnitType" value={formFields['businessUnitType']} />
                                <span className="text-danger">{flag === true && formFields.businessUnitType === '' ? 'Please Enter Business Unit Type' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control h-auto" id="" cols="30" rows="3" onChange={handleChange} name="description" value={formFields['description']}></textarea>
                                <div className="d-flex flex-column">
                                    <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Effective Start Date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholder="select Date"
                                    dateFormat="MM-dd-yyyy"
                                    minDate={new Date()}
                                    scrollableYearDropdown
                                    showYearDropdown
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => save()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}
                        <span>Save</span>
                    </button>
                </div>
            </ReactModal>

            {/* BELOW CODE IS ADDED FOR WOII-185 */}
            {/*Filter Modal Box*/}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={137.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={showFilter}
                disableKeystroke={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="">Filter Company Type</h5>
                    <button type="button" className="close" onClick={handleCloseFilter}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Company Type</label>
                                <input type="text" className="form-control" onChange={handleChange} name="businessUnitType" value={formFields['businessUnitType']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Description</label>
                                <input type="text" className="form-control" onChange={handleChange} name="description" value={formFields['description']} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status</label>
                                <select id="disabledSelect" className="from-control custom-select" onChange={handleChange} name="status" value={formFields['status']}>
                                    <option value="" disabled>select your option</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="DELETED">Archived</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={handleCloseFilter}>Cancel</button>
                    <button className="btn btn-lg btn-brand-1" onClick={() => _onResetFilter()}>Reset</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => _onFilter()}>Search</button>
                </div>
            </ReactModal>
            {/* ABOVE CODE IS ADDED FOR WOII-185 */}
        </>
    )
}
export default BusninessUnitType;