import React, { useEffect, useState, useRef } from 'react';
import Leftnavbar from '../../../../components/Leftnavbar';
import { Tabs, Tab, Nav, Row, Col, Modal } from "react-bootstrap";
import { NavLink, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form'
import Overlay from 'react-bootstrap/Overlay'
import { APIMethod, BASE_URL, TOAST_TIMEOUT} from "../../../../API/APIClient";
import { useToasts } from 'react-toast-notifications';
import Loader from '../../../../components/Loader';
import RawRFx from '../../../../components/RawRFx';
import RfxData from '../../../../components/RfxData';
import SectionizedRFx from '../../../../components/SectionizedRFx';
import { useHistory } from "react-router";
import ReactModal from 'react-modal-resizable-draggable';
import { checkEmptyString, isEdit, MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE, roleHelper } from '../../../../utility/utility';
import ModalPopup from '../../../../components/ModalPopUp';
import chatIcon from '../../../../assets/css/images/chat.png';
import Chat from '../../../../components/Chat';
import { Editor } from '@tinymce/tinymce-react';
import CustomLoader from '../../../../components/Loader';
import {CKEditor} from 'ckeditor4-react';

const RfxParsedDetails = (props) => {
    const editorRef = useRef(null);
    const propsalStructureRef = useRef(null);
    const submissionInstructionRef = useRef(null);
    const evaluationCriteriaRef = useRef(null);
    const contractRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [doneButton, setDoneButton] = useState(false);
    const location = useLocation();
    const [flag, setFlag] = useState(false);
    const { addToast } = useToasts();
    const [url, setUrl] = useState("");
    const [rfxData, setRfxData] = useState([]);
    const [preRfxData, setPreRfxData] = useState([]);
    const [systemPreRfxData, setSystemPreRfxData] = useState([]);
    const [aiSegmentationData, setAiSegmentationData] = useState([]);
    const [scrapedRfxDataView, setScrapedRfxDataView] = useState(true);
    const [show, setShow] = useState(false);
    const [id, setId] = useState('')
    const [buttonLoader, setButtonLoader] = useState(false)
    const [modalType, setModalType] = useState('');
    const [contentData, setContentData] = useState([]);
    const [editIndex, setEditIndex] = useState('');
    const [editId, setEditId] = useState('');
    const history = useHistory();
    const [requirementButtonLoader, setRequirementButtonLoader] = useState(false)
    const [deleteId, setDeleteId] = useState('');
    const [showModalPopup, setShowModalPopup] = useState(false);
    const [generateButtonLoader, setGenerateButtonLoader] = useState(false);
    const [aiScope, setAiScope] = useState('');
    const [aiProposalStructure, setAiProposalStructure] = useState('');
    const [aiSubmissionIntruction, setAiSubmissionIntruction] = useState('');
    const [aiEvaluationCriteria, setAiEvaluationCriteria] = useState('');
    const [aiContract, setAiContract] = useState('');
    const [formFields, setFormFields] = useState({
        businessUnit: '', rfxNumber: '', rfxTitle: '', rfxStatus: '', rfxType: '', rfxCategory: '',
        rfxPublishedDate: '', rfxDueDateTime: '', rfxQaDateTime: '', rfxQaReleaseDate: '', rfxClosingDate: '', rfxAwardDate: '', rfxClientAgency: '',
        aiScope: '', aiProposalStructure: '', aiSubmissionIntruction: '', aiEvaluationCriteria: '', aiImageSegemtationLoader: false, aiId: '',
        systemId: '', heading: '', description: '', aiContract: '', aiRequirementChecklist: [], requirement: '', requirementIn: '', page: '',
        requirementId: ''
    });
    const goBack = () => {
        /// Access getRFxList and currentPage from props.location.state
        // const currentPage = location.state?.currentPage;
        history.push({ pathname: "/rfx-list" })

        // window.history.back();
    }
    const [showReferenceModal, setShowReferenceModal] = useState(false)
    const [showChatModal, setShowChatModal] = useState(false)

    //coollapse menu
    const CollapseMenu = () => {
        var element = document.getElementById("ParsRfxDtl");
        element.classList.toggle("collapse-tab");
        for (var i = 0; i < 3; i++) {
            var hideIcon = document.getElementById(`hideCollapseButton${i + 1}`);
            if (hideIcon) {
                hideIcon.classList.toggle("show-coll-menu-page");
                var hideText = document.getElementById(`hideCollapseText${i + 1}`);
                hideText.classList.toggle("hide-coll-menu-page");
            } else {
                break;
            }
        }
    }

    useEffect(() => {
        getRfxData()
        getPreRfxData()
        getSystemPreRfx()
        getContractData()
    }, [])


    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.setData(aiScope, {
                callback: function () {
                    console.log('aiScope is set');
                },
              });
        }
        
    }, [aiScope])

    useEffect(() => {
        if (propsalStructureRef.current) {
            propsalStructureRef.current.setData(aiProposalStructure, {
                callback: function () {
                    console.log('aiProposalStructure is set');
                },
              });
        }
    }, [aiProposalStructure])

    useEffect(() => {
        if (submissionInstructionRef.current) {
            submissionInstructionRef.current.setData(aiSubmissionIntruction, {
                callback: function () {
                    console.log('aiSubmissionIntruction is set');
                },
              });
        }
    }, [aiSubmissionIntruction])

    useEffect(() => {
        if (evaluationCriteriaRef.current) {
            evaluationCriteriaRef.current.setData(aiEvaluationCriteria, {
                callback: function () {
                    console.log('aiEvaluationCriteria is set');
                },
              });
        }
    }, [aiEvaluationCriteria])

    useEffect(() => {
        if (contractRef.current) {
            contractRef.current.setData(aiContract, {
                callback: function () {
                    console.log('aiContract is set');
                },
              });
        }
    }, [aiContract])

    var access_Proposal_template = roleHelper(MANAGE_PROPOSALS_AND_AWARDS, PROPOSAL_TEMPLATE)


    const getRfxData = () => {
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/document-section/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setRfxData(data)
                    // setUrl(`https://docs.google.com/gview?url=${data.url}&embedded=true`);
                    setUrl(data.url)
                    setLoading(false)
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                setLoading(false)
            })
    }

    const getPreRfxData = (key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setPreRfxData(data)
                    setLoading(false);

                } else {
                }

            })

            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const getSystemPreRfx = (key) => {
        setLoading(true)
        const new_key = key === undefined ? '' : key
        var method = 'GET'
        const apifetcherObj = APIMethod(`/pre-rfx/${location.state.detail}?rfx_type=${'SYSTEM'}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setSystemPreRfxData(data)
                    setLoading(false);

                } else {
                }

            })

            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const getAiImageSegmentation = (id) => {
        formFields.aiId = id
        setLoading(true)
        var method = 'GET'
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]
                if (statusCode === 200) {
                    setAiSegmentationData(data)
                    formFields.aiScope = data.scope
                    setAiScope(data.scope)
                    formFields.aiProposalStructure = data.proposal_structure
                    setAiProposalStructure(data.proposal_structure)
                    formFields.aiSubmissionIntruction = data.submission_structure
                    setAiSubmissionIntruction(data.submission_structure)
                    formFields.aiEvaluationCriteria = data.evaluation_criteria
                    setAiEvaluationCriteria(data.evaluation_criteria)
                    formFields.aiContract = data.contract
                    setAiContract(data.contract)
                    formFields.aiRequirementChecklist = data.requirement_checklist.sort((a, b) => a.page - b.page);
                    getPreRfxData()
                    setLoading(false);
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                    setLoading(false);
                }
            })
            .catch(error => {
                alert(error)
                setLoading(false);
            })
    }

    const getContractData = () => {
        var method = 'GET'
        const apifetcherObj = APIMethod(`/contract/section/list/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setContentData(data)
                    // setContentData([...contentData])
                } else {

                }
            })

            .catch(error => {
                addToast(error, { appearance: 'error', autoDismiss: true });
            })
    }

    // const handleChange = (event) => {
    //     event.preventDefault();
    //     const { name, value } = event.target;
    //     const formFieldsObject = {
    //         ...formFields,
    //         [name]: value
    //     }
    //     setFormFields(formFieldsObject);
    // }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // Change the data type of page to String
        const formFieldsObject = {
            ...formFields,
            [name]: name === 'page' ? String(value) : value,
        };
        setFormFields(formFieldsObject);
    };

    const _onDoneWithData = (key) => {
        setDoneButton(true)
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/${key}/${location.state.detail}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { appearance: 'success', autoDismiss: true });
                    if (key === 'GENERATE_PROPOSAL_TEMPLATE') {
                        history.push({
                            pathname: `/add-proposal-template/${preRfxData.id}`,
                            state: { detail: location.state.detail }
                        })
                        setDoneButton(false);
                    } else {
                        //   updateStatus()
                        setDoneButton(false);
                        goBack()
                    }
                } else {
                    addToast(data.message, { appearance: 'error', autoDismiss: true });
                    //   setDoneButton(false)
                }
            })
            .catch(error => {
                alert(error)
                setDoneButton(false)
            })
    }

    const sendAiImageSegmentation = () => {
        formFields.aiImageSegemtationLoader = true;
        var method = 'PUT'
        var params = JSON.stringify({
            scope: editorRef.current.getData(),
            proposal_structure: propsalStructureRef.current.getData(),
            submission_structure: submissionInstructionRef.current.getData(),
            evaluation_criteria: evaluationCriteriaRef.current.getData(),
            contract: contractRef.current.getData(),
            owner: localStorage.getItem('userName')
        })
        const apifetcherObj = APIMethod(`/rfp-image-segmentation/${formFields.aiId}`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode == 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    formFields.aiImageSegemtationLoader = false;
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    formFields.aiImageSegemtationLoader = false;
                }
            })
            .catch(error => {
                addToast(error, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                formFields.aiImageSegemtationLoader = false;
            })
    }

    const setSytemData = (data) => {
        formFields.systemId = data
    }

    const handleClose = () => {
        setShow(false)
        setId('')
        setEditId('')
        setFormFields({ ...formFields, heading: "", description: "" })
    }

    const onsubmit = () => {
        if (formFields.heading === '' || formFields.description === '') {
            setFlag(true)
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            if (modalType == 'Edit') {
                formFields.modalButtonLoader = true
                for (var i in contentData) {
                    if (i == editIndex) {
                        contentData[i].title = formFields.heading
                    }
                }
                setContentData([...contentData])

                for (var i in contentData) {
                    if (i == editIndex) {
                        contentData[i].description = formFields.description
                    }
                }
                setContentData([...contentData])
                formFields.modalButtonLoader = false
                submitContract()
            } else {
                contentData.splice(editIndex + 1, 0, { title: formFields.heading, description: formFields.description, section: '', priority: '', id: '' })
                setContentData([...contentData])
                submitContract()

            }
        }
    }

    const submitContract = () => {
        setButtonLoader(true)
        var method = editId ? 'PUT' : 'POST'
        var params = JSON.stringify({
            id: editId,
            title: formFields.heading,
            description: formFields.description,
            priority: '0',
            pre_rfx_id: location.state.detail
        })
        const apifetcherObj = APIMethod(`/contract/section`, params, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    handleClose()
                    setFormFields({ heading: '', description: '' })
                    setEditIndex('')
                    setEditId('')
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    setButtonLoader(false)
                    getContractData()
                } else {
                    handleClose()
                    setButtonLoader(false)
                }
            })
            .catch(error => {
                setButtonLoader(true)
            })
    }

    const onEdit = (res, index) => {
        setFormFields({ ...formFields, heading: res.title, description: res.description })
        setEditIndex(index)
        setEditId(res.id)
        setShow(!show)
        setModalType('Edit')
    }

    const _onAddTemplateContent = () => {
        setModalType('Add')
        setShow(!show)
    }

    const _onDeleteTemplateContent = (data) => {
        var method = 'DELETE'
        const apifetcherObj = APIMethod(`/contract/section/${data.id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
                    getContractData()
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
                }
            })
            .catch(error => {
                addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
            })

    }

    const onGenerateProposalTemplate = () => {
        setGenerateButtonLoader(true)
        var method = 'PUT'
        const apifetcherObj = APIMethod(`/action/event/GENERATE_PROPOSAL_TEMPLATE/${preRfxData.id}`, null, method)
        apifetcherObj
            .then(response => { return Promise.all([response.status, response.json()]) })
            .then(res => {
                let statusCode = res[0]
                let data = res[1]

                if (statusCode === 200) {
                    setGenerateButtonLoader(false)
                    history.push({
                        pathname: `add-proposal-template/${preRfxData.id}`,
                        state: { detail: location.state.detail, from: 'RFxs', rfx_number: preRfxData.rfx_number, title: preRfxData.title }
                    })
                } else {
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    setGenerateButtonLoader(false)
                }
            })
            .catch(error => {
                setDoneButton(false)
                setGenerateButtonLoader(false)
            })

    }

    const handleCloseRequirementModal = () => {
        formFields.requirement = ''
        formFields.requirementIn = ''
        formFields.page = ''
        formFields.requirementId = ''
        setShowReferenceModal(false)
    }

    // const onAddRequirementChecklist = () => {
    //     if (formFields.requirement === '' || formFields.requirementIn === '' || formFields.page == '') {
    //         setFlag(true)
    //         addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
    //     } else {
    //         setRequirementButtonLoader(true)

    //         if (formFields.requirementId) {
    //             var method = 'PUT'
    //             var params = JSON.stringify({
    //                 requirement: formFields.requirement,
    //                 page: formFields.page,
    //                 reference_in: formFields.requirementIn,
    //                 rfx_id: preRfxData.id,
    //                 id: formFields.requirementId
    //             })
    //         } else {
    //             var method = 'POST'
    //             var params = JSON.stringify({
    //                 requirement: formFields.requirement,
    //                 page: formFields.page,
    //                 reference_in: formFields.requirementIn,
    //                 rfx_id: preRfxData.id
    //             })
    //         }
    //         const apifetcherObj = APIMethod(`/rfp-image-segmentation/check-list/requirement`, params, method)
    //         apifetcherObj
    //             .then(response => { return Promise.all([response.status, response.json()]) })
    //             .then(res => {
    //                 let statusCode = res[0]
    //                 let data = res[1]

    //                 if (statusCode === 200) {
    //                     setRequirementButtonLoader(false)
    //                     setShowReferenceModal(false)
    //                     formFields.requirement = ''
    //                     formFields.page = ''
    //                     formFields.requirementIn = ''
    //                     formFields.requirementId = ''
    //                     addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true })
    //                     getAiImageSegmentation(preRfxData.id)
    //                 } else {
    //                     setRequirementButtonLoader(false)
    //                     addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
    //                 }
    //             })
    //             .catch(error => {
    //                 setShowReferenceModal(false)
    //                 addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true })
    //             })
    //     }
    // }
    const onAddRequirementChecklist = () => {
        if (formFields.requirement === '' || formFields.requirementIn === '' || formFields.page === '') {
            setFlag(true);
            addToast('Please fill all the fields', { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
        } else {
            setRequirementButtonLoader(true);

            if (formFields.requirementId) {
                var method = 'PUT';
                var params = JSON.stringify({
                    requirement: formFields.requirement,
                    page: formFields.page, // Ensure that page is sent as a string
                    reference_in: formFields.requirementIn,
                    rfx_id: preRfxData.id,
                    id: formFields.requirementId
                });
            } else {
                var method = 'POST';
                var params = JSON.stringify({
                    requirement: formFields.requirement,
                    page: formFields.page, // Ensure that page is sent as a string
                    reference_in: formFields.requirementIn,
                    rfx_id: preRfxData.id
                });
            }
            const apifetcherObj = APIMethod(`/rfp-image-segmentation/check-list/requirement`, params, method);
            apifetcherObj
                .then(response => { return Promise.all([response.status, response.json()]) })
                .then(res => {
                    let statusCode = res[0];
                    let data = res[1];

                    if (statusCode === 200) {
                        setRequirementButtonLoader(false);
                        setShowReferenceModal(false);
                        formFields.requirement = '';
                        formFields.page = ''; // Clear the page field
                        formFields.requirementIn = '';
                        formFields.requirementId = '';
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'success', autoDismiss: true });
                        getAiImageSegmentation(preRfxData.id);
                    } else {
                        setRequirementButtonLoader(false);
                        addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                    }
                })
                .catch(error => {
                    setShowReferenceModal(false);
                    addToast(data.message, { autoDismissTimeout: TOAST_TIMEOUT, appearance: 'error', autoDismiss: true });
                });
        }
    };

    const _onDelete = (id) => {
        setDeleteId(id)
        isShowPopup(true)
    }

    const isShowPopup = (status) => {
        setShowModalPopup(status)
    };

    const getData = () => {
        getAiImageSegmentation(preRfxData.id)
    }

    return (
        <>
            <ModalPopup
                showModalPopup={showModalPopup}
                onPopupClose={isShowPopup}
                id={deleteId}
                endPoint={'/rfp-image-segmentation/check-list/requirement/'}
                updateList={getData}
            ></ModalPopup>
            <div className="page-wrapper form-style">
                <div className="page-content">
                    <div className="row mb-3 align-items-center justify-content-between">
                        <div className="col-lg-12 col-md-12 mb-md-0 mb-2 mb-lg-0 mb-md-3">
                            <div className='col-12 row'>
                                {/* Commenting the following code for WOII-63. Divya. September 13 2023.. Code Start */}
                                {/*<h2 className="col-6 page-title">{preRfxData.title}</h2>*/}
                                {/* Commenting end. Divya. September 13 2023.. */}
                                {/* Added this code for  WOII-63. Divya. September 13 2023.. Code Start */}
                                <h2 className="col-6 page-title" style={{ whiteSpace: 'nowrap' }}>{preRfxData.title}</h2>
                                {/* Divya. September 13 2023. Code End */}

                                {/* Commenting the following code for WOII-63. Divya. September 13 2023.. Code Start */}
                                {/*<button type="button" className="btn btn-brand-1" style={{ marginLeft: 300 }} onClick={() => onGenerateProposalTemplate()} disabled={location.state.page == 'View' || !isEdit(access_Proposal_template) || preRfxData.proposal_template_status_id || !preRfxData.is_done_edit ? true : false}>*/}
                                {/* Commenting end. Divya. September 13 2023.. */}
                                {/* Added this code for  WOII-63. Divya. September 13 2023.. Code Start */}
                                <button type="button" className="btn btn-brand-1" style={{ marginLeft: 800 }} onClick={() => onGenerateProposalTemplate()} disabled={location.state.page == 'View' || !isEdit(access_Proposal_template) || preRfxData.proposal_template_status_id || !preRfxData.is_done_edit ? true : false}>
                                    {/* Divya. September 13 2023. Code End */}
                                    {generateButtonLoader && (
                                        <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                    )}
                                    <span>Generate Proposal Template</span>
                                </button>



                            </div>
                            {

                                <div style={{marginLeft:30}}>
                                    <a href="javascript:void(0)" onClick={CollapseMenu}>
                                        <img src="/images/collapse-icon.svg" width="27" />
                                    </a>
                                </div>
                            }
                        </div>
                        <div className="col-lg-7 col-md-12 text-right d-md-flex align-items-center justify-content-center">
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex overflow-auto " style={{ height: "85vh" }}>
                            <div className="tab-style-2 mb-4" id="ParsRfxDtl" style={{ marginBottom: "40px" , marginLeft:40}}>
                                <p className="font-medium">Scraped RFx Details</p>
                                <Tab.Container id="controlled-tab-example2" defaultActiveKey="RawRFx">
                                    <Row>
                                        <div className="tab-style-1 col-12 d-flex">
                                            <Nav className="d-flex  nav nav-tabs ">
                                                {/* Nav items go here */}
                                                <Nav.Item className="mr-3">
                                                    {/* Icon and associated text for RawRFx */}
                                                    <img src={require('../../../../assets/css/images/view-icon.png').default} className='hide-coll-menu-page ml-2 mt-3 mr-2' id='hideCollapseButton1' title="Raw RFx" width={30} height={30} />
                                                    <div id='hideCollapseText1'>
                                                        <Nav.Link eventKey="RawRFx">Raw RFx</Nav.Link>
                                                    </div>
                                                </Nav.Item>
                                                <Nav.Item className="mr-3">
                                                    {!preRfxData.from_parsed_rfx ? (
                                                        <>
                                                        {/* Icon and associated text for RFx Data when `from_parsed_rfx` is true */}
                                                        <img
                                                            src={require('../../../../assets/css/images/data-icon.png').default}
                                                            className='hide-coll-menu-page ml-2 mt-3 mr-2'
                                                            id='hideCollapseButton2'
                                                            title="RFx Data"
                                                            width={30}
                                                            height={30}
                                                        />
                                                        <div id='hideCollapseText2'>
                                                            <Nav.Link eventKey="RFxDataDtl">RFx Data</Nav.Link>
                                                        </div>
                                                        </>
                                                    ) : (
                                                        // Optional: Render something else or null if `from_parsed_rfx` is false
                                                        null
                                                    )}
                                                    </Nav.Item>
                                            </Nav>
                                        </div>
                                        <Col sm={12} className="tab-style-2">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="RawRFx">
                                                    {
                                                        url ?
                                                            <RawRFx
                                                                url={url}
                                                            /> : null
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="RFxDataDtl">
                                                    <RfxData
                                                        page={window.location.pathname === '/view-parse-rfx-detail' ? '/view-parse-rfx-detail' : null}
                                                        type={"SYSTEM"}
                                                        onGetSystemTitle={(data) => setScrapedRfxDataView(data)}
                                                        onGetSystemId={(data) => setSytemData(data)}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="SectionizedRFx">
                                                    {
                                                        formFields.systemId ?
                                                            <SectionizedRFx
                                                                page={window.location.pathname === '/view-parse-rfx-detail' ? '/view-parse-rfx-detail' : null}
                                                                rfx_id={formFields.systemId}
                                                                // Added this code for  WOII-72. Divya. September 11 2023.. Code Start
                                                                height={'1000'}
                                                            //Divya. September 11 2023. Code End
                                                            /> : null
                                                    }
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>

                            <div style={{ minWidth: "1300px" , marginLeft: 80, marginRight: 80 }}>
                                <p className="font-medium">AI Parsed RFx Details</p>
                                <div className="tab-style-2 ">
                                    <Tab.Container id="controlled-tab-example" defaultActiveKey="RFxData" >
                                        <Row>
                                            <div className="tab-style-1 col-12 d-flex">
                                                <Nav className="d-flex  nav nav-tabs ">
                                                    <Nav.Item className="mr-3">
                                                        <Nav.Link eventKey="RFxData">RFx Data</Nav.Link>
                                                    </Nav.Item>

                                                    {/* Commenting the following code for WOI-204. June 6 2024.. Code Start */}
                                                    {/* <Nav.Item>
                                                        <Nav.Link eventKey="SectionizedRFx">Sectionized RFx</Nav.Link>
                                                    </Nav.Item> */}
                                                    {/* Ajay Krishna. June 6th 2024. Code End */}
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="Scope" className="mr-3">Scope</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="RequirementChecklist">Requirement Checklist</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="ProposalStru">Proposal Structure</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="SubmissionInst">Submission Instruction</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="EvaluationCriteria">Evaluation Criteria</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="Contract">Contract</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                            <Col sm={12} className="tab-style-2">
                                                <Tab.Content style={{ marginBottom: 40 }}>
                                                    <Tab.Pane eventKey="RFxData">
                                                        {/* Commenting the following code for WOII-73. Divya. September 01 2023.. Code Start */}
                                                        {/*<p className="font-medium">RFx Data</p>*/}
                                                        {/* Commenting end. Divya. September 01 2023.. */}
                                                        {/* Added this code for  WOII-73. Divya. September 01 2023.. Code Start */}
                                                        <p className="font-20 font-bold">RFx Data</p>
                                                        {/* Divya. September 01 2023. Code End */}
                                                        <RfxData
                                                            page={window.location.pathname === '/view-parse-rfx-detail' ? '/view-parse-rfx-detail' : null}
                                                            type={"AI"}
                                                            onGetAiId={(id) => getAiImageSegmentation(id)}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="SectionizedRFx" style={{ height: 1028 }}>
                                                        {
                                                            formFields.aiId ?
                                                                <SectionizedRFx
                                                                    page={window.location.pathname === '/view-parse-rfx-detail' ? '/view-parse-rfx-detail' : null}
                                                                    rfx_id={formFields.aiId}
                                                                    height={'1000'}
                                                                /> : null
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Scope" style={{ height: 1028 }}>
                                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                                        {/*<p className="font-medium">Scope</p>*/}
                                                        {/* Commenting end. Divya. September 07 2023.. */}
                                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                                        <p className="font-20 font-bold">Scope</p>
                                                        {/* Divya. September 07 2023. Code End */}
                                                        <div className="form-group">
                                                            {/* <textarea className="form-control h-auto" rows="37" disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false} onChange={handleChange} value={formFields['aiScope']} name="aiScope">
                                                            </textarea> */}
                                                            {/* <Editor
                                                                apiKey={tiny_key}
                                                                onInit={(evt, editor) => editorRef.current = editor}
                                                                initialValue={formFields.aiScope}
                                                                disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                init={{
                                                                    // Commenting the following code for WOII-105. Divya. October 13 2023.. Code Start
                                                                    //height: 500,
                                                                    //Commenting end. Divya. October 13 2023.. 
                                                                    //Added this code for  WOII-105. Divya. October 13 2023.. Code Start 
                                                                    height: 940,
                                                                    //Divya. October 13 2023. Code End
                                                                    menubar: 'file edit view insert format tools table tc help',
                                                                    selector: 'textarea#full-featured',
                                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists ',
                                                                    toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                }}
                                                            /> */}
                                                            <CKEditor
                                                                initData={formFields.aiScope}
                                                                readOnly={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                onInstanceReady={(event) => editorRef.current = event.editor}
                                                                name="ai_scope"
                                                                config={{
                                                                    height: 940,
                                                                    versionCheck: false,
                                                                    toolbar: [
                                                                    { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                    { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                    { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                <div className=" my-3 text-center">
                                                                    <button type="button" className="btn btn-brand-1" onClick={() => sendAiImageSegmentation()} disabled={formFields.aiImageSegemtationLoader}>
                                                                        {formFields.aiImageSegemtationLoader && (
                                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                        )}
                                                                        <span>Save</span>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="RequirementChecklist" style={{ height: 1028 }}>
                                                        <div className='d-flex justify-content-between'>
                                                            {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                                            {/*<p className="font-medium">Requirements Checklist </p>*/}
                                                            {/* Commenting end. Divya. September 07 2023.. */}
                                                            {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                                            <p className="font-20 font-bold">Requirements Checklist </p>
                                                            {/* Divya. September 07 2023. Code End */}
                                                            {window.location.pathname === '/view-parse-rfx-detail' ? null : <a onClick={() => setShowReferenceModal(true)} className="btn btn-brand-1 bg-success px-4 mb-2">+ Add</a>}
                                                        </div>

                                                        <table className="table table-style-1 table-responsive-lg mb-4 table ">
                                                            <thead>
                                                                <tr>
                                                                    <th>Requirement</th>
                                                                    <th>Reference in</th>
                                                                    <th>Page</th>
                                                                    {window.location.pathname === '/view-parse-rfx-detail' ? null : <th className="text-center" width="160">Action</th>}
                                                                </tr>

                                                                {
                                                                    formFields.aiRequirementChecklist && formFields.aiRequirementChecklist.map((res) => {
                                                                        return (
                                                                            <tr>
                                                                                <td title={res.requirement} style={{ maxWidth: "305px" }}>{res.requirement}</td>
                                                                                <td style={{ maxWidth: "305px" }}>{res.reference_in}</td>
                                                                                <td>{res.page}</td>
                                                                                {
                                                                                    window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                                        <td className="text-center" width="160">
                                                                                            <button type="button" className="btn btn-action edit " onClick={() => {
                                                                                                setShowReferenceModal(true)
                                                                                                formFields.requirement = res.requirement
                                                                                                formFields.requirementIn = res.reference_in
                                                                                                formFields.page = res.page
                                                                                                formFields.requirementId = res.id
                                                                                            }}><i className="icofont-pencil-alt-2" ></i></button>
                                                                                            <button type="button" className="btn btn-action delete ml-1" onClick={() => _onDelete(res.id)}><i className="icofont-archive"></i></button>
                                                                                        </td>
                                                                                }

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </thead>
                                                        </table>
                                                        {/* {
                                                            window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                <div className=" my-3 text-center">
                                                                    <button type="button" className="btn btn-brand-1">Save</button>
                                                                </div>
                                                        } */}
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="ProposalStru" style={{ height: 1028 }}>
                                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                                        {/*<p className="font-medium">Proposal Structure</p>*/}
                                                        {/* Commenting end. Divya. September 07 2023.. */}
                                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                                        <p className="font-20 font-bold">Proposal Structure</p>
                                                        {/* Divya. September 07 2023. Code End */}
                                                        <div className="form-group">
                                                            {/* <textarea className="form-control h-auto" rows="37" disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false} onChange={handleChange} value={formFields['aiProposalStructure']} name="aiProposalStructure">
                                                            </textarea> */}
                                                            {/* <Editor
                                                                apiKey={tiny_key}
                                                                onInit={(evt, editor) => propsalStructureRef.current = editor}
                                                                initialValue={formFields.aiProposalStructure}
                                                                disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                init={{
                                                                    // Commenting the following code for WOII-105. Divya. October 13 2023.. Code Start
                                                                    //height: 500,
                                                                    //Commenting end. Divya. October 13 2023.. 
                                                                    //Added this code for  WOII-105. Divya. October 13 2023.. Code Start 
                                                                    height: 940,
                                                                    //Divya. October 13 2023. Code End
                                                                    menubar: 'file edit view insert format tools table tc help',
                                                                    selector: 'textarea#full-featured',
                                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                    toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                }}
                                                            /> */}
                                                            <CKEditor
                                                                initData={formFields.aiProposalStructure}
                                                                readOnly={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                onInstanceReady={(event) => propsalStructureRef.current = event.editor}
                                                                name="ai_proposal_structure"
                                                                config={{
                                                                    height: 940,
                                                                    versionCheck: false,
                                                                    toolbar: [
                                                                    { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                    { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                    { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                <div className=" my-3 text-center">
                                                                    <button type="button" className="btn btn-brand-1" onClick={() => sendAiImageSegmentation()} disabled={formFields.aiImageSegemtationLoader}>
                                                                        {formFields.aiImageSegemtationLoader && (
                                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                        )}
                                                                        <span>Save</span>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="SubmissionInst" style={{ height: 1028 }}>
                                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                                        {/*<p className="font-medium">Submission Instructions</p>*/}
                                                        {/* Commenting end. Divya. September 07 2023.. */}
                                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                                        <p className="font-20 font-bold">Submission Instructions</p>
                                                        {/* Divya. September 07 2023. Code End */}
                                                        <div className="form-group">
                                                            {/* <textarea className="form-control h-auto" rows="37" disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false} onChange={handleChange} value={formFields['aiSubmissionIntruction']} name="aiSubmissionIntruction">
                                                            </textarea> */}
                                                            {/* <Editor
                                                                apiKey={tiny_key}
                                                                onInit={(evt, editor) => submissionInstructionRef.current = editor}
                                                                initialValue={formFields.aiSubmissionIntruction}
                                                                disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                init={{
                                                                    height: 500,
                                                                    menubar: 'file edit view insert format tools table tc help',
                                                                    selector: 'textarea#full-featured',
                                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                    toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                }}
                                                            /> */}
                                                            <CKEditor
                                                                initData={formFields.aiSubmissionIntruction}
                                                                readOnly={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                onInstanceReady={(event) => submissionInstructionRef.current = event.editor}
                                                                name="ai_submission_instruction"
                                                                config={{
                                                                    height: 940,
                                                                    versionCheck: false,
                                                                    toolbar: [
                                                                    { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                    { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                    { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                <div className=" my-3 text-center">
                                                                    <button type="button" className="btn btn-brand-1" onClick={() => sendAiImageSegmentation()} disabled={formFields.aiImageSegemtationLoader}>
                                                                        {formFields.aiImageSegemtationLoader && (
                                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                        )}
                                                                        <span>Save</span>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="EvaluationCriteria" style={{ height: 1028 }}>
                                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                                        {/*<p className="font-medium">Evaluation Criteria</p>*/}
                                                        {/* Commenting end. Divya. September 07 2023.. */}
                                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                                        <p className="font-20 font-bold">Evaluation Criteria</p>
                                                        {/* Divya. September 07 2023. Code End */}
                                                        <div className="form-group">
                                                            {/* <textarea className="form-control h-auto" rows="37" disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false} onChange={handleChange} value={formFields['aiEvaluationCriteria']} name="aiEvaluationCriteria">
                                                            </textarea> */}
                                                            {/* <Editor
                                                                apiKey={tiny_key}
                                                                onInit={(evt, editor) => evaluationCriteriaRef.current = editor}
                                                                initialValue={formFields.aiEvaluationCriteria}
                                                                disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                init={{
                                                                    // Commenting the following code for WOII-105. Divya. October 13 2023.. Code Start
                                                                    //height: 500,
                                                                    //Commenting end. Divya. October 13 2023.. 
                                                                    //Added this code for  WOII-105. Divya. October 13 2023.. Code Start 
                                                                    height: 940,
                                                                    //Divya. October 13 2023. Code End
                                                                    menubar: 'file edit view insert format tools table tc help',
                                                                    selector: 'textarea#full-featured',
                                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                    toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                }}
                                                            /> */}
                                                            <CKEditor
                                                                initData={formFields.aiEvaluationCriteria}
                                                                readOnly={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                onInstanceReady={(event) => evaluationCriteriaRef.current = event.editor}
                                                                name="ai_evaluation_criteria"
                                                                config={{
                                                                    height: 940,
                                                                    versionCheck: false,
                                                                    toolbar: [
                                                                    { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                    { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                    { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                <div className=" my-3 text-center">
                                                                    <button type="button" className="btn btn-brand-1" onClick={() => sendAiImageSegmentation()} disabled={formFields.aiImageSegemtationLoader}>
                                                                        {formFields.aiImageSegemtationLoader && (
                                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                        )}
                                                                        <span>Save</span>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Contract" style={{ height: 1028 }}>
                                                        {/* Commenting the following code for WOII-73. Divya. September 07 2023.. Code Start */}
                                                        {/*<p className="font-medium">Contract</p>*/}
                                                        {/* Commenting end. Divya. September 07 2023.. */}
                                                        {/* Added this code for  WOII-73. Divya. September 07 2023.. Code Start */}
                                                        <p className="font-20 font-bold">Contract</p>
                                                        {/* Divya. September 07 2023. Code End */}
                                                        <div className="form-group">
                                                            {/* <textarea className="form-control h-auto" rows="37" disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false} onChange={handleChange} value={formFields['aiContract']} name="aiContract">
                                                            </textarea> */}
                                                            {/* <Editor
                                                                apiKey={tiny_key}
                                                                onInit={(evt, editor) => contractRef.current = editor}
                                                                initialValue={formFields.aiContract}
                                                                disabled={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                init={{
                                                                    // Commenting the following code for WOII-105. Divya. October 13 2023.. Code Start 
                                                                    //height: 500,
                                                                    //Commenting end. Divya. October 13 2023.. 
                                                                    //Added this code for  WOII-105. Divya. October 13 2023.. Code Start 
                                                                    height: 940,
                                                                    //Divya. October 13 2023. Code End
                                                                    menubar: 'file edit view insert format tools table tc help',
                                                                    selector: 'textarea#full-featured',
                                                                    plugins: 'print preview importcss paste searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image  table lists',
                                                                    toolbar: 'undo redo | blocks| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange  | fullscreen  preview | image paste',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                                                }}
                                                            /> */}
                                                            <CKEditor
                                                                initData={formFields.aiContract}
                                                                readOnly={window.location.pathname === '/view-parse-rfx-detail' ? true : false}
                                                                onInstanceReady={(event) => contractRef.current = event.editor}
                                                                name="ai_contract"
                                                                config={{
                                                                    height: 940,
                                                                    versionCheck: false,
                                                                    toolbar: [
                                                                    { name: 'document', items: ['Source', 'Save', 'NewPage', 'Preview', 'Print', 'Templates'] },
                                                                    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo'] },
                                                                    { name: 'editing', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
                                                                    { name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
                                                                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'] },
                                                                    { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl', 'Language'] },
                                                                    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                                                                    { name: 'insert', items: ['Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe'] },
                                                                    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                                                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                                                                    { name: 'tools', items: ['Maximize', 'ShowBlocks', 'About'] }
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {/* <Tab.Container id="left-tab-example" defaultActiveKey="0">
                                                            <Row >
                                                                <Col md={4} style={{ height: 450, overflow: 'scroll', marginTop: 10, marginLeft: 20 }}>
                                                                    {
                                                                        contentData.length > 0 ? null :
                                                                            <div style={{ textAlign: 'end' }}>
                                                                                {
                                                                                    window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                                        <button type="button" className="btn btn-lg btn-brand-1 bg-success mb-3" onClick={() => setShow(!show)}>Add</button>
                                                                                }
                                                                            </div>
                                                                    }
                                                                    <Nav variant="pills" className="flex-column vertical-tabs">
                                                                        {
                                                                            contentData?.map((res, i) => {
                                                                                return (
                                                                                    <Nav.Item style={{ flexDirection: 'row' }}>
                                                                                        <Nav.Link eventKey={i}>{res.title}</Nav.Link>
                                                                                        <div style={{ flexDirection: 'row' }}>
                                                                                            <button type="button" className="btn btn-action edit mr-1" onClick={() => onEdit(res, i)} ><i className="icofont-pencil-alt-2"></i></button>
                                                                                            <button type="button" className="btn" style={{ padding: 0 }} onClick={() => _onAddTemplateContent()} ><i className="icofont-plus-square"></i></button>
                                                                                            <button type="button" className="btn" onClick={() => _onDeleteTemplateContent(res)} ><i className="icofont-minus-square"></i></button>
                                                                                        </div>
                                                                                    </Nav.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Nav>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <h4 className="page-title mb-2" style={{ marginTop: 10 }}>Description</h4>
                                                                    <Tab.Content className="bg-white py-4 px-lg-4 px-md-4 border rounded-theme mt-4 h-75 mb-4" >
                                                                        {
                                                                            contentData?.map((res, j) => {
                                                                                return (
                                                                                    <Tab.Pane eventKey={j}>
                                                                                        <div title={res.description}>{res.description && res.description.length > 840 ? `${res.description.substring(0, 840)}...` : res.description}</div>
                                                                                    </Tab.Pane>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tab.Content>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Container> */}
                                                        {
                                                            window.location.pathname === '/view-parse-rfx-detail' ? null :
                                                                <div className=" my-3 text-center">
                                                                    <button type="button" className="btn btn-brand-1" onClick={() => sendAiImageSegmentation()} disabled={formFields.aiImageSegemtationLoader}>
                                                                        {formFields.aiImageSegemtationLoader && (
                                                                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                                                                        )}
                                                                        <span>Save</span>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=" my-3 text-center">
                        <button type="button" className="btn btn-lg btn-brand-1 bg-danger mr-3" onClick={goBack}>Cancel</button>
                        <button type="button" className="btn btn-brand-1" onClick={() => _onDoneWithData('DONE_WITH_EDITING')} disabled={doneButton || location.state.page == 'View' || location.state.rfx_status_name == 'Ready (RDY)' || preRfxData.is_done_edit == true || checkEmptyString(preRfxData.due) || checkEmptyString(preRfxData.closing) ? true : false}>
                            {doneButton && (
                                <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                            )}
                            <span>Done With Data Edits</span>
                        </button>
                    </div>
                </div>
            </div>

            <ReactModal
                initWidth={550}
                initHeight={385}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleClose}
                isOpen={show}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editId ? 'Edit' : 'Add'} Contract</h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Heading</label>
                                <input type="text" className="form-control" onChange={handleChange} name="heading" value={formFields['heading']} />
                                <span className="text-danger">{flag === true && formFields.heading === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea type="text" rows="3" cols="30" className="form-control h-auto" onChange={handleChange} name="description" value={formFields['description']} />
                                <span className="text-danger">{flag === true && formFields.description === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleClose()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onsubmit()} disabled={buttonLoader}>
                        {buttonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                </div>
            </ReactModal>


            {/* REFERNCE MODAL */}
            <ReactModal
                initWidth={550}
                initHeight={'auto'}
                top={70.5}
                className={"contained-modal-title-vcenter"}
                onRequestClose={handleCloseRequirementModal}
                isOpen={showReferenceModal}
                disableResize={true}
                disableKeystroke={true}>
                <div className="modal-header">
                    <h5 className="modal-title" id="">{editId ? 'Edit' : 'Add'} Requirement Checklist</h5>
                    <button type="button" className="close" onClick={() => handleCloseRequirementModal()}>
                        <i className="icofont-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row form-style">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Requirement</label>
                                <textarea type="text" rows="3" cols="30" className="form-control h-auto" onChange={handleChange} name="requirement" value={formFields['requirement']} />
                                <span className="text-danger">{flag === true && formFields.requirement === '' ? 'Please Enter Description' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Refernce In</label>
                                <input type="text" className="form-control" onChange={handleChange} name="requirementIn" value={formFields['requirementIn']} />
                                <span className="text-danger">{flag === true && formFields.requirementIn === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Page</label>
                                <input type="text" className="form-control" onChange={handleChange} name="page" value={formFields['page']} />
                                <span className="text-danger">{flag === true && formFields.page === '' ? 'Please Enter Heading' : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-lg btn-brand-1 bg-danger" onClick={() => handleCloseRequirementModal()}>Cancel</button>
                    <button type="button" className="btn btn-lg btn-brand-1 bg-success" onClick={() => onAddRequirementChecklist()} disabled={requirementButtonLoader}>
                        {requirementButtonLoader && (
                            <img src={require('../../../../assets/css/images/button_loader.gif').default} alt="loading..." style={{ marginLeft: 5 }} />
                        )}Save</button>
                </div>
            </ReactModal>
            {
                showChatModal == false &&
                <div className='chat' onClick={() => setShowChatModal(!showChatModal)}>
                    {/* Commenting the following code for WOII-103. Divya. October 06 2023.. Code Start */}
                    {/*<img src={chatIcon} />*/}
                    {/* Commenting end. Divya. October 06 2023.. */}
                    {/* Added this code for  WOII-103. Divya. October 06 2023.. Code Start */}
                    <img src={chatIcon} className="chat-icon" />
                    {/* Commenting the following code for WOII-103. Divya. November 14 2023.. Code Start */}
                    {/*<span className='chat-tooltip'>Ask me any question related to the RFx.<br/>Example: What is the due date?</span>*/}
                    {/* Commenting end. Divya. November 14 2023.. */}
                    {/* Added this code for  WOII-103. Divya. November 14 2023.. Code Start */}
                    <span className='chat-tooltip'>Ask me any question related to the RFx.</span>
                    {/* Divya. November 14 2023. Code End */}
                    {/* Divya. October 06 2023. Code End */}
                </div>
            }
            {
                showChatModal &&
                <Chat
                    id={preRfxData.id}
                    title={preRfxData.title}
                    onClose={() => setShowChatModal(!showChatModal)} />
            }
        </>
    )
}
export default RfxParsedDetails;